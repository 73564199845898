import React, { FormEvent } from "react";
import { useNavigate } from "helpers/tixxt-router";
import Spectrum from "components/shared/Spectrum";
import {
  CreateCalendarParams,
  UpdateCalendarParams,
} from "components/appointments/calendars/api";
import MemberSelect, { Value } from "components/shared/MemberSelect";
import { isEmpty, map, trim } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { Membership } from "../../../@types";

const COLOR_POOL = [
  "CC2929",
  "CC8129",
  "C6CC29",
  "A8CC29",
  "6ACC29",
  "29CC53",
  "29CCB7",
  "299CCC",
  "2962CC",
  "7229CC",
  "CA29CC",
  "CC296A",
];

type FormData = {
  calendarName: string;
  calendarColor: string;
  calendarShare: Value[];
};

type EditableCalendar = {
  type?: string;
  parent_id?: string;
  id?: string;
  name?: string;
  memberships?: Membership[];
  color?: string;
};

type CalendarFormArgs = {
  calendar: EditableCalendar;
  onSubmit: (params: CreateCalendarParams | UpdateCalendarParams) => void;
};

export default function CalendarForm({ calendar, onSubmit }: CalendarFormArgs) {
  const navigate = useNavigate();

  const { handleSubmit, control } = useForm<FormData>();

  function submit({ calendarName, calendarColor, calendarShare }: FormData) {
    const values = {
      name: calendarName,
      color: trim(calendarColor, "#"),
      type: calendar.type,
      membership_ids: !isEmpty(calendarShare)
        ? map(calendarShare, "value")
        : undefined,
    };

    if (calendar.id) {
      onSubmit({
        calendar: { id: calendar.id, ...values },
      });
    } else {
      onSubmit({
        parent_id: calendar.parent_id,
        calendar: values,
      });
    }
  }

  function reset(e: FormEvent) {
    e.preventDefault();
    navigate(`/calendars/manage`);
  }

  return (
    <div>
      <div className="calendar-edit-view calendar-composer composer">
        <form
          className="form-horizontal"
          onSubmit={handleSubmit(submit)}
          onReset={reset}
        >
          <div className="control-group required string">
            <label className="control-label">
              {I18n.t("js.calendars.calendar.name")}
              <abbr className="ml-2" title={I18n.t("required")}>
                *
              </abbr>
            </label>
            <div className="controls">
              <Controller
                name="calendarName"
                control={control}
                defaultValue={calendar.name || ""}
                render={({ field }) => (
                  <input
                    type="text"
                    {...field}
                    placeholder={I18n.t("calendars.calendar.name")}
                  />
                )}
              />
            </div>
          </div>
          <div className="control-group">
            <label className="control-label">
              {I18n.t("calendars.calendar.color")}
            </label>
            <div className="controls">
              <Controller
                name="calendarColor"
                control={control}
                defaultValue={
                  calendar.color ||
                  COLOR_POOL[Math.floor(Math.random() * COLOR_POOL.length)]
                }
                render={({ field }) => (
                  <Spectrum {...field} colorPalette={COLOR_POOL} showPalette />
                )}
              />
            </div>
          </div>
          {calendar.type === "personal" && (
            <div className="control-group member_select">
              <label className="control-label">
                {I18n.t("calendars.calendar.shares")}
              </label>
              <div className="controls">
                <Controller
                  name="calendarShare"
                  control={control}
                  defaultValue={
                    map(calendar.memberships, (member) => ({
                      value: member.id,
                      label: member.name,
                      type: "member",
                    })) || []
                  }
                  render={({ field }) => <MemberSelect field={field} multi />}
                />
              </div>
            </div>
          )}
          <fieldset className="flex justify-end mt-2 gap-2">
            <button className="btn btn-light" type="reset">
              {I18n.t("cancel")}
            </button>
            <button className="btn btn-primary" type="submit">
              {I18n.t("save")}
            </button>
          </fieldset>
        </form>
      </div>
    </div>
  );
}
