import { all, call, cancel, take, fork, race, put } from "redux-saga/effects";
import delay from "@redux-saga/delay-p";
import { Socket } from "phoenix";
import { map } from "lodash";

import { createEventSaga } from "./helpers";
import channelsSaga from "./channels";
import notificationsSaga from "./notifications";
import applicationSaga from "./application";

// Create a new socket connection, i.e. after auth changed
function createSocket({ socketUrl, ...options }) {
  return new Socket(socketUrl, {
    reconnectAfterMs() {
      return 5000;
    },
    // logger: (kind, msg, data) => {
    //   if (!/push|receive/.exec(kind)) {
    //     console.log(`SOCKET ${kind}: ${msg}`, data);
    //   }
    // },
    ...options,
  });
}

// This saga turns the basic socket open, error and close events into dispatched actions
const socketEventSaga = createEventSaga({
  events: (dispatch) => ({
    onOpen: () => dispatch({ type: "chat/SOCKET/ON_OPEN" }),
    onError: () => dispatch({ type: "chat/SOCKET/ON_ERROR" }),
    onClose: () => dispatch({ type: "chat/SOCKET/ON_CLOSE" }),
  }),
  bind: (socket, handlers) => map(handlers, (fn, name) => socket[name](fn)),
});

// Allows caching of credentials while respecting their expires_in value
const credentialsCache = (function () {
  let payload = null;
  let validUntil = null;
  const SAFETY_MARGIN = 3 * 60; // 3 minutes

  return {
    get: () =>
      payload && validUntil - SAFETY_MARGIN > new Date() / 1000
        ? payload
        : null,
    set: (p) => {
      payload = p;
      // use expires_in if available, otherwise default to 3 hours
      const expiresIn = 3 * 60 * 60;
      validUntil = new Date() / 1000 + expiresIn;
    },
  };
})();

function* memoizedCredentials(fetchCredentials) {
  yield put({ type: "chat/FETCH_CREDENTIALS/START" });
  const cachedPayload = yield call([credentialsCache, "get"]);

  if (cachedPayload) {
    yield put({
      type: "chat/FETCH_CREDENTIALS/FROM_CACHE",
    });
    return cachedPayload;
  }

  try {
    const payload = yield call(fetchCredentials);
    yield call([credentialsCache, "set"], payload);
    yield put({ type: "chat/FETCH_CREDENTIALS/SUCCESS" });
    return payload;
  } catch (e) {
    yield put({ type: "chat/FETCH_CREDENTIALS/FAILURE" });
    throw e;
  }
}

// root saga for chat
function* rootSaga({ fetchCredentials, socketUrl }) {
  let windowUnloading = false;
  window.addEventListener("beforeunload", () => {
    windowUnloading = true;
  });

  yield all([fork(applicationSaga), fork(notificationsSaga)]);

  while (true) {
    const { token, network_id: networkId } = yield call(
      memoizedCredentials,
      fetchCredentials,
    );

    yield put({ type: "chat/CONNECT/START" });
    const socket = yield call(createSocket, { params: { token }, socketUrl });
    const socketEventTask = yield fork(socketEventSaga, socket);
    //window.socket = socket; // for dev/debugging purposes
    socket.connect();

    console.log("waiting for connection");
    const events = yield race({
      open: take("chat/SOCKET/ON_OPEN"),
      error: take("chat/SOCKET/ON_ERROR"),
      close: take("chat/SOCKET/ON_CLOSE"),
    });

    if (events.open) {
      yield put({ type: "chat/CONNECT/SUCCESS" });

      console.log(`Joining channels for network ${networkId} now!`);

      const channelsTask = yield fork(channelsSaga, socket, networkId);

      // Socket was disconnected after some time
      yield take("chat/SOCKET/ON_CLOSE");

      // Stop here if we are reloading/navigating away
      if (windowUnloading) {
        console.log("Stopping chat rootSaga");
        return;
      }

      console.log("Cancelling channelsTask");
      yield cancel(channelsTask);
    }

    // This will stop all reconnect attempts
    socket.disconnect();

    console.log("Cancelling socketEventTask");
    yield cancel(socketEventTask);

    // Socket failed on first connect, delay reconnect
    if (events.error || events.close) {
      const retryIn = 5000 + Math.random() * 5000;
      yield put({ type: "chat/CONNECT/ERROR", retryIn });
      yield delay(retryIn);
    }
  }
}

export default rootSaga;
