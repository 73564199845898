import React from "react";
import { useSelector } from "react-redux";
import { filter, includes } from "lodash";
import Select from "react-select";
import { getNetworkProfileFields } from "helpers/selectors/networkSelectors";
import { getCalendars } from "helpers/selectors/widgetbarSelectors";
import { getOptions, scopeSelection } from "helpers/widgets/widgets";
import styles from "./MultiselectField.module.css";
import useTooltip from "hooks/useTooltip";

interface MultiselectFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: Array<string>;
  type?: string;
}

export default function MultiselectField({
  name,
  onChange,
  value: rawValue,
  type,
}: MultiselectFieldTypes) {
  const rawOptions = {
    profile_fields: useSelector(getNetworkProfileFields),
    additional_calendar_ids: useSelector(getCalendars),
  };
  const options = getOptions(name, { ...rawOptions, scope: scopeSelection() });
  const value = filter(options, (option) => includes(rawValue, option.value));

  let labelProps = {};

  const tooltipRef = useTooltip<HTMLDivElement>();

  if (type === "appointments" && name === "scope") {
    labelProps = {
      ["data-bs-toggle"]: "tooltip",
      title: I18n.t(`js.administration.widgetbars.${name}.hint`),
    };
  }

  return (
    <div ref={tooltipRef} className={styles.ModuleselectField}>
      <label htmlFor={`widget-${name}-input`} {...labelProps}>
        {I18n.t(`js.administration.widgetbars.${name}.label`)}
      </label>
      <Select
        isMulti
        value={value}
        options={options}
        onChange={onChange}
        placeholder={I18n.t(
          `js.administration.widgetbars.multiselect.placeholder.${name}`,
        )}
        id={`widget-${name}-input`}
      />
    </div>
  );
}
