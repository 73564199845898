import { convertRoutes } from "components/tixxt/BackboneFeature";
import { RouteObject } from "helpers/tixxt-router";

const tasksRoutes: RouteObject[] = convertRoutes({
  feature: "Tasks",
  featureRoutes: {
    "groups/:group_slug/tasks": "index",
    "(groups/:group_slug/)tasks": "my",
    "(groups/:group_slug/)tasks/my": "my",
    "(groups/:group_slug/)tasks/assigned": "assigned",
    "(groups/:group_slug/)tasks/archive": "archive",
    "(groups/:group_slug/)tasks/starred": "starred",
    "tasks/notes": "notes",
    "tasks/declined": "declined",
    "tasks/:id": "showTask",
    "tasks/:id/edit": "editTask",
  },
});

export default tasksRoutes;
