import React from "react";
import PropTypes from "prop-types";

const LOOKUP = {
  archive: ["fa fa-caret-left", "fa fa-bars"],
  filter: ["fa fa-times", "fa fa-filter"],
};

function HeaderButtons({ url, type }) {
  return (
    <div className="btn-toolbar">
      <div className="btn-group">
        <a className="btn" href={`${url}/items`}>
          <i className={LOOKUP[type][0]} />
          <i className={LOOKUP[type][1]} />
        </a>
      </div>
    </div>
  );
}
HeaderButtons.propTypes = {
  url: PropTypes.string,
  type: PropTypes.string,
};

export default HeaderButtons;
