import React from "react";
import PropTypes from "prop-types";
import { useMutation } from "react-query";
import { archiveGroup, deleteGroup } from "./api";

function EditGroupActions({ archived, can, slug, name }) {
  const { mutate: onDeleteGroup } = useMutation(deleteGroup, {
    onSuccess: ({ redirect }) => {
      toastr.success(I18n.t("groups.destroy.success", { group: name }));
      // eslint-disable-next-line no-undef
      Turbo.visit(redirect, {
        action: "replace",
        target: document.documentElement,
        historyChanged: true,
      });
    },
  });

  const { mutate: onArchiveGroup } = useMutation(archiveGroup, {
    onSuccess: ({ archived, redirect }) => {
      toastr.success(
        I18n.t(
          archived
            ? "js.groups.group.successfully_archived"
            : "js.groups.group.successfully_unarchived",
          { group_name: name },
        ),
      );
      // eslint-disable-next-line no-undef
      Turbo.visit(redirect, {
        action: "replace",
        target: document.documentElement,
        historyChanged: true,
      });
    },
  });

  return (
    <div className={"alert alert-danger flex gap-4"}>
      <div>
        <i className="fa fa-5x fa-archive" />
      </div>
      <div className={"grow space-y-2"}>
        <h3 className={"text-lg font-semibold"}>
          {I18n.t(
            archived
              ? "js.groups.edit.is_archived"
              : "js.groups.edit.do_archive",
          )}
        </h3>
        <p>
          {I18n.t(
            archived
              ? "js.groups.edit.help_do_unarchive"
              : "js.groups.edit.help_do_archive",
          )}
        </p>
        <div className={"flex gap-2"}>
          <a
            className={"btn btn-light"}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              window.bridge.confirm(
                I18n.t(
                  archived
                    ? "js.groups.edit.confirm_unarchive"
                    : "js.groups.edit.confirm_archive",
                ),
                () => onArchiveGroup({ slug, archived }),
              );
            }}
          >
            {I18n.t(
              archived ? "js.groups.edit.unarchive" : "js.groups.edit.archive",
            )}
          </a>
          {can.destroy ? (
            <a
              className="btn btn-danger"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.bridge.confirm(
                  I18n.t("js.groups.edit.confirm_delete"),
                  () => onDeleteGroup({ slug }),
                );
              }}
            >
              {I18n.t("js.groups.edit.delete")}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
}

EditGroupActions.propTypes = {
  archived: PropTypes.bool,
  can: PropTypes.shape({
    archive: PropTypes.bool,
    destroy: PropTypes.bool,
  }),
  slug: PropTypes.string,
};

export default EditGroupActions;
