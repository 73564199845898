import React from "react";
import Modal from "./Modal";

interface RecurringAppointmentDecisionModalProps {
  showModal: boolean;
  onClose: (show: boolean) => void;
  handleDecision: (decision: "this" | "all") => void;
}

const RecurringAppointmentDecisionModal: React.FC<
  RecurringAppointmentDecisionModalProps
> = ({ showModal, onClose, handleDecision }) => {
  return (
    showModal && (
      <Modal
        static
        title={I18n.t("js.calendars.reucrring_appointment.rsvp.apply_to.label")}
        onClose={onClose}
        useMinHeightMax={true}
      >
        <div className="btn-group flex justify-center">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handleDecision("this")}
          >
            {I18n.t("js.calendars.reucrring_appointment.rsvp.apply_to.this")}
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={() => handleDecision("all")}
          >
            {I18n.t("js.calendars.reucrring_appointment.rsvp.apply_to.all")}
          </button>
        </div>
      </Modal>
    )
  );
};

export default RecurringAppointmentDecisionModal;
