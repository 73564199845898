import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import EditGroup from "./EditGroup";
import NewGroup from "./NewGroup";
import GroupInvites from "./Invites";

const groupRoutes: RouteObject[] = [
  { path: "/groups/:groupSlug/edit", element: <EditGroup />, forceTurbo: true }, // groups#edit runs profile initialization and must thus be called when accessing this route
  { path: "/groups/new/:groupCategorySlug", element: <NewGroup /> },
  { path: "/invites", element: <GroupInvites /> },
];

export default groupRoutes;
