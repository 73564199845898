import React, { useState } from "react";
import { map, filter } from "lodash";

import MemberSelect, { Value } from "../../shared/MemberSelect";
import { useRecurringAppointment } from "../../../hooks/useRecurringAppointment";
import { useAddParticipants } from "components/appointments/participants/api";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type NewParticipantFormArgs = {
  id: string;
  invite_consumers?: boolean;
  reloadAppointment: () => void;
};

type FormData = {
  participantsStatus: string;
  emailNotification: boolean;
  inviteConsumers: boolean;
  selectedEntities: Value[];
  applyTo?: boolean;
};

function NewParticipantForm({ reloadAppointment, id }: NewParticipantFormArgs) {
  const { register, handleSubmit, control, reset } = useForm<FormData>();
  const [reloading, setReloading] = useState(false);
  const isRecurring = useRecurringAppointment(id);
  const { mutate: addParticipants, isLoading } = useAddParticipants(id, {
    onSuccess: (_data, { participant }) => {
      reset();
      setReloading(true);
      window.setTimeout(reloadAppointment, 3000);
      window.setTimeout(reloadAppointment, 9000);
      toastr.success(
        I18n.t(
          participant.membership_ids.length === 0
            ? "js.calendars.appointment.participations.success_alert"
            : "js.calendars.appointment.participations.success_alert_with_mr",
          {
            member_count: participant.membership_ids.length,
            group_count: participant.group_ids.length,
            membership_role_count: participant.membership_role_ids.length,
          },
        ),
      );
      window.setTimeout(() => {
        reloadAppointment();
        setReloading(false);
      }, 18000);
    },
    onError: () => {
      toastr.error(I18n.t("js.saving_failure"));
    },
  });

  const addParticipantsHandler = ({
    memberIds,
    groupIds,
    membershipRoleIds,
    participantsStatus,
    emailNotification,
    inviteConsumers,
    applyTo,
  }: {
    memberIds: (string | undefined)[];
    groupIds: (string | undefined)[];
    membershipRoleIds: (string | undefined)[];
    participantsStatus: string;
    emailNotification: boolean;
    inviteConsumers: boolean;
    applyTo?: "this" | "all";
  }) => {
    const params = {
      participant: {
        membership_ids: memberIds,
        group_ids: groupIds,
        membership_role_ids: membershipRoleIds,
        status: participantsStatus,
        send_email_notification: emailNotification,
        invite_consumers: inviteConsumers,
      },
      apply_to: applyTo,
    };
    addParticipants(params);
  };

  return (
    <div className="border-box mb-4 p-3">
      <form
        className="form-horizontal"
        onSubmit={handleSubmit(onAddParticipants)}
      >
        <div className="control-group member-select">
          <label className="control-label">
            {I18n.t("js.calendars.appointment.participations.add")}
          </label>
          <div className="controls contacts-container member-select-control mt-2">
            <div className="invites-select-view mb-2">
              <Controller
                name="selectedEntities"
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <MemberSelect
                    field={field}
                    multi={true}
                    membersAndGroups
                    includeMembershipRoles
                  />
                )}
              />
            </div>
            <div className="flex flex-col">
              <select
                {...register("participantsStatus")}
                className="invites-status-view pull-left mb-2"
              >
                <option value="open">
                  {I18n.t("js.calendars.appointment.participant_status.open")}
                </option>
                <option value="accepted">
                  {I18n.t(
                    "js.calendars.appointment.participant_status.accepted",
                  )}
                </option>
                <option value="declined">
                  {I18n.t(
                    "js.calendars.appointment.participant_status.declined",
                  )}
                </option>
              </select>
              <label className="mail-notifications">
                <Controller
                  name="emailNotification"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <input
                      className="mail-notification-checkbox mr-2"
                      type="checkbox"
                      checked={field.value}
                      {...field}
                      value="true"
                    />
                  )}
                />
                <span className="mail-notifications-label">
                  {I18n.t(
                    "js.calendars.appointment.participations.send_notification",
                  )}
                </span>
              </label>
              {Tixxt.currentNetwork.getConfig("consumers_enabled") ? (
                <label className="invite-consumers">
                  <input
                    className="invite-consumers-checkbox mr-2"
                    {...register("inviteConsumers")}
                    type="checkbox"
                  />
                  <span className="invite-consumers-label">
                    {I18n.t(
                      "js.calendars.appointment.invites.invite_consumers",
                    )}
                  </span>
                </label>
              ) : null}
              {isRecurring ? (
                <label className="response-for">
                  <input
                    className="response-for-checkbox mr-2"
                    type="checkbox"
                    {...register("applyTo")}
                  />
                  <span className="response-for-label">
                    {I18n.t(
                      "js.calendars.recurring_appointment.invites.apply_to",
                    )}
                  </span>
                </label>
              ) : null}
            </div>
          </div>
        </div>
        <div className="new-participants-send-button flex flex-row justify-end items-center gap-2 mt-4">
          {isLoading || reloading ? (
            <FontAwesomeIcon
              icon={regular("spinner")}
              className="text-muted add-participants-loading-icon"
              spin
            />
          ) : null}
          <button
            type="submit"
            className="btn btn-success"
            disabled={isLoading}
          >
            {I18n.t("js.calendars.appointment.add_participant.send_title")}
          </button>
        </div>
      </form>
    </div>
  );

  function onAddParticipants({
    participantsStatus,
    emailNotification,
    selectedEntities,
    inviteConsumers,
    applyTo,
  }: FormData) {
    const memberIds = map(
      filter(selectedEntities, (entity) => entity.type === "member"),
      (m) => m.value,
    );

    const groupIds = map(
      filter(selectedEntities, (entity) => entity.type === "group"),
      (g) => g.value,
    );

    const membershipRoleIds = map(
      filter(selectedEntities, (entity) => entity.type === "membershipRole"),
      (mr) => mr.value,
    );

    addParticipantsHandler({
      memberIds,
      groupIds,
      membershipRoleIds,
      participantsStatus,
      emailNotification,
      inviteConsumers,
      applyTo: applyTo ? "this" : "all",
    });
  }
}

export default NewParticipantForm;
