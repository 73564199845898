import { useQuery } from "react-query";
import { State } from "../@types";
import queryClient from "helpers/queryClient";

if (Preload.current_member?.id) {
  queryClient.setQueryData(
    `/members/${Preload.current_member.id}`,
    () => Preload.current_member,
  );
}

export function useMembership(id: string | undefined) {
  return useQuery<State["currentMember"]>(`/members/${id}`, {
    staleTime: Infinity,
    enabled: !!id,
  });
}
