import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import GenericErrorBoundary from "components/shared/GenericErrorBoundary";
import { restoreVisibility, restoreStaticChat } from "layout/togglebar";

function createPortalComponent(
  rootId: string,
  callback?: (el: HTMLElement) => void,
) {
  return function ({ children }) {
    const elemRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
      function callCallback() {
        if (callback && elemRef.current) callback(elemRef.current);
      }

      document.addEventListener("turbo:render", callCallback);
      callCallback();

      return () => {
        document.removeEventListener("turbo:render", callCallback);
      };
    }, [callback, elemRef]);

    if (!elemRef.current) {
      elemRef.current = document.getElementById(rootId);
      while (elemRef.current?.firstChild)
        elemRef.current.removeChild(elemRef.current.firstChild);
    }

    if (!elemRef.current) return null;

    return createPortal(
      <GenericErrorBoundary>{children || null}</GenericErrorBoundary>,
      elemRef.current,
    );
  };
}

const HeaderPortal = createPortalComponent("header-root");
const UserbarPortal = createPortalComponent("userbar-root");
const ChatbarPortal = createPortalComponent("chatbar-root", (el) => {
  restoreVisibility("chatbar", el);
  restoreStaticChat();
});
const ContentPortal = createPortalComponent("spa-root");
const GroupNavigationPortal = createPortalComponent("group-navigation-root");
const PageTitlePortal = createPortalComponent("legacy-page-title-root");

export {
  HeaderPortal,
  UserbarPortal,
  ChatbarPortal,
  ContentPortal,
  GroupNavigationPortal,
  PageTitlePortal,
};
