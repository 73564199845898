import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export function useFetchDeviceSettings(deviceId: string, options = {}) {
  return useQuery(
    ["deviceSettings", deviceId],
    () => fetchApi(`/api/v1/devices/${deviceId}/settings`),
    options,
  );
}

export function updateDeviceSettings({ deviceId, ...body }) {
  return fetchApi(`/api/v1/devices/${deviceId}/settings`, {
    method: "POST",
    body,
  });
}
