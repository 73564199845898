import React, { useContext } from "react";
import RouteContext from "./RouteContext";
import PropTypes from "prop-types";
import { reject } from "lodash";
import { useMutation } from "react-query";
import { queryClient } from "helpers/queryClient";
import { deleteFactor } from "./api";

function TotpActions({ id, type }) {
  return (
    <>
      {id ? (
        <div className="flex flex-col gap-2">
          <ResetBackupCodesButton id={id} />
          <DeleteFactorButton
            id={id}
            directDelete={false}
            routeName="delete_totp"
            type={type}
          />
        </div>
      ) : (
        <AddFactorButton
          route="add_totp"
          translation="js.authentication.2fa.add_authenticator_app"
        />
      )}
    </>
  );
}

TotpActions.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

function EmailActions({ id, type }) {
  return (
    <>
      {id ? (
        <DeleteFactorButton id={id} directDelete={true} type={type} />
      ) : (
        <AddFactorButton
          route="add_email"
          translation="js.authentication.2fa.add_email"
        />
      )}
    </>
  );
}

EmailActions.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

const LOOKUP = {
  totp: TotpActions,
  email: EmailActions,
};

function FactorActions({ id, type }) {
  const Component = LOOKUP[type];

  if (!Component)
    return <div>{I18n.t("js.authentication.2fa.unkown_type")}</div>;

  return <Component id={id} type={type} />;
}

FactorActions.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
};

function DeleteFactorButton({ id, directDelete, routeName, type }) {
  const routeContext = useContext(RouteContext);
  const { mutate: submitDeleteFactor } = useMutation(deleteFactor, {
    onSuccess: () => {
      let updatedFactors = reject(queryClient.getQueryData("factors"), {
        type: type,
      });
      updatedFactors.push({
        id: null,
        type: type,
        last_used_at: null,
      });

      queryClient.setQueryData("factors", updatedFactors);

      window.toastr &&
        window.toastr.success(
          I18n.t("js.authentication.2fa.second_factor_deleted"),
        );
    },
  });

  return (
    <button
      className="btn btn-sm btn-danger"
      onClick={(e) => {
        e.preventDefault();

        if (directDelete) {
          submitDeleteFactor({
            id,
          });
        } else {
          routeContext.setRoute({ name: routeName, id });
        }
      }}
    >
      <i className="fa-regular fa-trash mr-2" aria-hidden="true" />
      {I18n.t("js.authentication.2fa.delete_factor_title")}
    </button>
  );
}

DeleteFactorButton.propTypes = {
  id: PropTypes.string,
  directDelete: PropTypes.bool,
  routeName: PropTypes.string,
  type: PropTypes.string,
};

export function AbortButton() {
  const routeContext = useContext(RouteContext);
  return (
    <button
      className="btn btn-light"
      onClick={(event) => {
        event.preventDefault();
        routeContext.setRoute({ name: "index" });
      }}
    >
      {I18n.t("js.cancel")}
    </button>
  );
}

export function BackButton() {
  const routeContext = useContext(RouteContext);
  return (
    <button
      className="btn btn-light"
      onClick={(event) => {
        event.preventDefault();
        routeContext.setRoute({ name: "index" });
      }}
    >
      {I18n.t("js.authentication.2fa.continue")}
    </button>
  );
}

export function AddFactorButton({ route, translation }) {
  const routeContext = useContext(RouteContext);
  return (
    <button
      className="btn btn-sm btn-light"
      onClick={(event) => {
        event.preventDefault();
        routeContext.setRoute({ name: route });
      }}
    >
      <i className="fa-regular fa-plus" /> {I18n.t(translation)}
    </button>
  );
}

function ResetBackupCodesButton({ id }) {
  const routeContext = useContext(RouteContext);
  return (
    <button
      className="btn btn-light btn-sm"
      onClick={(e) => {
        e.preventDefault();
        routeContext.setRoute({ name: "resetBackupCodes", id });
      }}
    >
      <i className="fa-regular fa-terminal mr-2" aria-hidden="true" />
      {I18n.t("js.authentication.2fa.reset_backup_codes")}
    </button>
  );
}

ResetBackupCodesButton.propTypes = {
  id: PropTypes.string,
};

export default FactorActions;
