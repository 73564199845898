import React, { useState } from "react";
import classnames from "classnames";

import PropertiesReference from "./PropertiesReference";
import ActivityIntegration from "./ActivityIntegration";
import EmailIntegration from "./EmailIntegration";

function IntegrationsEditor({
  properties,
  activity,
  emailIntegrations,
  onEmailIntegrationsChange,
}) {
  const [activeTab, setActiveTab] = useState("activity");

  return (
    <div className="tabbable tabs-right">
      <ul className="nav nav-tabs">
        <li className={classnames({ active: activeTab === "activity" })}>
          <a href="#" onClick={() => setActiveTab("activity")}>
            {I18n.t(
              "js.administration.app_creator.app.integrations.activity.title",
            )}
          </a>
        </li>
        <li className={classnames({ active: activeTab === "email" })}>
          <a href="#" onClick={() => setActiveTab("email")}>
            {I18n.t(
              "js.administration.app_creator.app.integrations.email_notifications.title",
            )}
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className={classnames({
            hidden: activeTab !== "activity",
          })}
        >
          <ActivityIntegration properties={properties} activity={activity} />
        </div>
        <div className={classnames({ hidden: activeTab !== "email" })}>
          <EmailIntegration
            properties={properties}
            emailIntegrations={emailIntegrations}
            onEmailIntegrationsChange={onEmailIntegrationsChange}
          />
        </div>
      </div>
    </div>
  );
}

IntegrationsEditor.propTypes = {
  properties: PropertiesReference.propTypes.properties,
  activity: ActivityIntegration.propTypes.activity,
  emailIntegrations: EmailIntegration.propTypes.emailIntegrations,
  onEmailIntegrationsChange:
    EmailIntegration.propTypes.onEmailIntegrationsChange,
};

export default IntegrationsEditor;
