import React, { useEffect } from "react";
import InfoUserbar from "components/info/Userbar";

export default function PagesUserbar() {
  useEffect(() => {
    document.body.classList.add("tixxt--network-page");
    return () => document.body.classList.remove("tixxt--network-page");
  }, []);

  return <InfoUserbar />;
}
