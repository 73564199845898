import React, { MutableRefObject, useContext, useEffect, useRef } from "react";
import { Theme } from "components/administration/designManager/types";
import { lightThemeToCss } from "components/administration/designManager/themeToCss";
import { compact, every, find, isEmpty, isEqual } from "lodash";
import { Group, GroupCategory, State } from "../../../@types";
import { useParams, useSearchParams } from "helpers/tixxt-router";
import invariant from "invariant";
import { useDispatch, useSelector } from "react-redux";
import {
  resetTheme as groupCategoryResetTheme,
  setTheme as groupCategorySetTheme,
} from "components/administration/designManagerLight/groupCategoryThemeSlice";

import {
  setTheme as groupSetTheme,
  resetTheme as groupResetTheme,
} from "components/administration/designManagerLight/groupThemeSlice";

const themeSliceActions = {
  group: {
    setTheme: groupSetTheme,
    resetTheme: groupResetTheme,
  },
  groupCategory: {
    setTheme: groupCategorySetTheme,
    resetTheme: groupCategoryResetTheme,
  },
};

export function applyStyleOverride(
  styleEl: React.MutableRefObject<HTMLStyleElement | undefined>,
  theme: Theme,
) {
  /* Create style tag to apply theme overrides */
  if (!styleEl.current) {
    styleEl.current = document.createElement("style");
    styleEl.current.type = "text/css";
    styleEl.current.setAttribute("data-theme-source", "override");
  }
  styleEl.current.textContent = lightThemeToCss({
    ...theme,
    override: true,
  } as Theme);
  if (!styleEl.current.isConnected) document.head.appendChild(styleEl.current);
}

export function removeStyleOverride(
  styleEl: React.MutableRefObject<HTMLStyleElement | undefined>,
) {
  if (styleEl.current && document.head.contains(styleEl.current))
    document.head.removeChild(styleEl.current);
}

export function appointmentIdToGroupSlugs(appointmentId): string[] {
  const appointment = Tixxt.Calendars.Appointment.all().get(appointmentId);

  const calendarParentIds =
    appointment
      ?.calendars()
      .filter({ attributes: { type: "group" } })
      .map((as) => as.get("parent_id"))
      .value() || [];

  return compact(
    calendarParentIds.map((ci) => find(Preload.my_groups, { id: ci })?.slug),
  );
}

export function fileIdToGroupSlugs(fileId): string[] {
  return Tixxt.Files.File.all().get(fileId)?.attributes?.group_slugs;
}

export function folderIdToGroupSlugs(folderId): string[] {
  const group = groupFromId(
    Tixxt.Files.Folder.all().get(folderId)?.attributes?.group_id,
  );

  return compact([group?.slug]);
}

export function taskIdToGroupSlugs(taskId): string[] {
  return compact([
    Tixxt.Tasks.controller?.currentCollection?.get(taskId)?.attributes?.context
      ?.first_stream_slug,
  ]);
}

export function groupFromId(id: string): Group | undefined {
  return find(Preload.my_groups, { id });
}

export function groupsFromSlugs(slugs: string[]): Group[] {
  return compact(slugs.map((slug) => find(Preload.my_groups, { slug })));
}

export function activityIdToGroupSlugs(activityId) {
  const activity = Tixxt.Activitystreams.Activity.all().get(activityId);
  const groupSlugs =
    activity
      ?.activitystreams()
      .map((as) => as.get("group_slug"))
      .value() || [];

  // returns empty array when the activity is also posted in a global activitystream
  // if (groupSlugs.includes(undefined)) return [];
  // TODO: Warum hatten wir das hier gemacht? Das sorgt dafür,
  // dass bei einem Beitrag, welcher auch in einem globalen Aktivitystream liegt,
  // das Gruppen-/ GruppenCategoryTheme nicht angezeigt wird

  return groupSlugs;
}

export function groupCategoriesFromGroups(groups: Group[]): GroupCategory[] {
  return compact(
    groups.map((group) =>
      find(Preload.current_network.group_categories, {
        id: group?.category_id,
      }),
    ),
  );
}

export function groupCategoriesFromSlugs(slugs: string[]): GroupCategory[] {
  return compact(
    slugs.map((slug) =>
      find(Preload.current_network.group_categories, { slug }),
    ),
  );
}

export function sameTheme(
  themeParents: GroupCategory[] | Group[] | undefined,
): boolean {
  if (!themeParents) return false;
  const isSameTheme = (themeParent) => {
    return themeParents[0].active_theme == themeParent.active_theme;
  };

  return every(themeParents, isSameTheme);
}

export type SlugsState = { slugs: string[]; previousSlug: string | null };
export const SlugsContext = React.createContext<
  React.Dispatch<React.SetStateAction<SlugsState>>
>(() => {
  void 0;
});

export function GroupsFromSlug() {
  const { contextSlug } = useParams();
  invariant(contextSlug, "GroupsFromSlug used in route without :contextSlug");

  const setSlugsState = useContext(SlugsContext);

  useEffect(() => {
    setSlugsState({ slugs: [contextSlug], previousSlug: contextSlug });
  }, [contextSlug]);

  return null;
}

export function GroupsFromActivityId() {
  const { activityId } = useParams();
  const setSlugsState = useContext(SlugsContext);

  useEffect(() => {
    const resolve = () =>
      setSlugsState((state) => ({
        ...state,
        slugs: activityIdToGroupSlugs(activityId),
      }));

    resolve();
    Tixxt.Activitystreams.Activity.all().on("sync", resolve);
    Tixxt.Activitystreams.Activitystream.all().on("sync", resolve);

    return () => {
      Tixxt.Activitystreams.Activity.all().off("sync", resolve);
      Tixxt.Activitystreams.Activitystream.all().off("sync", resolve);
    };
  }, [activityId]);

  return null;
}

export function GroupsFromAppointmentId() {
  const { appointmentId } = useParams();
  const setSlugsState = useContext(SlugsContext);

  useEffect(() => {
    const resolve = () => {
      setSlugsState((state) => ({
        ...state,
        slugs: appointmentIdToGroupSlugs(appointmentId),
      }));
    };

    resolve();
    Tixxt.Calendars.Appointment.all().on("sync", resolve);

    return () => {
      Tixxt.Calendars.Appointment.all().off("sync", resolve);
    };
  }, [appointmentId]);

  return null;
}

export function GroupsFromTaskId() {
  const { taskId } = useParams();
  const setSlugsState = useContext(SlugsContext);

  useEffect(() => {
    setSlugsState((state) => ({
      ...state,
      slugs: taskIdToGroupSlugs(taskId),
    }));
  }, [taskId]);

  return null;
}

export function GroupsFromFolderId() {
  const { paramsFolderId } = useParams();
  const [searchParams] = useSearchParams();
  const queryFolderId = searchParams.get("folder_id");

  const folderId = paramsFolderId || queryFolderId;
  const setSlugsState = useContext(SlugsContext);

  useEffect(() => {
    const resolve = () => {
      setSlugsState((state) => ({
        ...state,
        slugs: folderIdToGroupSlugs(folderId),
      }));
    };

    resolve();
    Tixxt.Files.Folder.all().on("sync", resolve);
    return () => {
      Tixxt.Files.Folder.all().off("sync", resolve);
    };
  }, [folderId]);

  return null;
}

export function GroupsFromFileId() {
  const { fileId } = useParams();
  const setSlugsState = useContext(SlugsContext);

  useEffect(() => {
    const resolve = () => {
      setSlugsState((state) => ({
        ...state,
        slugs: fileIdToGroupSlugs(fileId),
      }));
    };

    resolve();
    Tixxt.Files.File.all().on("sync", resolve);
    return () => {
      Tixxt.Files.File.all().off("sync", resolve);
    };
  }, [fileId]);

  return null;
}

export function NoSlugs() {
  const setSlugsState = useContext(SlugsContext);
  useEffect(() => {
    setSlugsState({ slugs: [], previousSlug: null });
  }, []);

  return null;
}

const selectors = {
  network: (state: State) => state.theme,
  groupCategory: (state: State) => state.groupCategoryTheme,
  group: (state: State) => state.groupTheme,
};

export const HandleContextHeader = React.memo(function HandleContextHeader({
  slugs,
  relevantContext,
  lastContext,
  type,
}: {
  slugs: string[];
  relevantContext: GroupCategory[] | Group[] | undefined;
  lastContext: MutableRefObject<Group | GroupCategory | undefined>;
  type: "group" | "groupCategory";
}) {
  const styleEl = useRef<HTMLStyleElement>();
  const dispatch = useDispatch();
  const activeTheme = useSelector(selectors[type]);

  // This sets state.groupTheme or state.groupCategoryTheme when slugs change
  useEffect(() => {
    if (!relevantContext) return;

    // the first element is used because the slug either comes from the path so only one is available,
    // from the last context so only one is available,
    // or from the activity/ appointment object so it is only interesting if all the `groupCategories` have the same Theme
    const context = relevantContext[0];
    const hasSameTheme = sameTheme(relevantContext);

    if (lastContext.current == context) return;
    lastContext.current = context;

    if (!context?.active_theme || !hasSameTheme) {
      if (!isEmpty(activeTheme)) dispatch(themeSliceActions[type].resetTheme());
    } else {
      dispatch(themeSliceActions[type].setTheme(context.active_theme));
    }
  }, [slugs]);

  // This applies styles from theme from state
  useEffect(() => {
    if (!isEmpty(activeTheme)) {
      applyStyleOverride(styleEl, activeTheme);
    } else {
      removeStyleOverride(styleEl);
    }
  }, [activeTheme]);

  return null;
}, isEqual);

export function Noop() {
  return null;
}
