import React from "react";

interface GenericErrorBoundaryTypes {
  children: React.ReactNode;
  headless?: boolean;
}

class GenericErrorBoundary extends React.Component<GenericErrorBoundaryTypes> {
  state: {
    hasError: boolean;
  };

  constructor(props: GenericErrorBoundaryTypes) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
    debug("Caught at ErrorBoundary", error, info);
  }

  render() {
    if (this.state.hasError && this.props.headless) return null;

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="alert alert-error">{I18n.t("js.generic_error")}</div>
      );
    }
    return this.props.children;
  }
}

export default GenericErrorBoundary;
