import React, { useContext } from "react";
import { useMutation } from "react-query";
import PropTypes from "prop-types";

import { deleteFactor } from "./api";
import RouteContext from "./RouteContext";
import { AbortButton } from "./FactorActions";

function DeleteFactorPage({ id }) {
  const routeContext = useContext(RouteContext);
  const { mutate: submitDeleteFactor, error: deleteError } = useMutation(
    deleteFactor,
    {
      onSuccess: () => {
        routeContext.setRoute({ name: "index" });
        window.toastr &&
          window.toastr.success(
            I18n.t("js.authentication.2fa.second_factor_deleted"),
          );
      },
    },
  );

  return (
    <>
      <p>{I18n.t("js.authentication.2fa.delete_factor_description")}</p>
      <form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();

          submitDeleteFactor({
            id,
            validationCode: e.target.code.value,
          });
        }}
      >
        <div className="control-group">
          <label className="control-label">
            {I18n.t("js.authentication.2fa.valid_code")}
          </label>
          <div className="controls">
            <input
              type="text"
              name="code"
              inputMode="numeric"
              autoComplete="off"
              placeholder={I18n.t("js.authentication.2fa.input_placeholder")}
              required
              autoFocus
            />
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <AbortButton />
          <button className="btn btn-primary" type="submit">
            {I18n.t("js.authentication.2fa.delete_factor")}
          </button>
        </div>
      </form>
      {deleteError ? (
        <div className="alert alert-danger">{deleteError.message}</div>
      ) : null}
    </>
  );
}

DeleteFactorPage.propTypes = {
  id: PropTypes.string,
};

export default DeleteFactorPage;
