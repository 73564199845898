import React from "react";
import { Provider } from "react-redux";
import store from "store";
import WidgetbarSelection from "components/administration/widgetbar/WidgetbarSelection";
import InfoText from "components/shared/InfoText";

function WidgetbarIndex() {
  return (
    <Provider store={store}>
      <div className="edit-widgetbar">
        <InfoText translation="js.administration.widgetbars.info_md" />
        <WidgetbarSelection />
      </div>
    </Provider>
  );
}

export default WidgetbarIndex;
