import React, { useRef, useState } from "react";
import { first, uniq } from "lodash";
import { useRoutes } from "helpers/tixxt-router";
import { GroupCategory } from "../../../@types";
import {
  groupsFromSlugs,
  groupCategoriesFromSlugs,
  groupCategoriesFromGroups,
  GroupsFromActivityId,
  GroupsFromFileId,
  GroupsFromSlug,
  GroupsFromTaskId,
  GroupsFromAppointmentId,
  NoSlugs,
  HandleContextHeader,
  GroupsFromFolderId,
  Noop,
  SlugsState,
  SlugsContext,
} from "components/administration/designManagerLight/functions";

function pickRelevantGroupCategories({
  slugs,
  previousSlug,
}): GroupCategory[] | undefined {
  if (previousSlug && slugs?.includes(previousSlug)) {
    const groupCategory = first(groupCategoriesFromSlugs([previousSlug]));
    if (groupCategory) return [groupCategory] as GroupCategory[];
  }

  if (!slugs) return undefined;

  const groups = groupsFromSlugs(slugs);
  return uniq([
    ...groupCategoriesFromGroups(groups),
    ...groupCategoriesFromSlugs(slugs),
  ]);
}

const routes = [
  {
    path: "/groups/:contextSlug/administration/designs/:designId/*",
    element: <Noop />,
  },
  {
    path: "/groups/:contextSlug/*",
    element: <GroupsFromSlug />,
  },
  {
    path: "/group-categories/:contextSlug/administration/designs/:designId/*",
    element: <Noop />,
  },
  {
    path: "/group-categories/:contextSlug/*",
    element: <GroupsFromSlug />,
  },
  {
    path: "/activities/:activityId",
    element: <GroupsFromActivityId />,
  },
  {
    path: "/appointments/:appointmentId",
    element: <GroupsFromAppointmentId />,
  },
  {
    path: "/appointments/:appointmentId/*",
    element: <GroupsFromAppointmentId />,
  },
  {
    path: "/files/folders/new",
    element: <GroupsFromFolderId />,
  },
  {
    path: "/files/folders/:folderId",
    element: <GroupsFromFolderId />,
  },
  {
    path: "/files/:fileId",
    element: <GroupsFromFileId />,
  },
  {
    path: "/tasks/:taskId",
    element: <GroupsFromTaskId />,
  },
  {
    path: "/tasks/:taskId/*",
    element: <GroupsFromTaskId />,
  },
  {
    path: "*",
    element: <NoSlugs />,
  },
];

export default function ApplyOverrideGroupCategoryTheme() {
  const [state, setState] = useState<SlugsState>({
    slugs: [],
    previousSlug: null,
  });
  const lastContext = useRef<GroupCategory>();
  const element = useRoutes(routes);

  return (
    <>
      <HandleContextHeader
        slugs={state.slugs}
        relevantContext={pickRelevantGroupCategories(state)}
        lastContext={lastContext}
        type={"groupCategory"}
      />
      <SlugsContext.Provider value={setState}>{element}</SlugsContext.Provider>
    </>
  );
}
