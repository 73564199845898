import WidgetbarEdit from "components/administration/WidgetbarEdit";
import WidgetbarIndex from "components/administration/WidgetbarIndex";
import LegalTextIndex from "components/administration/legalTexts/LegalTextIndex";
import ImprintEdit from "components/administration/imprints/ImprintEdit";
import MembershipRolesManager from "components/administration/MembershipRolesManager";

export default {
  "administration/MembershipRolesManager": MembershipRolesManager,
  "administration/WidgetbarEdit": WidgetbarEdit,
  "administration/WidgetbarIndex": WidgetbarIndex,
  "administration/legal_texts": LegalTextIndex,
  "administration/imprint/edit": ImprintEdit,
};
