import { Controller } from "@hotwired/stimulus";
import ClipboardJS from "clipboard/src/clipboard";

// Connects to data-controller="clipboard"
export default class extends Controller {
  connect() {
    this.clipboard = new ClipboardJS(this.element);
  }

  disconnect() {
    this.clipboard.destroy();
  }
}
