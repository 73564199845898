import React from "react";
import moment from "moment";

const DaySeparator = ({ date }) => (
  <section className="chat__seperator text-center border-b border-dashed border-neutral text-muted h-3 text-sm mb-5 mt-2">
    <span className={"px-2"}>{moment(date).format("DD.MM.YYYY")}</span>
  </section>
);

export default DaySeparator;
