import React, { RefObject } from "react";
import { Appointment, Calendar } from "../../../@types/appointments";
import { map } from "lodash";
import classNames from "classnames";
import moment from "moment";
import AppointmentItem from "components/appointments/appointmentsList/AppointmentItem";

type CalendarDayArgs = {
  appointments: Appointment[];
  day: string;
  calendars?: Calendar[];
  todayRef: RefObject<HTMLDivElement>;
};

export default function CalendarDay({
  appointments,
  day,
  calendars,
  todayRef,
}: CalendarDayArgs) {
  const today = moment();
  const date = moment(day);

  return (
    <div
      className={classNames(
        "cal-day grid grid-cols-[1fr] md:grid-cols-[100px,1fr] gap-2 p-1.5 md:p-2 border-t border-box-border-color -mt-px",
        {
          "past-month past-day": today.isAfter(date, "month"),
          "past-day": today.isAfter(date, "day"),
          "today bg-primary/10 border-primary/50 border-x rounded border-y -mx-px -mb-0.5":
            today.isSame(date, "day"),
          future: today.isBefore(date),
        },
      )}
    >
      <div className="date">
        {date.format(I18n.t("js.plugins.Locale.Formats.day_in_month"))}
      </div>
      <ul>
        {map(appointments, (appointment) => {
          return (
            <AppointmentItem
              key={appointment.id}
              appointment={appointment}
              date={date}
              calendars={calendars}
              todayRef={todayRef}
            />
          );
        })}
      </ul>
    </div>
  );
}
