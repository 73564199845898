import React from "react";
import styles from "../globalSearch.module.css";
import CalendarList from "components/appointments/CalendarList";
import classNames from "classnames";

function RecurringAppointmentHit({
  html_url,
  name,
  html_description,
  date,
  calendars,
  recurrence_pattern,
}) {
  return (
    <div className={`RecurringAppointmentHit border-box flex flex-col`}>
      <div className="flex items-center p-4 border-neutral border-dashed border-b">
        <div>
          <a href={html_url} className="font-semibold">
            {name}
          </a>
          <CalendarList calendars={calendars} className={styles.hitMeta} />
          <div className="flex gap-2 muted pt-3 text-sm">
            <i className="fa-regular fa-arrows-repeat" />
            <div className="flex flex-col">
              <div className="font-semibold uppercase">
                {I18n.t("js.calendars.recurring_appointment.subtitle")}
              </div>
              <div>
                {I18n.t("js.calendars.recurring_appointment.starts_at")}{" "}
                <time>{moment(date.start).format("dddd, LL")}</time>
              </div>
              <div>
                {recurrence_pattern.recurrence_end_time
                  ? I18n.t("js.calendars.recurring_appointment.ends_at") +
                    (
                      <time>
                        {moment(recurrence_pattern.recurrence_end_time).format(
                          "dddd, LL",
                        )}
                      </time>
                    )
                  : I18n.t("js.calendars.recurring_appointment.no_end")}
              </div>
              <div>
                {I18n.t("js.calendars.recurring_appointment.frequency")}{" "}
                {I18n.t(
                  "js.calendars.appointment.recurrence-pattern.frequency." +
                    recurrence_pattern.recurrence_frequency,
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {html_description ? (
        <div
          className="p-4 prose prose-sm"
          dangerouslySetInnerHTML={{ __html: html_description }}
        />
      ) : null}
      <div
        className={classNames({
          "border-neutral border-dashed border-t": html_description,
        })}
      >
        <a className="btn btn-link block" href={html_url}>
          {I18n.t("js.global_search.messages.show_recurring_appointment")}
        </a>
      </div>
    </div>
  );
}

export default RecurringAppointmentHit;
