import React, { useContext } from "react";
import { map, isEmpty } from "lodash";

import { FolderContentsTableWithProfile } from "../../files/table/FolderContentsTable";
import FolderRow from "../../files/table/FolderRow";
import styles from "../globalSearch.module.css";
import { MetaRow, FoundIn } from "./FileHits";
import UrlContextProvider from "../../files/UrlContextProvider";
import { parseSortByParam, serializeSortBy } from "../urls";
import SearchQueryPaginationContext from "../SearchQueryPaginationContext";

function FolderHit({ found_in, striped, ...rowProps }) {
  return (
    <>
      <FolderRow {...rowProps} className={styles.FolderHit} striped={striped} />
      <MetaRow
        extraColumns={rowProps.extraColumns}
        striped={striped}
        className={styles.FolderHitMeta}
      >
        {isEmpty(found_in) ? null : <FoundIn found_in={found_in} />}
      </MetaRow>
    </>
  );
}

export default function FolderHits({ hits, meta, groupSlug }) {
  const { sort_by, setPaginationState } = useContext(
    SearchQueryPaginationContext,
  );

  const contents = map(hits, (hit, index) => ({
    ...hit,
    type: "folder",
    meta: meta[index],
    profile: hit.folder_profile,
  }));

  return (
    <UrlContextProvider contextUrl={groupSlug ? `/groups/${groupSlug}` : ""}>
      <FolderContentsTableWithProfile
        contextType={groupSlug ? "group" : "network"}
        contextId={groupSlug}
        contents={contents}
        FolderRow={FolderHit}
        handleSort={(sortBy) => {
          setPaginationState({ page: 1, sort_by: serializeSortBy(sortBy) });
        }}
        sortBy={parseSortByParam(sort_by)}
        skipFileAttributes
      />
    </UrlContextProvider>
  );
}

FolderHits.propTypes = {};
