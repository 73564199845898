import { useDispatch, useSelector } from "react-redux";
import React, { useEffect } from "react";
import map from "lodash/map";
import { getWidgetbars } from "helpers/selectors/widgetbarSelectors";
import { fetchWidgetbars } from "actions/administration/widgetbar";
import { getWidgetName } from "helpers/widgets/widgets";

interface WidgetbarSelectionTypes {
  groupId?: string;
}

export default function WidgetbarSelection({
  groupId,
}: WidgetbarSelectionTypes) {
  const widgetbars = useSelector(getWidgetbars) as Array<{
    slug: string;
    widgets: Array<unknown>;
  }>;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWidgetbars({ groupId }));
  }, []);

  return (
    <table className="tixxt-table">
      <thead>
        <tr>
          <th>{I18n.t("js.administration.widgetbars.table.name")}</th>
          <th>{I18n.t("js.administration.widgetbars.table.widget_count")}</th>
        </tr>
      </thead>
      <tbody>
        {map(widgetbars, (widgetbar) => (
          <tr key={widgetbar.slug}>
            <td>
              <a
                href={`${
                  groupId ? `/groups/${groupId}` : ""
                }/administration/widgetbars/${widgetbar.slug}/edit`}
              >
                {getWidgetName(widgetbar.slug)}
              </a>
            </td>
            <td>{widgetbar.widgets.length}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
