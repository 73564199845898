export default (state = {}, action) => {
  switch (action.type) {
    case "chat/COMPOSER/SET_MESSAGE_TO_QUOTE":
      return {
        ...state,
        [action.chatId]: {
          ...state[action.chatId],
          messageToQuoteId: action.messageId,
        },
      };
    case "chat/COMPOSER/SET_TEXT":
      return {
        ...state,
        [action.chatId]: {
          ...state[action.chatId],
          text: action.text,
        },
      };
    case "chat/SEND_MESSAGE": {
      return {
        ...state,
        [action.meta.chatId]: {
          text: "",
          messageToQuoteId: "",
        },
      };
    }
    default:
      return state;
  }
};
