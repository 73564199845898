import React from "react";
import PropTypes from "prop-types";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import PropertySetEdit from "./PropertySetEdit";
import store from "../../store";
import queryClient from "../../helpers/queryClient";

export default function PropertySetEditWrapper({ propertySetName, groupSlug }) {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <PropertySetEdit
          propertySetName={propertySetName}
          groupSlug={groupSlug}
        />
      </QueryClientProvider>
    </Provider>
  );
}

PropertySetEditWrapper.propTypes = {
  propertySetName: PropTypes.string,
};
