import React from "react";
import { map } from "lodash";

import {
  NameWithProfileField,
  showNameWithProfileField,
} from "components/memberships/MemberNameWithAdditions";
import pickImage from "../../../helpers/pickImage";
import styles from "../globalSearch.module.css";

export default function MessageHit({
  id,
  author,
  subject,
  html_content,
  to,
  created_at,
}) {
  return (
    <div className={`MessageHit border-box`}>
      <div className="detailsAuthor flex items-center p-4 border-neutral border-dashed border-b">
        <a
          className="member-link mr-4"
          href={author.html_url}
          title={author.name}
        >
          <img
            className="w-10 h-10"
            src={pickImage(author.images, [80, 80])}
            alt={author.name}
          />
        </a>

        <div className="grow">
          <a href={author.html_url} className="font-semibold">
            <NameWithProfileField
              name={author.name}
              detectionProfileField={author.detection_profile_field}
              isExternal={author.is_external}
            />
          </a>
          <div className={"text-sm"}>
            <span key="to">{I18n.t("js.global_search.messages.to")} </span>
            {map(
              to,
              ({ id, name, detection_profile_field, is_external }, index) => (
                <React.Fragment key={index}>
                  {index > 0 ? <span key={`sep-${index}`}>, </span> : null}
                  <a key={id} href={`/members/${id}`}>
                    {showNameWithProfileField({
                      name: name,
                      detectionProfileField: detection_profile_field,
                      isExternal: is_external,
                    })}
                  </a>
                </React.Fragment>
              ),
            )}
          </div>
        </div>
        <div className={"text-sm"}>
          <span className={"text-muted"}>
            <time
              className="created"
              dateTime={created_at}
              title={moment(created_at).format("LLLL")}
            >
              {moment(created_at).format("DD.MM.YYYY HH:mm")}
            </time>
          </span>
        </div>
      </div>

      <div className="p-4 my-2">
        <div className="font-bold">{subject}</div>
        <div className={`activity-object ${styles.hitContent}`}>
          <div
            className="note-view markdown-content prose break-words"
            dangerouslySetInnerHTML={{
              __html:
                html_content.length > 0
                  ? $.truncate(html_content, { length: 600 })
                  : `<span class="text-muted">${I18n.t(
                      "js.global_search.no_content",
                    )}</span>`,
            }}
          />
        </div>
      </div>
      <div className="border-neutral border-dashed border-t">
        <a className="btn btn-link block" href={`/messages/${id}`}>
          {I18n.t("js.global_search.messages.show_message")}
        </a>
      </div>
    </div>
  );
}
