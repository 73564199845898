import { useQuery, useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export function useLegalTexts() {
  return useQuery("legalTexts", () => fetchApi("/administration/legal_texts"));
}

export function useLegalText(id) {
  return useQuery(["legalText", id], () =>
    fetchApi(`/administration/legal_texts/${id}`),
  );
}

export function useCreateLegalText(options) {
  return useMutation(
    (body) => fetchApi(`/administration/legal_texts`, { method: "POST", body }),
    options,
  );
}

export function useUpdateLegalText(options) {
  return useMutation(
    ({ id, ...body }) =>
      fetchApi(`/administration/legal_texts/${id}`, { method: "PUT", body }),
    options,
  );
}

export function useDeleteLegalText(options) {
  return useMutation(
    (id) => fetchApi(`/administration/legal_texts/${id}`, { method: "DELETE" }),
    options,
  );
}
