import React from "react";
import { createRoot } from "react-dom/client";
import { Controller } from "@hotwired/stimulus";
import CategoriesEditor from "../../components/administration/appointments/categories/CategoriesEditor";

// Connects to data-controller="administration--edit-categories"
export default class extends Controller {
  static values = { context: String, categories: Array };

  connect() {
    const root = createRoot(this.element);
    root.render(
      <CategoriesEditor
        context={this.hasContextValue ? this.contextValue : undefined}
        categories={this.categoriesValue}
      />,
    );
  }

  disconnect() {
    createRoot(this.element).unmount();
  }
}
