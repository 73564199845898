import React, { useEffect } from "react";
import {
  RouteObject,
  useParams,
  useRoutes,
  useSearchParams,
} from "helpers/tixxt-router";
import { at, defer, flatMap } from "lodash";
import invariant from "invariant";
import TixxtGroupNavigation from "components/layout/TixxtGroupNavigation";
import TixxtPageTitle from "components/layout/TixxtPageTitle";
import documentReady from "helpers/documentReady";
import { AppointmentPageTitle } from "components/appointments/AppointmentPageTitle";

type Props = {
  feature: string;
  action: string;
  paramKeys: string[];
};

function toggleBackbone(show: boolean) {
  document.getElementById("backbone-root")?.classList.toggle("hidden", !show);
}

export default function BackboneFeature({ feature, action, paramKeys }: Props) {
  const params = useParams();
  const actionArguments = at(params, paramKeys);

  const [searchParams] = useSearchParams();
  const query = {};
  searchParams.forEach((k, v) => (query[v] = k));

  useEffect(() => {
    toggleBackbone(true);
    return function () {
      toggleBackbone(false);
    };
  }, []);

  useEffect(() => {
    return function () {
      Tixxt.stopFeature();
    };
  }, [feature]);

  useEffect(() => {
    documentReady(() =>
      defer(() => {
        Tixxt.startFeature(feature, action, [...actionArguments, query]);
      }),
    );
  }, [feature, action, params, query]);

  const elem = useRoutes([
    { path: "/appointments/:appointmentId", element: <AppointmentPageTitle /> },
    { path: "/groups/:groupSlug/*", element: <TixxtPageTitle /> },
    { path: "*", element: <TixxtPageTitle /> },
  ]);

  return (
    <>
      {elem}
      <TixxtGroupNavigation />
    </>
  );
}

const paramRegex = /:(\w+)/g;

export function convertRoutes({ feature, featureRoutes }): RouteObject[] {
  function route(
    path: string,
    action: string,
    omittedParamKeys: string[] = [],
  ) {
    const paramKeys = Array.from(
      path.matchAll(paramRegex),
      ([, capture]) => capture,
    );
    return {
      path,
      element: (
        <BackboneFeature
          feature={feature}
          action={action}
          paramKeys={omittedParamKeys.concat(paramKeys)}
        />
      ),
    };
  }

  return flatMap(featureRoutes, (action, path) => {
    // path with optional group slug
    if (path.startsWith("(groups/:group_slug/)")) {
      const networkPath = path.replace("(groups/:group_slug/)", "");
      const groupPath = path.replace(
        "(groups/:group_slug/)",
        "/groups/:group_slug/",
      );
      return [
        route("/" + networkPath, action, ["group_slug"]),
        route(groupPath, action),
      ];
    }

    invariant(
      path.indexOf("(") == -1,
      `path for convertRoutes must not contain other optional segments, got "${path}"`,
    );

    // regular path
    return [route("/" + path, action)];
  });
}
