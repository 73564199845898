export default (state = {}, action) => {
  switch (action.type) {
    case "chat/INIT":
      return {
        networkUrl: action.payload.networkUrl,
      };
    case "chat/SOCKET/ON_OPEN":
      return {
        ...state,
        connected: true,
      };
    case "chat/SOCKET/ON_CLOSE":
    case "chat/SOCKET/ON_ERROR":
      return {
        ...state,
        connected: false,
      };
    case "chat/FETCH_CREDENTIALS/FAILURE":
      return {
        ...state,
        authorizationError: "fetch_credentials_failed",
      };
    default:
      return state;
  }
};
