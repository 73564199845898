import { useParams, useRoutes } from "helpers/tixxt-router";
import React from "react";
import { ContextPageTitle } from "components/layout/PageTitle";
import { useSelector } from "react-redux";
import { State } from "../../@types";
import store from "../../store";
import { setTitle } from "reducers/app";
import { PageTitlePortal } from "components/layout/portals";

/* This is used by Backbone to set a different title */
window.setTixxtPageTitle = function setTixxtPageTitle(title: string): void {
  document
    .getElementById("static-page-title")
    ?.classList.toggle("!hidden", !!title);
  if ((store.getState() as State).app.title !== title)
    store.dispatch(setTitle(title));
};

/* This is used together with the backbone marionette frontend */
export default function TixxtPageTitle() {
  const title = useSelector((state: State) => state.app.title);
  const params = useParams();
  const groupSlug = params.group_slug || params.groupSlug || params.group_id;

  if (!title && !groupSlug) return null;

  return <ContextPageTitle title={title} groupSlug={groupSlug} />;
}

/* This is used to render the group title on backend rendered pages */
export function StaticTixxtPageTitle() {
  const elem = useRoutes([
    { path: "/groups/:groupSlug", element: <TixxtPageTitle /> },
    { path: "/groups/:groupSlug/pages/:pageSlug", element: <TixxtPageTitle /> },
    { path: "/groups/:groupSlug/*", element: <TixxtPageTitle /> },
    { path: "/apps/*", element: <TixxtPageTitle /> },
  ]);

  if (!elem) return null;

  return <PageTitlePortal>{elem}</PageTitlePortal>;
}
