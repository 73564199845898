import { useNavigate } from "helpers/tixxt-router";
import { useSelector } from "react-redux";
import { useEffect } from "react";

// TODO move to store.tsx
type RootState = {
  network: {
    start_page?: string;
  };
};

export default function RedirectToStartpage() {
  const navigate = useNavigate();
  const startPage = useSelector((state: RootState) => state.network.start_page);

  useEffect(() => {
    if (startPage) navigate(startPage, { replace: true });
  }, []);

  return null;
}
