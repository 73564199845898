import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { get, map, isEmpty } from "lodash";
import classNames from "classnames";
import {
  getMemberBusyStatus,
  getMemberOnlineStatus,
  getSortedChats,
} from "../../../selectors/chat/chat";
import ChatHeader from "../shared/ChatHeader";
import Row from "./Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Textarea from "react-textarea-autosize";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { toggleChatbar } from "../../../layout/togglebar";
import { setChatStatus } from "../../../actions/chat";

const statusOption = {
  busy: "busy",
  online: "online",
  invisible: "invisible",
};

const useCurrentStatus = () => {
  const isOnline = useSelector((state) =>
    getMemberOnlineStatus(state, window.Preload.current_member.id),
  );

  const presence = useSelector((state) =>
    getMemberBusyStatus(state, window.Preload.current_member.id),
  );

  return (
    presence?.type || (isOnline ? statusOption.online : statusOption.invisible)
  );
};

const StartNewButton = connect(null, (dispatch) => ({
  navigateToNewChat: () =>
    dispatch({ type: "chat/NAVIGATE", payload: { route: "newChat" } }),
}))(({ disabled, navigateToNewChat }) => (
  <button
    className="btn btn-light btn-sm newChatButton"
    onClick={navigateToNewChat}
    disabled={disabled}
  >
    <FontAwesomeIcon
      icon={regular("comment")}
      className={"mr-1 text-primary"}
    />
    {I18n.t("js.chat.list.start_new")}
  </button>
));

function ChatStatusCircle({ status }) {
  switch (status) {
    case statusOption.online:
      return (
        <FontAwesomeIcon
          icon={solid("circle")}
          className={"chat__status--online"}
        />
      );

    case statusOption.busy:
      return (
        <FontAwesomeIcon
          icon={solid("circle")}
          className={"chat__status--busy"}
        />
      );
    case statusOption.invisible:
      return (
        <FontAwesomeIcon
          icon={solid("eye-slash")}
          className={"chat__status--invisible"}
        />
      );
  }
}

function StatusButton({ toggleStatusMessageForm }) {
  const status = useCurrentStatus();
  return (
    <button
      className={"btn btn-light btn-sm"}
      onClick={() => toggleStatusMessageForm(true)}
    >
      <ChatStatusCircle status={status} /> Status
    </button>
  );
}

function OnlineStatusDropdown({ selectedOption, setSelectedOption }) {
  const handleOptionChange = (e, option) => {
    e.preventDefault();
    setSelectedOption(option);
  };

  return (
    <>
      <div className="btn-group">
        <button
          type="button"
          className="btn border btn-light  dropdown-toggle flex items-center"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          role="button"
        >
          <ChatStatusCircle status={selectedOption} />
        </button>
        <ul className="dropdown-menu">
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => handleOptionChange(e, statusOption.online)}
            >
              <span className="flex items-center space-x-1">
                <FontAwesomeIcon
                  icon={"fa-circle"}
                  className={"chat__status--online"}
                />
                <span className="flex-1">
                  {I18n.t("js.chat.status_text.online.label")}
                </span>
              </span>
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={(e) => handleOptionChange(e, statusOption.busy)}
            >
              <span className="flex items-center space-x-1">
                <FontAwesomeIcon
                  icon={"fa-circle"}
                  className={"chat__status--busy"}
                />
                <span className="flex-1">
                  {I18n.t("js.chat.status_text.busy.label")}
                </span>
              </span>
            </a>
          </li>
          {window.Preload.current_network.config.show_online_status ==
            "optional" && (
            <li>
              <a
                className="dropdown-item"
                href="#"
                onClick={(e) => handleOptionChange(e, statusOption.invisible)}
              >
                <span className="flex items-center space-x-1">
                  <FontAwesomeIcon
                    icon={"fa-eye-slash"}
                    className={"chat__status--invisible"}
                  />
                  <span className="flex-1">
                    {I18n.t("js.chat.status_text.invisible.label")}
                  </span>
                </span>
              </a>
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

function StatusMessageForm({ toggleStatusMessageForm }) {
  const presence = useSelector((state) =>
    getMemberBusyStatus(state, window.Preload.current_member.id),
  );
  const initialRender = useRef(true);
  const [statusText, setStatusText] = useState(presence?.text || "");
  const currentStatus = useCurrentStatus();
  const [selectedOption, setSelectedOption] = useState(currentStatus);

  const dispatch = useDispatch();

  function submitStatus(status) {
    if (status.text) status.text = status.text.replace(/[\n\r]/g, " ");
    dispatch(setChatStatus({ payload: status }));
  }

  function removeStatusText() {
    setStatusText("");
    submitStatus({ text: "", type: selectedOption });
  }

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    if (selectedOption == statusOption.invisible) {
      submitStatus({ text: statusText, type: statusOption.invisible });
      toggleStatusMessageForm(false);
    }

    if (
      selectedOption === statusOption.busy &&
      (isEmpty(statusText) ||
        statusText === I18n.t("js.chat.status_text.online.default"))
    ) {
      setStatusText(I18n.t("js.chat.status_text.busy.default"));
    } else if (
      selectedOption === statusOption.online &&
      (isEmpty(statusText) ||
        statusText === I18n.t("js.chat.status_text.busy.default"))
    ) {
      setStatusText(I18n.t("js.chat.status_text.online.default"));
    }
  }, [selectedOption, setSelectedOption]);

  return (
    <div
      className={classNames("p-2 py-4 flex gap-2", {
        chat__header: !window.isApp,
        "sticky left-0 right-0 bg-white z-10 top-10": window.isApp,
      })}
    >
      <OnlineStatusDropdown
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />

      {selectedOption != "invisible" && (
        <>
          <Textarea
            maxLength="100"
            minRows={1}
            maxRows={2}
            value={statusText}
            autoFocus
            onChange={(e) => setStatusText(e.target.value)}
            className={
              "grow text-sm rounded-none border-0 border-r border-neutral resize-none"
            }
          />

          <div className="btn-group">
            <button
              className={"btn btn-light btn-sm"}
              onClick={() => {
                submitStatus({ text: statusText, type: selectedOption });
                toggleStatusMessageForm(false);
              }}
            >
              <FontAwesomeIcon
                icon="fa-regular fa-check"
                className="text-primary"
              />
            </button>
            <button
              className={"btn btn-light btn-sm"}
              onClick={() => {
                removeStatusText();
                toggleStatusMessageForm(false);
              }}
            >
              <FontAwesomeIcon
                icon="fa-regular fa-trash"
                className="text-danger"
              />
            </button>
          </div>
        </>
      )}
    </div>
  );
}

function CloseButton() {
  return (
    <button
      className={"btn btn-light btn-sm"}
      title={I18n.t("js.chat.close")}
      onClick={toggleChatbar}
    >
      <FontAwesomeIcon icon={regular("xmark")} />
    </button>
  );
}

const ChatList = ({ connected, chats }) => {
  const [showStatusMessageForm, toggleStatusMessageForm] = useState(false);

  return (
    <>
      <ChatHeader
        title={I18n.t("js.chat.list.headline")}
        ButtonLeft={!window.isApp && <CloseButton />}
        ButtonRight={
          <div className="btn-group">
            {window.Preload.current_network.config.show_online_status !=
              "disabled" && (
              <StatusButton toggleStatusMessageForm={toggleStatusMessageForm} />
            )}

            <StartNewButton disabled={!connected} />
          </div>
        }
      />
      {showStatusMessageForm && (
        <StatusMessageForm toggleStatusMessageForm={toggleStatusMessageForm} />
      )}
      <div
        className={classNames(
          "chat__list grow overflow-y-auto overflow-x-hidden w-full",
          {
            mobile_app_chat__list: window.isApp,
          },
        )}
      >
        {map(chats, (item, index) => (
          <Row key={index} {...item} />
        ))}
      </div>
    </>
  );
};
ChatList.propTypes = {
  chats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      image_id: PropTypes.string,
    }),
  ),
};

export default connect((state) => ({
  chats: getSortedChats(state),
  connected: get(state, ["chat", "session", "connected"]),
}))(ChatList);
