import { DragSource, DropTarget } from "react-dnd";
import React, { Component, createRef } from "react";
import flow from "lodash/flow";
import classNames from "classnames";

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect =
      component.hoverRef.current.getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    props.moveCard(dragIndex, hoverIndex);
    monitor.getItem().index = hoverIndex;
  },
};

interface CardTypes {
  connectDragSource(...args: unknown[]): unknown;
  connectDropTarget(...args: unknown[]): unknown;
  index: number;
  isDragging: boolean;
  id: string;
  text: string;
  customBackgroundColor?: string;
  moveCard(...args: unknown[]): unknown;
}

class Card extends Component<CardTypes> {
  hoverRef: React.RefObject<HTMLLIElement>;

  constructor(props: CardTypes) {
    super(props);
    this.hoverRef = createRef();
  }

  render() {
    const {
      text,
      isDragging,
      customBackgroundColor,
      connectDragSource,
      connectDropTarget,
    } = this.props;

    return connectDragSource(
      connectDropTarget(
        <li
          className={classNames(
            "group-category-sorter border-box p-3 cursor-pointer",
            {
              "drag-card": isDragging,
            },
          )}
          ref={this.hoverRef}
        >
          <i
            className="fa-regular fa-ellipsis-vertical fa-lg pr-3 text-neutral"
            aria-hidden="true"
          />
          <span
            style={{
              color: customBackgroundColor ? customBackgroundColor : undefined,
            }}
          >
            {text}
          </span>
        </li>,
      ),
    ) as React.ReactNode;
  }
}

export default flow([
  DragSource("card", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget("card", cardTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })),
])(Card);
