import { isEmpty } from "lodash";
import keyByFp from "lodash/fp/keyBy";
import flow from "lodash/fp/flow";
import getFp from "lodash/fp/get";
import { get, includes } from "lodash";
import { myStateReducer, partnerStateReducer } from "./chatStateReducers";
import { loadChatDetails } from "../../actions/chat";

// Pull chats value from payload and key it by id
const extractChats = flow(getFp("chats"), keyByFp("id"));

export const requestStateReducer = (state = {}, action) => {
  switch (action.type) {
    case loadChatDetails.type:
    case "chat/MORE_MESSAGES": {
      return {
        ...state,
        loadingMessages: true,
      };
    }
    case loadChatDetails.replyType:
    case "chat/MORE_MESSAGES/REPLY": {
      return {
        ...state,
        loadingMessages: false,
        moreMessagesAvailable: !isEmpty(action.payload.messages),
      };
    }
    default:
      return state;
  }
};

const defaultStateFromAction = (action) => ({
  id: get(action.payload, ["chat_id"]),
  name: get(action.payload, ["from", "name"]),
  image_id: get(action.payload, ["from", "image_id"]),
  state: myStateReducer(undefined, { type: "INIT" }),
  partner_state: partnerStateReducer(undefined, { type: "INIT" }),
  last_interacted_at: null,
});

export const chatReducer = (maybeUndefinedState, action) => {
  const state = maybeUndefinedState || defaultStateFromAction(action);

  switch (action.type) {
    case "chat/MESSAGE/RECEIVE": {
      return {
        ...state,
        last_interacted_at: action.payload.created_at,
        state: myStateReducer(state.state, action),
        last_message: action.payload,
      };
    }
    case "chat/MESSAGE/REPLY": {
      return {
        ...state,
        last_interacted_at: action.payload.created_at,
        last_message: action.payload,
      };
    }
    case "chat/MARK_AS_RECEIVED/RECEIVE":
    case "chat/MARK_AS_READ/RECEIVE":
    case "chat/MARK_AS_READ": {
      return {
        ...state,
        state: myStateReducer(state.state, action),
        partner_state: partnerStateReducer(state.partner_state, action),
      };
    }
    case "chat/MUTE_CHAT":
    case "chat/APP_MUTE_CHAT":
    case "chat/MUTE_CHAT/RECEIVE":
    case "chat/APP_MUTE_CHAT/RECEIVE":
    case "chat/UNMUTE_CHAT":
    case "chat/APP_UNMUTE_CHAT":
    case "chat/UNMUTE_CHAT/RECEIVE":
    case "chat/APP_UNMUTE_CHAT/RECEIVE": {
      return {
        ...state,
        state: myStateReducer(state.state, action),
      };
    }
    case loadChatDetails.type:
    case "chat/MORE_MESSAGES":
    case "chat/MORE_MESSAGES/REPLY":
      return {
        ...state,
        requestState: requestStateReducer(state.requestState, action),
      };
    case loadChatDetails.replyType:
      return {
        ...state,
        can: action.payload.can,
        requestState: requestStateReducer(state.requestState, action),
      };
    default:
      return state;
  }
};

const getChatIdFromPayload = getFp(["payload", "chat_id"]);
const getChatIdFromMeta = getFp(["meta", "chatId"]);
const getChatId = (action) =>
  getChatIdFromPayload(action) || getChatIdFromMeta(action);

// Workaround for composing starts a new chat for the counterpart
const BLACKLIST = ["chat/COMPOSING/RECEIVE", "chat/COMPOSING/STOPPED"];

export default (state = {}, action) => {
  switch (action.type) {
    // Full list of chats received, store them keyed by id
    case "chat/MY_CHATS/REPLY":
      return extractChats(action.payload);
    case "chat/CREATE_CHAT/REPLY":
    case "chat/CREATE_CHAT/RECEIVE":
      return { ...state, [action.payload.id]: action.payload };
    default: {
      const chatId = getChatId(action);
      if (!chatId || includes(BLACKLIST, action.type)) {
        return state;
      }
      return {
        ...state,
        [chatId]: chatReducer(state[chatId], action),
      };
    }
  }
};
