import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import EditMembership from "./EditMembership";
import NewChangeRequest from "./NewChangeRequest";
import EditMembershipSettings from "./EditMembershipSettings";
import BackboneFeature from "components/tixxt/BackboneFeature";

const membershipRoutes: RouteObject[] = [
  {
    path: "/members/:membershipId/edit",
    element: <EditMembership />,
    forceTurbo: true,
  },
  {
    path: "/members/:membershipId/change_requests/new", // deprecated
    element: <NewChangeRequest />,
  },
  {
    path: "/change_requests/new",
    element: <NewChangeRequest />,
  },
  {
    path: "/members/:membershipId/settings/edit",
    element: <EditMembershipSettings />,
  },
  {
    path: "/members/:id/activities",
    element: (
      <BackboneFeature
        feature="Memberships"
        action="showActivities"
        paramKeys={["id"]}
      />
    ),
  },
  {
    path: "/members/:id/likes",
    element: (
      <BackboneFeature
        feature="Memberships"
        action="showLikedActivities"
        paramKeys={["id"]}
      />
    ),
  },
];

export default membershipRoutes;
