import React, { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import ChatImage from "./ChatImage";
import classNames from "classnames";
import { get } from "lodash";

type ChatHeaderArgs = {
  chatId: string;
  title: string;
  subtitle: string;
  ButtonLeft: ReactElement;
  ButtonRight: ReactElement;
};

export default function ChatHeader({
  chatId,
  title,
  subtitle,
  ButtonLeft,
  ButtonRight,
}: ChatHeaderArgs) {
  const chatStatus = useSelector((state) =>
    get(state, ["chat", "chats", chatId, "status"]),
  );

  useEffect(() => {
    if (window.isApp) {
      // hide navigation in chat
      const navigation = document.getElementById("main-navigation");

      if (navigation) {
        navigation.classList.remove("tixxt__navigation"); // remove styles otherwise hidden is overwritten
        navigation.hidden = true;
      }
    }
  }, []);

  return (
    <div
      className={classNames(
        "px-3 flex items-center border-b border-neutral gap-3 shrink-0 h-topbar",
        {
          chat__header: !window.isApp,
          "fixed left-0 right-0 bg-white border-none z-10": window.isApp,
        },
      )}
    >
      {ButtonLeft ? ButtonLeft : null}
      {chatId ? <ChatImage chatId={chatId} name={title} /> : null}
      <div
        className="chat__title grow flex flex-col overflow-hidden"
        title={title}
      >
        <span className="font-semibold truncate text-ellipsis text-xl">
          {title}
        </span>

        {subtitle ? (
          <>
            <span className="subtitle leading-none">{subtitle}</span>
          </>
        ) : chatStatus?.text ? (
          <span
            className="subtitle leading-none truncate"
            title={chatStatus?.text}
          >
            {chatStatus?.text}
          </span>
        ) : null}
      </div>

      {ButtonRight ? ButtonRight : null}
    </div>
  );
}
