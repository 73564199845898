import { fetchApi } from "../helpers/reactQueryApi.js";
import store from "../store.js";
import { DeviceData } from "../@types/devices";
import {
  disableBodyScrolling,
  restoreBodyScrolling,
} from "../layout/togglebar";

export default class Bridge {
  static isIOS() {
    return /iOS/.test(navigator.userAgent);
  }

  static isAndroid() {
    return /Android/.test(navigator.userAgent);
  }

  static postMessage(message: object) {
    if (Bridge.isIOS()) {
      window.webkit?.messageHandlers.nativeApp.postMessage(message);
    }

    if (Bridge.isAndroid()) {
      window["nativeApp"]?.postMessage(JSON.stringify(message));
    }
  }

  static toggleUserbar() {
    const userbar = document.getElementById("userbar-root");

    if (userbar?.classList.contains("userbar--visible")) {
      userbar?.classList.remove("userbar--visible");
      restoreBodyScrolling("userbar");
    } else {
      userbar?.classList.add("userbar--visible");
      disableBodyScrolling("userbar");
    }
  }

  static toggleChat() {
    const chat = document.getElementById("chatbar-root");

    if (chat?.classList.contains("chatbar--visible")) {
      chat?.classList.remove("chatbar--visible");
    } else {
      chat?.classList.add("chatbar--visible");
    }
  }

  static toggleSearchbar() {
    const searchbar = document.getElementById("app__header");
    const searchInput: HTMLInputElement = document.getElementsByClassName(
      "search-bar__input",
    )[0] as HTMLInputElement;

    if (searchbar?.classList.contains("header--visible")) {
      searchbar?.classList.remove("header--visible");
      searchInput.blur();
    } else {
      searchbar?.classList.add("header--visible");
      searchInput?.focus();
    }
  }

  static registerDevice(data: DeviceData) {
    fetchApi("/api/v1/devices", {
      method: "POST",
      body: data,
    });
  }

  static async removeDevice(deviceUniqId: string) {
    const result = await fetchApi(`/api/v1/devices/${deviceUniqId}`, {
      method: "DELETE",
    });

    Bridge.postMessage({ deletedDevice: result });
  }

  static showChat(id: string) {
    store.dispatch({
      type: "chat/NAVIGATE",
      payload: { route: "showChat", params: { chatId: id } },
    });
  }

  static async loadNetworkConfig() {
    const network = await fetchApi("/api/v1/network");

    const pages = await fetchApi("/pages");

    Bridge.postMessage({
      setNetworkConfig: network,
      setPages: pages,
    });
  }

  static async loadUnreads() {
    const unreads = await fetchApi("/api/v1/unreads");

    Bridge.postMessage({ unreads });
  }

  static getDeviceUniqueId(
    setDeviceUniqueId: (props: { id: string; name: string }) => void,
  ) {
    Bridge.postMessage({
      getDeviceUniqueId: "setDeviceUniqueId",
    });

    Bridge["setDeviceUniqueId"] = setDeviceUniqueId;
  }

  static setPageTitle(title: string) {
    Bridge.postMessage({
      pageTitle: title,
    });
  }

  static goBack() {
    Bridge.postMessage({
      goBack: "goBack",
    });
  }

  static setMemberIsLoggedIn(isLoggedIn: boolean) {
    Bridge.postMessage({
      isLoggedIn,
    });
  }

  static setMemberId(membershipId: string) {
    Bridge.postMessage({
      membershipId,
    });
  }

  static setMemberCanChat(canChat: boolean) {
    Bridge.postMessage({
      canChat,
    });
  }

  static configureLayout() {
    document.body.classList.add("tixxt--in-app");

    const pageTitle = document.getElementById("static-page-title");

    if (pageTitle?.textContent) {
      Bridge.setPageTitle(pageTitle?.textContent);
    }

    const title = document.querySelector("title");
    if (title) {
      title?.parentNode?.removeChild(title);
    }
  }

  static async getAuthToken(key: string, options: { url: string }) {
    const token = await fetchApi("/api/v1/get-web-token");

    Bridge.postMessage({
      [key]: { authToken: token, options },
    });
  }

  static alert(
    title: string,
    message: string,
    callbackName: string,
    callback: () => void,
    defaultValue?: string,
  ) {
    Bridge.postMessage({
      alert: { title, message, defaultValue, callbackName },
    });

    if (callback) {
      Bridge[callbackName] = callback;
    }
  }

  static prompt(message: string, callback: () => void, defaultValue?: string) {
    Bridge.alert("", message, "promptCallback", callback, defaultValue);
  }

  static confirm(message: string, callback: () => void) {
    Bridge.alert(message, "", "confirmCallback", callback, undefined);
  }
}
