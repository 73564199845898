import React from "react";
import map from "lodash/map";
import { propTypeSelections } from "helpers/widgets/widgets";
interface RadioFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: string;
  hint?: string;
}

function RadioField({ name, onChange, value }: RadioFieldTypes) {
  return (
    <div>
      <label>{I18n.t(`js.administration.widgetbars.${name}.label`)}</label>
      {map(propTypeSelections[name], (selection, index) => (
        <label key={index} className="radio">
          <input
            value={selection}
            type="radio"
            onChange={onChange}
            checked={selection === value}
          />
          {I18n.t(`js.administration.widgetbars.selections.${selection}`)}
        </label>
      ))}
    </div>
  );
}

export default RadioField;
