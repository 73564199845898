import { isEmpty, get, last, first, find, findLast } from "lodash";
import { createSelector } from "reselect";

import { getLastReadIdForChat } from "./chat";
import { getMyId } from "./profile";

export const getMessagesForChat = (state, { chatId }) =>
  get(state, ["chat", "messages", chatId]);

export const getMessageById = (state, { id, chatId }) =>
  find(getMessagesForChat(state, { chatId }), (m) => m.id === id);

export const getLastUnreadMessageInChat = createSelector(
  [getMessagesForChat, getLastReadIdForChat, getMyId],
  (messages, lastReadId, myId) =>
    findLast(messages, (m) => m.from.id !== myId && m.id > lastReadId),
);

export const getLastMessageIdInChat = createSelector(
  getMessagesForChat,
  (messages) => (isEmpty(messages) ? null : last(messages).id),
);

export const getFirstMessageIdInChat = createSelector(
  getMessagesForChat,
  (messages) => (isEmpty(messages) ? null : first(messages).id),
);

export const getChatPartnerState = (state, { chatId }) =>
  get(state, ["chat", "chats", chatId, "partner_state"]);

// Message delivery states in order of occurrence
//
// pushed:
//   my messages only
//   pushed via channel but no successful reply from server yet
//
// persisted:
//   my messages only
//   got successful reply after push, so it is persisted on the server
//   but not yet delivered to chat partner
//   or if it is in group chat then this is the final status
//
// received:
//   also means unread
//   messages has been received by ourselves or the chat partner
//
// read:
//   message has been read by ourselves or the chat partner

export const getMessageDeliveryStatus = (state, { id, chatId }) => {
  const myId = getMyId(state);
  const message = getMessageById(state, { id, chatId });

  if (myId == message.from.id) {
    if (isEmpty(message.id)) return "pushed";

    if (message.chat_id.indexOf("member/") === 0) {
      const partnerState = getChatPartnerState(state, { chatId });
      if (partnerState) {
        if (partnerState.last_read_id >= id) return "read";
        if (partnerState.last_received_id >= id) return "received";
      }
    }
    return "persisted";
  } else {
    const last_read_id = getLastReadIdForChat(state, { chatId });
    if (last_read_id >= id) return "read";
    return "received";
  }
};
