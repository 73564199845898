import React from "react";
import styles from "../globalSearch.module.css";
import DateRange from "components/appointments/DateRange";
import CalendarList from "components/appointments/CalendarList";
import classNames from "classnames";

function ModifiedRecurringAppointmentHit({
  html_url,
  name,
  html_description,
  date,
  calendars,
  recurring_appointment,
}) {
  return (
    <div className={`ModifiedRecurringAppointmentHit border-box flex flex-col`}>
      <div className="flex items-center p-4 border-neutral border-dashed border-b">
        <div>
          <a href={html_url} className="font-semibold mr-1">
            {name}
          </a>
          {recurring_appointment ? (
            <small>
              (
              <i className="fa-regular fa-arrows-repeat mr-1" />
              <a href={recurring_appointment.html_url}>
                {recurring_appointment.name}
              </a>
              )
            </small>
          ) : null}
          <CalendarList calendars={calendars} className={styles.hitMeta} />
          <DateRange {...date} className={styles.hitMeta} />
        </div>
      </div>
      {html_description ? (
        <div
          className="p-4 prose prose-sm"
          dangerouslySetInnerHTML={{ __html: html_description }}
        />
      ) : null}
      <div
        className={classNames({
          "border-neutral border-dashed border-t": html_description,
        })}
      >
        <a className="btn btn-link block" href={html_url}>
          {I18n.t("js.global_search.messages.show_appointment")}
        </a>
      </div>
    </div>
  );
}

export default ModifiedRecurringAppointmentHit;
