import React, { useEffect, useState } from "react";
import {
  map,
  includes,
  pull,
  get,
  toInteger,
  isEmpty,
  divide,
  ceil,
} from "lodash";

import { useNavigate } from "helpers/tixxt-router";
import { useQueryParams } from "helpers/useQueryParams";
import MessageRow from "./MessageRow";
import MessagesInteractions from "./MessagesInteractions";
import { useFetchMessages } from "./api";
import { MessagesListProps } from "./@types";
import Pagination from "components/shared/Pagination";

function MessagesList({ filter = "" }: MessagesListProps) {
  const [selectedMessageIds, setSelectedMessageIds] = useState<string[]>([]);
  const { page: pageParam } = useQueryParams();
  const [page, setPage] = useState<number>(toInteger(pageParam || "1"));
  const { isLoading, isFetching, data, refetch } = useFetchMessages(
    filter,
    page,
  );

  useEffect(() => {
    if (page !== 1) setPage(1);
  }, [filter]);

  const navigate = useNavigate();

  if (isLoading)
    return (
      <div className="messagesLoading">
        <i className="fa fa-spinner fa-spin" />
      </div>
    );

  return (
    <div className="message-list-view mb-8">
      {data && (
        <MessagesInteractions
          filter={filter}
          {...data}
          isFetching={isFetching}
          refetchMessages={refetch}
          selectedMessageIds={selectedMessageIds}
          setSelectedMessageIds={setSelectedMessageIds}
          onLoadMoreMessages={onLoadMoreMessages}
        />
      )}
      <div className="table w-full">
        <div className="table-row-group">
          {isEmpty(data) ? (
            <span>{I18n.t("js.messages.message_list.no_messages")}</span>
          ) : (
            map(data?.messages, (message) => {
              const id = get(message, "id");

              return (
                <MessageRow
                  id={id}
                  key={id}
                  subject={get(message, "subject")}
                  date={get(message, "created_at")}
                  members={
                    filter === "sent"
                      ? get(message, "to")
                      : [get(message, "author")]
                  }
                  to_count={filter === "sent" ? message?.to_count : null}
                  content={get(message, "plain_content")}
                  selectMessage={onSetMessageId}
                  isActive={includes(selectedMessageIds, id)}
                  read={get(message, "i.read")}
                  hasReplies={get(message, "has_replies")}
                  hasAttachments={get(message, "has_attachments")}
                  conversationLength={message.conversation_length}
                />
              );
            })
          )}
        </div>
      </div>
      <div className={"flex justify-end"}>
        {data && (
          <Pagination
            page={page}
            totalPages={ceil(divide(data.total, data.limit))}
            onPaginate={onLoadMoreMessages}
            loading={isFetching}
            width={2}
          />
        )}
      </div>
    </div>
  );

  function onLoadMoreMessages(page: number) {
    setPage(page);
    setSelectedMessageIds([]);
    const navigationUrl = filter ? `/messages/${filter}` : "/messages";
    if (page === 1) {
      navigate(navigationUrl);
    } else {
      navigate(`${navigationUrl}?page=${page}`);
    }
  }

  function onSetMessageId(messageId: string) {
    if (includes(selectedMessageIds, messageId)) {
      setSelectedMessageIds([...pull(selectedMessageIds, messageId)]);
    } else {
      setSelectedMessageIds([...selectedMessageIds, messageId]);
    }
  }
}

export default MessagesList;
