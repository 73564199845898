import { Remarkable } from "remarkable";
import { linkify } from "remarkable/linkify";
import { noop } from "lodash";

const noMarkdown = {
  render: (text) => text,
};

const getNewDevRenderer = (options) => new Remarkable(options).use(linkify);
const getRenderer =
  (window.Application && window.Application.getNewMarkdownRenderer) ||
  (Remarkable && getNewDevRenderer) ||
  noop;

const markdownRenderer = getRenderer({
  html: false,
  breaks: true,
  linkTarget: "_blank",
});

if (markdownRenderer) {
  markdownRenderer.core.ruler.disable([
    "replacements",
    "abbr2",
    "footnote_tail",
  ]);
  markdownRenderer.inline.ruler.disable(["footnote_ref", "htmltag"]);
  markdownRenderer.inline.ruler.enable(["ins", "mark"]);
  markdownRenderer.block.ruler.disable([
    "blockquote",
    "code",
    "fences",
    "footnote",
    "heading",
    "hr",
    "htmlblock",
    "lheading",
    "table",
  ]);
}

export default markdownRenderer || noMarkdown;
