import React from "react";
import CustomConfigurator from "../../CustomConfigurator";

function ExternalIdInput({ onChangeExternalId }: Props) {
  return (
    <>
      <label>Rundschreibennummer</label>
      <input
        type={"text"}
        onChange={(e) => {
          onChangeExternalId(e.target.value);
        }}
      />
    </>
  );
}

type Props = {
  externalId?: string;
  onChangeExternalId: (value: string) => void;
};

CustomConfigurator.setDefault(
  ["rundschreiben", "ExternalIdInput"],
  ExternalIdInput,
);

function ExternalIdComponent(props) {
  const ExternalIdInput = CustomConfigurator.get([
    "rundschreiben",
    "ExternalIdInput",
  ]);

  return (
    <>
      <ExternalIdInput {...props} />
    </>
  );
}

export default ExternalIdComponent;
