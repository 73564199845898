import { TinyColor } from "@ctrl/tinycolor";
import { ColorObject, DerivedTheme, Theme } from "./types";
import { CSSProperties } from "react";
import { map } from "lodash";

/*
  The logic of this function is duplicated in ruby
  Dont forget to update it as well: app/services/theme_service/theme_to_css.rb
 */
export function lightThemeToCss(theme: Theme) {
  const { bannerType, logoType } = theme;
  const cssContent: string[] = [];

  cssContent.push(LOGO_RULES[logoType]());
  cssContent.push(BANNER_RULES[bannerType](theme));

  return cssContent.join("\n");
}

/*
  The logic of this function is duplicated in ruby
  Dont forget to update it as well: app/services/theme_service/theme_to_css.rb
 */
export default function themeToCss(theme: DerivedTheme) {
  const { derivedColors, logoType, bannerType } = theme;
  const cssContent: string[] = [];

  cssContent.push(":root {");

  for (const variable in derivedColors) {
    const colorObj = derivedColors[variable];

    cssContent.push(
      map(
        colorObjToCSSProperties(variable, colorObj),
        (value, variable) => `${variable}: ${value};`,
      ).join(" "),
    );
  }

  cssContent.push(`}`);

  cssContent.push(LOGO_RULES[logoType]());
  cssContent.push(BANNER_RULES[bannerType](theme));

  return cssContent.join("\n");
}

export function colorObjToCSSProperties(
  variable: string,
  colorObj: ColorObject | undefined,
): CSSProperties {
  if (!colorObj) return {};

  const result = {};
  const { color, abstract, tailwind } = colorObj;

  if (!abstract) {
    result[`--${variable}`] = color;
  }
  if (tailwind) {
    const { r, g, b } = new TinyColor(color).toRgb();
    result[`--tailwind-${variable}`] = `${r} ${g} ${b}`;
  }

  return result;
}

const LOGO_RULES = {
  default() {
    // This is exported to the defaultTheme
    return `:root {
      --header-logo-height: 60px;
      --header-logo-display: grid;
      --header-home-icon-display: none;
      --header-small-search-display: none;
      --header-small-grid: 'burger logo user-central';
      --navigation-offset: 60px;
    }`;
  },
  extended() {
    return `:root {
      --header-logo-height: 80px;
      --header-logo-display: grid;
      --header-home-icon-display: none;
      --header-small-search-display: none;
      --header-small-grid: 'burger logo user-central';
      --navigation-offset: 60px;
    }`;
  },
  hidden() {
    return `:root {
      --header-logo-height: 0px;
      --header-logo-display: none;
      --header-home-icon-display: grid;
      --header-small-search-display: flex;
      --header-small-grid: 'burger home-icon search user-central';
      --navigation-offset: 60px;
    }`;
  },
};

const BANNER_RULES = {
  hidden() {
    // This is exported to the defaultTheme
    return `:root {
      --header-banner-height: 0;
      --header-banner-position: unset;
      --header-banner-background: transparent; 
    }`;
  },
  color({ colors, derivedColors }: DerivedTheme) {
    const col =
      colors["banner-background"] || derivedColors?.["banner-background"];
    return `:root {
      --header-banner-height: 20px;
      --header-banner-position: fixed;
      --header-banner-background: ${col?.color || "transparent"};
      --navigation-offset: 80px;
    }`;
  },
  image({ override, bannerImage, bannerUrl, logoType }: Theme) {
    let url = override
      ? bannerImage
        ? `/api/storage/assets/${bannerImage.id}/preview`
        : null
      : bannerUrl;
    url = url ? `url("${url}")` : "none";

    return `:root {
      --header-banner-height: 120px;
      --header-banner-position: unset;
      --header-banner-background: transparent ${url} 0 0 no-repeat;
      ${logoType == "extended" ? "--header-logo-height: 120px;" : ""}
    }`;
  },
};
