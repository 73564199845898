import styles from "./documents.module.css";
import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

function DocumentAction({
  fileId,
  action,
  icon,
  type,
  showReviewChanges,
  disabled,
}) {
  const trackView = () => {
    fetch(`/api/storage/files/${fileId}/track_view`, {
      method: "POST",
      credentials: "include",
    });
  };

  const El = disabled
    ? ({ children, ...props }) => <span {...props}>{children}</span>
    : ({ children, ...props }) => (
        <a
          {...props}
          href={`/files/${fileId}/${type}/host/${action}${
            showReviewChanges ? "?show_review_changes" : ""
          }`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={trackView}
        >
          {children}
        </a>
      );

  return (
    <El
      className={classNames(
        "btn btn-light flex items-center gap-1",
        styles.DocumentActionButton,
        {
          disabled,
        },
      )}
    >
      {icon ? <>{icon} </> : null}
      {I18n.t(`js.files.show.toolbar.wopi.actions.${action}`)}
    </El>
  );
}

DocumentAction.propTypes = {
  fileId: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["wopi", "docs"]),
  icon: PropTypes.node,
  showReviewChanges: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DocumentAction;
