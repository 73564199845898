import React, { useContext, useEffect, useState } from "react";
import { useQuery, useMutation } from "react-query";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import { AbortButton } from "./FactorActions";
import { getNewEmail, createNewEmail, perform2fa } from "./api";
import RouteContext from "./RouteContext";

function AddEmailPage({ context }) {
  const routeContext = useContext(RouteContext);
  const [validationCode, setValidationCode] = useState(null);

  const { data, isLoading, isError } = useQuery("newEmailFactor", getNewEmail, {
    retry: false,
  });
  const {
    mutate: submitCreateNewEmail,
    data: createResult,
    error: createError,
    isLoading: isCreating,
  } = useMutation(createNewEmail);

  useEffect(() => {
    if (validationCode && createResult) {
      if (context === "sign_in") {
        perform2fa(validationCode).then((data) => {
          window.location.href = data.redirect_to;
        });
      } else {
        routeContext.setRoute({ name: "index" });
      }
    }
  }, [validationCode, createResult]);
  return isError ? (
    <div className="alert alert-error max-w-prose">
      {I18n.t("js.generic_error")}
    </div>
  ) : isLoading || isCreating ? (
    <span className="loadingMessage">
      <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
    </span>
  ) : createResult ? null : (
    <>
      <div className="max-w-prose alert alert-info text-sm">
        {I18n.t("js.authentication.2fa.add_email_description")}
      </div>
      <form
        className="form-horizontal"
        onSubmit={(e) => {
          e.preventDefault();
          const { verificationCodeInput } = e.target.elements;
          if (isEmpty(verificationCodeInput.value)) return;
          submitCreateNewEmail({
            data,
            validationCode: verificationCodeInput.value,
          });
          setValidationCode(verificationCodeInput.value);
        }}
      >
        <div className="control-group">
          <label className="control-label" htmlFor="verificationCodeInput">
            {I18n.t("js.authentication.2fa.valid_code")}
          </label>
          <div className="controls">
            <input
              id="verificationCodeInput"
              type="text"
              name="code"
              inputMode="numeric"
              autoComplete="off"
              placeholder={I18n.t("js.authentication.2fa.input_placeholder")}
              required
            />
            {createError ? (
              <div className="alert alert-danger mt-2 max-w-prose">
                {createError.message}
              </div>
            ) : null}
          </div>
        </div>

        <div className="flex gap-2 justify-end">
          <AbortButton />
          <button className="btn btn-primary" type="submit">
            {I18n.t("js.authentication.2fa.add_2fa")}
          </button>
        </div>
      </form>
    </>
  );
}

AddEmailPage.propTypes = {
  context: PropTypes.oneOf([undefined, "sign_in"]),
};

export default AddEmailPage;
