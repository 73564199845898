import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="administration--teams--fallback-upn-assignments"
export default class extends Controller {
  connect() {
    new window.Phoenix.Views.Inputs.MemberSelect({
      el: this.element,
      multiple: false,
    });
  }

  disconnect() {
    super.disconnect();
  }
}
