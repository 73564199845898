import React from "react";
import { split, find, includes } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  regular,
  duotone,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { Item } from "../../../@types/searchBar";

const MODULE_WHITELIST = [
  "activities",
  "messages",
  "files",
  "appointments",
  "calendars",
  "pages",
  "items",
];

function getModule(module: string) {
  switch (module) {
    case "activitystreams":
      return "activities";
    case "calendars":
      return "appointments";
    case "apps":
      return "items";
    case "search":
      return getModuleFromQueryParams();
    default:
      return module;
  }
}

function getModuleFromQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const module = urlParams.get("module");

  // needed to make typescript happy
  if (!module) return;

  if (includes(MODULE_WHITELIST, module)) {
    return module;
  }
}

export function getIcon({ module }: Item, duo?: boolean) {
  switch (module) {
    case "activities":
      return (
        <FontAwesomeIcon
          className={""}
          icon={duo ? duotone("earth-europe") : regular("earth-europe")}
        />
      );
    case "messages":
      return (
        <FontAwesomeIcon
          className={""}
          icon={duo ? duotone("inbox") : regular("inbox")}
        />
      );
    case "files":
      return (
        <FontAwesomeIcon
          className={""}
          icon={duo ? duotone("folder-open") : regular("folder-open")}
        />
      );
    case "appointments":
      return (
        <FontAwesomeIcon
          className={""}
          icon={duo ? duotone("calendar-day") : regular("calendar-day")}
        />
      );
    case "items":
      return (
        <FontAwesomeIcon
          className={""}
          icon={duo ? duotone("table") : regular("table")}
        />
      );
    default:
      return (
        <FontAwesomeIcon
          className={""}
          icon={duo ? duotone("compass") : regular("compass")}
        />
      );
  }
}

export function generateItems() {
  const location = split(window.location.pathname, "/");

  let module = getModule(location[1]);

  const items: Item[] = [{}];

  if (location[1] === "groups" && Preload && Preload.my_groups) {
    const groupSlug = location[2];
    const group = find(Preload.my_groups, { slug: location[2] });
    module = getModule(location[3]);

    if (group) {
      items.unshift({
        parentName: group.name,
        groupSlug,
      });

      if (includes(MODULE_WHITELIST, module)) {
        items.unshift({
          parentName: group.name,
          module,
          groupSlug,
        });
      }
    }
  } else {
    if (includes(MODULE_WHITELIST, module)) {
      items.unshift({ module });
    }
  }

  return items;
}
