import React from "react";
import PageTitle from "components/layout/PageTitle";
import CalendarForm from "components/appointments/calendars/CalendarForm";
import { useNavigate, useSearchParams } from "helpers/tixxt-router";
import { useCreateCalendar } from "components/appointments/calendars/api";

export default function NewCalendarPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const parentId = searchParams.get("parent_id");

  const { mutate: createCalendar } = useCreateCalendar({
    onSuccess: () => {
      toastr.success(I18n.t("js.saving_successful"));
      navigate(`/calendars/manage`);
    },
    onError: () => {
      toastr.error(I18n.t("js.saving_failure"));
    },
  });

  const calendar = {
    id: undefined,
    name: undefined,
    type: type || undefined,
    parent_id: parentId || undefined,
  };

  return (
    <>
      <PageTitle title={I18n.t("calendars.add_calendar")} />
      <CalendarForm calendar={calendar} onSubmit={createCalendar} />
    </>
  );
}
