import { createApiAction } from "helpers/api";

export const createMembership = createApiAction({
  method: "POST",
  endpoint: () => `/administration/members`,
  baseType: `administration/CREATE_MEMBERSHIP`,
});

export const updateMembership = createApiAction({
  method: "PUT",
  endpoint: ({ membershipId }) => `/administration/members/${membershipId}`,
  baseType: `administration/UPDATE_MEMBERSHIP`,
});
