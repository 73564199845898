import React from "react";
import { useNavigate } from "helpers/tixxt-router";

interface BackButtonProps {
  title?: string;
  href?: string;
}

export function BackButton({ title, href = "#" }: BackButtonProps) {
  const navigate = useNavigate();

  return (
    <div className="btn-group desktop-only">
      <a
        className="btn btn-light btn-sm backButton"
        href={href}
        onClick={(e) => {
          e.preventDefault();
          if (navigate) {
            if (href !== "#") {
              navigate(href);
            } else {
              navigate(-1);
            }
            // Stop the handler in routes to manipulate the history again.
            e.nativeEvent.stopImmediatePropagation();
          } else {
            window.history.back();
          }
        }}
        title={title}
      >
        <i className="fa fa-arrow-left text-primary" />
      </a>
    </div>
  );
}

export default BackButton;
