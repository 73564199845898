import { match } from "path-to-regexp";
import { map, memoize } from "lodash";
import { CompiledRoute, RouteObject } from "helpers/tixxt-router";

export type Params = {
  [key: string]: string | undefined;
};

const WILDCARD_END = /\/?\*$/;

function compileRoute(path: string) {
  const endsWithWildcard = WILDCARD_END.exec(path);
  let pathToCompile = path;
  if (endsWithWildcard) pathToCompile = path.slice(0, endsWithWildcard.index);
  const matchFn = match<Params>(pathToCompile, {
    end: endsWithWildcard === null,
  });
  return { path, match: matchFn };
}

function compileRoutes(routes: RouteObject[]): CompiledRoute[] {
  return map(routes, ({ path, element, forceTurbo }) => {
    try {
      return { ...compileRoute(path), element, forceTurbo };
    } catch (e) {
      console.error("while compiling path:", path);
      throw e;
    }
  });
}

export const compileRouteMemoized = memoize(compileRoute);
export const compileRoutesMemoized = memoize(compileRoutes);
