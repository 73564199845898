import React from "react";
import { isNil, map } from "lodash";
import PropertyShow from "../../appCreator/properties/PropertyShow";

import styles from "./propertySets.module.css";
import { usePropertySet } from "components/shared/propertySets/api";

interface MaybeWrappedProps {
  onlyRows?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

function MaybeWrapped({ onlyRows, children }: MaybeWrappedProps) {
  return onlyRows ? (
    <>{children}</>
  ) : (
    <table className={`${styles.ValueSetTable}`}>
      <tbody>{children}</tbody>
    </table>
  );
}

interface ValueSetTableProps {
  contextType: string;
  contextId?: string;
  name: string;
  values?: unknown;
  onlyRows?: boolean;
  showLoading?: boolean;
  options?: object;
}

function ValueSetTable({
  contextType,
  contextId,
  name,
  values,
  options,
  onlyRows = false,
  showLoading = true,
}: ValueSetTableProps) {
  const { isLoading: loading, data: propertySet } = usePropertySet({
    name,
    contextId,
    contextType,
  });

  return (
    <>
      {showLoading && loading ? (
        <MaybeWrapped onlyRows={onlyRows} key="loading">
          <tr>
            <th className="muted">Wird geladen...</th>
            <td>
              <i className="fa-regular fa-spinner fa-spin" />
            </td>
          </tr>
        </MaybeWrapped>
      ) : null}
      {isNil(propertySet) || isNil(values) ? null : (
        <MaybeWrapped onlyRows={onlyRows} key="list">
          {map(propertySet.properties, (prop) => (
            <tr key={prop.name}>
              <th className="font-medium text-left pb-2">{prop.label}</th>
              <td className="pl-4 pb-2">
                <PropertyShow
                  property={prop}
                  item={{ values }}
                  options={options}
                />
              </td>
            </tr>
          ))}
        </MaybeWrapped>
      )}
    </>
  );
}

export default ValueSetTable;
