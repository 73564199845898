import { map } from "lodash";
import React from "react";
import { MoreOptions } from "../../../../../@types/appNavigation";

function More({ options }: { options?: MoreOptions }) {
  return (
    <div>
      <p>{I18n.t("js.administration.app_navigation_items.edit.more.label")}</p>
      <ul>
        {map(options?.items, (i) => (
          <li key={i.id}>{i.label}</li>
        ))}
      </ul>
      <p>{I18n.t("js.administration.app_navigation_items.edit.more.hint")}</p>
    </div>
  );
}

export default More;
