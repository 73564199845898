import { Calendar } from "../../../@types/appointments";
import { filter, forEach, groupBy, keyBy } from "lodash";

export function isShared(cal: Calendar | undefined) {
  return (
    cal?.type === "personal" && cal?.parent_id !== Preload.current_member?.id
  );
}

export function canAddCalendar(type: string) {
  switch (type) {
    case "personal":
      return !!Preload.current_member?.can.create_personal_calendars;
    case "network":
      return !!Preload.current_network?.can.create_calendars;
    default:
      return false;
  }
}

export function groupCalendarsByType(calendars: Calendar[] | undefined) {
  const groupedCalendars: { [key: string]: Calendar[] } = {
    personal: [],
    network: [],
    shared: [],
  };

  forEach(
    groupBy(
      filter(calendars, (cal) => cal.type !== "group" && !isShared(cal)),
      "type",
    ),
    (cals, key) => (groupedCalendars[key] = cals),
  );

  groupedCalendars.shared = filter(calendars, (cal) => isShared(cal));

  return groupedCalendars;
}

export function groupCalendarsByGroup(calendars: Calendar[] | undefined) {
  const groupsById = keyBy(Preload.my_groups, "id");
  const groupsWithCalendars = {};

  forEach(
    groupBy(
      filter(calendars, (cal) => cal.type === "group"),
      "parent_id",
    ),
    (calendars, group_id) =>
      (groupsWithCalendars[group_id] = { ...groupsById[group_id], calendars }),
  );

  return groupsWithCalendars;
}
