import React, { useMemo, useRef, useState } from "react";
import { filter, includes, intersection, isEmpty, map, orderBy } from "lodash";
import moment from "moment";

import { ContextPageTitle } from "components/layout/PageTitle";
import AppointmentToolbar from "components/appointments/AppointmentToolbar";
import CalendarSheet from "./CalendarSheet.js";
import { useAppointments, useCalendars } from "components/appointments/api";
import { collectAppointmentsByDate } from "components/appointments/helpers";
import AppointmentsList from "components/appointments/appointmentsList";
import { useParams } from "helpers/tixxt-router";
import GroupNavigation from "components/layout/GroupNavigation";
import { useSelector } from "react-redux";
import { State } from "../../@types";
import { Appointment } from "../../@types/appointments";
import useAppointmentView from "components/appointments/useAppointmentView";

export default function AppointmentsPage({
  participating = false,
}: {
  participating?: boolean;
}) {
  const { view, setViewType } = useAppointmentView();
  const { groupSlug } = useParams();
  const group = useSelector((state: State) =>
    groupSlug ? state.groups.bySlug?.[groupSlug] : null,
  );
  const network = useSelector((state: State) => state.network);

  const [date, setDate] = useState(
    sessionStorage.getItem("appointmentsDate") || moment().format(),
  );
  const [excludedCalendarIds, setExcludedCalendarIds] = useState<string[]>([]);

  const { isLoading, data: appointments } = useAppointments({
    date,
    view,
    participating,
  });
  const todayRef = useRef<HTMLDivElement>(null);
  const scrollToToday = () => {
    setTimeout(() => {
      if (todayRef.current) {
        todayRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }, 100);
  };
  const { data: calendars } = useCalendars();
  const contextCalendars = !isEmpty(group)
    ? filter(calendars, (cal) => cal.parent_id === group?.id)
    : calendars;

  function filterAppointments() {
    let contextAppointments: Appointment[] | undefined;

    if (groupSlug) {
      contextAppointments = filter(appointments, (appointment: Appointment) => {
        const groupAppointmentsCalendarIds = intersection(
          map(contextCalendars, "id"),
          appointment.calendar_ids,
        );

        return (
          groupAppointmentsCalendarIds >
          intersection(groupAppointmentsCalendarIds, excludedCalendarIds)
        );
      });
    } else {
      contextAppointments = filter(appointments, (appointment) =>
        appointment.calendar_ids
          ? appointment.calendar_ids.length >
            intersection(excludedCalendarIds, appointment.calendar_ids).length
          : false,
      );
    }

    return orderBy(contextAppointments, "date.start", "asc");
  }

  const filteredAppointments = useMemo(filterAppointments, [
    appointments,
    groupSlug,
    excludedCalendarIds,
    calendars,
    contextCalendars,
  ]);

  function onSetDate(date: string) {
    sessionStorage.setItem("appointmentsDate", date);
    setDate(date);
  }

  let appointmentsByDate = {};

  if (filteredAppointments) {
    appointmentsByDate = collectAppointmentsByDate(filteredAppointments, date);
  }

  function toggleExcludedCalendarIds(calendarId: string) {
    if (calendarId === "all") {
      if (isEmpty(excludedCalendarIds)) {
        setExcludedCalendarIds(map(calendars, "id"));
      } else {
        setExcludedCalendarIds([]);
      }
    } else {
      if (includes(excludedCalendarIds, calendarId)) {
        setExcludedCalendarIds(
          filter(excludedCalendarIds, (calId: string) => calId !== calendarId),
        );
      } else {
        setExcludedCalendarIds([...excludedCalendarIds, calendarId]);
      }
    }
  }

  return (
    <>
      <ContextPageTitle
        groupSlug={groupSlug}
        title={
          participating
            ? I18n.t("calendars.participating_appointments")
            : I18n.t("calendars.all_appointments")
        }
      />
      {groupSlug && <GroupNavigation groupSlug={groupSlug} />}
      <h2 className="tixxt__subtitle">
        {I18n.t("js.calendars.appointments.subtitle")}
      </h2>
      <AppointmentToolbar
        isLoading={isLoading}
        view={view}
        setViewType={setViewType}
        date={date}
        setDate={onSetDate}
        calendars={contextCalendars}
        excludedCalendarIds={excludedCalendarIds}
        toggleExcludedCalendarIds={toggleExcludedCalendarIds}
        scrollToToday={scrollToToday}
        canCreate={
          groupSlug
            ? group?.can.create_appointments
            : network.can.create_appointments
        }
      />
      {view === "sheet" ? (
        <CalendarSheet
          appointmentsByDate={appointmentsByDate}
          calendars={calendars}
        />
      ) : (
        <AppointmentsList
          appointmentsByDate={appointmentsByDate}
          date={date}
          todayRef={todayRef}
          calendars={calendars}
        />
      )}
    </>
  );
}
