import React, { useState } from "react";
import { map, split, get, orderBy, includes, isEmpty } from "lodash";

import useFolderResource from "components/files/hooks/useFolderResource";
import Modal from "components/shared/Modal";
import Sorter from "components/shared/tables/Sorter";
import FolderRow from "./table/FolderRow";
import { Folder } from "../../@types/files";

type FolderSelectModalArgs = {
  currentRootFolder: { id: string; namespace: string };
  modalTitle: string;
  closeModal: () => void;
  onSelectFolder: (folder: Folder) => void;
  selectedItemIds: string[];
};

function FolderSelectModal({
  currentRootFolder,
  closeModal,
  onSelectFolder,
  selectedItemIds,
  modalTitle,
}: FolderSelectModalArgs) {
  const [sortBy, setSortBy]: [
    [string, number],
    (sortBy: [string, number]) => void,
  ] = useState(["name", 1]);
  const { loading, currentFolder, handleNavigate, breadcrumb } =
    useFolderResource({ currentRootFolder });

  function handleSort(sortBy) {
    setSortBy(sortBy);
  }

  function selectFolder(e) {
    e.preventDefault();
    onSelectFolder(currentFolder);
    closeModal();
  }

  return (
    <Modal
      onClose={closeModal}
      title={modalTitle}
      modalActionProps={{
        submitText: "js.files.folder.select",
        submit: selectFolder,
        closeModal: (e) => {
          e.preventDefault();
          closeModal();
        },
      }}
    >
      {breadcrumb}
      <table className="w-full">
        <thead className="bg-gray-100">
          <tr>
            <th className="col-icon p-2" />
            <th className="col-name p-2">
              <Sorter
                attribute="name"
                label={I18n.t("js.files.folder.file_name")}
                handleSort={handleSort}
                sortBy={sortBy}
              />
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300">
          {loading ? (
            <tr>
              <td colSpan={2} className="empty-folder">
                {I18n.t("js.files.folder.loading")}
              </td>
            </tr>
          ) : isEmpty(get(currentFolder, "subfolders")) ? (
            <tr>
              <td colSpan={2} className="empty-folder">
                {I18n.t("js.files.folder.no_subfolders_message")}
              </td>
            </tr>
          ) : (
            map(
              orderBy(
                get(currentFolder, "subfolders"),
                sortBy[0],
                sortBy[1] === -1 ? "desc" : "asc",
              ),
              (folder: Folder) => (
                <FolderRow
                  key={folder.id}
                  {...folder}
                  type={split(folder.i18n_type, ".")[0]}
                  navigateDisabled={
                    includes(selectedItemIds, folder.id) ||
                    split(folder.i18n_type, ".")[0] === "activitystream_folder"
                  }
                  handleNavigate={(e) => {
                    e.preventDefault();
                    handleNavigate({ id: folder.id });
                  }}
                />
              ),
            )
          )}
        </tbody>
      </table>
    </Modal>
  );
}

export default FolderSelectModal;
