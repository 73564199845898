import React from "react";
import { compose, toClass, withHandlers } from "recompose";
import { DragSource, DropTarget } from "react-dnd";
import classNames from "classnames";

const cardSource = {
  beginDrag: ({ property, column, index }) => ({
    property,
    column,
    index,
  }),

  endDrag: ({ addColumnProperty, moveColumnProperty }, monitor) => {
    const item = monitor.getItem();
    const dropResult = monitor.getDropResult();

    if (!dropResult) {
      return;
    }

    if (item.column) {
      debug("moveColumnProperty", item, dropResult);
      moveColumnProperty(item, dropResult);
    } else {
      debug("addColumnProperty", item.property, dropResult);
      addColumnProperty(item.property, dropResult);
    }
  },
};

const cardTarget = {
  drop: ({ property, column, index }) => {
    return {
      property,
      column,
      index,
      _type: "property",
    };
  },
};

const DragSourceProperty = compose(
  DropTarget("property", cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })),
  DragSource("property", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  toClass,
  withHandlers({
    onSelect:
      ({ selected, column, index, onSelect }) =>
      () => {
        onSelect ? onSelect(selected ? null : { column, index }) : null;
      },
  }),
)(
  ({
    isDragging,
    isOver,
    connectDragSource,
    connectDropTarget,
    property,
    selected,
    onSelect,
  }) =>
    connectDragSource(
      connectDropTarget(
        <div>
          {isOver && <div className="h-10 rounded bg-neutral/50 mb-1" />}
          <div
            onClick={onSelect}
            className={classNames(
              "overflow-hidden bg-primary p-2 rounded text-white text-center cursor-move",
              {
                "bg-primary/75": selected,
                "border-4 border-white/50": isOver,
                hidden: isDragging,
              },
            )}
          >
            {property.label}
          </div>
        </div>,
      ),
    ),
);

export default DragSourceProperty;
