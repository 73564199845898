import React from "react";
import { map, reduce, isEmpty } from "lodash";
import pickImage from "helpers/pickImage";
import styles from "../globalSearch.module.css";

import { NameWithProfileField } from "components/memberships/MemberNameWithAdditions";
import Note from "../../../components/activitystreams/types/Note";
import Question from "../../../components/activitystreams/types/Question";
import Survey from "../../../components/activitystreams/types/Survey";
import Appointment from "../../../components/activitystreams/types/Appointment";
import ActivityAttachments from "../../activitystreams/ActivityAttachments";

const LOOKUP = {
  appointment: Appointment,
  note: Note,
  question: Question,
  schedule: Survey,
  survey: Survey,
};

function ActivityHit({ actor, object, html_url, created_at, activitystreams }) {
  const TypeView = LOOKUP[object.type] || Note;
  const actorImage = (
    <img
      className="w-16 h-16"
      src={pickImage(actor.images, [128, 128])}
      alt={actor.display_name}
    />
  );
  const nameWithProfileField = (
    <NameWithProfileField
      name={actor.display_name}
      detectionProfileField={actor.detection_profile_field}
      isExternal={actor.is_external}
    />
  );

  return (
    <div
      className={`ActivityHit border-box py-2 ${styles.hit} activity clearfix`}
    >
      <div className="activity-header px-3 flex items-center mb-2">
        <div className="activity-actor-image py-4 mr-4">
          {actor.deleted ? (
            <span className="member-link">{actorImage}</span>
          ) : (
            <a
              className="member-link"
              href={actor.html_url}
              title={actor.display_name}
            >
              {actorImage}
            </a>
          )}
        </div>
        <div className="activity-headline py-4 flex-1">
          {actor.deleted ? (
            <span className="activity-actor font-semibold member-link">
              {nameWithProfileField}
            </span>
          ) : (
            <a
              href={actor.html_url}
              className={"activity-actor font-semibold member-link"}
            >
              {nameWithProfileField}
            </a>
          )}
          <div className="activity-meta">
            <div className={"flex flex-col "}>
              <div>
                {reduce(
                  map(activitystreams, ({ slug, name, html_url }) => (
                    <a
                      className="font-semibold leading-none text-normal text-sm"
                      href={html_url}
                      key={slug}
                    >
                      {name}
                    </a>
                  )),
                  (prev, curr) => [
                    prev,
                    <span className="text-muted" key={curr.key + "sep"}>
                      {", "}
                    </span>,
                    curr,
                  ],
                )}
              </div>
              <a href={html_url} className="muted text-sm leading-none">
                <time
                  className="created"
                  dateTime={created_at}
                  title={moment(created_at).format("LLLL")}
                >
                  {moment(created_at).format("LLLL")}
                </time>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={"flex flex-col p-3 gap-4"}>
        <div className={`activity-object ${styles.hitContent}`}>
          <TypeView {...object} />
        </div>
        {!isEmpty(object.attachments) ? (
          <ActivityAttachments
            attachments={object.attachments}
            activityUrl={html_url}
          />
        ) : null}
        <div className={"flex text-muted text-sm gap-2 empty:hidden"}>
          {object.answer_count > 0 ? (
            <span>
              {I18n.t("js.global_search.activities.answers.counting", {
                count: object.answer_count,
              })}
            </span>
          ) : null}
          {object.reply_count > 0 ? (
            <span>
              {I18n.t("js.global_search.activities.replies.counting", {
                count: object.reply_count,
              })}
            </span>
          ) : null}
        </div>
      </div>
      <div className="border-neutral border-dashed border-t">
        <a className="btn btn-link block" href={html_url}>
          {I18n.t("js.global_search.activities.show_activity")}
        </a>
      </div>
    </div>
  );
}

export default ActivityHit;
