import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import QRCode from "qrcode.react";
import { map, get, isEmpty } from "lodash";
import PropTypes from "prop-types";

import { AbortButton, BackButton } from "./FactorActions";
import { getNewTotp, createNewTotp, perform2fa } from "./api";

function AddTotpPage({ context }) {
  const [validationCode, setValidationCode] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const { data, isLoading, isError } = useQuery("newTotpFactor", getNewTotp, {
    retry: false,
  });
  const {
    mutate: submitCreateNewTotp,
    data: createResult,
    error: createError,
    isLoading: isCreating,
  } = useMutation(createNewTotp);

  const backupCodes = get(createResult, "backup_codes");

  useEffect(() => {
    if (validationCode && backupCodes && context === "sign_in") {
      perform2fa(validationCode).then((data) =>
        setRedirectTo(data.redirect_to),
      );
    }
  }, [validationCode, backupCodes]);

  return isError ? (
    <div className="alert alert-error">{I18n.t("js.generic_error")}</div>
  ) : isLoading || isCreating ? (
    <span className="loadingMessage">
      <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
    </span>
  ) : backupCodes ? (
    <BackupCodes
      backupCodes={backupCodes}
      context={context}
      redirectTo={redirectTo}
    />
  ) : (
    <>
      <div className="alert alert-info">
        {I18n.t("js.authentication.2fa.add_totp_description")}
      </div>
      <div className="mt-6">
        <h4> {I18n.t("js.authentication.2fa.add_totp_step1")}</h4>
        <div className="form-horizontal">
          <div className="control-group">
            <label className="control-label">QR Code</label>
            <div className="controls">
              <div className="border-box flex justify-center p-3">
                <QRCode
                  renderAs="svg"
                  value={data.provisioning_uri}
                  aria-label="QR-Code"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <h4>{I18n.t("js.authentication.2fa.add_totp_step2")}</h4>
        <form
          className="form-horizontal"
          onSubmit={(e) => {
            e.preventDefault();

            const { verificationCodeInput } = e.target.elements;
            if (isEmpty(verificationCodeInput.value)) return;

            submitCreateNewTotp({
              data,
              validationCode: verificationCodeInput.value,
            });
            setValidationCode(verificationCodeInput.value);
          }}
        >
          <div className="control-group">
            <label className="control-label" htmlFor="verificationCodeInput">
              {I18n.t("js.authentication.2fa.valid_code")}
            </label>
            <div className="controls">
              <input
                id="verificationCodeInput"
                type="text"
                inputMode="numeric"
                name="code"
                autoComplete="off"
                placeholder={I18n.t("js.authentication.2fa.input_placeholder")}
                required
                autoFocus
              />
              {createError ? (
                <div className="alert alert-danger">{createError.message}</div>
              ) : null}
            </div>
          </div>

          <div className="flex gap-2 justify-end mt-4">
            <AbortButton />
            <button className="btn btn-primary" type="submit">
              {I18n.t("js.authentication.2fa.add_2fa")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

AddTotpPage.propTypes = {
  context: PropTypes.oneOf([undefined, "sign_in"]),
};

export function BackupCodes({ backupCodes, context, redirectTo }) {
  const downloadCodesAsTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([map(backupCodes, (code) => `${code}\n`).join("")], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "backup_codes.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  return (
    <>
      <div className="btn-toolbar justify-end">
        <button
          className="btn btn-primary"
          onClick={() => downloadCodesAsTxtFile()}
        >
          {I18n.t("js.authentication.2fa.save_backup_codes_as_txt")}
        </button>
      </div>
      <h4>{I18n.t("js.authentication.2fa.backup_codes_title")}</h4>
      {/*TODO change to markdown and extract <Markdown> component*/}
      <div className="alert alert-info">
        <p
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              "js.authentication.2fa.backup_codes_description_html",
            ),
          }}
        />
      </div>
      <div className="form-horizontal">
        <div className="control-group">
          <label className="control-label">Backup Codes</label>
          <div className="controls">
            <div className="border-box p-3">
              <pre>
                {map(backupCodes, (code) => (
                  <div key={code}>
                    {code}
                    <br />
                  </div>
                ))}
              </pre>
            </div>
          </div>
        </div>
        <div className="flex gap-2 justify-end">
          {context === "sign_in" ? (
            <a className="btn btn-light" href={redirectTo || "/"}>
              {I18n.t("js.authentication.2fa.continue")}
            </a>
          ) : (
            <BackButton />
          )}
        </div>
      </div>
    </>
  );
}

BackupCodes.propTypes = {
  context: PropTypes.oneOf([undefined, "sign_in"]),
  backupCodes: PropTypes.array,
  redirectTo: PropTypes.string,
};

export default AddTotpPage;
