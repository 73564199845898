import React from "react";
import { FieldArray } from "redux-form";
import { map } from "lodash";
import SelectionOptionsFields from "../properties/selection/SelectionOptionsFields";

interface AddOptionsTypes {
  name: string;
  existingOptions: Array<{ label: string }>;
}

// Allows editing of addOptions modifications
// Shows existing options above fields for new options
export default function AddOptions({ name, existingOptions }: AddOptionsTypes) {
  return (
    <>
      <label>Existierende Auswahloptionen</label>
      <ol>
        {map(existingOptions, ({ label }, index) => (
          <li key={index}>
            <input type="text" value={label} disabled />
          </li>
        ))}
      </ol>
      <FieldArray
        name={`${name}.modifications.addOptions` as string}
        component={SelectionOptionsFields as never}
        start={existingOptions.length + 1}
        label="Neue Auswahloptionen ab dieser Ebene"
      />
    </>
  );
}
