import React from "react";
import { compose, toClass } from "recompose";
import { DropTarget } from "react-dnd";
import DragSourceProperty from "./DragSourceProperty";
import classNames from "classnames";

const columnTarget = {
  drop: ({ char, column }, monitor) => {
    if (monitor.didDrop()) {
      return;
    } // hasDroppedOnChild

    return {
      column: char,
      index: column.length,
      _type: "column",
    };
  },
};

const DropTargetColumn = compose(
  DropTarget("property", columnTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOverAny: monitor.isOver(),
    isOver: monitor.isOver({ shallow: true }),
  })),
  toClass,
)(
  ({
    connectDropTarget,
    isOver,
    isOverAny,
    char,
    column,
    selectedColumnProperty,
    selectColumnProperty,
    moveColumnProperty,
    removeColumnProperty,
  }) => (
    <div className="content">
      {connectDropTarget(
        <div
          className={classNames("min-h-[128px] bg-neutral/40 rounded p-2", {
            "pb-10": isOverAny,
          })}
        >
          <div className="flex flex-col gap-1">
            {column.map(({ property }, i) => (
              <DragSourceProperty
                index={i}
                key={i}
                property={property}
                column={char}
                selected={
                  selectedColumnProperty &&
                  selectedColumnProperty.column === char &&
                  selectedColumnProperty.index === i
                }
                onSelect={selectColumnProperty}
                moveColumnProperty={moveColumnProperty}
                removeColumnProperty={removeColumnProperty}
              />
            ))}
            {isOver && <div className="h-10 rounded bg-neutral/75" />}
          </div>
        </div>,
      )}
    </div>
  ),
);

export default DropTargetColumn;
