import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="administration--activitystream--close-activity-reply-month"
export default class extends Controller {
  onClickChangeValue(event) {
    this.$CloseActivityReplyAutomaticSelect = this.element.querySelector(
      ".close_activity_reply_automatic_select",
    );

    this.$CloseActivityReplyAutomaticSelect.classList.toggle(
      "hidden",
      !(event.target.value === "automatic"),
    );
  }
}
