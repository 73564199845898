import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

const Notification = ({ className, counter }) =>
  counter ? (
    <span
      className={classnames(
        "badge rounded-xl bg-unread text-[10px]",
        className,
      )}
    >
      {counter === -1
        ? I18n.t("js.chat.notifications.new")
        : counter > 99
          ? "99+"
          : counter}
    </span>
  ) : null;

Notification.propTypes = {
  counter: PropTypes.number,
};

export default Notification;
