import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";

import {
  getChatImageUrl,
  getChatOnlineStatus,
  getChatBusyStatus,
} from "../../../selectors/chat/chat";
import { OnlineStatus } from "./OnlineStatus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const ICONS = {
  member: solid("user"),
  group: solid("users"),
};

const Image = ({ type, imageUrl, name }) =>
  imageUrl ? (
    <img
      className="chatImage h-10 w-10 rounded-full"
      alt={name}
      title={name}
      src={imageUrl}
    />
  ) : (
    <FontAwesomeIcon
      icon={ICONS[type]}
      title={name}
      className={classnames(
        "chatIcon h-10 w-10 text-muted flex items-center justify-center fa-xl",
      )}
    />
  );

Image.propTypes = {
  type: PropTypes.string,
  imageUrl: PropTypes.string,
  name: PropTypes.string,
};

const ChatImage = ({ type, imageUrl, name, isOnline, isBusy }) => {
  return (
    <div className="shrink-0">
      <OnlineStatus isOnline={isOnline} isBusy={isBusy}>
        <Image type={type} name={name} imageUrl={imageUrl} />
      </OnlineStatus>
    </div>
  );
};

export default connect((state, { chatId }) => ({
  imageUrl: getChatImageUrl(state, chatId),
  isOnline: getChatOnlineStatus(state, chatId),
  isBusy: getChatBusyStatus(state, chatId)?.type === "busy",
  type: chatId.split("/")[0],
}))(ChatImage);
