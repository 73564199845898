import React from "react";
import PropTypes from "prop-types";

import MessageAuthor from "./MessageAuthor";
import MessageText from "./MessageText";

const QuotedMessage = ({ message }) => (
  <div className="chat__quote rounded">
    <MessageAuthor id={message.from.id} name={message.from.name} />
    <MessageText text={message.text} />
  </div>
);
QuotedMessage.propTypes = {
  id: PropTypes.string,
  message: PropTypes.shape({
    text: PropTypes.string,
    from: PropTypes.shape(MessageAuthor.propTypes),
  }),
};

export default QuotedMessage;
