import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  regular,
  light,
  duotone,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSelector } from "react-redux";
import {
  currentMemberSelector,
  groupCategoriesSelector,
  networkSelector,
} from "selectors/environment";
import { map, filter } from "lodash";
import DropDown, { DropDownItem } from "../shared/DropDown";
import UnreadBadge from "components/shared/UnreadBadge";
import { useFeaturesUnreads } from "api/unreads";
import ChatButton from "components/layout/userCentral/ChatButton";
import classNames from "classnames";
import { AvailableUpdatesPayload } from "components/layout/CustomerCentralUpdateCheck";

function NotificationPing({ className }: { className?: string }) {
  return (
    <span className={classNames("absolute top-4 right-4", className)}>
      <span className="animate-ping absolute inline-flex h-2 w-2 rounded-full opacity-75 bg-unread" />
      <span className="rounded-full absolute h-2 w-2 bg-unread" />
    </span>
  );
}

function NotificationDropDownItems({
  unreads,
  className,
}: {
  unreads?:
    | {
        Notifications: number;
        GroupInvites: number;
        Calendars: number;
      }
    | undefined;
  className?: string;
}) {
  return (
    <>
      <DropDownItem
        url="/notifications"
        icon={regular("newspaper")}
        text={I18n.t("js.activitystreams.userbar.filter.notifications")}
        additional={<UnreadBadge count={unreads?.Notifications} />}
        className={className}
      />
      <DropDownItem
        url="/invites"
        icon={regular("envelope-open-text")}
        text={I18n.t("groups.invites.title")}
        additional={<UnreadBadge count={unreads?.GroupInvites} />}
        className={className}
      />
      <DropDownItem
        url="/appointments/invites"
        icon={regular("calendar-circle-user")}
        text={I18n.t("calendars.invites.title")}
        additional={<UnreadBadge count={unreads?.Calendars} />}
        className={className}
      />
    </>
  );
}

export default function UserCentral({
  duo,
  availableUpdates,
}: {
  duo?: boolean;
  availableUpdates: AvailableUpdatesPayload;
}) {
  const currentMember = useSelector(currentMemberSelector);
  const network = useSelector(networkSelector);
  const groupCategories = useSelector(groupCategoriesSelector);
  const { data: unreads } = useFeaturesUnreads();

  const unreadCount = unreads
    ? unreads?.GroupInvites + unreads?.Calendars + unreads?.Notifications
    : 0;

  if (!currentMember) return null;

  const administratableGroupCategories = filter(
    groupCategories,
    (cat) => cat.can.administrate,
  );

  return (
    <ul className="user-central flex">
      <li className={"hidden sm:flex"}>
        <button
          className="header__link notifications__link relative"
          data-bs-toggle="dropdown"
        >
          <FontAwesomeIcon
            title="Neues"
            className="notifications__icon fa-xl"
            icon={duo ? duotone("bell") : light("bell")}
          />
          {unreadCount > 0 ? <NotificationPing /> : null}
        </button>
        <DropDown>
          <NotificationDropDownItems unreads={unreads} />
        </DropDown>
      </li>

      <li className={"flex"}>
        <button
          className="header__link"
          data-bs-toggle="dropdown"
          title={I18n.t("js.memberships.my_profile")}
        >
          <img
            className="menu__profile-image rounded-full w-9"
            src={currentMember.images.medium}
            alt={currentMember.displayName}
          />
          <FontAwesomeIcon
            icon={duotone("circle-chevron-down")}
            className={"menu__indicator absolute right-2.5 bottom-2.5"}
          />
          {availableUpdates?.length > 0 ? (
            <NotificationPing className={"right-2"} />
          ) : null}
        </button>
        <DropDown
          className={"user-central__dropdown-menu max-h-96 overflow-y-auto"}
        >
          <NotificationDropDownItems
            unreads={unreads}
            className={"sm:hidden"}
          />
          <li className="divider sm:hidden" />
          <DropDownItem
            url={`/members/${currentMember.id}`}
            icon={regular("id-badge")}
            text={I18n.t("js.memberships.my_profile")}
          />
          {currentMember.can.update_profile && (
            <DropDownItem
              url={`/members/${currentMember.id}/edit`}
              icon={regular("user-pen")}
              text={I18n.t("js.layouts.user_central.edit_profile")}
            />
          )}
          {currentMember.can.update_registration && (
            <DropDownItem
              url="/users/edit"
              icon={regular("pen-to-square")}
              text={I18n.t("js.layouts.user_central.edit_registration")}
            />
          )}
          {currentMember?.can.update_profile && (
            <DropDownItem
              url={`/members/${currentMember.id}/settings/edit`}
              icon={regular("gears")}
              text={I18n.t("js.layouts.user_central.edit_membership_settings")}
            />
          )}
          {currentMember && (
            <DropDownItem
              url={`/members/${currentMember.id}/ignored`}
              icon={regular("eye")}
              text={I18n.t("js.memberships.userbar.ignored_members")}
            />
          )}
          {network.config.memberships?.change_requests?.enabled && (
            <DropDownItem
              url={`/change_requests/new`}
              icon={regular("chart-user")}
              text={I18n.t("js.layouts.user_central.change_request")}
            />
          )}

          {network.can.administrate ||
          administratableGroupCategories.length > 0 ||
          network.can.version_and_updates ? (
            <li className="divider" />
          ) : null}

          {network.can.administrate && (
            <DropDownItem
              url="/administration"
              icon={regular("screwdriver-wrench")}
              text={I18n.t("js.layouts.user_central.administration")}
            />
          )}
          {map(administratableGroupCategories, (cat) => (
            <DropDownItem
              key={cat.id}
              url={`/group-categories/${cat.slug}/administration`}
              text={I18n.t("js.layouts.user_central.group_categories", {
                name: cat.plural_name,
              })}
            />
          ))}

          {network.can.version_and_updates && (
            <DropDownItem
              url={`/administration/update-zentrale`}
              icon={regular("gear")}
              text={I18n.t("js.layouts.user_central.versions_and_updates")}
              additional={<UnreadBadge count={availableUpdates?.length} />}
            />
          )}
          <li className="divider" />
          <DropDownItem
            linkStyle="user-central__logout-link !text-danger"
            url="/users/sign_out"
            icon={regular("arrow-right-from-bracket")}
            text={I18n.t("js.layouts.user_central.sign_out")}
            method="delete"
            data-turbo="false"
            className={"user-central__logout"}
          />
        </DropDown>
      </li>
      {Preload.current_member?.can.chat ? (
        <li className={"flex chat__link"}>
          <ChatButton duo={duo} />
        </li>
      ) : null}
    </ul>
  );
}
