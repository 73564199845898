import React, { useRef } from "react";
import { Field } from "redux-form";
import { defer } from "lodash";

interface AddOptionsFieldsTypes {
  fields: Array<unknown> & { remove(i: number): void };
  start?: number;
  label?: string;
}

// Renders editable list of selection options
// Component to be used in FieldArray
export default function AddOptionsFields({
  fields,
  start,
  label,
}: AddOptionsFieldsTypes) {
  const lastInput = useRef(null);
  const focusLast = () => {
    lastInput.current && (lastInput.current as HTMLElement).focus();
  };

  return (
    <div className="control-group">
      {label ? <label className="control-label">{label}</label> : null}
      <ol className="controls flex flex-col gap-2" start={start}>
        {fields.map((fieldName, index) => (
          <li key={index}>
            <div className="input-group">
              <Field
                name={`${fieldName}.label`}
                component="input"
                type="text"
                props={fields.length === index + 1 ? { ref: lastInput } : null}
              />
              <button
                className="btn btn-danger"
                type="button"
                onClick={() => {
                  fields.remove(index);
                  defer(focusLast);
                }}
              >
                <i className="fa-regular fa-trash" />
              </button>
            </div>
          </li>
        ))}
        <li className={"flex justify-end"}>
          <button
            className="btn btn-success"
            type="button"
            onClick={() => {
              fields.push({ label: "" });
              defer(focusLast);
            }}
          >
            <i className="fa fa-plus" /> Auswahloption hinzufügen
          </button>
        </li>
      </ol>
    </div>
  );
}
