import { Controller } from "@hotwired/stimulus";
import { fetchApi } from "../../../helpers/reactQueryApi";
import { map } from "lodash";

// Connects to data-controller="administration--newsletter--drag-and-drop"
export default class extends Controller {
  static targets = ["entry"];

  connect() {
    this.initializeDraggable();
  }

  disconnect() {
    this.disconnectDraggable();
  }

  initializeDraggable() {
    this.entryTargets.forEach((entry) => {
      entry.setAttribute("draggable", true);
      entry.addEventListener("dragstart", this.dragstart);
      entry.addEventListener("dragover", this.dragover);
      entry.addEventListener("dragend", this.dragend);
      entry.addEventListener("drop", this.drop);
    });
  }

  disconnectDraggable() {
    this.entryTargets.forEach((entry) => {
      entry.setAttribute("draggable", false);
      entry.removeEventListener("dragstart", this.dragstart);
      entry.removeEventListener("dragover", this.dragover);
      entry.removeEventListener("dragend", this.dragend);
      entry.removeEventListener("drop", this.drop);
    });
  }

  dragstart = (event) => {
    event.target.classList.add("alert-info");
    event.dataTransfer.setData("text/plain", event.target.dataset.entryId);
  };

  dragover = (event) => {
    event.preventDefault();

    const targetElement = event.currentTarget;

    this.removeHighlight(targetElement);
    targetElement.classList.add("alert-info");
  };

  dragend = (event) => {
    event.target.classList.remove("alert-info");
  };

  drop = (event) => {
    event.preventDefault();

    const entryId = event.dataTransfer.getData("text/plain");
    const targetEntryId = event.currentTarget.dataset.entryId;

    if (entryId && targetEntryId) {
      const entryElement = this.entryElementById(entryId);
      const targetElement = this.entryElementById(targetEntryId);

      this.moveEntryElement(entryElement, targetElement);
      this.persistOrder();
      this.removeHighlight(targetElement);
    }
  };

  removeHighlight(targetElement) {
    const parentElement = targetElement.parentNode;

    Array.from(parentElement.children).forEach((child) => {
      child.classList.remove("alert-info");
    });
  }

  moveEntryElement(sourceElement, targetElement) {
    const targetParent = targetElement.parentNode;

    if (sourceElement.compareDocumentPosition(targetElement) === 2) {
      // Move up
      targetParent.insertBefore(sourceElement, targetElement);
    } else {
      // Move down
      if (targetElement.nextElementSibling) {
        targetParent.insertBefore(
          sourceElement,
          targetElement.nextElementSibling,
        );
      } else {
        targetParent.appendChild(sourceElement);
      }
    }
  }

  entryElementById(entryId) {
    return this.element.querySelector(`[data-entry-id="${entryId}"]`);
  }

  persistOrder() {
    const order = map(
      this.element.getElementsByClassName("activity"),
      (el) => el.dataset.entryId,
    );

    fetchApi("/administration/newsletter/settings/update_upcoming_activities", {
      method: "POST",
      body: { order },
    }).catch((error) => {
      window.Turbo.visit(
        "/administration/newsletter/settings/upcoming_activities",
        {
          frame: "upcoming_activities",
          action: "replace",
        },
      );
      console.error(error);
    });
  }
}
