import { get } from "lodash";
import { createSelector } from "reselect";

const widgetbarPath = ["administration", "widgetbar"];
const editWidgetbarPath = [...widgetbarPath, "edit"];

export const getWidgets = (state) =>
  get(state, [...editWidgetbarPath, "widgets"]);

export const getWidgetbarGroupId = (state) =>
  get(state, [...editWidgetbarPath, "groupId"]);

export const getWidgetbarSuccessfulSave = (state) =>
  get(state, [...editWidgetbarPath, "successfulSave"]);

export const getWidgetbarErrors = (state) =>
  get(state, [...widgetbarPath, "errors"]);

export const getWidgetbarLoadingState = (state) =>
  get(state, [...widgetbarPath, "fetchWidgetbar", "loading"]);

export const getSelectedWidgetIndex = (state) =>
  get(state, [...editWidgetbarPath, "selectedWidgetIndex"]);

export const getCalendars = (state) =>
  get(state, [...editWidgetbarPath, "calendars"]);

export const getWidgetbarIdentifier = (state) =>
  get(state, [...editWidgetbarPath, "identifier"]);

export const getSelectedWidget = createSelector(
  [getWidgets, getSelectedWidgetIndex],
  (widgets, index) => widgets[index],
);

export const getWidgetbars = (state) =>
  get(state, [...widgetbarPath, "index", "widgetbars"]);
