import React from "react";
import { createRoot } from "react-dom/client";
import connectBackbone from "./connectBackbone";
import { Wrapper } from "helpers/queryClient";

const createHandleClose = (view, rawEl) => () => {
  const el = rawEl || "VIEW_ROOT";
  const root = view._reactRoots[el];

  if (root) {
    // debug("UNMOUNT component at", el || "view root");
    view._reactRoots[el] = null;
    root.unmount();
  }

  return view;
};

const createHandleRender = (view, rawEl, component) => () => {
  const el = rawEl || "VIEW_ROOT";
  const $container = rawEl ? view.$(rawEl) : view.$el;
  let root;

  if (rawEl) {
    // root el is inside of rendered view and thus recreated on render
    const oldRoot = view._reactRoots[el];
    if (oldRoot) oldRoot.unmount();
    view._reactRoots[el] = root = createRoot($container.get(0));
  } else {
    // root is equal to view.el which stays the same on renders so we can keep the root
    root = view._reactRoots[el];
    if (!root) root = view._reactRoots[el] = createRoot($container.get(0));
  }

  // debug("MOUNT", getDisplayName(component), "at", el);
  root.render(
    React.createElement(Wrapper, null, React.createElement(component)),
  );

  return view;
};

const createAdaptBackboneView = () => {
  let componentsMap = {};

  const adaptBackboneView = ({
    view,
    el,
    component: componentName,
    mapProps,
    listenTo,
    standalone = false,
  }) => {
    const component = componentsMap[componentName];
    if (component === undefined) {
      throw new Error(
        `No component named "${componentName}" registered for adapter usage.`,
      );
    }

    const connectedComponent = connectBackbone({ view, mapProps, listenTo })(
      component,
    );

    // Store react roots
    view._reactRoots = view._reactRoots || {};

    const handleRender = createHandleRender(view, el, connectedComponent);
    const handleClose = createHandleClose(view, el);

    if (standalone) {
      view.render = handleRender;
      view.close = handleClose;
    } else {
      view.on("render", handleRender);
      view.once("close", () => {
        view.off("render", handleRender);
        handleClose();
      });
    }
  };
  componentsMap.componentsMap = componentsMap;

  adaptBackboneView.addComponents = (extraComponentMap) => {
    componentsMap.componentsMap = componentsMap = {
      ...componentsMap,
      ...extraComponentMap,
    };
  };

  return adaptBackboneView;
};

export default createAdaptBackboneView;
