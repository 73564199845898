import React from "react";
import { compose, setDisplayName, toClass } from "recompose";
import { DropTarget } from "react-dnd";
import { connect } from "react-redux";
import { isEmpty, map } from "lodash";
import { DraggableWidget } from "components/administration/widgetbar/Widget";
import {
  getWidgets,
  getSelectedWidgetIndex,
} from "helpers/selectors/widgetbarSelectors";
import {
  addOrMoveWidget,
  selectWidget,
} from "actions/administration/widgetbar";
import { Widget } from "components/administration/widgetbar/Widget";

const layoutTarget = {
  drop({ index, addOrMoveWidget }, monitor) {
    addOrMoveWidget(monitor.getItem(), index);
  },
};

const WidgetbarDragNDrop = compose(
  connect(null, (dispatch) => ({
    selectWidget: (index) => dispatch(selectWidget(index)),
    addOrMoveWidget: (widget, index) =>
      dispatch(addOrMoveWidget(widget, index)),
  })),
  DropTarget("widget", layoutTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver({ shallow: true }),
    widgetType: monitor.getItem() ? monitor.getItem().type : null,
  })),
  toClass,
  setDisplayName("WidgetbarDragNDrop"),
)(({ connectDropTarget, isOver, index, children, selectWidget, widgetType }) =>
  connectDropTarget(
    <div onClick={() => selectWidget(index)}>
      {widgetType && isOver ? (
        <div style={{ opacity: 0.2 }}>
          <Widget widgetType={widgetType} />
        </div>
      ) : null}
      {children}
    </div>,
  ),
);

export default compose(
  connect((state) => ({
    widgets: getWidgets(state),
    selectedWidgetIndex: getSelectedWidgetIndex(state),
  })),
  setDisplayName("WidgetbarLayout"),
)(({ widgets, selectedWidgetIndex }) => (
  <div className="border-box p-4">
    <div>
      <h4>{I18n.t("js.administration.widgetbars.layout.headline")}</h4>
    </div>
    <div className="widgetbar-layout flex flex-col gap-2 mt-3">
      {isEmpty(widgets) ? (
        <WidgetbarDragNDrop index={0}>
          {I18n.t("js.administration.widgetbars.empty_drop_target_hint")}
        </WidgetbarDragNDrop>
      ) : null}
      {map(widgets, (widget, index) => (
        <WidgetbarDragNDrop index={index} key={index} widget={widget}>
          <DraggableWidget
            widgetType={widget.type}
            widget={widget}
            index={index}
            isSelected={index === selectedWidgetIndex}
          />
        </WidgetbarDragNDrop>
      ))}
      <WidgetbarDragNDrop index={widgets.length}>
        <div className="drag-n-drop-spacer">&nbsp;</div>
      </WidgetbarDragNDrop>
    </div>
  </div>
));
