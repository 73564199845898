import PropTypes from "prop-types";
import React from "react";
import { isEmpty, map } from "lodash";

function PropertiesReference({ properties, additional_fields }) {
  return (
    <div className="props-reference">
      {isEmpty(properties) ? (
        <p className="text-info">
          {I18n.t("js.administration.app_creator.app.no_fields_present")}
        </p>
      ) : (
        <>
          <label className="font-medium">
            {I18n.t("js.administration.app_creator.app.available_fields")}
          </label>
          <div className="border-box p-3 prose">
            {isEmpty(additional_fields) ? null : (
              <ul>
                {map(additional_fields, (field) => (
                  <li key={field} className="m-0 p-0">
                    {field}
                  </li>
                ))}
              </ul>
            )}
            <ul>
              {map(properties, (prop) =>
                !isEmpty(prop.name) && prop.type !== "image" ? (
                  <li
                    key={prop.name}
                    className={`ref-${prop.type} m-0 p-0`}
                  >{`${prop.name} [${prop.type}]`}</li>
                ) : null,
              )}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}
PropertiesReference.propTypes = {
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  additional_fields: PropTypes.arrayOf(PropTypes.string),
};

export default PropertiesReference;
