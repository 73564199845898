import type { TabbableFieldsTypes } from "./TabbableFields";
import type { FormTypes } from "components/shared/reduxForm";
import React, { useMemo } from "react";
import { FieldArray } from "redux-form";
import { get, reduce, map, isEmpty, forEach } from "lodash";
import ReduxForm from "../../shared/reduxForm";
import ModificationForm from "./modifications/ModificationForm";
import finalizeSelectionOptions from "./properties/selection/finalizeSelectionOptions";
import TabbableFields from "./TabbableFields";

// Turn ModifiedPropertySet payload into something we can easily build a form for
//
// Convert { modifications: [], parent_property_set: { properties: [] } }
// to { properties: [{ ..., modifications: { addOptions: [] }}] }
const addModificationsToProperties = ({
  modifications,
  parent_property_set: parentPropertySet,
}) => {
  const properties = get(parentPropertySet, "properties", []);
  const addedOptionsByName = reduce(
    modifications,
    (result, { add_options }) => {
      if (isEmpty(add_options)) return result;

      result[add_options.name] = add_options.options;
      return result;
    },
    {},
  );

  const propertiesWithModifications = map(properties, (property) => ({
    ...property,
    modifications: { addOptions: addedOptionsByName[property.name] || [] },
  }));

  return { properties: propertiesWithModifications };
};

// Turn ModifiedPropertySetForm data into something we can save
//
// Convert { properties: [{ name: 'foo', modifications: { addOptions: [{ label: 'Bar' }] }}] }
// to { modifications: [{ add_options: { name: 'foo', options: [{ label: 'Bar', value: 'bar' }] }}]}
const pullModificationsFromProperties = ({ properties }) => {
  const finalModifications: Array<{
    add_options: { name: string; options: unknown };
  }> = [];

  forEach(properties, ({ name, modifications }) => {
    if (!isEmpty(modifications.addOptions)) {
      finalModifications.push({
        add_options: {
          name,
          options: finalizeSelectionOptions(modifications.addOptions),
        },
      });
    }
  });

  return { modifications: finalModifications };
};

interface PropertiesFieldsTypes {
  fields: TabbableFieldsTypes["fields"];
}

function PropertiesFields({ fields }: PropertiesFieldsTypes) {
  return (
    <TabbableFields fields={fields}>
      {({ name, property }) => (
        <ModificationForm name={name} property={property} />
      )}
    </TabbableFields>
  );
}

export default function ModifiedPropertySetForm({
  initialValues,
  onSubmit,
  ...formProps
}: FormTypes) {
  const transformedInitialValues = useMemo(
    () =>
      addModificationsToProperties(
        initialValues as {
          modifications: unknown;
          parent_property_set: unknown;
        },
      ),
    [initialValues],
  );

  return (
    <ReduxForm
      {...formProps}
      initialValues={transformedInitialValues}
      onSubmit={(values, ...args) =>
        onSubmit(
          pullModificationsFromProperties(values as { properties: unknown }),
          ...args,
        )
      }
      renderChildren={() => (
        <FieldArray name="properties" component={PropertiesFields as never} />
      )}
    />
  );
}
