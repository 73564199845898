import React from "react";
import { isEmpty } from "lodash";
import { useParams, useSearchParams } from "helpers/tixxt-router";
import PageTitle from "components/layout/PageTitle";
import SearchResults from "./SearchResults";
import GroupNavigation from "../layout/GroupNavigation";
import { useSearchResults } from "./api";

function Content() {
  const routeParams = useParams();
  const [searchParams] = useSearchParams();

  // rebuild legacy params
  routeParams.q = searchParams.get("q");
  routeParams.module = searchParams.get("module");
  routeParams.page = searchParams.get("page");

  const {
    data: searchResults,
    isLoading,
    error,
  } = useSearchResults(routeParams);

  return (
    <>
      <PageTitle
        title={
          isEmpty(routeParams.q)
            ? I18n.t("js.global_search.search_title")
            : I18n.t("js.global_search.search_for_title", {
                search_text: routeParams.q,
              })
        }
        key="title"
      />
      {routeParams.groupSlug ? (
        <GroupNavigation key="groupNav" groupSlug={routeParams.groupSlug} />
      ) : null}
      <SearchResults
        results={searchResults}
        params={routeParams}
        loading={isLoading}
        error={error}
        key="results"
      />
    </>
  );
}

export default Content;
