import React from "react";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import MessageDetails from "components/messages/message";

export default function ShowMessagePage() {
  return (
    <>
      <PageTitle title={I18n.t("js.messages.message.title")} />
      <Subtitle className={"mb-3 mt-8"}>
        {I18n.t("js.messages.message.history")}
      </Subtitle>
      <MessageDetails />
    </>
  );
}
