import React from "react";
import { isEmpty, get } from "lodash";
import LegalTextForm from "components/administration/legalTexts/LegalTextForm";
import { useUpdateLegalText, useLegalText } from "./api";
import PageTitle from "../../layout/PageTitle";
import { useNavigate, useParams } from "helpers/tixxt-router";

function LegalTextEdit() {
  const { legalTextId } = useParams();
  const navigate = useNavigate();
  const { isLoading: loading, data: legalText } = useLegalText(legalTextId);
  const { mutate: updateLegalText } = useUpdateLegalText({
    onSuccess: () => {
      navigate(`/administration/legal_texts`);
      navigate(0);
      toastr.success(I18n.t("js.saving_successful"));
    },
    onError: () => {
      toastr.error(I18n.t("js.saving_failure"));
    },
  });

  return (
    <>
      <PageTitle
        title={I18n.t("js.administration.legal_texts.edit.title", {
          name: get(legalText, "name", ""),
        })}
      />
      <div className="btn-toolbar mb-10">
        <a
          className="btn btn-light backButton"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/administration/legal_texts`);
          }}
        >
          <i className="fa fa-arrow-left" />
        </a>
      </div>
      <div className="">
        <div className="flex flex-col gap-4 mt-8">
          {loading || isEmpty(legalText) ? (
            <div>
              <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
            </div>
          ) : (
            <>
              <div
                className="alert alert-warning"
                dangerouslySetInnerHTML={{
                  __html: Application.markdown(
                    I18n.t("js.administration.legal_texts.edit.hint_md"),
                  ),
                }}
              />
              <LegalTextForm
                initialValues={legalText}
                key={legalTextId}
                onSubmit={(values) => updateLegalText(values)}
                isEditing
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LegalTextEdit;
