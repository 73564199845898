import React, { useState } from "react";
import { DragDropContext } from "react-dnd";
import { useSelector } from "react-redux";
import HTML5Backend from "react-dnd-html5-backend";
import { pick, sortBy } from "lodash";

import Flash from "../shared/Flash";
import PageTitle from "../layout/PageTitle";
import Card from "./groupCategorySorter/Card";
import "./groupCategorySorter.css";
import { State } from "../../@types";

function GroupCategorySorter() {
  const groupCategories = useSelector((state: State) =>
    state.network.group_categories.map((model) =>
      pick(model, "plural_name", "id", "color", "order", "slug"),
    ),
  );
  const [state, setState] = useState({
    cards: sortBy(groupCategories, "order"),
    isSaving: false,
    saveSuccess: false,
    saveError: false,
  });

  async function sendData() {
    setState((curr) => ({
      ...curr,
      isSaving: true,
      saveError: false,
      saveSuccess: false,
    }));

    const response = await fetch("/administration/group-categories/order", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        order: state.cards.map((x) => x.slug),
      }),
    });

    response.status == 204
      ? setState((curr) => ({ ...curr, isSaving: false, saveSuccess: true }))
      : setState((curr) => ({ ...curr, isSaving: false, saveError: true }));
  }

  function moveCard(dragIndex, hoverIndex) {
    const dragCard = state.cards[dragIndex];
    const newCards = [...state.cards];
    newCards.splice(dragIndex, 1);
    newCards.splice(hoverIndex, 0, dragCard);
    setState((curr) => ({ ...curr, cards: newCards }));
  }

  return (
    <div>
      <PageTitle
        title={I18n.t(
          "js.administration.group_manager.categories_order_edit.title",
        )}
      />

      <div className="btn-toolbar mb-6">
        <a
          className="btn btn-light"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            window.history.back();
          }}
        >
          <i className="fa-regular fa-arrow-left" />
        </a>
        <a
          className="btn btn-primary"
          href="/administration/group-categories/new"
        >
          {I18n.t("js.administration.group_manager.new_category")}
        </a>
      </div>

      {state.saveSuccess ? (
        <Flash alert="success">
          {I18n.t(
            "js.administration.group_manager.categories_order_edit.success",
          )}
        </Flash>
      ) : null}
      {state.saveError ? (
        <Flash alert="error">
          {I18n.t(
            "js.administration.group_manager.categories_order_edit.error",
          )}
        </Flash>
      ) : null}
      <div className="boxed-form-fields form-horizontal group-category-sorter">
        <p className="text-sm">
          {I18n.t("js.administration.group_manager.categories_order_edit.help")}
        </p>
        <div className="sortable-region">
          <ul className="sortable-view unstyled flex flex-col gap-2">
            {state.cards.map((category, i) => (
              <Card
                key={category.id}
                index={i}
                id={category.id}
                text={category.plural_name}
                moveCard={moveCard}
                customBackgroundColor={category.color}
              />
            ))}
          </ul>
        </div>
      </div>

      <fieldset className="actions flex justify-end gap-2 mt-4">
        <a className="btn btn-light" href="/administration/groups">
          {I18n.t("js.cancel")}
        </a>
        <button
          className="btn btn-primary"
          onClick={sendData}
          disabled={state.isSaving}
        >
          {I18n.t("js.administration.group_manager.categories_order_edit.save")}
        </button>
      </fieldset>
    </div>
  );
}

// getting the data from the Backbone Model because Card colors
// do not get directly saved to the store, when editing/creating a category
// store alternative : groupCategories: state.network.group_categories
export default DragDropContext(HTML5Backend)(GroupCategorySorter);
