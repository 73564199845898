import React, { ReactNode } from "react";
import { map } from "lodash";
import {
  canAddCalendar,
  groupCalendarsByGroup,
  groupCalendarsByType,
} from "components/appointments/calendars/helpers";
import { Calendar } from "../../../@types/appointments";
import CalendarList from "components/appointments/calendars/CalendarList";
import { Group } from "../../../@types";

type GroupWithCalendars = Group & {
  calendars: Calendar[];
};

export default function CalendarListView({
  renderActions,
  calendars,
  isLoading,
  addCalendarsAllowed = false,
}: {
  renderActions: (calendar: Calendar | undefined) => ReactNode[];
  isLoading: boolean;
  addCalendarsAllowed?: boolean;
  calendars?: Calendar[];
}) {
  const groupedCalendars = groupCalendarsByType(calendars);
  const groupsWithCalendars = groupCalendarsByGroup(calendars);

  return (
    <div className="calendar-manager">
      {isLoading && I18n.t("js.loading")}
      {map(groupedCalendars, (contextCalendars, type) => {
        return (
          <CalendarList
            key={type}
            title={I18n.t(`js.calendars.${type}_calendars`)}
            calendars={contextCalendars}
            renderActions={renderActions}
            addCalendar={
              canAddCalendar(type) && addCalendarsAllowed ? { type } : undefined
            }
          />
        );
      })}
      {map(groupsWithCalendars, (group: GroupWithCalendars) => {
        return (
          <CalendarList
            key={group.id}
            title={group.name}
            calendars={group.calendars}
            renderActions={renderActions}
            addCalendar={
              group.can.create_calendars && addCalendarsAllowed
                ? { type: "group", parent_id: group.id }
                : undefined
            }
          />
        );
      })}
    </div>
  );
}
