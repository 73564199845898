import React, { useState, useEffect } from "react";

import MemberRow, { Member } from "../shared/MemberRow";

export type SpeedDialWidgetProps = {
  headline?: string;
  memberships: Member[];
  show_age?: boolean;
};

const SpeedDialWidget: React.FC<SpeedDialWidgetProps> = ({
  headline,
  memberships,
  show_age,
}) => {
  const [members, setMembers] = useState<Member[]>([]);

  useEffect(() => {
    setMembers(memberships);
  }, [memberships]);

  return (
    <div className="widget speed-dial-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      <ul className="memberships unstyled space-y-2">
        {members.length === 0 ? (
          <li>{I18n.t("js.widgets.speed_dial.empty_list_text")}</li>
        ) : (
          members.map((member) => (
            <MemberRow key={member.id} member={member} showAge={show_age} />
          ))
        )}
      </ul>
    </div>
  );
};

export default SpeedDialWidget;
