import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import type { Dispatch } from "redux";
import { currentMemberSelector } from "selectors/environment";
import DropDown, {
  DropDownItem,
  DropDownSubMenu,
} from "components/shared/DropDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light } from "@fortawesome/fontawesome-svg-core/import.macro";

interface TranslationControlsProps {
  object_language: string;
  type: string;
  target_id: string;
  resetTranslation: (language: string) => void;
  translateObject: (content: string, language: string) => void;
}

interface Language {
  language: string;
  name: string;
  supports_formality: boolean;
}

export default function TranslationControls({
  type,
  target_id,
  object_language,
  resetTranslation,
  translateObject,
}: TranslationControlsProps) {
  const dispatch: Dispatch = useDispatch();
  const currentMember = useSelector(currentMemberSelector);
  const { data: languages } = useQuery<Language[]>("/translations/languages");
  const [primaryLanguages, setPrimaryLanguages] = useState<Language[]>([]);
  const [secondaryLanguages, setSecondaryLanguages] = useState<Language[]>([]);
  const [selectedLanguage, setSelectedLanguage] = useState(
    object_language || "ORIGINAL",
  );

  useEffect(() => {
    const [primary, secondary] = categorizeLanguages();
    setPrimaryLanguages(primary);
    setSecondaryLanguages(secondary);
  }, [languages, selectedLanguage, currentMember]);

  function handleLanguageSelect(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    language: string,
  ) {
    event.preventDefault();
    dispatch({
      type: "currentMember/LANGUAGE/SET_LAST_USED",
      payload: language,
    });

    translateActivityReply(language);
    setSelectedLanguage(language);
  }

  function translateActivityReply(targetLanguage: string) {
    if (targetLanguage === "ORIGINAL") {
      resetTranslation(targetLanguage);
    } else {
      fetch(`/translations/${targetLanguage}/${type}/${target_id}`)
        .then((response) => response.json())
        .then((data) => {
          translateObject(data.text, targetLanguage);
        })
        .catch((e) => {
          console.error(e);
          toastr.error(
            I18n.t("js.activitystreams.activity.translation_controls.error"),
          );
        });
    }
  }
  function categorizeLanguages() {
    const primaryLanguageKeys = [
      currentMember?.last_used_translation_language,
      "DE",
      "EN-GB",
      "ES",
      "FR",
    ];
    const primary =
      languages
        ?.filter((lang) => primaryLanguageKeys.includes(lang.language))
        .sort(
          (a, b) =>
            primaryLanguageKeys.indexOf(a.language) -
            primaryLanguageKeys.indexOf(b.language),
        ) || [];

    const secondary =
      languages?.filter(
        (lang) => !primaryLanguageKeys.includes(lang.language),
      ) || [];

    return [primary, secondary];
  }

  function renderLanguageListItem(lang: Language) {
    return (
      <a
        className={classNames("dropdown-item translate-menu-item", {
          disabled: selectedLanguage === lang.language,
        })}
        href="#"
        onClick={(event) => handleLanguageSelect(event, lang.language)}
      >
        {lang.name}
      </a>
    );
  }

  return (
    <div className="translation-controls">
      <a
        className="translate-button btn btn-sm text-muted dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-label="Translate"
      >
        <FontAwesomeIcon icon={light("language")} />
      </a>
      <DropDown>
        {selectedLanguage !== "ORIGINAL" && (
          <DropDownItem>
            <a
              className="dropdown-item translate-menu-item"
              href="#"
              onClick={(event) => handleLanguageSelect(event, "ORIGINAL")}
            >
              {I18n.t(
                "js.activitystreams.activity.translation_controls.show_original",
              )}
            </a>
          </DropDownItem>
        )}
        {primaryLanguages.map((lang) => (
          <DropDownItem key={lang.language}>
            {renderLanguageListItem(lang)}
          </DropDownItem>
        ))}
        {secondaryLanguages.length > 0 && (
          <DropDownSubMenu
            text={I18n.t(
              "js.activitystreams.activity.translation_controls.more",
            )}
            side={window.isApp ? "left" : "right"}
          >
            {secondaryLanguages.map((lang) => (
              <DropDownItem key={lang.language}>
                {renderLanguageListItem(lang)}
              </DropDownItem>
            ))}
          </DropDownSubMenu>
        )}
      </DropDown>
    </div>
  );
}
