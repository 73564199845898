import React from "react";
import {
  useActivitystreamWidgetbar,
  WidgetResponse,
} from "components/widgetbars/api";
import AdminMembersWidget from "./widgets/AdminMembersWidget";
import AppointmentsWidget from "./widgets/AppointmentsWidget";
import BirthdayWidget from "./widgets/BirthdayWidget";
import GroupInfoWidget from "./widgets/GroupInfoWidget";
import NewestMembersWidget from "./widgets/NewestMembersWidget";
import OnlineMembersWidget from "./widgets/OnlineMembersWidget";
import RssWidget from "./widgets/RssWidget";
import SpeedDialWidget from "./widgets/SpeedDialWidget";
import TextWidget from "./widgets/TextWidget";

type Props = {
  group_id?: string;
  slug: string;
};

function renderWidgetComponent(widget: WidgetResponse) {
  switch (widget.type) {
    case "appointments":
      return <AppointmentsWidget key={widget.id} {...widget} />;
    case "online_members":
      return <OnlineMembersWidget key={widget.id} {...widget} />;
    case "admin_members":
      return <AdminMembersWidget key={widget.id} {...widget} />;
    case "rss":
      return <RssWidget key={widget.id} {...widget} />;
    case "speed_dial":
      return <SpeedDialWidget key={widget.id} {...widget} />;
    case "group_info":
      return <GroupInfoWidget key={widget.id} {...widget} />;
    case "newest_members":
      return <NewestMembersWidget key={widget.id} {...widget} />;
    case "text":
      return <TextWidget key={widget.id} {...widget} />;
    case "birthday":
      return <BirthdayWidget key={widget.id} {...widget} />;
  }
}

const ActivitystreamWidgetbar = ({ group_id, slug }: Props) => {
  const { data, isLoading, isError } = useActivitystreamWidgetbar({
    group_id,
    slug,
  });

  if (isLoading) {
    const loadingText = I18n.t("js.widgets.widgetbar.loading_widgets");
    return (
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated bg-gray-300"
          style={{ width: "100%" }}
        >
          {loadingText}
        </div>
      </div>
    );
  }

  if (isError) {
    const errorText = I18n.t("js.widgets.widgetbar.loading_error");
    return <div className="alert">{errorText}</div>;
  }

  return (
    <div className="widgets space-y-12">
      {data?.widgets.map(renderWidgetComponent)}
    </div>
  );
};

export default ActivitystreamWidgetbar;
