import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--toggle"
// Can be set on an element containing radio buttons with value 0 and 1 (target will be shown when 1 is checked)
//   @see app/views/administration/appointment_settings/_form.html.haml
// or on a single checkbox (target will be shown when checkbox is checked)
//   @see app/views/administration/newsletter_settings/_form.html.haml
export default class extends Controller {
  connect() {
    this.target = this.element.dataset.toggleTarget;
    this.toggle();
  }

  toggle() {
    let show;
    if (this.element.nodeName === "INPUT") {
      show = this.element.checked;
    } else {
      show =
        this.element.querySelector(
          '[data-action="shared--toggle#toggle"]:checked',
        )?.value === "1";
    }

    document
      .querySelectorAll(this.target)
      .forEach((elem) => elem.classList.toggle("hidden", !show));
  }
}
