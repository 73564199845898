import React from "react";

interface CheckboxesTypes {
  newWindow?: boolean;

  onChangeNewWindow(...args: unknown[]): unknown;

  isPublic?: boolean;

  onChangePublic(...args: unknown[]): unknown;
}

function Checkboxes({
  newWindow,
  onChangeNewWindow,
  isPublic,
  onChangePublic,
}: CheckboxesTypes) {
  return (
    <div>
      <label>
        {I18n.t("js.administration.navigation_items.option_input_label")}
      </label>
      <div className="checkbox-container">
        <label className="checkbox flex gap-2">
          <span>{I18n.t("js.administration.navigation_items.new_window")}</span>
          <input
            type="checkbox"
            name="new_window"
            checked={newWindow}
            onChange={(e) => onChangeNewWindow(e.target.checked)}
          />
        </label>
        <label className="checkbox flex gap-2">
          <span>
            {I18n.t("js.administration.navigation_items.visible_for_guests")}
          </span>
          <input
            type="checkbox"
            name="public"
            checked={isPublic}
            onChange={(e) => onChangePublic(e.target.checked)}
          />
        </label>
      </div>
    </div>
  );
}

export default Checkboxes;
