import React from "react";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";

import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import { useSelector } from "react-redux";
import { networkNameSelector } from "selectors/environment";
import { useSearchParams } from "helpers/tixxt-router";
import GroupCategorySections from "components/shared/GroupCategorySections";

export default function DirectoryUserbar({ map }: { map?: boolean }) {
  const networkName = useSelector(networkNameSelector);
  const [searchParams] = useSearchParams();
  const query = searchParams.toString();
  const path = map ? "/map" : "/members";
  const pathAndQuery = query.length > 0 ? `${path}?${query}` : path;

  return (
    <>
      <TixxtModuleSwitcher activeModule={undefined} />
      <ContextNavigation>
        <UserbarSection>
          <UserbarLink to={pathAndQuery}>{networkName}</UserbarLink>
        </UserbarSection>
        <GroupCategorySections>
          {({ slug, name, favorited }, { collapsed }) => (
            <UserbarLink
              key={slug}
              className="relative"
              to={`/groups/${slug}${pathAndQuery}`}
              showWhileClosed={favorited}
              favorited={favorited}
              collapsed={collapsed}
            >
              {name}
            </UserbarLink>
          )}
        </GroupCategorySections>
      </ContextNavigation>
    </>
  );
}
