import React from "react";
import { DragSource } from "react-dnd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

interface WidgetTypes {
  widgetType: string;
  isDragging?: boolean;
  isSelected?: boolean;
  draggable?: boolean;

  onClick?(...args: unknown[]): unknown;
}

export function Widget({
  isDragging,
  widgetType,
  onClick,
  isSelected,
  draggable,
}: WidgetTypes) {
  return (
    <div
      className="flex items-center break-all cursor-move border-2 rounded-md border-gray-900 p-1 hover:border-primary bg-white inline-block"
      style={{ opacity: isDragging || isSelected ? 1 : 0.65 }}
      onClick={onClick}
    >
      {draggable ? (
        <FontAwesomeIcon
          className="text-primary mr-1 p-1 border-2 rounded-md border-primary"
          icon={solid("grip-dots")}
        />
      ) : null}
      <span>{I18n.t(`js.administration.widgetbars.${widgetType}.label`)}</span>
    </div>
  );
}

export const DraggableWidget = DragSource(
  "widget",
  {
    beginDrag({ widget }) {
      return widget;
    },
  } as { beginDrag(arg: unknown): unknown },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
)(({ connectDragSource, ...props }) =>
  (connectDragSource as CallableFunction)(
    Widget({ draggable: true, ...props }),
  ),
);
