import React from "react";

interface NumberFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: string;
  hint?: string;
}

function NumberField({ name, onChange, value }: NumberFieldTypes) {
  return (
    <>
      <label htmlFor={`widget-${name}-input`}>
        {I18n.t(`js.administration.widgetbars.${name}.label`)}
      </label>
      <input
        id={`widget-${name}-input`}
        onChange={onChange}
        type="number"
        min={0}
        defaultValue={value}
      />
    </>
  );
}

export default NumberField;
