import { createEventSaga } from "./helpers";
import { Presence } from "phoenix";
import { map, maxBy } from "lodash";

// Listen to presence synchronisations and dispatch them to redux
const presenceSaga = createEventSaga({
  events: (dispatch) => ({
    sync: (presence) => {
      const onlineMembers = {};
      const busyMembers = {};
      presence.list((id, { metas }) => {
        onlineMembers[id] = map(metas, (m) => parseInt(m.online_at, 10));
        busyMembers[id] = maxBy(map(metas, "status"), "updated_at");
      });
      dispatch({
        type: "chat/PRESENCE/SYNC",
        payload: { onlineMembers, busyMembers },
      });
    },
  }),
  bind: (channel, handlers) => {
    const presence = new Presence(channel);
    presence.onSync(handlers.sync.bind(null, presence));
  },
});

export default presenceSaga;
