import React from "react";
import { SubmissionError } from "redux-form";
import { useSelector, useStore } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "helpers/tixxt-router";
import GroupForm from "./form";
import EditGroupActions from "./EditGroupActions";
import { updateGroup } from "actions/groups";
import persistItem from "helpers/items/persistItem";
import TixxtPageTitle from "../layout/TixxtPageTitle";
import { fetchGroup } from "./api";
import { useQuery } from "react-query";
import { selectNetworkId } from "../../selectors/environment";
import { useLoadApp } from "../appCreator/api";

export default function EditGroup() {
  const { groupSlug } = useParams();

  const { data: group } = useQuery(["group", groupSlug], () =>
    fetchGroup(groupSlug),
  );
  const store = useStore();
  const networkId = useSelector(selectNetworkId);
  const externalAppId = `network_${networkId}:group_category_${group?.group_category_id}:group_profile`;
  const { data: app } = useLoadApp(
    { externalAppId },
    { enabled: !!group?.group_category_id },
  );

  const onSubmit = async (
    values,
    dispatch,
    { externalAppId, externalItemId },
  ) => {
    const { profile, ...group } = values;

    await persistItem(app, store.dispatch, {
      externalAppId,
      externalItemId,
      values: profile,
    });

    const groupResult = await dispatch(
      updateGroup({ groupSlug, body: { group } }),
    );
    if (groupResult.error) throw new SubmissionError();

    return groupResult;
  };

  return (
    <>
      <TixxtPageTitle />
      {!isEmpty(group) ? (
        <>
          {group.can.archive ? <EditGroupActions {...group} /> : null}
          {!group.archived ? (
            <GroupForm
              {...group}
              onSubmit={onSubmit}
              formName={`group-${group.id}`}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
}
