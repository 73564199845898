import React from "react";
import PropTypes from "prop-types";
import { compose, withState, withHandlers, lifecycle } from "recompose";
import { DropTarget } from "react-dnd";
import { map, isEmpty, get } from "lodash";

import Card from "./Card";

const columnTitleTarget = {
  hover(props) {
    props.setIsOverColumnTitle(true);
    props.setHoveredCardId(null);
  },
  drop(props) {
    props.setIsOverColumnTitle(false);
    return {
      column: props.value,
      fieldName: props.columnField.name,
      insertAfterCardId: null,
    };
  },
};

const ColumnTitle = compose(
  DropTarget("card", columnTitleTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  })),
  withHandlers({
    changeIsOver:
      ({ setIsOverColumnTitle }) =>
      () =>
        setIsOverColumnTitle(false),
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.isOver !== this.props.isOver && !nextProps.isOver) {
        this.props.changeIsOver(false);
      }
    },
  }),
)(({ title, connectDropTarget }) =>
  connectDropTarget(<h5 className="title flex justify-center">{title}</h5>),
);
ColumnTitle.propTypes = {
  title: PropTypes.string,
};

const columnTarget = {
  hover(props) {
    props.setHoveredCardId(null);
  },
  drop(props) {
    return {
      column: props.value,
      fieldName: props.columnField.name,
      insertAfterCardId: props.hoveredCardId,
    };
  },
};

const Column = compose(
  DropTarget("card", columnTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  })),
  withState("currentCard", "setCurrentCard", {}),
  withState("isOverColumnTitle", "setIsOverColumnTitle", false),
)((props) => {
  return !isEmpty(props.value)
    ? props.connectDropTarget(
        <div
          className={`column flex flex-col gap-2 p-3 overflow-y-auto h-screen w-80 bg-gray-100`}
        >
          <ColumnTitle
            title={props.label}
            setIsOverColumnTitle={props.setIsOverColumnTitle}
            columnField={props.columnField}
            setHoveredCardId={props.setHoveredCardId}
          />
          <div className="cards">
            <div className="cards-container flex flex-col gap-2">
              {props.isOverColumnTitle ? (
                <div
                  className="new-position rounded bg-neutral"
                  style={{ height: props.cardHeight }}
                />
              ) : null}
              {map(props.cards, (card) => (
                <Card
                  key={card.id}
                  title={
                    !isEmpty(props.cardTitleField)
                      ? get(card, ["values", props.cardTitleField.name])
                      : null
                  }
                  filesCount={
                    !isEmpty(props.cardFileField)
                      ? get(card, [
                          "values",
                          props.cardFileField.name,
                          "length",
                        ])
                      : 0
                  }
                  memberships={
                    !isEmpty(props.cardMembershipField)
                      ? get(card, ["values", props.cardMembershipField.name])
                      : []
                  }
                  description={
                    !isEmpty(props.cardDescriptionField)
                      ? get(card, ["values", props.cardDescriptionField.name])
                      : ""
                  }
                  date={
                    props.cardDateField
                      ? {
                          value: get(card, [
                            "values",
                            props.cardDateField.name,
                          ]),
                          format: props.cardDateField.format,
                          label: props.cardDateField.label,
                        }
                      : null
                  }
                  tags={
                    !isEmpty(props.cardTagField)
                      ? get(card, ["values", props.cardTagField.name])
                      : []
                  }
                  logo={
                    !isEmpty(props.cardLogoField)
                      ? get(card, ["values", props.cardLogoField.name])
                      : null
                  }
                  setCard={() => props.setCurrentCard(card)}
                  onMoveCard={props.onMoveCard}
                  cardId={card.id}
                  column={props.value}
                  fieldName={props.columnField.name}
                  setHoveredCardId={props.setHoveredCardId}
                  setCardHeight={props.setCardHeight}
                  cardHeight={props.cardHeight}
                  appId={props.appId}
                  isArchive={props.isArchive}
                  setFilteredByTag={props.setFilteredByTag}
                  setFilteredByMember={props.setFilteredByMember}
                  archivedBy={
                    !isEmpty(card.archived_by)
                      ? card.archived_by.displayValue
                      : null
                  }
                  archivedAt={
                    !isEmpty(card.archived_at) ? card.archived_at : null
                  }
                  canMoveCard={
                    get(card, "can.update") &&
                    get(props.columnField, "can.update")
                  }
                />
              ))}
              {props.hasMoreItems ? (
                <div className="more-items-link" onClick={props.loadMoreItems}>
                  {I18n.t("js.apps.wall.show_more_items")}{" "}
                  <i className="fa fa-chevron-down" />
                </div>
              ) : null}
              {props.totalArchivedItems > 0 ? (
                <a href={`${props.appUrl}/items/archive`}>{`${I18n.t(
                  "js.apps.wall.archived_cards",
                )}: ${props.totalArchivedItems}`}</a>
              ) : null}
            </div>
          </div>
        </div>,
      )
    : null;
});
Column.propTypes = {
  cardTitleField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardDescriptionField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardLogoField: PropTypes.shape({
    name: PropTypes.string,
  }),
  columnField: PropTypes.shape({
    name: PropTypes.string,
  }),
  onMoveCard: PropTypes.func,
  cardFileField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardMembershipField: PropTypes.shape({
    name: PropTypes.string,
  }),
  cardTagField: PropTypes.shape({
    name: PropTypes.string,
  }),
  hasMoreItems: PropTypes.bool,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      attributes: PropTypes.shape({}),
    }),
  ),
  connectDropTarget: PropTypes.func,
  setCurrentCard: PropTypes.func,
  parseMarkdown: PropTypes.func,
  loadMoreItems: PropTypes.func,
  setHoveredCardId: PropTypes.func.isRequired,
  setCardHeight: PropTypes.func,
  setFilteredByTag: PropTypes.func,
  setFilteredByMember: PropTypes.func,
  cardHeight: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.string,
  hoveredCardId: PropTypes.string,
  loadMoreUrl: PropTypes.string,
  appId: PropTypes.string,
  isLast: PropTypes.bool,
  isArchive: PropTypes.bool,
  cardDateField: PropTypes.shape([
    {
      format: PropTypes.string,
      label: PropTypes.string,
    },
    PropTypes.string,
  ]),
  totalArchivedItems: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  appUrl: PropTypes.string,
};

export default compose(withState("hoveredCardId", "setHoveredCardId", null))(
  Column,
);
