import MultiselectField from "components/administration/widgetbar/fields/MultiselectField";
import NumberField from "components/administration/widgetbar/fields/NumberField";
import RadioField from "components/administration/widgetbar/fields/RadioField";
import SelectField from "components/administration/widgetbar/fields/SelectField";
import TextareaField from "components/administration/widgetbar/fields/TextareaField";
import TextField from "components/administration/widgetbar/fields/TextField";
import AdditionalCalendarsField from "components/administration/widgetbar/fields/AdditionalCalendarField";
import { changeValue } from "actions/administration/widgetbar";
import { getWidgetbarGroupId } from "helpers/selectors/widgetbarSelectors";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { keys, remove, map, includes, slice, last } from "lodash";

// if scope includes the value 'all', then remove all other selections
// if scope includes 'all' and it is the latest selected value, then this should be the only selection
// if scope includes 'all' and an other value were selected, then remove 'all' out of the selection
const handleMultiselectLogic = (eventOrNewVal, valueChange, name) => {
  if (includes(eventOrNewVal, "all")) {
    if (last(eventOrNewVal) === "all") {
      valueChange({
        propertyKey: name,
        data: ["all"],
      });
    } else {
      valueChange({
        propertyKey: name,
        data: [...slice(eventOrNewVal, 1)],
      });
    }
  } else {
    valueChange({
      propertyKey: name,
      data: eventOrNewVal,
    });
  }
};

const withOnChange = compose(
  connect(
    (state) => ({
      groupId: getWidgetbarGroupId(state),
    }),
    (dispatch) => ({
      valueChange: ({ propertyKey, data }) =>
        dispatch(changeValue(propertyKey, data)),
    }),
  ),
  withHandlers({
    onChange:
      ({ valueChange, name }) =>
      (eventOrNewVal) => {
        // MultiSelect field doesn't return an event
        if (eventOrNewVal instanceof Array) {
          handleMultiselectLogic(
            map(eventOrNewVal, (hash) => hash.value),
            valueChange,
            name,
          );
        } else {
          valueChange({
            propertyKey: name,
            data: eventOrNewVal.target.value,
          });
        }
      },
  }),
);

export const PropTypeFields = {
  headline: withOnChange(TextField),
  content: withOnChange(TextareaField),
  renderer: withOnChange(SelectField),
  profile_fields: withOnChange(MultiselectField),
  scope: withOnChange(MultiselectField),
  count: withOnChange(NumberField),
  show_age: withOnChange(RadioField),
  sort: withOnChange(RadioField),
  url: withOnChange(TextField),
  position: withOnChange(NumberField),
  additional_calendar_ids: withOnChange(AdditionalCalendarsField),
};

export const getOptions = (name, possibleOptions) => {
  const options = possibleOptions[name];

  switch (name) {
    case "profile_fields":
      return options.map((o) => ({
        value: o.name,
        label: o.label,
      }));
    case "additional_calendar_ids":
      return options.map((o) => ({
        value: o.id,
        label: o.name,
      }));
    case "scope":
      return options.map((o) => ({
        value: o,
        label: I18n.t(`js.administration.widgetbars.selections.${o}`),
      }));
    default:
      return [];
  }
};

const checkIfHintShouldShow = (widget, property) => {
  const MembersHeadline =
    /(newest|online)_members/.test(widget.type) && property === "headline";
  const GroupInformationText =
    widget.type === "group_info" && property === "content";

  return MembersHeadline || GroupInformationText;
};

export const generateHint = (widget, property, groupId) => {
  if (checkIfHintShouldShow(widget, property)) {
    if (widget.type == "group_info") {
      return I18n.t(
        `js.administration.widgetbars.hints.${widget.type}.${property}_group`,
        { percent: "%" },
      );
    }

    return I18n.t(
      `js.administration.widgetbars.hints.${widget.type}.${property}_${
        groupId ? "group" : "network"
      }`,
      { percent: "%" },
    );
  }
  return null;
};

export const getWidgetName = (slug) => {
  const prefix = "js.administration.widgetbars.names.";

  if (slug === "as-:slug") {
    return I18n.t(prefix + "default_network");
  } else {
    return I18n.t(prefix + "default_group");
  }
};

const widgets = (type) => widgetConfig[type];

export const widgetTypes = (identifier) => {
  let types = keys(widgetConfig);

  if (scope(identifier) !== "group") {
    remove(types, (widgetType) => widgetType === "group_info");
  }

  return types;
};

export const scopeSelection = () => {
  return ["all", "personal", "additional_calendar_ids", "groups"];
};

// Returns true if given identifier belongs to widgetbar in group
export const scope = (identifier) =>
  /^g-/.test(identifier) ? "group" : "network";

export const propTypeSelections = {
  renderer: ["markdown", "html_sanitized"],
  show_age: ["true", "false"],
  sort: ["asc", "desc"],
};

const widgetConfig = {
  admin_members: {
    type: "admin_members",
    properties: {
      headline: "",
      profile_fields: [],
    },
  },
  appointments: {
    type: "appointments",
    properties: {
      headline: "",
      scope: ["personal"],
      additional_calendar_ids: [],
      count: 10,
    },
  },
  birthday: {
    type: "birthday",
    properties: {
      headline: "",
      count: 5,
      show_age: "false", // Backend requires boolean. Check submit function where the value type is changed
      profile_fields: [],
    },
  },
  group_info: {
    type: "group_info",
    properties: {
      headline: "",
      content: "%{profile_image}" + "" + "## %{group_name}",
      renderer: "markdown",
    },
  },
  newest_members: {
    type: "newest_members",
    properties: {
      headline: "",
      count: 5,
      profile_fields: [],
    },
  },
  online_members: {
    type: "online_members",
    properties: {
      headline: "",
      count: 5,
      profile_fields: [],
    },
  },
  rss: {
    type: "rss",
    properties: {
      headline: "",
      url: null,
      count: 12,
    },
  },
  speed_dial: {
    type: "speed_dial",
    properties: {
      headline: "",
      count: 15,
      profile_fields: [],
    },
  },
  text: {
    type: "text",
    properties: {
      headline: "",
      content: "",
      renderer: "markdown",
    },
  },
};

export default widgets;
