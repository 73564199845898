import React, { useRef } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import Tags from "./Tags";

export default function Info({ logo, description, tags, setFilteredByTag }) {
  const descriptionContainer = useRef(null);

  return (
    <div className="card-info flex flex-col gap-2">
      {!isEmpty(logo) ? (
        <div className="logo-container">
          <img className="logo rounded" src={`/api/storage${logo.url}/get`} />
        </div>
      ) : null}
      {!isEmpty(description) ? (
        <div className="description-container">
          <div
            ref={descriptionContainer}
            className="description-text line-clamp-3 break-words prose"
            dangerouslySetInnerHTML={{
              __html: Application.markdown(description),
            }}
          />
          {descriptionContainer?.current?.clientHeight > 64 ? (
            <div className="description-overlay" />
          ) : null}
        </div>
      ) : null}
      {!isEmpty(tags) ? (
        <Tags tags={tags} setFilteredByTag={setFilteredByTag} />
      ) : null}
    </div>
  );
}
Info.propTypes = {
  logo: PropTypes.shape({
    url: PropTypes.string,
  }),
  description: PropTypes.string,
  setDescriptionContainer: PropTypes.func,
  setFilteredByTag: PropTypes.func,
  parseMarkdown: PropTypes.func,
  descriptionContainer: PropTypes.shape({
    clientHeight: PropTypes.number,
  }),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
};
