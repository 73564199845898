import React from "react";
import { useNavigate, useParams } from "helpers/tixxt-router";
import {
  CreateCalendarParams,
  UpdateCalendarParams,
  useCalendar,
  useUpdateCalendar,
} from "components/appointments/calendars/api";
import CalendarForm from "components/appointments/calendars/CalendarForm";
import PageTitle from "components/layout/PageTitle";

export default function EditCalendarPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: calendar, isLoading } = useCalendar(id);

  const { mutate: updateCalendar } = useUpdateCalendar({
    onSuccess: () => {
      toastr.success(I18n.t("js.saving_successful"));
      navigate(`/calendars/manage`);
    },
    onError: () => {
      toastr.error(I18n.t("js.saving_failure"));
    },
  });

  const handleSubmit = (
    params: UpdateCalendarParams | CreateCalendarParams,
  ) => {
    updateCalendar(params as UpdateCalendarParams);
  };

  return (
    <>
      <PageTitle title={I18n.t("calendars.edit_calendar")} />
      {!isLoading && calendar ? (
        <CalendarForm calendar={calendar} onSubmit={handleSubmit} />
      ) : (
        I18n.t("js.loading")
      )}
    </>
  );
}
