import React, { useRef, useState, useEffect } from "react";
import classNames from "classnames";

interface FullSizeContentTypes {
  children: ({ height }: { height: number }) => React.ReactNode;
  className?: string;
}

function FullSizeContent({ children, className }: FullSizeContentTypes) {
  const ref = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<{ height: number }>();

  useEffect(() => {
    function updateStyle() {
      if (!ref.current) return;
      if (ref.current.offsetTop == 0) return;
      setStyle({
        height: window.innerHeight - ref.current.offsetTop,
      });
    }

    updateStyle();
    window.addEventListener("resize", updateStyle);
    return () => {
      window.removeEventListener("resize", updateStyle);
    };
  }, [ref.current]);

  return (
    <div className={classNames("FullSizeContent", className)} ref={ref}>
      {ref.current && style && children(style)}
    </div>
  );
}

export default FullSizeContent;
