import React, { Fragment, useMemo } from "react";
import { useDispatch } from "react-redux";
import { map, castArray, isArray, compact, debounce } from "lodash";
import { Field } from "redux-form";
import AsyncSelect from "react-select/async";

import styles from "../../appCreator/properties/edit/editFields.module.css";
import { fetchMembersAndGroups } from "actions/memberships";

const normalizeValue = (value) => {
  if (value === null) return null; // When nothing is selected
  if (isArray(value)) return map(value, normalizeValue);

  return { id: value.value, name: value.label };
};

const formatValue = (state) =>
  map(compact(castArray(state)), ({ id, name }) => ({
    value: id,
    label: name,
  }));

interface SelectAdapterProps {
  input?: Record<PropertyKey, unknown>;
  required?: boolean;
  id?: string;
  disabled?: boolean;
  multiple?: boolean;
  className?: string;
}

function SelectAdapter({
  input,
  required,
  id,
  disabled,
  className,
  multiple,
}: SelectAdapterProps) {
  const dispatch = useDispatch();
  const loadOptions = useMemo(
    () =>
      debounce(async (value, callback) => {
        const { payload } = await dispatch(
          fetchMembersAndGroups({ q: value, groups_only: true }),
        );
        callback(formatValue(payload.entries));
      }, 375),
    [],
  );

  const props = {
    ...input,
    // workaround for mobile,
    // see ticket #9930 or issue https://github.com/JedWatson/react-select/issues/2692#issuecomment-395743446
    onBlur: (e) => e.preventDefault(),
    isClearable: !required,
    isDisabled: disabled,
    inputId: id,
    placeholder: I18n.t("js.group_select.placeholder"),
    noOptionsMessage: () => I18n.t("plugins.select2.no_match"),
    className,
    loadOptions: (value, callback) => {
      loadOptions(value, callback);
    },
    isMulti: multiple,
    styles: { menu: (base) => ({ ...base, zIndex: 999 }) },
  };

  return (
    <Fragment>
      <AsyncSelect {...props} classNamePrefix={"Select"} />
    </Fragment>
  );
}

interface GroupSelectFieldProps {
  name: string;
  multiple: boolean;
  required?: boolean;
  disabled?: boolean;
  id?: string;
}

function GroupSelectField({
  name,
  required,
  disabled,
  id,
  multiple,
}: GroupSelectFieldProps) {
  return (
    <Field
      component={SelectAdapter}
      name={name}
      required={required}
      disabled={disabled}
      normalize={normalizeValue}
      format={formatValue}
      multiple={multiple}
      id={id}
      props={{
        className: styles.Selection,
      }}
    />
  );
}

export default GroupSelectField;
