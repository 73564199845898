import React, { useEffect, useState } from "react";
import { filter, find, isEmpty, map } from "lodash";

import {
  useActivitystreams,
  type Activitystream as ActivitystreamType,
} from "../../../../../hooks/administration/appNavigation";

type Props = {
  options: { activitystream: ActivitystreamType };
  onChange: (props: {
    label: string;
    options: { activitystream: ActivitystreamType };
  }) => void;
};

function Activitystream({ options, onChange }: Props) {
  const { data, isLoading } = useActivitystreams();
  const streams = filter(
    data,
    (stream: ActivitystreamType) => stream?.slug !== "all",
  );
  const [selectedStream, selectStream] = useState<ActivitystreamType>(
    options?.activitystream,
  );

  useEffect(() => {
    if (streams && isEmpty(options?.activitystream)) {
      onChange({
        label: streams[0]?.name,
        options: { activitystream: streams[0] },
      });
    }
  }, [data]);

  return (
    <>
      {isLoading ? (
        <p className="input-text">
          <i className="fa fa-spinner spin" />
          {I18n.t(
            "js.administration.app_navigation_items.edit.loading_activitystreams",
          )}
        </p>
      ) : (
        <select
          className="input-text"
          name="stream"
          onChange={(e) => {
            const activitystream = find(streams, {
              slug: e.target.value,
            });

            if (activitystream) {
              selectStream(activitystream);
              onChange({
                label: activitystream?.name,
                options: { activitystream },
              });
            }
          }}
          value={selectedStream?.slug}
        >
          {map(streams, (stream: ActivitystreamType) => (
            <option value={stream.slug} key={stream.slug}>
              {stream.name}
            </option>
          ))}
        </select>
      )}
      <p>
        Hinweis: Wird ein Activity Stream einer Gruppe als Startseite gesetzt,
        müssen alle Nutzer Mitglied dieser Gruppe sein, da sonst eine
        Fehlermeldung bei Aufruf der Startseite erscheint.
      </p>
    </>
  );
}

export default Activitystream;
