import React, { RefObject } from "react";
import { Appointment, Calendar } from "../../../@types/appointments";
import { weeksInMonth } from "components/appointments/helpers";
import { map } from "lodash";
import CalendarWeek from "components/appointments/appointmentsList/CalendarWeek";

type AppointmentsListArgs = {
  appointmentsByDate: { [date: string]: Appointment[] };
  date: string;
  calendars?: Calendar[];
  todayRef: RefObject<HTMLDivElement>;
};

export default function AppointmentsList({
  appointmentsByDate,
  date,
  calendars,
  todayRef,
}: AppointmentsListArgs) {
  const weeks = weeksInMonth(date);

  return (
    <div className="appointments-list-view">
      {map(weeks, (week, index) => (
        <CalendarWeek
          key={index}
          week={week}
          appointmentsByDate={appointmentsByDate}
          calendars={calendars}
          todayRef={todayRef}
        />
      ))}
    </div>
  );
}
