import { get, findLastIndex } from "lodash";
import { loadChatDetails } from "../../actions/chat";

// merge received messages into existing list of messages while maintaining a correct order
// both lists must already be ordered by id ascending themselves
//
// see tests for examples
export const mergeMessages = (messages, received) => {
  // shortcuts
  if (messages.length == 0) {
    return received;
  }
  if (received.length == 0) {
    return messages;
  }

  // a,b are the heads, r1,r2 are the remainders, out is for result
  let [a, ...r1] = messages;
  let [b, ...r2] = received;
  const out = [];

  while (a && b) {
    // keep a (existing) over b (received) if both are equal and shift both
    if (a.id === b.id) {
      out.push(a);
      a = r1.shift();
      b = r2.shift();
      // insert a next if its id is smaller than b and shift a
    } else if (a.id < b.id) {
      out.push(a);
      a = r1.shift();
      // insert b next if its id is smaller than a and shift b
    } else {
      out.push(b);
      b = r2.shift();
    }
  }

  if (a) {
    out.push(a);
  }
  if (b) {
    out.push(b);
  }

  return out.concat(r1).concat(r2);
};

// Inserts message into list or updates it if it already exists
// Always keeps the order
const insertOrUpdateMessage = (list, message) => {
  const index = findLastIndex(list, ({ id }) => id <= message.id);

  // Prevent duplication
  const keepUntil =
    index >= 0 && list[index].id === message.id ? index : index + 1;

  return [...list.slice(0, keepUntil), message, ...list.slice(index + 1)];
};

// Remove message from list by cid
// Is slightly optimized to process as little as possible, instead of using filter(list, ...)
const removeByClientId = (list, searchClientId) => {
  const index = findLastIndex(list, ({ cid }) => cid === searchClientId);
  if (index === -1) return list;

  return [...list.slice(0, index), ...list.slice(index + 1)];
};

export const messageList = (list = [], action) => {
  switch (action.type) {
    case "chat/MESSAGE/PUSH":
      return [
        ...list,
        {
          id: null,
          from: action.meta.from,
          ...action.payload,
          cid: action.meta.ref,
        },
      ];
    case "chat/MESSAGE/REPLY":
      return insertOrUpdateMessage(removeByClientId(list, action.meta.ref), {
        cid: action.meta.ref,
        ...action.payload,
      });
    case "chat/MESSAGE/RECEIVE":
      return insertOrUpdateMessage(list, action.payload);
    case loadChatDetails.replyType:
      return mergeMessages(list, action.payload.messages.reverse());
    case "chat/MORE_MESSAGES/REPLY":
      return mergeMessages(action.payload.messages.reverse(), list);
    default:
      return list;
  }
};

export default (state = {}, action) => {
  switch (action.type) {
    case "chat/MESSAGE/PUSH":
    case "chat/MESSAGE/REPLY":
    case "chat/MESSAGE/RECEIVE":
    case loadChatDetails.replyType:
    case "chat/MORE_MESSAGES/REPLY": {
      const chatId =
        get(action, ["payload", "chat_id"]) || get(action, ["meta", "chatId"]);
      if (!chatId) {
        return state;
      }
      return {
        ...state,
        [chatId]: messageList(get(state, [chatId]), action),
      };
    }
    default:
      return state;
  }
};
