import React, { useState } from "react";
import { Category } from "../../../../@types/appointmentCategories";

function CategoryRow({
  category,
  changeCategory,
  removeCategory,
}: {
  category: Category;
  changeCategory: (id: string, label: string) => void;
  removeCategory: (id: string) => void;
}) {
  const [text, setText] = useState(category.label);
  const [edit, setEdit] = useState(false);

  return (
    <li className="flex justify-between items-center p-2">
      {edit ? (
        <div className="input-group">
          <input
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button
            className="btn btn-light"
            onClick={(e) => {
              e.preventDefault();
              changeCategory(category.id, text);
              setEdit(false);
            }}
          >
            <i className="fa-regular fa-check" />
          </button>
        </div>
      ) : (
        category.label
      )}
      <div className="btn btn-group">
        <button
          className="btn btn-sm btn-light"
          onClick={(e) => {
            e.preventDefault();
            setEdit(true);
          }}
        >
          <i className="fa-regular fa-pencil" />
        </button>
        <button
          className="btn btn-sm btn-danger"
          onClick={(e) => {
            e.preventDefault();
            removeCategory(category.id);
          }}
        >
          <i className="fa-regular fa-trash" />
        </button>
      </div>
    </li>
  );
}

export default CategoryRow;
