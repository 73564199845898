import { get } from "lodash";

export const invitesSelector = (state) => get(state, ["invites", "data"]);

export const loadMoreUrlInvitesSelector = (state) =>
  get(state, ["invites", "loadMoreUrl", "next"]);

export const invitesLoadingSelector = (state) =>
  get(state, ["invites", "loading"]);

export const participationsSelector = (state) =>
  get(state, ["participations", "data"]);

export const loadMoreUrlParticipationsSelector = (state) =>
  get(state, ["participations", "loadMoreUrl"]);

export const participantsLoadingSelector = (state) =>
  get(state, ["participations", "loading"]);
