import React from "react";
import { useNavigate } from "helpers/tixxt-router";

import LegalTextForm from "components/administration/legalTexts/LegalTextForm";
import { useCreateLegalText } from "./api";
import PageTitle from "../../layout/PageTitle";

function LegalTextNew() {
  const navigate = useNavigate();
  const { mutate: createLegalText } = useCreateLegalText({
    onSuccess: () => {
      navigate(`/administration/legal_texts`);
      navigate(0);
      toastr.success(I18n.t("js.saving_successful"));
    },
    onError: () => {
      toastr.error(I18n.t("js.saving_failure"));
    },
  });

  return (
    <>
      <PageTitle title={I18n.t("js.administration.legal_texts.new.title")} />
      <div className="btn-toolbar mb-10">
        <a
          className="btn btn-light backButton"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            navigate(`/administration/legal_texts`);
          }}
        >
          <i className="fa fa-arrow-left" />
        </a>
      </div>
      <div className="row">
        <div className="span10">
          <LegalTextForm
            onSubmit={(values) => createLegalText(values)}
            isEditing={false}
          />
        </div>
      </div>
    </>
  );
}

export default LegalTextNew;
