import React from "react";
import PropTypes from "prop-types";
import DocumentActions from "./DocumentActions";

const ICONS = {
  spreadsheets: "fa-regular fa-file-excel",
  documents: "fa-regular fa-file-word",
  presentations: "fa-regular fa-file-powerpoint",
};
export default function OnlyOfficeActions({ fileId, locked, finalized }) {
  return (
    <DocumentActions
      type="docs"
      fileId={fileId}
      locked={locked}
      finalized={finalized}
      renderSuptext={() => I18n.t("js.files.show.toolbar.only_office.suptext")}
      renderIcon={(app) =>
        ICONS[app] ? <i className={ICONS[app]} aria-hidden="true" /> : null
      }
    />
  );
}
OnlyOfficeActions.propTypes = {
  fileId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
};
