import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import { size, filter } from "lodash";
import { State } from "@types";
import FileUploadField from "../../shared/fields/FileUploadField";

import styles from "./ReplyComposer.module.css";

interface AttachmentsTypes {
  composerName: string;
  selectedTab?: string;
  filesCount?: number;
  imagesCount?: number;
}

function Attachments({
  filesCount,
  imagesCount,
  selectedTab = "",
}: AttachmentsTypes) {
  const [openTab, setOpenTab] = useState(selectedTab);

  // To react to external isOpen prop changes
  useEffect(() => setOpenTab(selectedTab), [selectedTab]);

  return (
    <div className="mt-2">
      <div className="flex space-x-2">
        <a
          href="#"
          className="relative btn btn-sm btn-light"
          onClick={(e) => {
            e.preventDefault();
            setOpenTab("files");
          }}
        >
          <i className="fa-regular fa-files" />{" "}
          {(filesCount as number) > 0 ? (
            <span className="!absolute badge rounded-xl bg-unread -right-1.5 !-top-1.5">
              {filesCount}
            </span>
          ) : null}
        </a>

        <a
          className="relative btn btn-sm btn-light"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setOpenTab("images");
          }}
        >
          <i className="fa fa-image" />
          {(imagesCount as number) > 0 ? (
            <span className="!absolute badge rounded-xl bg-unread -right-1.5 !-top-1.5">
              {imagesCount}
            </span>
          ) : null}
        </a>
      </div>
      {openTab == "files" ? (
        <Field
          name="files"
          component={FileUploadField}
          props={{
            className: styles.FileUpload,
            multiple: true,
            selectExisting: true,
          }}
        />
      ) : null}
      {openTab == "images" ? (
        <Field
          name="images"
          component={FileUploadField}
          props={{
            className: styles.FileUpload,
            multiple: true,
            isImage: true,
            storageDirectory: "images",
          }}
        />
      ) : null}
    </div>
  );
}

const filterRemoved = (items) =>
  filter(items, (item) => item.state != "removed");

export default connect((state, { composerName }: AttachmentsTypes) => {
  const selector = formValueSelector(composerName);

  return {
    filesCount: size(filterRemoved(selector(state as State, "files"))),
    imagesCount: size(filterRemoved(selector(state as State, "images"))),
  };
})(Attachments);
