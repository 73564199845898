import React from "react";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose } from "recompose";
import PropTypes from "prop-types";

import TextField from "../appCreator/properties/edit/TextField";
import { selectFileProfilesEnabled } from "selectors/propertySets";
import FolderSelectionField from "./FolderSelectionField";
import EmbeddedValueSetForm from "components/shared/propertySets/EmbeddedValueSetForm";
import ControlGroup from "components/shared/fields/ControlGroup";
import styles from "./files.module.css";
import FormActions from "../shared/reduxForm/FormActions";
import { useParams, useSearchParams } from "helpers/tixxt-router";
import { rootFolderIdSelector } from "../../selectors/folders";
import { isEmpty } from "lodash";
import { useFetchFolder } from "./api";

function EditFileForm({
  initialValues,
  handleSubmit,
  onCancel,
  showFileProfileFields,
  pristine,
  submitting,
}) {
  const { groupSlug } = useParams();
  const [searchParams] = useSearchParams();
  const contextRootFolderId = useSelector(rootFolderIdSelector({ groupSlug }));
  const folderId = searchParams.get("folder_id") || contextRootFolderId;

  const { data: folder, isLoading: loading } = useFetchFolder(folderId);

  const defaultFolder = folder;

  return (
    <>
      {loading && isEmpty(folder) ? (
        I18n.t("js.loading")
      ) : (
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <ControlGroup name="name" label={I18n.t("js.files.edit.name.label")}>
            <TextField name="name" autoFocus />
          </ControlGroup>
          <ControlGroup
            name="folder_ids"
            label={I18n.t("js.files.file.folder_selection.label")}
          >
            <Field
              name="folder_ids"
              component={FolderSelectionField}
              props={{ defaultFolder }}
            />
          </ControlGroup>
          <ControlGroup
            name="description"
            label={I18n.t("js.files.edit.description.label")}
          >
            <Field
              name="description"
              component="textarea"
              rows={5}
              className={styles.FileDescription}
            />
          </ControlGroup>
          {showFileProfileFields ? (
            <EmbeddedValueSetForm
              name="file_profile"
              contextType="file"
              contextId={initialValues.id}
            />
          ) : null}
          <FormActions
            pristine={pristine}
            submitting={submitting}
            submitText={I18n.t("js.files.edit.submit")}
            onCancel={onCancel}
          />
        </form>
      )}
    </>
  );
}

EditFileForm.propTypes = {
  defaultFolder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    group_id: PropTypes.string,
  }),
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  showFileProfileFields: PropTypes.bool,
};

export default compose(
  connect((state) => ({
    showFileProfileFields: selectFileProfilesEnabled(state),
  })),
  reduxForm({
    form: "edit-file",
    enableReinitialize: true,
  }),
)(EditFileForm);
