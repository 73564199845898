import React, { useEffect } from "react";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { useMaxHeight } from "../../hooks/useMaxHeight";

import ChatList from "./ChatList";
import ChatRoom from "./ChatRoom";
import ChatNew from "./ChatNew";

import store from "../../store";
import chatReducer from "../../reducers/chat";
import chatSaga from "../../sagas/chat";

function launchChat() {
  const host = window.location.host;
  const networkUrl = `https://${host}`;
  const tokenUrl = "/api/v1/chat_token";
  const socketUrl = `wss://${host}/api/chat/socket`;

  const fetchCredentials = async () => {
    const response = await fetch(tokenUrl, {
      method: "POST",
      credentials: "include",
    });
    return await response.json();
  };

  // Add chat reducer and init its state
  store.addReducers({ chat: chatReducer });
  store.dispatch({ type: "chat/INIT", payload: { networkUrl } });

  // Run the chat saga
  store.runSaga(chatSaga, { fetchCredentials, socketUrl });
}

function MobileAppChat() {
  const navigation = useSelector((state) => get(state, ["chat", "navigation"]));
  const connected = useSelector((state) =>
    get(state, ["chat", "session", "connected"]),
  );
  const authorizationError = useSelector((state) =>
    get(state, ["chat", "session", "authorizationError"]),
  );

  const style = useMaxHeight();
  useEffect(launchChat, []);

  window.bridge?.setPageTitle(
    I18n.t("js.administration.app_navigation_items.defaults.chat.label"),
  );

  return (
    <div
      style={style}
      className="mobile_app_chat__content chat flex flex-col -m-6"
    >
      {authorizationError ? (
        <div className="alert alert-danger w-full z-20">
          {I18n.t("js.chat.connection_failed")}
        </div>
      ) : connected ? null : connected === false ? (
        <div className="alert alert-warning w-full z-20">
          {I18n.t("js.chat.reconnecting")}
        </div>
      ) : (
        <div className="alert alert-info z-20">
          {I18n.t("js.chat.connecting")}
        </div>
      )}
      {navigation?.route === "root" ? <ChatList /> : null}
      {navigation?.route === "showChat" ? (
        <ChatRoom chatId={navigation.params.chatId} />
      ) : null}
      {navigation?.route === "newChat" ? <ChatNew /> : null}
    </div>
  );
}

export default MobileAppChat;
