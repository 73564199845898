const myDefaultState = {
  muted_until: null,
  app_muted_until: null,
  last_read_id: null,
  unread_count: 0,
};

// Lookup for best performance
const POSSIBLE_PARTNER_ACTION_TYPES = {
  "chat/MARK_AS_READ/RECEIVE": true,
  "chat/MARK_AS_RECEIVED/RECEIVE": true,
};
// True if action origins from partner, determined by membership_id and channel topic it was received on
const isPartnerAction = ({ type, payload, meta }) =>
  POSSIBLE_PARTNER_ACTION_TYPES[type] &&
  meta.topic !== `member:${payload.membership_id}`;

export const myStateReducer = (state = myDefaultState, action) => {
  if (isPartnerAction(action)) {
    return state;
  }

  switch (action.type) {
    case "chat/MARK_AS_READ/RECEIVE":
    case "chat/MARK_AS_READ":
      return {
        ...state,
        unread_count: 0,
        last_read_id: action.payload.last_read_id,
      };
    case "chat/MUTE_CHAT":
    case "chat/MUTE_CHAT/RECEIVE":
      return {
        ...state,
        muted_until: action.payload.muted_until,
      };
    case "chat/APP_MUTE_CHAT":
    case "chat/APP_MUTE_CHAT/RECEIVE":
      return {
        ...state,
        app_muted_until: action.payload.muted_until,
      };
    case "chat/UNMUTE_CHAT":
    case "chat/UNMUTE_CHAT/RECEIVE":
      return {
        ...state,
        muted_until: null,
      };
    case "chat/APP_UNMUTE_CHAT":
    case "chat/APP_UNMUTE_CHAT/RECEIVE":
      return {
        ...state,
        app_muted_until: null,
      };
    case "chat/MESSAGE/RECEIVE":
      return {
        ...state,
        unread_count: state.unread_count + 1,
      };
    default:
      return state;
  }
};

const partnerDefaultState = {
  last_received_id: null,
  last_read_id: null,
};

export const partnerStateReducer = (state = partnerDefaultState, action) => {
  if (!isPartnerAction(action)) {
    return state;
  }

  switch (action.type) {
    case "chat/MARK_AS_RECEIVED/RECEIVE":
      return {
        ...state,
        last_received_id: action.payload.last_received_id,
      };

    case "chat/MARK_AS_READ/RECEIVE":
      return {
        ...state,
        last_read_id: action.payload.last_read_id,
      };
    default:
      return state;
  }
};
