import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  get,
  filter,
  find,
  map,
  reduce,
  isEmpty,
  compact,
  uniq,
  concat,
  debounce,
} from "lodash";
import { Field } from "redux-form";
import classnames from "classnames";
import {
  selectFileProfilesEnabled,
  selectFolderProfilesEnabled,
} from "selectors/propertySets";

import SelectionField from "components/appCreator/properties/edit/SelectionField";
import TagField from "components/appCreator/properties/edit/TagField";
import mergeFileAndFolderProfiles from "./mergeFileAndFolderProfiles";
import styles from "../files.module.css";
import ReduxForm from "../../shared/reduxForm";
import { usePropertySet } from "../../shared/propertySets/api";

// Helper component to isolate profile loading and filter building
function ProfileFilters({ contextId, contextType, children }) {
  const { loading: folderProfileLoading, data: folderProfile } = usePropertySet(
    { name: "folder_profile", contextId, contextType },
  );

  const { loading: fileProfileLoading, data: fileProfile } = usePropertySet({
    name: "file_profile",
    contextId,
    contextType,
  });

  const filterLoading = fileProfileLoading || folderProfileLoading;

  const mergedProfile = mergeFileAndFolderProfiles(
    get(fileProfile, ["properties"], []),
    get(folderProfile, ["properties"], []),
  );

  // Filter given profile for selection properties
  const selectionFilters = filter(mergedProfile, (p) => p.type === "selection");
  // Get the first tag field from given profile
  const tagFilter = find(mergedProfile, (p) => p.type === "tag");
  const tagParents = uniq(
    compact(
      concat(
        ["network"],
        get(fileProfile, ["tag_parents"], []),
        get(folderProfile, ["tag_parents"], []),
      ),
    ),
  );

  return children({ filterLoading, selectionFilters, tagFilter, tagParents });
}

// Helper component to skip profile loading and filter building
const NoExtraFilters = ({ children }) => children({ filterLoading: false });

const formValuesToSearchParams = ({
  search: q = null,
  tag: rawTag = null,
  ...rawFilters
}) => {
  const filters = reduce(
    rawFilters,
    (result, filterValue, filterName) => {
      if (!isEmpty(filterValue)) result[`profile.${filterName}`] = filterValue;
      return result;
    },
    {},
  );

  if (!isEmpty(rawTag)) filters.tag = rawTag.name;

  if (isEmpty(q) && isEmpty(filters)) return null;

  return { q, filters };
};

function SearchFilters({ contextId, contextType, initialValues, onSubmit }) {
  const handleSubmit = (values) => onSubmit(formValuesToSearchParams(values));
  const debouncedSubmit = useMemo(
    () => debounce(handleSubmit, 300),
    [onSubmit],
  );
  const fileProfilesEnabled = useSelector(selectFileProfilesEnabled);
  const folderProfilesEnabled = useSelector(selectFolderProfilesEnabled);
  const profileEnabled = fileProfilesEnabled || folderProfilesEnabled;

  const FilterProvider = profileEnabled ? ProfileFilters : NoExtraFilters;

  const [showFilters, setShowFilters] = useState(false);

  return (
    <FilterProvider contextId={contextId} contextType={contextType}>
      {({ filterLoading, selectionFilters, tagFilter, tagParents }) => {
        const filterAvailable =
          !isEmpty(selectionFilters) || !isEmpty(tagFilter);

        return (
          <div className="FilesSearch grow">
            <ReduxForm
              className="!max-w-none"
              form="files-search"
              onSubmit={handleSubmit}
              onChange={(values, _dispatch, _props, previousValues) =>
                // Submit changes to search text debounced
                values.search !== previousValues.search
                  ? debouncedSubmit(values)
                  : handleSubmit(values)
              }
              initialValues={initialValues}
              noActions
            >
              <div className="flex flex-row gap-2 divide-gray-300 divide-x place-items-end">
                <div className="input-group grow">
                  <Field
                    component="input"
                    type="text"
                    name="search"
                    placeholder={I18n.t("js.files.search.placeholder")}
                  />
                  <button className="btn btn-primary" type="submit">
                    <i className="fa fa-search" />
                  </button>
                </div>
                {filterAvailable ? (
                  <div className="pl-2">
                    <a
                      href="#"
                      className={classnames("btn btn-light", {
                        active: showFilters,
                      })}
                      onClick={(e) => {
                        e.preventDefault();
                        setShowFilters(!showFilters);
                      }}
                    >
                      <i className="fa-regular fa-filter" />
                    </a>
                  </div>
                ) : filterLoading ? (
                  <div className="pl-2">
                    <button className="btn btn-light disabled" disabled>
                      <i className="fa-regular fa-spinner fa-spin" />
                    </button>
                  </div>
                ) : null}
              </div>

              {filterAvailable && showFilters ? (
                <div
                  className={classnames(
                    "form-horizontal mt-2 max-w-xl",
                    styles.FilesSearchFilters,
                  )}
                >
                  {map(selectionFilters, (property) => (
                    <div key={property.name} className="control-group">
                      <label
                        className="control-label"
                        htmlFor={`filter-${property.name}`}
                      >
                        {property.label}
                      </label>
                      <div className="controls">
                        <SelectionField
                          id={`filter-${property.name}`}
                          name={property.name}
                          options={property.options}
                          select={true}
                        />
                      </div>
                    </div>
                  ))}
                  {tagFilter ? (
                    <div key="tags" className="control-group">
                      <label className="control-label" htmlFor="filter-tags">
                        {tagFilter.label}
                      </label>
                      <div className="controls">
                        <TagField
                          id="filter-tags"
                          name="tag"
                          allowCreate={false}
                          multiple={false}
                          tagParents={tagParents}
                          internal={tagFilter.internal}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </ReduxForm>
          </div>
        );
      }}
    </FilterProvider>
  );
}

SearchFilters.propTypes = {
  contextId: PropTypes.string,
  contextType: PropTypes.string,
  initialValues: PropTypes.shape({
    q: PropTypes.text,
    filters: PropTypes.object,
  }),
  onSubmit: PropTypes.func,
};

export default SearchFilters;
