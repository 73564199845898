import React from "react";
import { useParams } from "helpers/tixxt-router";
import { ContextPageTitle } from "components/layout/PageTitle";
import GroupNavigation from "components/layout/GroupNavigation";
import { useSelector } from "react-redux";
import { State } from "../../@types";
import MarkdownTranslation from "components/shared/MarkdownTranslation";

export default function GroupAdministrationMainPage() {
  const { groupSlug } = useParams();
  const group = useSelector((state: State) =>
    groupSlug ? state.groups.bySlug[groupSlug] : null,
  );

  return (
    <>
      <ContextPageTitle groupSlug={groupSlug} />
      <GroupNavigation groupSlug={groupSlug} />
      {group?.hidden_main_group ? (
        <MarkdownTranslation
          translation={"js.administration.main.category_message_md"}
        />
      ) : (
        <MarkdownTranslation
          translation={"js.administration.main.group_message_md"}
        />
      )}
    </>
  );
}
