import React from "react";
import { useQuery } from "react-query";
import { map } from "lodash";
import PropTypes from "prop-types";
import classNames from "classnames";

import { allFactors } from "./api";
import FactorActions from "./FactorActions";

export default function FactorsPage() {
  const { data, isLoading, isError, error } = useQuery("factors", allFactors);

  if (isError) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <>
      <p className="mb-4">{I18n.t("js.authentication.2fa.info_text")}</p>
      {isLoading ? (
        <span className="loadingMessage">
          <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
        </span>
      ) : (
        <>
          <ListFactors factors={data} />
        </>
      )}
    </>
  );
}

function ListFactors({ factors }) {
  const factorDisplayName = {
    totp: {
      name: I18n.t("js.authentication.2fa.authenticator_app"),
      icon: "fa-regular fa-mobile",
    },
    email: {
      name: I18n.t("js.authentication.2fa.email"),
      icon: "fa-regular fa-envelope",
    },
  };

  return (
    <div className="flex gap-4 flex-wrap">
      {map(factors, (factor) => (
        <div key={factor.type} className="border-box p-3">
          <div className="media-body flex flex-col gap-2">
            <div className="flex gap-2 items-center">
              <i
                className={classNames(
                  factorDisplayName[factor.type].icon,
                  "text-2xl",
                )}
              />
              <div className="flex grow justify-between gap-2 items-center">
                <span className="font-semibold">
                  {factorDisplayName[factor.type].name}
                </span>
                {factor.id ? (
                  <span className="badge bg-primary">
                    {" "}
                    {I18n.t("js.authentication.2fa.activated")}
                  </span>
                ) : (
                  <span className="badge bg-neutral">
                    {I18n.t("js.authentication.2fa.deactivated")}
                  </span>
                )}
              </div>
            </div>
            <div>
              <FactorActions {...factor} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

ListFactors.propTypes = {
  factors: PropTypes.array,
};
