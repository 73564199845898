import React, { useMemo } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { get, isEmpty, map, filter } from "lodash";
import { useParams, useSearchParams } from "helpers/tixxt-router";

import PageTitle from "../layout/PageTitle";
import ControlGroup from "../shared/fields/ControlGroup";
import { Field } from "redux-form";
import styles from "./files.module.css";
import FileUploadField from "../shared/fields/FileUploadField";
import FolderSelectionField from "./FolderSelectionField";
import EmbeddedValueSetForm from "../shared/propertySets/EmbeddedValueSetForm";
import ReduxForm from "../shared/reduxForm";
import { selectFileProfilesEnabled } from "../../selectors/propertySets";
import { submitApi } from "../../helpers/api";
import { publishFiles } from "../../actions/files";
import { rootFolderIdSelector } from "../../selectors/folders";
import Breadcrumb from "./Breadcrumb";
import GroupNavigation from "../layout/GroupNavigation";
import { useFetchFolder } from "./api";

const validate = ({ files }) => {
  const errors = {};
  if (isEmpty(files)) errors.files = I18n.t("js.required");
  return errors;
};

function ActualForm({
  defaultFolder,
  showFileProfileFields,
  postToStreamDefault,
  files,
  onSubmit,
  onSubmitSuccess,
  onCancel,
}) {
  const initialValues = useMemo(
    () => ({
      folder_ids: [defaultFolder.id],
      post_to_stream: postToStreamDefault,
    }),
    [defaultFolder.id, postToStreamDefault],
  );

  return (
    <ReduxForm
      form="new-files"
      className={`${styles.NewFilesForm} form-horizontal`}
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      onCancel={onCancel}
      validate={validate}
      processing={
        !files?.every(
          (file) => file.state === "volatile" || file.state === "published",
        )
      }
      initialValues={initialValues}
      submitText={I18n.t("js.files.add_files")}
      renderChildren={() => (
        <>
          <ControlGroup
            name="files"
            label={I18n.t("js.files.file.label")}
            required
          >
            <Field
              className={styles.FileUpload}
              name="files"
              required
              component={FileUploadField}
              multiple
            />
          </ControlGroup>
          <ControlGroup
            name="folder_ids"
            label={I18n.t("js.files.file.folder_selection.label")}
          >
            <Field
              name="folder_ids"
              component={FolderSelectionField}
              props={{ defaultFolder }}
            />
          </ControlGroup>
          <ControlGroup
            name="description"
            label={I18n.t("js.files.edit.description.label")}
          >
            <Field
              name="description"
              component="textarea"
              rows={5}
              className={styles.FileDescription}
            />
          </ControlGroup>
          {showFileProfileFields ? (
            <EmbeddedValueSetForm
              name="file_profile"
              contextType={defaultFolder.group_id ? "group" : "network"}
              contextId={defaultFolder.group_id}
            />
          ) : null}
          <ControlGroup name="post_to_stream">
            <label className="checkbox">
              <Field name="post_to_stream" component="input" type="checkbox" />
              {I18n.t("js.files.file.post_to_stream")}
            </label>
            <span className="help-block">
              {I18n.t("js.files.file.description_help_1")}
            </span>
            <span className="help-block">
              {I18n.t("js.files.file.description_help_2")}
            </span>
          </ControlGroup>
        </>
      )}
    />
  );
}

ActualForm.propTypes = {
  defaultFolder: PropTypes.shape({
    id: PropTypes.string.isRequired,
    group_id: PropTypes.string,
  }),
  showFileProfileFields: PropTypes.bool,
  postToStreamDefault: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onSubmitSuccess: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

const ConnectedForm = connect((state) => ({
  files: get(state, ["form", "new-files", "values", "files"], null),
  showFileProfileFields: selectFileProfilesEnabled(state),
  postToStreamDefault: get(
    state,
    ["network", "config", "files", "default_post_to_stream"],
    false,
  ),
}))(ActualForm);

// Complete page for adding files to one or more folders
// Loads parent folder information first, by id if one was given, root folder
// of current context otherwise
export default function NewFilesPage() {
  const { groupSlug } = useParams();
  const [searchParams] = useSearchParams();
  const contextRootFolderId = useSelector(rootFolderIdSelector({ groupSlug }));
  const folderId = searchParams.get("folder_id") || contextRootFolderId;

  const { data: folder, isLoading: loading } = useFetchFolder(folderId);

  const onSubmit = submitApi(({ files, ...remainingValues }) => ({
    body: {
      files: map(filter(files, { state: "volatile" }), ({ id }) => ({ id })),
      ...remainingValues,
    },
  }))(publishFiles);

  const navigateToFolder = () => {
    const contextPath = groupSlug ? `/groups/${groupSlug}` : "";
    window.Turbo.visit(`${contextPath}/files/folders/${folderId}`, {
      action: "replace",
    });
  };

  return (
    <>
      <PageTitle title={I18n.t("js.files.add_files")} />
      {groupSlug && <GroupNavigation groupSlug={groupSlug} />}
      {loading || isEmpty(folder) ? (
        I18n.t("js.loading")
      ) : (
        <>
          <Breadcrumb folder={folder} />
          <ConnectedForm
            defaultFolder={folder}
            onSubmit={onSubmit}
            onSubmitSuccess={navigateToFolder}
            onCancel={navigateToFolder}
          />
        </>
      )}
    </>
  );
}
NewFilesPage.propTypes = {
  groupSlug: PropTypes.string,
  query: PropTypes.shape({
    folder_id: PropTypes.string,
  }),
};
