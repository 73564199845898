import React, { useRef, useState } from "react";
import { compact, first } from "lodash";
import { useRoutes } from "helpers/tixxt-router";
import { Group } from "../../../@types";
import {
  groupsFromSlugs,
  GroupsFromSlug,
  GroupsFromActivityId,
  GroupsFromAppointmentId,
  GroupsFromFileId,
  GroupsFromTaskId,
  NoSlugs,
  HandleContextHeader,
  GroupsFromFolderId,
  Noop,
  SlugsState,
  SlugsContext,
} from "components/administration/designManagerLight/functions";

function pickRelevantGroups({ slugs, previousSlug }): Group[] | undefined {
  if (previousSlug && slugs?.includes(previousSlug)) {
    const group = first(groupsFromSlugs(compact([previousSlug])));
    if (group) return [group] as Group[];
  }

  if (!slugs) return;

  return groupsFromSlugs(slugs);
}

const routes = [
  {
    path: "/groups/:contextSlug/administration/designs/:designId/*",
    element: <Noop />,
  },
  { path: "/groups/:contextSlug/*", element: <GroupsFromSlug /> },
  { path: "/activities/:activityId", element: <GroupsFromActivityId /> },
  {
    path: "/appointments/:appointmentId",
    element: <GroupsFromAppointmentId />,
  },
  {
    path: "/appointments/:appointmentId/*",
    element: <GroupsFromAppointmentId />,
  },
  { path: "/files/folders/new", element: <GroupsFromFolderId /> },
  { path: "/files/folders/:folderId", element: <GroupsFromFolderId /> },
  { path: "/files/:fileId", element: <GroupsFromFileId /> },
  { path: "/tasks/:taskId", element: <GroupsFromTaskId /> },
  { path: "/tasks/:taskId/*", element: <GroupsFromTaskId /> },
  { path: "*", element: <NoSlugs /> },
];

export default function ApplyOverrideGroupTheme() {
  const [state, setState] = useState<SlugsState>({
    slugs: [],
    previousSlug: null,
  });
  const lastContext = useRef<Group>();
  const element = useRoutes(routes);

  return (
    <>
      <HandleContextHeader
        slugs={state.slugs}
        relevantContext={pickRelevantGroups(state)}
        lastContext={lastContext}
        type={"group"}
      />
      <SlugsContext.Provider value={setState}>{element}</SlugsContext.Provider>
    </>
  );
}
