import React from "react";
import { isEmpty, map } from "lodash";

import { useNetworkSettings, useUpdateNetworkSettings } from "./api";
import PageTitle from "../../layout/PageTitle";
import NetworkSettingsForm from "./NetworkSettingsForm";
import { useDispatch } from "react-redux";
import publishAllVolatile from "helpers/files/publishAllVolatile";

function NetworkSettings() {
  const { isLoading, data: networkSettings } = useNetworkSettings();
  const dispatch = useDispatch();
  const { mutateAsync: updateNetworkSettings } = useUpdateNetworkSettings({
    onSuccess: () => {
      toastr.success(I18n.t("js.saving_successful"));
      window.location.reload(); // hard reload, to apply language settings
    },
  });

  const onSubmit = async (values) => {
    let body = {
      ...values,
      // The SelectField resolve to an empty array if you clear the input
      category: isEmpty(values.category) ? null : values.category,
      detection_profile_field: isEmpty(values.detection_profile_field)
        ? null
        : values.detection_profile_field,
      // Send array of group_ids instead of an array with group objects
      group_promotion_config: {
        ...values.group_promotion_config,
        group_ids: map(values.group_promotion_config.groups, (g) => g.id),
      },
      guest_users_allow_chat: values.guest_users_allow_chat === "enabled",
      birthday_emails: {
        subject:
          values.birthday_emails.subject === ""
            ? null
            : values.birthday_emails.subject,
        text:
          values.birthday_emails.text === ""
            ? null
            : values.birthday_emails.text,
        enabled: values.birthday_emails.enabled === "true",
      },
    };

    if (values.birthday_emails.image != null) {
      const result = await publishAllVolatile({
        dispatch,
        values: { birthday_emails_image: values.birthday_emails.image },
        storageDirectory: "assets",
      });

      body = {
        ...body,
        birthday_emails: {
          ...body.birthday_emails,
          image: result.birthday_emails_image[0],
        },
      };

      return updateNetworkSettings(body);
    } else {
      body = {
        ...body,
        birthday_emails: {
          ...body.birthday_emails,
          image: null,
        },
      };

      return updateNetworkSettings(body);
    }
  };

  return (
    <>
      <PageTitle
        title={I18n.t("js.administration.network_settings.edit.title")}
      />
      <div>
        <div>
          {isLoading || isEmpty(networkSettings) ? (
            <div>
              <i className="fa fa-spin fa-spinner" /> {I18n.t("js.loading")}
            </div>
          ) : (
            <NetworkSettingsForm
              initialValues={networkSettings}
              onSubmit={onSubmit}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default NetworkSettings;
