import type { ChangeEvent } from "react";
import type { TreeItem } from "react-sortable-tree";
import React from "react";
import "../navigationManager.css";
import { isEmpty, findKey, isEqual, find } from "lodash";
import { connect } from "react-redux";
import LinkForm from "./LinkForm";
import SelectContent from "./SelectContent";
import MainFields from "./MainFields";
import Checkboxes from "./Checkboxes";
import { State } from "../../../@types";

function findOption(items, link) {
  const keyName = findKey(items, (value) =>
    find(value, (item) => isEqual(item.link, link)),
  );
  if (!isEmpty(keyName)) return keyName;
  return "link";
}

type items = {
  apps?: Array<unknown>;
  pages?: Array<unknown>;
};

interface ItemFormTypes {
  option: string;
  items: items;
  link: string;
  text: string;

  onChange(...args: unknown[]): unknown;
}

function ItemForm({ option, items, link, text, onChange }: ItemFormTypes) {
  if (items[option]) {
    return (
      <SelectContent data={items[option]} onChange={onChange} link={link} />
    );
  }
  return <LinkForm link={link} text={text} onChange={onChange} />;
}

interface NavigationManagerFormTypes {
  node: TreeItem | null;

  onChangeNode(...args: unknown[]): unknown;

  items: items;
}

function NavigationManagerForm({
  node,
  onChangeNode,
  items,
}: NavigationManagerFormTypes) {
  if (isEmpty(node)) {
    return null;
  }
  const hasChildren = !isEmpty((node as TreeItem).children);
  const option = (node as TreeItem).option
    ? (node as TreeItem).option
    : findOption(items, (node as TreeItem).link);
  return (
    <div className="navigation-manager-form">
      <form>
        <MainFields
          hasChildren={hasChildren}
          items={items}
          option={option}
          onChangeOption={(e: ChangeEvent<HTMLInputElement>) => {
            onChangeNode({ ...node, option: e.target.value });
          }}
        />
        {!hasChildren && [
          <ItemForm
            option={option}
            link={(node as TreeItem).link}
            text={(node as TreeItem).text}
            onChange={({ link, text }) => onChangeNode({ ...node, link, text })}
            items={items}
            key="options"
          />,
        ]}
        <label>
          {I18n.t("js.administration.navigation_items.name_input_label")}
        </label>
        <input
          className="input-text form-control"
          value={(node as TreeItem).text}
          onChange={(e) => {
            onChangeNode({ ...node, text: e.target.value });
          }}
        />
        {!hasChildren && [
          <Checkboxes
            newWindow={(node as TreeItem).new_window}
            isPublic={(node as TreeItem).public}
            onChangeNewWindow={(new_window) =>
              onChangeNode({ ...node, new_window })
            }
            onChangePublic={(p) => onChangeNode({ ...node, public: p })}
            key="checkboxes"
          />,
        ]}
      </form>
    </div>
  );
}

export default connect(
  (
    state,
    { items, groupSlug }: { items: items; groupSlug: string | null },
  ) => ({
    items: {
      ...items,
      standard: groupSlug
        ? []
        : [
            {
              link: "/",
              label: I18n.t(
                "js.administration.navigation_items.standard.start.option_label",
              ),
            },
            {
              link: "/members",
              label: I18n.t(
                "js.administration.navigation_items.standard.user-folder.option_label",
              ),
            },
          ].concat(
            (state as State).network.group_categories.map(
              ({ slug, plural_name }) => ({
                link: `/directory/${slug}`,
                label: plural_name,
              }),
            ),
          ),
    },
  }),
)(NavigationManagerForm);
