import React, { Component } from "react";
import { debounce, each } from "lodash";

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default function connectBackbone({
  mapProps,
  listenTo,
  debounce: debounceTime = 10,
}) {
  return function wrapWithConnect(WrappedComponent) {
    const MemoizedWrappedComponent = React.memo(WrappedComponent);

    class Connect extends Component {
      constructor(props, context) {
        super(props, context);

        this.state = Connect.getDerivedStateFromProps(this.props);
      }

      static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
      }

      componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error("TixxtErrorBoundary to the rescue", error, errorInfo);
      }

      componentDidMount() {
        this.trySubscribe();
      }

      componentWillUnmount() {
        this.tryUnsubscribe();
      }

      trySubscribe() {
        if (!this.unsubscribe) {
          // Bind change events for each tracked entity (i.e. model and/or collection)
          const changeHandler = debounce(
            this.handleChange.bind(this),
            debounceTime,
          );
          each(listenTo, (entity) => {
            if (!entity) {
              throw new Error(`Undefined/null listenTo entity given`);
            }
            entity.on("change add remove reset sync sort", changeHandler, this);
          });

          this.unsubscribe = () => {
            each(listenTo, (entity) => entity.off(null, changeHandler, this));
          };

          this.handleChange();
        }
      }

      tryUnsubscribe() {
        if (this.unsubscribe) {
          this.unsubscribe();
          this.unsubscribe = null;
        }
      }

      handleChange() {
        if (!this.unsubscribe) {
          return;
        }
        mapProps && this.setState({ entityState: mapProps(this.props) });
      }

      static getDerivedStateFromProps(props) {
        return mapProps ? { entityState: mapProps(props) } : {};
      }

      render() {
        /*if (this.state.hasError) {
          return null; // Something went wrong
        }*/

        const propsToPass = { ...this.props, ...this.state.entityState };
        return <MemoizedWrappedComponent {...propsToPass} />;
      }
    }

    Connect.displayName = `ConnectBackbone(${getDisplayName(
      MemoizedWrappedComponent,
    )})`;
    Connect.WrappedComponent = MemoizedWrappedComponent;

    return Connect;
  };
}
