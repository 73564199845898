import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  getUnreadChatsCount,
  getUnreadChatsCountWithoutChat,
} from "../../../selectors/chat/chat";
import Notification from "./Notification";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const BackButton = ({ onClick, counter }) => (
  <button
    onClick={onClick}
    className="btn btn-light btn-sm backButton relative"
  >
    <FontAwesomeIcon icon={solid("arrow-left")} />
    <Notification counter={counter} className={"!absolute"} />
  </button>
);
BackButton.propTypes = {
  counter: PropTypes.number,
  chatId: PropTypes.string,
};

const BackToRootButton = connect(
  (state, { chatId }) => ({
    counter: chatId
      ? getUnreadChatsCountWithoutChat(state, { chatId })
      : getUnreadChatsCount(state),
  }),
  (dispatch) => ({
    onClick: () =>
      dispatch({ type: "chat/NAVIGATE", payload: { route: "root" } }),
  }),
)(BackButton);

export { BackToRootButton };

export default BackButton;
