import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";

import CheckboxField from "components/shared/fields/CheckboxField";
import CheckboxFields from "components/shared/fields/CheckboxFields";
import { Controls } from "components/shared/fields/ControlGroup";

function GroupAccess({
  sensitive,
  active_main_group,
  formName,
  can_create_hidden,
}) {
  const accessValue = useSelector((state) =>
    formValueSelector(formName)(state, "access"),
  );

  const access_options = [
    {
      value: "open",
      label: I18n.t("js.groups.form.open"),
      disabled: sensitive,
    },
  ];
  if (!active_main_group) {
    access_options.push({
      value: "closed",
      label: I18n.t("js.groups.form.closed"),
    });
  }
  if (can_create_hidden) {
    access_options.push({
      value: "hidden",
      label: I18n.t("js.groups.form.hidden"),
    });
  }

  return (
    <div className="form-horizontal">
      <CheckboxFields
        name="access"
        label={I18n.t("js.groups.form.access")}
        disabled={active_main_group}
        hint={
          active_main_group
            ? I18n.t("js.groups.form.access_change_disabled")
            : null
        }
        options={access_options}
      />
      {accessValue !== "open" ? (
        <div className="access-options control-group">
          <div className="controls border-box p-3">
            <Controls wrapperClassName="moderated-join input boolean">
              {accessValue == "hidden" ? null : (
                <CheckboxField
                  name="moderated_join"
                  label={I18n.t("js.groups.form.moderated_join")}
                  type="checkbox"
                  multiple
                />
              )}
            </Controls>
            <Controls wrapperClassName="members-can-invite input boolean">
              <CheckboxField
                name="members_can_invite"
                label={I18n.t("js.groups.form.members_can_invite")}
                type="checkbox"
                multiple
              />
            </Controls>
          </div>
          {accessValue == "hidden" ? null : (
            <span className="controls help-block text-sm -mt-4">
              {I18n.t("js.groups.form.moderated_membership")}
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
}
GroupAccess.propTypes = {
  access: PropTypes.string,
  sensitive: PropTypes.bool,
  formName: PropTypes.string,
  active_main_group: PropTypes.bool,
  can_create_hidden: PropTypes.bool,
};

export default GroupAccess;
