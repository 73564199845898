import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import Content from "./Content";

const globalSearchRoutes: RouteObject[] = [
  { path: "/search", element: <Content /> },
  { path: "/groups/:groupSlug/search", element: <Content /> },
];

export default globalSearchRoutes;
