import React from "react";
import { Field } from "redux-form";
import ControlGroup from "./ControlGroup";
import Hint from "./Hint";

interface TextFieldProps {
  name: string;
  label?: string;
  required?: boolean;
  multiline?: boolean;
  className?: string;
  hint?: string;
  disabled?: boolean;
  placeholder?: string;

  onChange?(...args: unknown[]): unknown;
}

function TextField({
  name,
  label,
  required,
  multiline,
  className,
  hint,
  ...fieldProps
}: TextFieldProps) {
  return (
    <ControlGroup
      name={name}
      label={label}
      required={required}
      className="text-property"
    >
      <Field
        name={name}
        type={multiline ? undefined : "text"}
        component={multiline ? "textarea" : "input"}
        required={required}
        className={className}
        {...fieldProps}
      />
      <Hint hint={hint} />
    </ControlGroup>
  );
}

export default TextField;
