import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import {
  networkAdminSelector,
  currentMemberSelector,
} from "selectors/environment";
import CheckboxField from "components/shared/fields/CheckboxField";
import MembershipAndGroupField from "components/shared/MembershipAndGroupField";
import TextField from "components/appCreator/properties/edit/TextField";
import FileField from "components/appCreator/properties/edit/FileField";
import { State } from "@types";

function MessageForm() {
  const currentMember = useSelector((state: State) =>
    currentMemberSelector(state),
  );
  const isAdmin = useSelector(networkAdminSelector);
  const toAllNetworkMembers = useSelector((state) =>
    formValueSelector("newMessage")(state as State, "to_all_network_members"),
  );
  const to = useSelector((state) =>
    formValueSelector("newMessage")(state as State, "to"),
  );
  const hasGroupRecipient = (to || []).find((to) => to.type === "group");
  const [toAll, setToAll] = useState(false);

  return (
    <div className="flex flex-col gap-3 mb-6">
      <div className="border-box p-3 flex justify-start items-start gap-2 bg-gray-50">
        <div className={"flex items-center gap-2"}>
          {currentMember && (
            <img
              className={"member-image w-10 h-10"}
              src={currentMember.images["40x40@2"]}
              alt=""
              aria-hidden
            />
          )}
          <label className="font-semibold">
            {I18n.t("js.messages.composer.to")}
          </label>
        </div>
        <div className="grow space-y-2">
          <MembershipAndGroupField
            name="to"
            multiple
            id="to"
            extraQueryParams={{ only_my_admin_groups: !isAdmin }}
            disabled={toAllNetworkMembers}
          />

          {isAdmin ? (
            <CheckboxField
              type="checkbox"
              className="checkbox items-center gap-2"
              label={I18n.t("js.messages.composer.to_all_network_members")}
              name="to_all_network_members"
              id="to_all_network_members"
              value={toAll}
              setValue={setToAll}
              additionalText={
                toAll
                  ? I18n.t("js.messages.composer.warn_to_all_network_members")
                  : hasGroupRecipient
                    ? I18n.t("js.messages.composer.warn_to_all_group_members")
                    : ""
              }
            />
          ) : null}
        </div>
      </div>

      <div className="inline items-center gap-2">
        <label className="font-semibold">
          {I18n.t("js.messages.composer.subject")}
        </label>
        <TextField name="subject" htmlId="subject" />
      </div>

      <TextField name="content" htmlId="content" multiline />
      <div className="inline items-center ">
        <label className="font-semibold">
          {I18n.t("js.messages.composer.attachments")}
        </label>
        <FileField name="attachments" htmlId="attachments" multiple />
      </div>
    </div>
  );
}

export default MessageForm;
