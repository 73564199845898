import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import PropertiesReference from "./PropertiesReference";

const ADDITIONAL_PROPERTIES = [
  "created_at",
  "updated_at",
  "author",
  "last_author",
];

function ActivityIntegration({ properties, activity }) {
  const [teaserTemplate, setTeaserTemplate] = useState(
    activity.teaser_template,
  );

  useEffect(
    () => setTeaserTemplate(activity.teaser_template),
    [activity.teaser_template],
  );

  return (
    <div className="mt-6">
      <h4>
        {I18n.t(
          "js.administration.app_creator.app.integrations.item_preview_in_stream.header",
        )}
      </h4>
      <p className="max-w-prose">
        {I18n.t(
          "js.administration.app_creator.app.integrations.item_preview_in_stream.description",
        )}
      </p>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <div className="col-span-3">
          <label
            htmlFor="app-integration-activity-body"
            className="font-medium"
          >
            {I18n.t(
              "js.administration.app_creator.app.integrations.item_preview_in_stream.label",
            )}
          </label>
          <textarea
            className="span12"
            id="app-integration-activity-body"
            rows={10}
            value={teaserTemplate}
            onChange={(e) => {
              e.preventDefault();
              setTeaserTemplate(e.target.value);

              activity.onActivityTeaserChange(e.target.value);
            }}
          ></textarea>
          <p
            className="help-block prose"
            dangerouslySetInnerHTML={{
              __html: I18n.t(
                "js.administration.app_creator.app.overview.list.example",
                {
                  example_var1: "%{category}",
                  example_var2: "%{date}",
                },
              ),
            }}
          ></p>
        </div>
        <PropertiesReference
          properties={properties}
          additional_fields={ADDITIONAL_PROPERTIES}
        />
      </div>
    </div>
  );
}
ActivityIntegration.propTypes = {
  properties: PropertiesReference.propTypes.properties,
  activity: PropTypes.shape({
    teaser_template: PropTypes.string,
    onActivityTeaserChange: PropTypes.func,
  }),
};

export default ActivityIntegration;
