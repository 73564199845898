import { convertRoutes } from "components/tixxt/BackboneFeature";
import { RouteObject } from "helpers/tixxt-router";

const activitystreamsRoutes: RouteObject[] = convertRoutes({
  feature: "Activitystreams",
  featureRoutes: {
    "(groups/:group_slug/)activitystreams": "showStream",
    "(groups/:group_slug/)activitystreams/:stream_slug": "showStream",
    "(groups/:group_slug/)activitystreams/:stream_slug/:filter":
      "showSubstream",
    rundschreiben: "showRundschreibenEditor",
    "activities/:id": "showActivity",
    "activities/:activity_id/replies/:comment_id": "showActivity",
    "activities/:activity_id/answers/:comment_id": "showActivity",
    notifications: "showNotifications",
  },
});

export default activitystreamsRoutes;
