import React from "react";
import { isEmpty } from "lodash";
import AppointmentPreview from "components/appointments/participants/AppointmentPreview";
import NewParticipantForm from "components/appointments/participants/NewParticipantForm";
import ParticipantsOverview from "components/appointments/participants/ParticipantsOverview";
import { useParams } from "helpers/tixxt-router";
import { useAppointment } from "components/appointments/api";
import PageTitle from "components/layout/PageTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import DropDown, { DropDownItem } from "components/shared/DropDown";

export default function ParticipantsView() {
  const { id } = useParams();
  const {
    data: appointment,
    isLoading,
    refetch: reloadAppointment,
  } = useAppointment(id);

  return isLoading ? (
    <p>{I18n.t("js.calendars.appointment.loading")}</p>
  ) : (
    <div>
      <PageTitle
        title={I18n.t("js.calendars.appointment.participations.manage")}
      />
      <div className="btn-toolbar">
        <div className="btn-group">
          <a
            className="btn btn-light"
            href={`/appointments/${id}`}
            title={I18n.t("js.back")}
          >
            <FontAwesomeIcon icon={solid("caret-left")} />
            <FontAwesomeIcon icon={regular("bars")} />
          </a>
        </div>
        {appointment?.can.download_attendees &&
          !isEmpty(appointment?.participants) && (
            <div className="btn-group pull-right">
              <button
                className="btn btn-light dropdown-toggle"
                data-bs-toggle="dropdown"
              >
                <FontAwesomeIcon
                  className="mr-2"
                  icon={regular("cloud-arrow-down")}
                />
                {I18n.t("js.calendars.appointment.invitees.label")}
                <FontAwesomeIcon className="ml-2" icon={solid("caret-down")} />
              </button>
              <DropDown className="dropdown-menu" alignment="start">
                <DropDownItem
                  icon={regular("file-csv")}
                  text={I18n.t(
                    "js.calendars.appointment.invitees.download_as",
                    { file_ending: "CSV" },
                  )}
                  url={`/appointments/${id}/attendees.csv`}
                />
                <DropDownItem
                  icon={regular("file-excel")}
                  text={I18n.t(
                    "js.calendars.appointment.invitees.download_as",
                    { file_ending: "XLSX" },
                  )}
                  url={`/appointments/${id}/attendees.xlsx`}
                />
              </DropDown>
            </div>
          )}
      </div>
      {appointment && (
        <div className="participants-view my-4">
          <AppointmentPreview {...appointment} />
          <NewParticipantForm
            {...appointment}
            reloadAppointment={reloadAppointment}
          />

          <ParticipantsOverview
            participants={appointment?.participants}
            appointmentId={appointment.id}
            reloadAppointment={reloadAppointment}
          />
        </div>
      )}
    </div>
  );
}
