import React from "react";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import { useParams } from "helpers/tixxt-router";
import classNames from "classnames";
import { useMembership } from "../../api/memberships";

export default function MembershipsUserbar() {
  let { membershipId } = useParams();
  membershipId = (membershipId || Preload.current_member?.id) as
    | string
    | undefined;
  const { data: membership } = useMembership(membershipId);

  return (
    <>
      <TixxtModuleSwitcher activeModule={"directory"} />
      <ContextNavigation>
        {membership && (
          <img
            src={membership.images.large}
            className="w-full mt-4 rounded"
            aria-hidden
          />
        )}
        <UserbarSection className="border-none">
          <UserbarLink to={`/members/${membershipId}`}>
            {I18n.t("js.memberships.userbar.informations")}
          </UserbarLink>
          <UserbarLink to={`/members/${membershipId}/activities`}>
            {I18n.t("js.memberships.userbar.activities")}
          </UserbarLink>
          <UserbarLink to={`/members/${membershipId}/likes`}>
            {I18n.t("js.memberships.userbar.interesting")}
          </UserbarLink>
        </UserbarSection>
        {membership && (
          <UserbarSection
            title={I18n.t("js.memberships.userbar.my_account")}
            className={classNames({
              hidden: Preload.current_member?.id !== membershipId,
            })}
          >
            <UserbarLink
              to={`/users/edit`}
              className={classNames({
                hidden: !membership?.can.update_registration,
              })}
            >
              {I18n.t("js.memberships.userbar.edit_registration")}
            </UserbarLink>
            <UserbarLink
              to={`/members/${membership.id}/edit`}
              className={classNames({
                hidden: !membership?.can.update_profile,
              })}
            >
              {I18n.t("js.memberships.userbar.edit_profile")}
            </UserbarLink>
            <UserbarLink
              to={`/members/${membership.id}/ignored`}
              className={classNames({
                hidden: !membership?.can.update_profile,
              })}
            >
              {I18n.t("js.memberships.userbar.ignored_members")}
            </UserbarLink>
          </UserbarSection>
        )}
      </ContextNavigation>
    </>
  );
}
