import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="ministry"
export default class extends Controller {
  openEditExternalIds(event) {
    event.preventDefault();
    document.querySelector("#show_external_ids").style.display = "none";
    document.querySelector("#edit_external_ids").style.display = "";
    document.querySelector("#edit_external_ids input[type=text]").focus();
  }

  closeEditExternalIds(event) {
    event.preventDefault();
    document.querySelector("#edit_external_ids form").reset();
    document.querySelector("#edit_external_ids").style.display = "none";
    document.querySelector("#show_external_ids").style.display = "";
  }
}
