import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { stringify } from "query-string";

type AdministrationUserbarResponse = {
  title: string;
  links: {
    label: string;
    path: string;
    unreads?: number;
    target?: "_blank" | "_self" | "_parent" | "_top";
    exact?: boolean;
    alternative_paths?: string[];
  }[];
  image?: {
    src: string;
  };
}[];

export function useAdministrationUserbarLinks({
  groupSlug,
  groupCategorySlug,
}: { groupSlug?: string; groupCategorySlug?: string } = {}) {
  const params = groupSlug
    ? { context_type: "group", context_id: groupSlug }
    : groupCategorySlug
      ? { context_type: "group_category", context_id: groupCategorySlug }
      : { context_type: "network" };

  return useQuery<AdministrationUserbarResponse>(
    ["userbars", "administration", params],
    () => fetchApi(`/api/v1/userbars/administration?${stringify(params)}`),
    { refetchOnWindowFocus: false },
  );
}

// Membership Api calls
export interface Membership {
  id: string;
}

export function useMembershipProfile(membershipId: string | undefined) {
  return useQuery<Membership>(`/api/v1/profiles/${membershipId}`, {
    enabled: !!membershipId,
  });
}
