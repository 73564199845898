import React, { Suspense, lazy } from "react";
import { RouteObject } from "helpers/tixxt-router";

const MemberMapPage = lazy(() => import("./MemberMapPage"));

const mapRoutes: RouteObject[] = [
  {
    path: "/map",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MemberMapPage />
      </Suspense>
    ),
  },
  {
    path: "/members/map",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MemberMapPage />
      </Suspense>
    ),
  },
  {
    path: "/groups/:groupSlug/map",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <MemberMapPage />
      </Suspense>
    ),
  },
];

export default mapRoutes;
