import React from "react";
import { createRoot } from "react-dom/client";
import { Controller } from "@hotwired/stimulus";
import FactorsWrapper from "../components/authentication/FactorsWrapper";

// Connects to data-controller="two-factor-authentications"
export default class extends Controller {
  static values = { context: String };

  connect() {
    const root = createRoot(this.element);
    root.render(<FactorsWrapper context={this.contextValue} />);
  }

  disconnect() {
    createRoot(this.element).unmount();
  }
}
