import React, { useState } from "react";
import { Field } from "redux-form";
import classNames from "classnames";
import useTooltip from "hooks/useTooltip";
import "./CheckboxField.css";

interface CheckboxFieldProps {
  name: string;
  label: string;
  type?: "radio" | "checkbox";
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: boolean | string;

  setValue?(val: boolean): void;

  id?: string;
  labelHint?: string;
  additionalText?: string;
}

export default function CheckboxField({
  name,
  type = "checkbox",
  className,
  disabled,
  required,
  value = false,
  setValue,
  label,
  id,
  labelHint,
  additionalText,
}: CheckboxFieldProps) {
  if (!setValue) {
    const [val, setVal] = useState(value);
    value = val;
    setValue = setVal;
  }

  const tooltipRef = useTooltip<HTMLLabelElement>();

  let labelProps = {};

  if (labelHint) {
    labelProps = { ["data-bs-toggle"]: "tooltip", title: I18n.t(labelHint) };
  }

  return (
    <>
      <label
        ref={tooltipRef}
        className={classNames(
          className,
          type,
          additionalText ? "checkboxfield__label" : "",
        )}
        {...labelProps}
      >
        <Field
          name={name}
          component="input"
          type={type}
          required={required}
          value={String(value)}
          onChange={(_, newVal) => setValue?.(newVal)}
          disabled={disabled}
          id={id}
        />
        {label}
      </label>

      {additionalText ? (
        <p className="additionalText">
          <i className="fa fa-exclamation text-info mr-2" />
          {additionalText}
        </p>
      ) : null}
    </>
  );
}
