import React from "react";

export type PaginationState = {
  page: null | number;
  sort_by: null | string;
  enabled: boolean;
};
const SearchQueryPaginationContext = React.createContext<
  PaginationState & { setPaginationState: (setPaginationState) => void }
>({
  page: null,
  sort_by: null,
  enabled: false,
  setPaginationState: function () {
    return;
  },
});

export default SearchQueryPaginationContext;
