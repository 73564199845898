import React from "react";

interface ItemButtonTypes {
  onClick?(...args: unknown[]): unknown;

  buttonClass?: string;
  icon?: string;
}

function ItemButton({ onClick, buttonClass, icon }: ItemButtonTypes) {
  return (
    <button onClick={onClick} className={`btn btn-sm ${buttonClass}`}>
      <i className={icon} />
    </button>
  );
}

export default ItemButton;
