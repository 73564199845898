import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withProps } from "recompose";
import { startsWith, min } from "lodash";
import moment from "moment";

import {
  getCounterForChat,
  getComposingHomie,
  isMuted,
} from "../../../selectors/chat/chat";
import ChatImage from "../shared/ChatImage";
import Notification from "../shared/Notification";
import Composing from "../shared/Composing";
import Muted from "../shared/Muted";

const Row = ({
  id,
  select,
  name,
  last_message,
  last_interacted_at,
  counter,
  muted,
  state,
  type,
  composingHomie,
}) => {
  const mom = min([moment(last_interacted_at), moment()]);

  return (
    <div
      className="chat__list_row flex gap-2 items-center px-3 py-2 cursor-pointer border-b border-neutral"
      onClick={select}
      title={name}
    >
      <div className={"relative shrink-0"}>
        <ChatImage chatId={id} />
        <Notification
          className="absolute top-0 right-0"
          counter={state && state.last_read_id ? counter : -1}
        />
        {composingHomie ? <Composing /> : null}
        {muted ? <Muted /> : null}
      </div>
      <div className="chatButtonElement texts grow truncate">
        <div className="chatName truncate font-semibold leading-tight">
          {name}
        </div>
        {last_message ? (
          <div className="chatTeaser text-xs text-muted truncate">
            {type === "group" ? <span>{last_message.from.name}: </span> : null}
            {last_message.text}
          </div>
        ) : null}
      </div>
      <div className="chatButtonElement chatLastInteraction text-xs text-muted shrink-0">
        <time title={mom.format("DD.MM.YYYY HH:mm")}>{mom.fromNow()}</time>
      </div>
      <i className="fa fa-chevron-right chatButtonElement openChatIcon" />
    </div>
  );
};
Row.propTypes = {
  select: PropTypes.func,
  name: PropTypes.string,
  type: PropTypes.string,
  counter: PropTypes.number,
};

export default compose(
  withProps(({ id }) => {
    return {
      type: startsWith(id, "member") ? "member" : "group",
    };
  }),
  connect(
    (state, { id }) => ({
      counter: getCounterForChat(state, { chatId: id }),
      muted: isMuted(state, { chatId: id }),
      composingHomie: getComposingHomie(state, { chatId: id }),
    }),
    (dispatch, { id }) => ({
      select: () => {
        dispatch({
          type: "chat/NAVIGATE",
          payload: { route: "showChat", params: { chatId: id } },
        });

        if (
          window.Notification &&
          window.Notification.permission !== "granted"
        ) {
          window.Notification.requestPermission();
        }
      },
    }),
  ),
)(Row);
