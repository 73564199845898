import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { map } from "lodash";

import styles from "./iconSelection.module.css";

export const ICONS = {
  globe: "fa fa-globe",
  "envelope-o": "fa-regular fa-envelope",
  "comments-o": "fa-regular fa-comments",
  calendar: "fa fa-calendar",
  "folder-open-o": "fa-regular fa-folder-open",
  "list-alt": "fa fa-list-alt",
  "file-o": "fa-regular fa-file",
  cog: "fa fa-cog",
  link: "fa fa-link",
  "external-link": "fa fa-external-link",
  "user-circle-o": "fa-regular fa-user-circle",
  users: "fa fa-users",
  "folder-o": "fa-regular fa-folder",
  "file-text-o": "fa-regular fa-file-text",
  columns: "fa fa-columns",
  paperclip: "fa fa-paperclip",
  book: "fa fa-book",
  bullhorn: "fa fa-bullhorn",
  certificate: "fa fa-certificate",
  "bookmark-o": "fa-regular fa-bookmark",
  flag: "fa fa-flag",
  "handshake-o": "fa-regular fa-handshake",
};

type Props = {
  icon: string;
  changeIcon: (icon: string) => void;
  disabled: boolean;
};

function IconSelection({ icon, changeIcon, disabled }: Props) {
  const [selectedIcon, selectIcon] = useState(icon);

  // update icon if its changed from e.g. changing the link type
  useEffect(() => {
    selectIcon(icon);
  }, [icon]);

  return (
    <>
      <label>
        {I18n.t("js.administration.app_navigation_items.edit.icon")}
      </label>
      <div className={styles.iconSelectionContainer}>
        {map(ICONS, (iconClass, iconName) => (
          <button
            className={classnames("btn", styles.iconButton, {
              active: selectedIcon == iconName,
            })}
            disabled={disabled}
            onClick={(e) => {
              e.preventDefault();
              selectIcon(iconName);
              changeIcon(iconName);
            }}
            key={iconName}
          >
            <i className={classnames(iconClass, styles.icon)} />
          </button>
        ))}
      </div>
    </>
  );
}

export default IconSelection;
