import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import BackboneFeature from "components/tixxt/BackboneFeature";

const invitesRoutes: RouteObject[] = [
  {
    path: "/administration/invites/new",
    element: (
      <BackboneFeature
        feature="Invites"
        action="newInvite"
        paramKeys={["group_slug"]}
      />
    ),
  },
  {
    path: "/groups/:group_slug/administration/invites/new",
    element: (
      <BackboneFeature
        feature="Invites"
        action="newInvite"
        paramKeys={["group_slug"]}
      />
    ),
  },
  {
    path: "/groups/:group_slug/membership_invites/new",
    element: (
      <BackboneFeature
        feature="Invites"
        action="newInvite"
        paramKeys={["group_slug"]}
      />
    ),
  },
];

/*
 * /invites/sent is rendered in backend but requires backbone views to render modals i.e. to refresh invites
 */
const legacyInvitesRoutes: RouteObject[] = [
  {
    path: "/groups/:group_slug/administration/invites/sent",
    element: (
      <BackboneFeature
        feature="Invites"
        action="sentInvites"
        paramKeys={["group_slug"]}
      />
    ),
  },
  {
    path: "/administration/invites/sent",
    element: (
      <BackboneFeature
        feature="Invites"
        action="sentInvites"
        paramKeys={["group_slug"]}
      />
    ),
  },
];

export default invitesRoutes;
export { legacyInvitesRoutes };
