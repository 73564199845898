import React from "react";
import { useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import classNames from "classnames";
import { matchPath, useLocation } from "helpers/tixxt-router";

type GroupNavigationItem = {
  text: string;
  link: string;
  new_window?: boolean;
  join_request_count?: number;
  navigation_items?: {
    text: string;
    link: string;
    new_window?: boolean;
  }[];
  active_match?: string;
};

function useGroupNavigationItems(groupSlug: string | null | undefined) {
  return useQuery<GroupNavigationItem[]>(
    ["groupNavigationItems", groupSlug],
    () => fetchApi(`/groups/${groupSlug}/navigation`),
    {
      enabled: !!groupSlug && groupSlug !== "new",
      refetchOnWindowFocus: false,
    },
  );
}

/* Use this if you want to pass the groupSlug in as a prop */
export default function GroupNavigation({
  groupSlug,
}: {
  groupSlug?: string | null;
}) {
  const { isLoading, data } = useGroupNavigationItems(groupSlug);

  if (!groupSlug) return null;

  return (
    <ul className={"group-navigation mb-8 flex flex-wrap flex-stretch"}>
      {isLoading
        ? [
            I18n.t("js.groups.navigation.activities"),
            I18n.t("js.groups.navigation.members"),
            I18n.t("js.groups.navigation.calendar"),
            I18n.t("js.groups.navigation.tasks"),
            I18n.t("js.groups.navigation.files"),
            I18n.t("js.groups.navigation.profile"),
          ].map((text, i) => <Placeholder key={i}>{text}</Placeholder>)
        : data?.map((nav, i) => (
            <NavigationItem key={i} last={i === data.length - 1} {...nav} />
          ))}
      <li className={"flex-1 border-b border-neutral"} />
    </ul>
  );
}

function NavigationItem({
  text,
  link,
  new_window,
  navigation_items,
  last,
  active_match,
  join_request_count,
}: GroupNavigationItem & { last?: boolean }) {
  const location = useLocation();
  // active_match provided? use this to match
  const isActive = active_match
    ? matchPath(active_match, location.pathname)
    : // Link looks like a module link? Match on prefix, equality otherwise
      link.match(/^\/groups\/.+?\//)
      ? location.pathname.indexOf(link) === 0
      : location.pathname === link;
  const hasNavigationItems = navigation_items && navigation_items.length > 0;

  return (
    <li
      className={classNames("flex border-b border-neutral h-8", {
        "pr-4": !last,
        dropdown: hasNavigationItems,
      })}
    >
      <a
        className={classNames("muted flex items-center text-sm", {
          "border-b-2 border-primary font-semibold -mb-[1px]": isActive,
        })}
        href={link}
        data-bs-toggle={hasNavigationItems ? "dropdown" : null}
        target={new_window ? "_blank" : undefined}
        rel="noreferrer"
      >
        {text}
        {hasNavigationItems && <i className={"fa fa-caret-down fa-xs ml-1"} />}
      </a>
      {hasNavigationItems && (
        <ul className="dropdown-menu">
          {navigation_items.map(({ text, link }, i) => (
            <li key={i}>
              <a className="dropdown-item" href={link}>
                {text}
              </a>
            </li>
          ))}
        </ul>
      )}
      {join_request_count ? (
        <span className="ml-1 badge rounded-xl self-center bg-unread text-[10px]">
          {join_request_count}
        </span>
      ) : null}
    </li>
  );
}

function Placeholder({ children }: { children?: React.ReactNode }) {
  return (
    <li className={"flex border-b border-neutral pr-4 h-8"}>
      <span className="flex items-center text-sm">
        <span className={"inline-block bg-gray-300 animate-pulse rounded-sm"}>
          <span className={"invisible"}>{children || "&nbsp;"}</span>
        </span>
      </span>
    </li>
  );
}
