import React from "react";

export default function Composing() {
  return (
    <span className="fa-layers absolute leading-none chat__composing">
      <i className="fa-solid fa-comment text-white" />
      <i className="fa-regular fa-comment-dots text-muted" />
    </span>
  );
}
