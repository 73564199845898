import React from "react";

import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";

import { map, some } from "lodash";
import { useAdministrationUserbarLinks } from "./api";
import { useParams, matchPath } from "helpers/tixxt-router";
import { useSelector } from "react-redux";
import { State } from "../../@types";
import {
  groupCategorySelector,
  groupSelector,
  networkSelector,
} from "selectors/environment";
import { userbarVariables } from "components/shared/GroupCategorySections";

export default function AdministrationUserbar() {
  const { groupSlug, groupCategorySlug } = useParams();
  const group = useSelector((state: State) => groupSelector(state, groupSlug));
  const groupCategory = useSelector((state: State) =>
    groupCategorySelector(state, groupCategorySlug),
  );
  const network = useSelector(networkSelector);
  const {
    data: sections,
    status,
    refetch,
  } = useAdministrationUserbarLinks({
    groupSlug,
    groupCategorySlug,
  });
  const groupCategoryTheme = useSelector(
    (state: State) => state.groupCategoryTheme,
  );

  const style = groupCategory
    ? userbarVariables(groupCategoryTheme)
    : undefined;

  const title = group
    ? group.name
    : groupCategory
      ? groupCategory.name
      : network.name;

  let elements;
  switch (status) {
    case "error":
      elements = (
        <UserbarSection
          title={title}
          extraChildren={
            <div className={"alert alert-danger"} role="alert">
              {I18n.t("js.generic_error")}
              <br />
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  refetch();
                }}
              >
                {I18n.t("js.generic_try_again")}
              </a>
            </div>
          }
          style={style}
        />
      );
      break;

    case "loading":
      elements = (
        <UserbarSection title={title} className={"animate-pulse"} style={style}>
          <UserbarLink
            key={0}
            to={`/groups/${groupSlug}/administration`}
            active={false}
          >
            {I18n.t("js.loading")}
          </UserbarLink>
        </UserbarSection>
      );
      break;
    case "success":
      elements = map(sections, (section, index) => (
        <React.Fragment key={index}>
          {section.image ? (
            <img src={section.image.src} aria-hidden={true} alt={""} />
          ) : null}
          <UserbarSection title={section.title} style={style}>
            {map(section.links, (link, index) => (
              <UserbarLink
                key={index}
                to={link.path}
                unreads={link.unreads}
                target={link.target}
                prefixMatch={!link.exact}
                active={
                  link.alternative_paths
                    ? some(link.alternative_paths, (path) =>
                        matchPath(path, location.pathname),
                      ) || undefined
                    : undefined
                }
              >
                {link.label}
              </UserbarLink>
            ))}
          </UserbarSection>
        </React.Fragment>
      ));
  }

  return (
    <>
      <TixxtModuleSwitcher />
      <ContextNavigation>{elements}</ContextNavigation>
    </>
  );
}
