import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import React, { Fragment, useEffect, useRef } from "react";
import CheckboxFields from "../../shared/fields/CheckboxFields";
import SelectionField from "../../appCreator/properties/edit/SelectionField";
import PropTypes from "prop-types";
import InfoText from "../../shared/InfoText";
import CheckboxField from "../../shared/fields/CheckboxField";
import { get } from "lodash";

const selector = formValueSelector("editMembershipSettings");
const DigestSettings = connect((state, { configCategory }) => ({
  switchValue: selector(state, `${configCategory}.switch`),
}))(({ configCategory, switchValue, conditions, dispatch }) => {
  const isMounted = useRef(false);
  const groupOptions = Tixxt.currentMember
    .groups()
    .map((group) => ({
      value: group.get("id"),
      label: group.get("name"),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (isMounted.current) {
      if (switchValue === "selected_groups") {
        dispatch(
          change(
            "editMembershipSettings",
            `${configCategory}.group_ids`,
            groupOptions.map((group) => group.value),
          ),
        );
      }
    } else {
      isMounted.current = true;
    }
  }, [switchValue, configCategory]);

  return (
    <Fragment>
      <h3>{I18n.t(`js.memberships.settings.${configCategory}.title`)}</h3>
      {get(conditions, ["email_daily_digests", "include_network_level"]) &&
      configCategory === "email_daily_digests" ? (
        <p>
          {I18n.t(
            `js.memberships.settings.${configCategory}.description_with_network`,
          )}
        </p>
      ) : (
        <p>{I18n.t(`js.memberships.settings.${configCategory}.description`)}</p>
      )}
      <CheckboxFields
        name={`${configCategory}.switch`}
        options={[
          {
            label: `js.memberships.settings.${configCategory}.checkboxes.disabled`,
            value: "disabled",
          },
          {
            label: `js.memberships.settings.${configCategory}.checkboxes.all`,
            value: "all",
          },
          {
            label: `js.memberships.settings.${configCategory}.checkboxes.selected_groups`,
            value: "selected_groups",
          },
        ]}
        multiple={false}
      />
      {switchValue === "selected_groups" ? (
        <div className="mt-3 ">
          <InfoText
            translation={
              "js.memberships.settings.email_digests.selected_groups.info"
            }
          />
          <label htmlFor={`group-select-${configCategory}-input`}>
            {I18n.t(`js.memberships.settings.${configCategory}.include_groups`)}
          </label>
          <div className="pt-3">
            <SelectionField
              select
              name={`${configCategory}.group_ids`}
              options={groupOptions}
              multiple
            />
          </div>
        </div>
      ) : null}
      {get(conditions, ["email_daily_digests", "include_network_level"]) &&
      configCategory === "email_daily_digests" &&
      switchValue !== "disabled" ? (
        <div className="control-group input property-email_daily_digests.include_network_level mt-3">
          <div className="controls radio_buttons border-box p-3">
            <CheckboxField
              name={`${configCategory}.include_network_level`}
              type="checkbox"
              className="checkbox"
              label={I18n.t(
                `js.memberships.settings.email_daily_digests.checkboxes.include_network_level`,
              )}
              value="true"
            />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
});

DigestSettings.propTypes = {
  configCategory: PropTypes.string,
};

export default DigestSettings;
