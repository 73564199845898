import React from "react";
import { connect } from "react-redux";
import map from "lodash/map";
import widgets, { widgetTypes } from "helpers/widgets/widgets";
import { DraggableWidget } from "components/administration/widgetbar/Widget";
import { getWidgetbarIdentifier } from "helpers/selectors/widgetbarSelectors";

interface WidgetTypesTypes {
  identifier: string;
}

function WidgetTypes({ identifier }: WidgetTypesTypes) {
  return (
    <div className="border-box p-3">
      <div>
        <h4>{I18n.t("js.administration.widgetbars.types.headline")}</h4>
      </div>
      <div className="flex flex-col gap-2 mt-3">
        {map(widgetTypes(identifier), (widgetType) => (
          <DraggableWidget
            key={widgets(widgetType).type as never}
            widgetType={widgets(widgetType).type as never}
            widget={widgets(widgetType) as never}
          />
        ))}
      </div>
    </div>
  );
}

export default connect((state) => ({
  identifier: getWidgetbarIdentifier(state),
}))(WidgetTypes);
