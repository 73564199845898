import type { ComponentType } from "react";
import React from "react";
import { map } from "lodash";
import { withProps } from "recompose";
import TimeAgo from "./TimeAgo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type Texts = {
  title: string;
  generatingInfo: string;
  createdAt: string;
  generateNew: string;
  generateNewHint: string;
};

interface ProgressTypes {
  percentage: number;
  texts: Texts;
}

function Progress({ percentage, texts }: ProgressTypes) {
  return (
    <>
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          style={{ width: `${percentage}%` }}
        >
          {percentage.toFixed()}%
        </div>
      </div>
      <p className={"text-sm"}>{texts.generatingInfo}</p>
    </>
  );
}

interface StartTypes {
  createdAt: string;
  files: Array<{
    id: string;
    extension: string;
    fileUrl: string;
    downloadUrl: string;
  }>;

  createExport(...args: unknown[]): unknown;

  status: "initialized" | "in_progress" | "finished";
  texts: Texts;
}

function Start({ createdAt, files, createExport, status, texts }: StartTypes) {
  return (
    <>
      {status === "finished" ? (
        <>
          <p>
            {texts.createdAt}{" "}
            <strong>
              <TimeAgo time={createdAt} />
            </strong>
          </p>
          <ul className="flex gap-4 border-b border-neutral border-solid pb-4 mb-4">
            {map(files, (file) => (
              <li key={file.id}>
                <a href={file.fileUrl}>
                  <FontAwesomeIcon
                    icon={regular("file-lines")}
                    className={"mr-1"}
                  />
                  {file.extension.toUpperCase()}
                </a>{" "}
                <a href={file.downloadUrl}>
                  <i className="fa fa-cloud-download" />
                </a>
              </li>
            ))}
          </ul>
        </>
      ) : null}
      <div className={"flex gap-2 items-baseline"}>
        <a onClick={createExport} href="#" className={"btn btn-light"}>
          {texts.generateNew}
        </a>
        <span className={"text-sm text-muted"}>{texts.generateNewHint}</span>
      </div>
    </>
  );
}

interface ExporterTypes extends ProgressTypes, StartTypes {
  canExport?: boolean;
  texts: Texts;
}

function Exporter({ status, canExport, texts, ...props }: ExporterTypes) {
  return canExport ? (
    <div className="flex gap-4 border-box p-4 w-max">
      <i className="fa-light fa-cloud-arrow-down fa-4x text-muted" />
      <div className="grow max-w-prose flex flex-col gap-2">
        <h4 className={"text-md font-semibold"}>{texts.title}</h4>
        {status === "initialized" || status === "in_progress" ? (
          <Progress texts={texts} {...props} />
        ) : (
          <Start status={status} texts={texts} {...props} />
        )}
      </div>
    </div>
  ) : null;
}

const AppItemsExporter = withProps(() => ({
  texts: {
    title: I18n.t("js.apps.export.headline"),
    generatingInfo: I18n.t("js.apps.export.in_progress_hint"),
    createdAt: I18n.t("js.apps.export.date"),
    generateNew: I18n.t("js.apps.export.create_new"),
    generateNewHint: I18n.t("js.apps.export.create_new_hint"),
    error: I18n.t("js.apps.export.failure"),
  },
}))(Exporter as ComponentType<never>);

const MembershipsExporter = withProps(() => ({
  texts: {
    title: I18n.t("js.administration.members.export.headline"),
    generatingInfo: I18n.t("js.administration.members.export.generating_info"),
    createdAt: I18n.t("js.administration.members.export.export_created_at"),
    generateNew: I18n.t("js.administration.members.export.generate_new"),
    generateNewHint: I18n.t(
      "js.administration.members.export.generate_new_hint",
    ),
  },
}))(Exporter);

const ConsumersExporter = withProps(() => ({
  texts: {
    title: I18n.t("js.administration.consumer_manager.export.headline"),
    generatingInfo: I18n.t(
      "js.administration.consumer_manager.export.generating_info",
    ),
    createdAt: I18n.t(
      "js.administration.consumer_manager.export.export_created_at",
    ),
    generateNew: I18n.t(
      "js.administration.consumer_manager.export.generate_new",
    ),
    generateNewHint: I18n.t(
      "js.administration.consumer_manager.export.generate_new_hint",
    ),
  },
}))(Exporter);

export default Exporter;
export { AppItemsExporter, MembershipsExporter, ConsumersExporter };
