import { useFeaturesUnreads } from "api/unreads";
import { mapValues, keyBy } from "lodash";

const useOpenJoinRequestsCount = () => {
  const { data: featuresUnreads } = useFeaturesUnreads();
  const openJoinRequestCount = mapValues(
    keyBy(featuresUnreads?.GroupJoinRequests, "group_slug"),
    (item) => item?.open_join_requests || 0,
  );
  return openJoinRequestCount;
};

export default useOpenJoinRequestsCount;
