import FolderForm from "./FolderForm";
import EditFileForm from "./EditFileForm";
import FolderListing from "./FolderListing";
import FileProfileRows from "./FileProfileRows";
import FileSelector from "./composer/FileSelector";
import WopiActions from "./documents/WopiActions";
import OnlyOfficeActions from "./documents/OnlyOfficeActions";
import wrapProvider from "../../helpers/components/wrapProvider";
import store from "../../store";

export default {
  "files/FolderForm": wrapProvider(store)(FolderForm),
  "files/EditFileForm": wrapProvider(store)(EditFileForm),
  "files/FolderListing": wrapProvider(store)(FolderListing),
  "files/FileProfileRows": wrapProvider(store)(FileProfileRows),
  "files/composer/FileSelector": wrapProvider(store)(FileSelector),
  "files/wopi/WopiActions": wrapProvider(store)(WopiActions),
  "files/onlyOffice/OnlyOfficeActions": wrapProvider(store)(OnlyOfficeActions),
};
