import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

const columnLayoutTypes = ["a", "ab", "abc", "a-bc", "a-bc-d", "ab-c"];

function LayoutSelect({ layout, onLayoutChange }) {
  return (
    <div
      className="select-layout mt-4"
      style={{ display: "simple" === layout ? "none" : null }}
    >
      <div className="controls">
        <div className="flex justify-start gap-2 flex-wrap sm:justify-evenly">
          {columnLayoutTypes.map((t) => (
            <label className="flex flex-col gap-1 items-center" key={t}>
              <input
                className="layout"
                type="radio"
                checked={t === layout}
                onChange={() => onLayoutChange(t)}
              />
              <img
                src={get(window.Preload, [
                  "AppCreatorAssets",
                  "item_layout",
                  t,
                ])}
                alt={I18n.t(
                  `js.administration.app_creator.app.item_layout.layouts.${t}`,
                )}
              />
            </label>
          ))}
        </div>
      </div>
    </div>
  );
}
LayoutSelect.propTypes = {
  layout: PropTypes.string.isRequired,
  onLayoutChange: PropTypes.func.isRequired,
};

export default LayoutSelect;
