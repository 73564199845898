import React, { useState } from "react";
import PropTypes from "prop-types";
import { map, get } from "lodash";

import Card from "./Card";

export default function UnassignedItems({
  cardProps,
  items,
  titlePropName,
  canUpdateColumn,
}) {
  const [showItems, setShowItems] = useState(false);

  function toggleShowItems() {
    setShowItems(!showItems);
  }

  return (
    <div className="alert alert-warning mt-4">
      <div
        onClick={toggleShowItems}
        className="unassigned-items-title hover:cursor-pointer"
      >
        {items.length === 1
          ? I18n.t("js.apps.wall.unassigned_item.hint")
          : I18n.t("js.apps.wall.unassigned_items.hint", {
              counter: items.length,
            })}
        <i
          className={`show-unassigned-items-icon ml-2 ${
            showItems
              ? "fa-regular fa-chevron-up"
              : "fa-regular fa-chevron-down"
          }`}
        />
      </div>
      {showItems ? (
        <div className="unassigned-cards flex gap-2 flex-wrap mt-2">
          {map(items, (item) => (
            <Card
              key={item.id}
              cardId={item.id}
              title={item.values[titlePropName]}
              isUnassigned={true}
              {...cardProps}
              canMoveCard={get(item, "can.update") && canUpdateColumn}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}
UnassignedItems.propTypes = {
  toggleShowItems: PropTypes.func,
  showItems: PropTypes.bool,
  cardProps: PropTypes.shape({
    isArchive: PropTypes.bool,
    onMoveCard: PropTypes.func,
    setCardHeight: PropTypes.func,
    setFilteredByTag: PropTypes.func,
    setFilteredByMember: PropTypes.func,
  }),
  items: PropTypes.array,
  titlePropName: PropTypes.string,
  canUpdateColumn: PropTypes.bool,
};
