import React from "react";
import { connect } from "react-redux";
import { Field, formValueSelector } from "redux-form";
import ControlGroup from "../../shared/fields/ControlGroup";
import MarkdownTranslation from "components/shared/MarkdownTranslation";
import TextField from "../../appCreator/properties/edit/TextField";
import SelectionField from "../../appCreator/properties/edit/SelectionField";
import GroupSelectField from "../../shared/fields/GroupSelectField";
import { State } from "@types";
import MarkdownEditorAdapter from "components/shared/markdownEditor/MarkdownEditorAdapter";

interface JoinSettingsTypes {
  join_user_can_initiate?: boolean;
  join_promote_groups?: boolean;
  decorators?: boolean;
  decorators_external?: boolean;
}

function JoinSettings({
  join_user_can_initiate,
  join_promote_groups,
  decorators,
  decorators_external,
}: JoinSettingsTypes) {
  return (
    <>
      <h3>
        {I18n.t("js.administration.network_settings.join_settings.title")}
      </h3>
      <div className="flex flex-col gap-4">
        {I18n.t("js.administration.network_settings.join_settings.hint") !=
        "no_content_do_not_change" ? (
          <div className="alert alert-info">
            {I18n.t("js.administration.network_settings.join_settings.hint")}
          </div>
        ) : null}

        <ControlGroup
          label={I18n.t("js.networks.join_settings.label")}
          className="white-box checkboxes border-box p-3"
        >
          <label className="choice checkbox">
            <Field
              component="input"
              type="checkbox"
              name="join_user_can_initiate"
            />{" "}
            {I18n.t("js.networks.join_settings.user_can_initiate")}
          </label>

          {join_user_can_initiate ? (
            <>
              <label className="choice checkbox ml-4">
                <Field
                  component="input"
                  type="checkbox"
                  name="join_moderated"
                />{" "}
                {I18n.t("js.networks.join_settings.moderated")}
              </label>

              {Tixxt.currentNetwork.getConfig("guest_users.allowed") ? (
                <label className="choice checkbox ml-4">
                  <Field
                    component="input"
                    type="checkbox"
                    name="join_as_external"
                  />{" "}
                  {I18n.t("js.networks.join_settings.as_external")}
                </label>
              ) : null}
            </>
          ) : null}

          <label className="choice checkbox">
            <Field
              component="input"
              type="checkbox"
              name="join_ask_for_profile_data"
            />{" "}
            {I18n.t("js.networks.join_settings.ask_for_profile_data")}
          </label>

          <label className="choice checkbox">
            <Field
              component="input"
              type="checkbox"
              name="group_promotion_config.active"
            />{" "}
            {I18n.t("js.networks.join_settings.join_promote_groups")}
          </label>
        </ControlGroup>

        {join_promote_groups ? (
          <div className="group_promotion_settings">
            <h4>{I18n.t("js.networks.group_promotion.headline")}</h4>

            <div className="help-block !mb-3">
              {I18n.t("js.networks.group_promotion.help_text")}
            </div>
            {decorators ? (
              <div className="help-block !mb-3 mt-1">
                <span className="text-warning">
                  {I18n.t("js.networks.group_promotion.decorators_help")}
                </span>
              </div>
            ) : null}

            <ControlGroup
              name="group_promotion_config.title"
              label={I18n.t("js.networks.group_promotion.title.label")}
              required
            >
              <TextField name="group_promotion_config.title" required />
              <div className="help-block !mb-3 mt-1">
                {I18n.t("js.networks.group_promotion.title.help")}
              </div>
            </ControlGroup>

            <ControlGroup
              name="group_promotion_config.description"
              label={I18n.t("js.networks.group_promotion.description.label")}
            >
              <TextField name="group_promotion_config.description" multiline />
              <div className="help-block !mb-3 mt-1">
                {I18n.t("js.networks.group_promotion.description.help")}
              </div>
            </ControlGroup>

            <ControlGroup
              name="group_promotion_config.group_category_ids"
              label={I18n.t(
                "js.networks.group_promotion.group_categories.label",
              )}
            >
              <SelectionField
                name="group_promotion_config.group_category_ids"
                options={Tixxt.currentNetwork
                  .group_categories()
                  .filter(
                    (gc) => !gc.get("hidden") && gc.get("access") != "hidden",
                  )
                  .map((gc) => ({
                    value: gc.get("id"),
                    label: gc.get("plural_name"),
                  }))}
                multiple
              />
              <div className="help-block !mb-3 mt-1">
                {I18n.t("js.networks.group_promotion.group_categories.help")}
              </div>
            </ControlGroup>

            <ControlGroup
              name="group_promotion_config.groups"
              label={I18n.t("js.networks.group_promotion.groups.label")}
            >
              <GroupSelectField name="group_promotion_config.groups" multiple />
              <div className="help-block !mb-3 mt-1">
                {I18n.t("js.networks.group_promotion.groups.help")}
              </div>
            </ControlGroup>
            {Tixxt.currentNetwork.getConfig("guest_users.allowed") ? (
              <ControlGroup
                name="group_promotion_config.enabled_for_external"
                label={I18n.t(
                  "js.networks.group_promotion.enabled_for_external.label",
                )}
                className="white-box checkboxes border-box p-3 mt-2"
              >
                <label className="choice checkbox border-box p-3">
                  <Field
                    component="input"
                    type="checkbox"
                    name="group_promotion_config.enabled_for_external"
                  />{" "}
                  {I18n.t(
                    "js.networks.group_promotion.enabled_for_external.choice",
                  )}
                </label>
                <div className="help-block mt-1">
                  {I18n.t(
                    "js.networks.group_promotion.enabled_for_external.help",
                  )}
                </div>
                {decorators_external ? (
                  <div className="help-block !mb-3 mt-1">
                    <span className="text-warning">
                      {I18n.t(
                        "js.networks.group_promotion.enabled_for_external.decorators_help",
                      )}
                    </span>
                  </div>
                ) : null}
              </ControlGroup>
            ) : null}
          </div>
        ) : null}

        {Tixxt.currentNetwork.getConfig("devise.login_form_configurable") ? (
          <>
            <div className="flex flex-col gap-4">
              <div>
                <h4>{I18n.t("js.networks.join_settings.login_form")}</h4>

                <MarkdownTranslation
                  styles="help-block !mb-3"
                  translation={"js.networks.join_settings.login_form_hint_md"}
                />
              </div>

              <ControlGroup
                label={I18n.t(
                  "js.networks.join_settings.label_devise_text_before",
                )}
              >
                <Field
                  component={MarkdownEditorAdapter}
                  name="devise_text_before"
                />
              </ControlGroup>
              <ControlGroup
                label={I18n.t(
                  "js.networks.join_settings.label_devise_text_between",
                )}
              >
                <Field
                  component={MarkdownEditorAdapter}
                  name="devise_text_between"
                />
              </ControlGroup>
              <ControlGroup
                label={I18n.t(
                  "js.networks.join_settings.label_devise_text_after",
                )}
              >
                <Field
                  component={MarkdownEditorAdapter}
                  name="devise_text_after"
                />
              </ControlGroup>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

const selector = formValueSelector("networkSettingsForm"); // the name of the outer form
export default connect((state) => ({
  join_user_can_initiate: selector(state as State, "join_user_can_initiate"),
  join_promote_groups: selector(
    state as State,
    "group_promotion_config.active",
  ),
  decorators: selector(state as State, "group_promotion_config.decorators"),
  decorators_external: selector(
    state as State,
    "group_promotion_config.decorators_external",
  ),
}))(JoinSettings);
