import React from "react";
import PropTypes from "prop-types";
import { compose, withContext } from "recompose";
import LayoutToggle from "./LayoutToggle";
import TabConfigurator from "./TabConfigurator";

function ItemLayoutEditor({
  type,
  onTypeChange,
  tabs,
  onTabsChange,
  properties,
}) {
  return (
    <div>
      <LayoutToggle type={type} onTypeChange={onTypeChange} />
      {type === "custom" ? (
        <TabConfigurator
          tabs={tabs}
          onTabsChange={onTabsChange}
          properties={properties}
        />
      ) : null}
    </div>
  );
}

ItemLayoutEditor.propTypes = {
  ...LayoutToggle.propTypes,
  ...TabConfigurator.propTypes,
};

const contextTypes = {
  enableInheritance: PropTypes.bool,
  enableColumns: PropTypes.bool,
  enableLabelHiding: PropTypes.bool,
  exclusivePropertyUsage: PropTypes.bool,
};

const ItemDetailLayoutEditor = compose(
  withContext(contextTypes, () => ({
    enableInheritance: false,
    enableColumns: true,
    enableLabelHiding: true,
    exclusivePropertyUsage: false,
  })),
)(ItemLayoutEditor);

const ItemEditLayoutEditor = compose(
  withContext(contextTypes, () => ({
    enableInheritance: true,
    enableColumns: false,
    enableLabelHiding: false,
    exclusivePropertyUsage: true,
  })),
)(ItemLayoutEditor);

export default ItemLayoutEditor;
export { ItemDetailLayoutEditor, ItemEditLayoutEditor };
