import React from "react";
import { map } from "lodash";

interface SurveyProps {
  html_content: string;
  options: Array<{
    id: string;
    label: string;
    percent: number;
  }>;
}

function Survey(props: SurveyProps) {
  return (
    <div className="survey-view activity-object-survey activity-object-question">
      <div className="note mb-2">
        <div
          className="note-view markdown-content"
          dangerouslySetInnerHTML={{ __html: props.html_content }}
        />
      </div>
      <div className="options">
        <div className="survey-options-view">
          <form>
            <ul className="options-container text-sm">
              {map(props.options, (option) => (
                <li key={option.id}>
                  <div className="flex items-center p-2 bg-primary/10 justify-between">
                    <label className="checkbox">{option.label}</label>
                    <span className="stats">
                      <span className="percent">{`${option.percent}%`}</span>
                    </span>
                  </div>
                  <span
                    className="votes-bar"
                    style={{ width: `${option.percent}%` }}
                  >
                    &nbsp;
                  </span>
                </li>
              ))}
            </ul>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Survey;
