import React from "react";
import { isEmpty } from "lodash";
import { useUpdateImprint, useImprint } from "./api";
import PageTitle from "../../layout/PageTitle";
import MarkdownEditorAdapter from "../../shared/markdownEditor/MarkdownEditorAdapter";
import ControlGroup from "../../shared/fields/ControlGroup";
import { Field } from "redux-form";
import ReduxForm from "../../shared/reduxForm";
import InfoText from "components/shared/InfoText";

function ImprintEdit() {
  const { isLoading: loading, data: imprints } = useImprint();
  const { mutate: updateImprint } = useUpdateImprint({
    onSuccess: () => {
      toastr.success(I18n.t("js.saving_successful"));
    },
  });

  return (
    <>
      <PageTitle title={I18n.t("js.administration.imprints.edit.title")} />
      {loading || isEmpty(imprints) ? (
        <div>
          <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
        </div>
      ) : (
        <>
          <ReduxForm
            form="editImprints"
            className={"form-horizontal"}
            initialValues={imprints}
            onSubmit={(values) => updateImprint({ imprint: values })}
            onSubmitFail={() => toastr.error(I18n.t("js.saving_failure"))}
            allowSaveWithoutChange={false}
          >
            <ControlGroup
              name="text"
              label={I18n.t("js.administration.imprints.web_imprint.label")}
              className="text-property"
            >
              <Field
                component={MarkdownEditorAdapter}
                name="imprint_attributes.content"
              />
            </ControlGroup>

            <ControlGroup
              name="text"
              label={I18n.t("js.administration.imprints.mail_imprint.label")}
              className="text-property space-y-4"
            >
              <Field component={MarkdownEditorAdapter} name="mail_imprint" />
              {imprints.show_newsletter_info ? (
                <InfoText
                  translation={
                    "js.administration.imprints.edit.newsletter_info"
                  }
                ></InfoText>
              ) : null}
            </ControlGroup>
          </ReduxForm>
        </>
      )}
    </>
  );
}

export default ImprintEdit;
