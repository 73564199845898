import type { WidgetPropertiesTypes } from "./WidgetProperties";
import React from "react";
import WidgetProperties from "components/administration/widgetbar/WidgetProperties";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedWidget,
  getWidgetbarGroupId,
} from "helpers/selectors/widgetbarSelectors";
import { removeWidget } from "actions/administration/widgetbar";

export default function WidgetPropertiesLayout() {
  const dispatch = useDispatch();
  const widget = useSelector(
    getSelectedWidget,
  ) as WidgetPropertiesTypes["widget"];
  const groupId = useSelector(getWidgetbarGroupId) as string;

  return (
    <div className="col-span-2 md:col-span-1 lg:col-span-2 pl-4 pt-4">
      <h4>{I18n.t("js.administration.widgetbars.properties.headline")}</h4>
      {widget ? (
        <WidgetProperties
          widget={widget}
          groupId={groupId}
          removeWidget={() => dispatch(removeWidget())}
        />
      ) : null}
    </div>
  );
}
