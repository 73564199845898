import {
  all,
  takeEvery,
  put,
  call,
  select,
  takeLatest,
} from "redux-saga/effects";
import Tinycon from "tinycon";

import { getProfileId } from "../../selectors/chat/application";
import {
  getUnreadChatsCount,
  isMuted,
  getChatProfile,
} from "../../selectors/chat/chat";

const notify = ({ title, ...options }) =>
  new Promise((resolve, reject) => {
    const notification = new Notification(title, options);

    const notificationCloser = () => {
      notification.close();
    };
    window.addEventListener("beforeunload", notificationCloser);

    notification.onclick = () => {
      window.focus();
      resolve();
      notification.close();
    };
    notification.onclose = () => {
      console.log("CLOSED", title, options);
      window.removeEventListener("beforeunload", notificationCloser);
      reject();
    };
  });

function* notifyForIncomingMessage({ payload }) {
  const membershipId = yield select(getProfileId);

  // Create notification only for messages from other people
  if (membershipId === payload.from.id) {
    return;
  }

  const _isMuted = yield select(isMuted, {
    chatId: payload.chat_id,
  });

  if (_isMuted) {
    return;
  }

  const profile = yield select(getChatProfile, { chatId: payload.chat_id });

  try {
    yield call(notify, {
      title: profile.name,
      icon: profile.imageUrl,
      body:
        profile.type == "group"
          ? `${payload.from.name}: ${payload.text}`
          : payload.text,
      tag: payload.chat_id,
    });

    yield put({
      type: "chat/NAVIGATE",
      payload: {
        route: "showChat",
        params: { chatId: payload.chat_id },
      },
    });
  } catch (e) {
    // Notification was closed
  }
}

function* setTinycon() {
  const unreadChats = yield select(getUnreadChatsCount);

  Tinycon.setOptions({
    color: "#FFFFFF",
    background: "#0081ce",
    fallback: true,
  });
  Tinycon.setBubble(unreadChats > 99 ? "+" : unreadChats);
}

function* notificationsSaga() {
  if (window.Notification) {
    yield takeEvery("chat/MESSAGE/RECEIVE", notifyForIncomingMessage);
  }

  yield all([
    call(setTinycon),
    takeLatest(
      [
        "chat/MY_CHATS/REPLY",
        "chat/MESSAGE/RECEIVE",
        "chat/MARK_AS_READ",
        "chat/MARK_AS_READ/RECEIVE",
      ],
      setTinycon,
    ),
  ]);
}

export default notificationsSaga;
