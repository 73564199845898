import React, { CSSProperties, useState } from "react";
import { at, isEmpty, map, reject, some, sum, assign } from "lodash";

import { useGroupsAndCategories } from "../../hooks/userbar";
import { Group, GroupCategory } from "../../@types";
import { UserbarSection } from "../layout/Userbar";
import { closeUserbar } from "../../layout/togglebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  DerivedTheme,
  Theme,
} from "components/administration/designManager/types";
import { colorObjToCSSProperties } from "components/administration/designManager/themeToCss";

function persistOpenState(id: string, open: boolean) {
  localStorage.setItem(`GroupCategorySections/${id}/open`, open.toString());
}

function loadOpenState(id: string) {
  const item = localStorage.getItem(`GroupCategorySections/${id}/open`);
  return item === null ? null : item === "true";
}

type Props = {
  children: SectionProps["children"];
  unreadCountByGroupSlug?: { [slug: string]: number | undefined };
};

export default function GroupCategorySections({
  children,
  unreadCountByGroupSlug,
}: Props) {
  const { groupCategories, groupsByCategoryId } = useGroupsAndCategories();

  return (
    <>
      {map(groupCategories, (groupCategory: GroupCategory) => {
        const groups = groupsByCategoryId[groupCategory.id];

        // Hide hidden group categories (sub-organisations) without membership
        if (groupCategory.access == "hidden" && isEmpty(groups)) return null;

        const unreadCount = sum(
          at(unreadCountByGroupSlug, map(groups, "slug")),
        );
        return (
          <GroupCategorySection
            key={groupCategory.id}
            id={groupCategory.id}
            name={groupCategory.plural_name}
            slug={groupCategory.slug}
            groups={groups}
            can={groupCategory.can}
            unreadCount={unreadCount}
            mainGroupHidden={groupCategory.main_group_hidden}
            mainGroupSlug={groupCategory.main_group_slug}
            theme={groupCategory.active_theme}
          >
            {children}
          </GroupCategorySection>
        );
      })}
    </>
  );
}

type SectionProps = {
  id: string;
  name: string;
  slug: string;
  groups: Group[];
  can: {
    create: boolean;
    administrate: boolean;
  };
  children: (
    group: Group,
    sectionStatus: { collapsed: boolean },
  ) => React.ReactNode;
  unreadCount?: number;
  mainGroupHidden: boolean;
  mainGroupSlug: string;
  theme?: Theme;
};

function GroupCategorySection({
  id,
  name,
  slug,
  groups,
  can,
  children,
  unreadCount,
  mainGroupHidden,
  mainGroupSlug,
  theme,
}: SectionProps) {
  const open = loadOpenState(id);
  const [isOpen, setIsOpen] = useState(open === null ? !isEmpty(groups) : open);

  groups = reject(groups, "archived");

  if (mainGroupHidden) {
    groups = reject(groups, (group) => {
      return group.slug === mainGroupSlug;
    });
  }

  let content;
  if (isEmpty(groups)) {
    content = (
      <div className="text-center userbar-link">
        <div className="opacity-60 userbar-link-hint">
          {I18n.t("js.activitystreams.userbar.group.empty")}
        </div>
        <a
          className="opacity-80"
          href={`/directory/${slug}`}
          onClick={closeUserbar}
        >
          {I18n.t("js.activitystreams.userbar.group.join", { name: name })}
        </a>
      </div>
    );
  } else {
    content = (
      <>
        {map(groups, (group: Group) => children(group, { collapsed: !open }))}
        <li className="userbar-link section-content__hide flex border-t pt-1">
          <a
            className="flex-1 opacity-80 px-2 text-center hover:no-underline"
            href={`/directory/${slug}`}
            onClick={closeUserbar}
          >
            {I18n.t("js.activitystreams.userbar.group.other", { name: name })}
          </a>
        </li>
      </>
    );
  }

  const addGroupLink = can.create ? (
    <a
      className="userbar-section--add-group text-lg px-2 -mr-2 font-semibold"
      href={`/groups/new/${slug}`}
      onClick={closeUserbar}
    >
      <FontAwesomeIcon icon={solid("plus")} className={"fa-xs"} />
    </a>
  ) : null;

  return (
    <UserbarSection
      title={name}
      collapsible
      isOpen={isOpen}
      setIsOpen={(open: boolean) => {
        setIsOpen(open);
        persistOpenState(id, open);
      }}
      hasFavorites={some(groups, {
        favorited: true,
      })}
      unreadCount={unreadCount}
      additionalTitleElement={addGroupLink}
      style={theme ? userbarVariables(theme) : undefined}
    >
      {content}
    </UserbarSection>
  );
}

export function userbarVariables(theme: DerivedTheme): CSSProperties {
  const names = [
    "userbar-accent",
    "userbar-background",
    "userbar-link-text",
    "userbar-section-background",
    "userbar-section__title-text",
    "userbar-link--active-background",
    "userbar-link--active-text",
    "userbar-link--hover-background",
    "userbar-link--hover-text",
  ];

  const result: CSSProperties = assign(
    {} as CSSProperties,
    ...map(names, (variable) =>
      colorObjToCSSProperties(
        variable,
        theme.colors?.[variable] || theme.derivedColors?.[variable],
      ),
    ),
  );

  return result;
}
