import React from "react";
import { map } from "lodash";

export interface TagAttachmentsTypes {
  tags: Array<{
    id: string;
    name: string;
  }>;
}

function TagAttachments({ tags }: TagAttachmentsTypes) {
  return (
    <div className="activity-tags pb-2 flex gap-2 flex-wrap empty:hidden">
      {map(tags, (tag) => (
        <span className="tag" key={tag.id}>
          <div className="label">
            <a href={`/activitystreams/all?tag=${tag.name}`}>{tag.name}</a>
          </div>
        </span>
      ))}
    </div>
  );
}

export default TagAttachments;
