import { useQuery, useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

import {
  AppNavigationItemsResponse,
  Item,
  Pages,
} from "../../@types/appNavigation";
import { updateAppNavigation } from "actions/administration/appNavigation";
import { useDispatch } from "react-redux";

export function useAppNavigationItems() {
  return useQuery<AppNavigationItemsResponse, Error>(
    ["appNavigationItems"],
    () => fetchApi("/administration/app_navigation.json"),
  );
}

export function usePages() {
  return useQuery<Pages, Error>(["pages"], () =>
    fetchApi("/administration/pages.json"),
  );
}

export function useUpdateAppNavigation() {
  const dispatch = useDispatch();
  const dispatchUpdateAppNavigation: (meta) => Promise<any> = (meta: {
    body: { items: Item[] };
  }) => dispatch(updateAppNavigation(meta));

  return useMutation(dispatchUpdateAppNavigation, {
    onSuccess: (data) => {
      if (data.error) {
        toastr.error(I18n.t("js.saving_failure"));
      } else {
        toastr.success(I18n.t("js.saving_successful"));
      }
    },
  });
}

export { useActivitystreams, type Activitystream } from "api/activitystreams";
