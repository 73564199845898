import React, { useRef } from "react";
import { createRoot } from "react-dom/client";
import { Controller } from "@hotwired/stimulus";
import Modal from "../../../components/shared/Modal";
import ControlGroup from "../../../components/shared/fields/ControlGroup";
import Textarea from "react-textarea-autosize";

function JoinRequestModal({ onClose, groupSlug, joinRequestId }) {
  const formRef = useRef();
  const submitUrl = `/groups/${groupSlug}/administration/join_requests/${joinRequestId}/reject`;

  return (
    <Modal
      static
      title={I18n.t(
        "js.administration.join_requests.open.reject_with_message_title",
      )}
      onClose={onClose}
      useMinHeightMax={true}
      modalActionProps={{
        submitText: "js.administration.join_requests.open.message.submit",
        modalSubmitActionClassName: "btn-danger",
        submit: () => formRef.current?.submit(),
      }}
    >
      <form className="form" action={submitUrl} ref={formRef}>
        <ControlGroup
          label={I18n.t("js.administration.join_requests.open.message.label")}
          className="text-property"
        >
          <Textarea minRows={10} name="rejection_message" />
        </ControlGroup>
      </form>
    </Modal>
  );
}

// Connects to data-controller="administration--groups--join-request-message"
export default class extends Controller {
  static values = { groupSlug: String, joinRequestId: String };

  connect() {
    this.childElement = document.createElement("div");
    this.element.appendChild(this.childElement);
    this.root = createRoot(this.childElement);
  }

  onCloseModal() {
    this.root.render(React.createElement(React.Fragment, null));
  }

  showModal() {
    this.root.render(
      <JoinRequestModal
        onClose={() => this.onCloseModal()}
        groupSlug={this.groupSlugValue}
        joinRequestId={this.joinRequestIdValue}
      />,
    );
  }

  disconnect() {
    this.element.removeChild(this.childElement);
    this.root.unmount();
  }
}
