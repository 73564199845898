import React, { useEffect, useState } from "react";
import Modal from "components/shared/Modal";
import { useQuery } from "react-query";
import { every } from "lodash";
import { useSelector } from "react-redux";
import { networkSelector } from "selectors/environment";

import moment from "moment";
import MarkdownTranslation from "components/shared/MarkdownTranslation";

type Props = {
  children: ({
    availableUpdates,
  }: {
    availableUpdates: AvailableUpdatesPayload;
  }) => React.ReactNode;
};

export type AvailableUpdatesPayload = {
  name: string;
  deadline: string;
  release_notes_url: string;
}[];

const setDeploymentOfferSeenDate = (
  availableUpdates: AvailableUpdatesPayload,
) =>
  availableUpdates?.forEach(({ name }) =>
    localStorage.setItem(
      `deploymentOfferSeenDate[${name}]`,
      new Date().toISOString(),
    ),
  );

const allDeploymentOffersSeen = (availableUpdates: AvailableUpdatesPayload) =>
  every(availableUpdates, ({ name }) => {
    const deploymentOffer = localStorage.getItem(
      `deploymentOfferSeenDate[${name}]`,
    );

    return moment(deploymentOffer).isSame(moment(), "day");
  });

const EMPTY = [];

export default function CustomerCentralUpdateCheck({ children }: Props) {
  const network = useSelector(networkSelector);
  const [showModal, setShowModal] = useState(false);

  const { data: availableUpdates } = useQuery<AvailableUpdatesPayload>({
    queryFn: () =>
      fetch(
        `${network.config.customer_central_url}/api/v1/available_upgrades`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${network?.customer_central_jwt}`,
          },
        },
      ).then((res) => res.json()),
    refetchInterval: 60000 * 5,
    refetchOnWindowFocus: false,
    staleTime: 60000 * 5,
    enabled: network.can.version_and_updates || false,
    retry: false,
  });

  useEffect(() => {
    if (!availableUpdates) return;

    if (allDeploymentOffersSeen(availableUpdates)) return;
    setShowModal(true);
  }, [availableUpdates]);

  return (
    <>
      {showModal && availableUpdates && availableUpdates.length > 0 ? (
        <Modal
          title={I18n.t("js.customer_central.deployment_offer.modal_title")}
          onClose={() => setDeploymentOfferSeenDate(availableUpdates)}
        >
          <div className="flex flex-col gap-4">
            <MarkdownTranslation
              translation={"js.customer_central.deployment_offer.modal_body_md"}
              options={{
                release_notes_url: availableUpdates[0].release_notes_url,
                days_till_deadline: moment(availableUpdates[0].deadline).diff(
                  moment(),
                  "days",
                ),
              }}
            />
            <a
              className="btn btn-primary btn-lg mt-24"
              target="_blank"
              href="/administration/update-zentrale/login"
            >
              {I18n.t(
                "js.customer_central.deployment_offer.open_customer_central",
              )}
            </a>
          </div>
        </Modal>
      ) : null}
      {children({ availableUpdates: availableUpdates || EMPTY })}
    </>
  );
}
