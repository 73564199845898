import React, { Fragment } from "react";
import { useSelector, useStore } from "react-redux";
import { isEmpty } from "lodash";
import { SubmissionError } from "redux-form";
import { useParams } from "helpers/tixxt-router";

import PageTitle from "components/layout/PageTitle";
import GroupForm from "./form";
import { groupCategorySelector, selectNetworkId } from "selectors/environment";
import { createGroup } from "actions/groups";

import persistItem from "helpers/items/persistItem";
import { fetchGroupDefaults } from "./api";
import { useQuery } from "react-query";
import { useLoadApp } from "../appCreator/api";

export default function NewGroup() {
  const networkId = useSelector(selectNetworkId);
  const { groupCategorySlug } = useParams();
  const category = useSelector((state) =>
    groupCategorySelector(state, groupCategorySlug),
  );
  const store = useStore();

  const { data: groupDefaults, isLoading: loading } = useQuery(
    ["groupDefaults", groupCategorySlug],
    () => fetchGroupDefaults(groupCategorySlug),
  );
  const externalAppId = `network_${networkId}:group_category_${category.id}:group_profile`;
  const { data: app } = useLoadApp({
    externalAppId,
  });

  const onSubmit = async (values, dispatch, { externalAppId }) => {
    const { profile, ...group } = values;
    const { id: itemId } = await persistItem(app, store.dispatch, {
      externalAppId,
      values: profile,
    });

    const groupResult = await dispatch(
      createGroup({
        body: { group: { ...group, profile_id: itemId } },
      }),
    );
    if (groupResult.error) throw new SubmissionError();

    return groupResult;
  };

  return (
    <Fragment>
      <PageTitle
        title={category.name}
        subtitle={I18n.t("js.groups.new.create")}
      />
      {!isEmpty(groupDefaults) && !loading ? (
        <GroupForm
          {...groupDefaults}
          onSubmit={onSubmit}
          group_category_id={category.id}
          groupCategoryName={category.name}
          formName={`group-new`}
        />
      ) : loading ? (
        <i className="fa-regular fa-spinner fa-spin" />
      ) : null}
    </Fragment>
  );
}
