import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEventHandler } from "react";
import classnames from "classnames";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

type DropDownArgs = {
  children: React.ReactNode;
  alignment?: "start" | "end";
  className?: string;
};

function DropDown({ children, className, alignment = "end" }: DropDownArgs) {
  return (
    <ul
      className={classnames(
        `dropdown-menu dropdown-menu-${alignment}`,
        className,
      )}
    >
      {children}
    </ul>
  );
}

type DropDownItemArgs = {
  children?: React.ReactNode;
  url?: string;
  icon?: IconProp;
  text?: string;
  additional?: React.ReactNode;
  linkStyle?: string;
  iconStyle?: React.CSSProperties;
  method?: string;
  className?: string;
  iconClassName?: string;
  onSelect?: MouseEventHandler<HTMLAnchorElement>;
};

export function DropDownItem({
  children,
  url,
  icon,
  text,
  additional,
  linkStyle,
  iconStyle,
  method,
  className,
  iconClassName,
  onSelect,
}: DropDownItemArgs) {
  return (
    <li className={className}>
      {children ? (
        children
      ) : (
        <a
          href={url || "#"}
          className={classNames("dropdown-item", linkStyle)}
          data-method={method}
          onClick={onSelect}
        >
          <span className="flex items-center space-x-1">
            {icon && (
              <FontAwesomeIcon
                icon={icon}
                className={classNames("fa-fw", iconClassName)}
                style={iconStyle}
              />
            )}
            {text && <span className="flex-1">{text}</span>}
            {additional}
          </span>
        </a>
      )}
    </li>
  );
}

type DropDownSubMenuArgs = {
  text: string;
  children: React.ReactNode;
  className?: string;
  icon?: IconProp;
  iconClassName?: string;
  side?: "left" | "right";
};

export function DropDownSubMenu({
  text,
  children,
  className,
  side = "right",
}: DropDownSubMenuArgs) {
  return (
    <li className={classnames("relative group", className)}>
      <div className="dropdown-item flex items-center w-full text-left cursor-pointer">
        <span className="flex-1">{text}</span>
        <FontAwesomeIcon icon={regular("chevron-right")} className="ml-auto" />
      </div>
      <ul
        className={classNames(
          "dropdown-menu absolute top-0 mt-0 hidden group-hover:block",
          {
            "left-full ml-1": side == "right",
            "right-full mr-1": side == "left",
          },
        )}
      >
        {children}
      </ul>
    </li>
  );
}

DropDown.Item = DropDownItem;
DropDown.SubMenu = DropDownSubMenu;
export default DropDown;
