import React from "react";
import classNames from "classnames";
import { range } from "lodash";
import { parse, stringify } from "query-string";

// Example:
// page: 10, totalPages: 20, width: 3
// => min: 10-3-1 => 6
// => max: 10+3 => 13
// => range: 0,1,2,3,4,5,6,7,8,9,"10",11,12,13,14,15,16,17,18,19,20
// => slice: min, max => 6,13 => 6,7,8,9,10,11,12
// => plusOne: 7,8,9,"10",11,12,13
// => add null (for ...)
// final window: null,7,8,9,"10",11,12,13,null
const plusOne = (i) => i + 1;
const pages = ({ page, totalPages, width = 3 }) => {
  const cappedPage = Math.min(page, totalPages); // in case we ended up on page greater than total page count
  const min = Math.max(0, cappedPage - width - 1);
  const max = Math.min(totalPages, cappedPage + width);
  const window = range(0, totalPages).slice(min, max).map(plusOne);
  if (window.length === 0) {
    window.push(1);
  }
  if (max < totalPages) {
    window.push(null);
  }
  if (min > 0) {
    window.unshift(null);
  }
  return window;
};

interface PaginationButtonTypes {
  disabled: boolean;
  active?: boolean;
  page: number;

  onPaginate(...args: unknown[]): unknown;

  children?: React.ReactNode;
}

function PaginationButton({
  disabled,
  active,
  page,
  onPaginate,
  children,
}: PaginationButtonTypes) {
  const query = stringify({ ...parse(location.search), page });
  const className = classNames("btn btn-light border-0", { disabled, active });

  return (
    <li>
      {active || disabled ? (
        <span className={className}>{children}</span>
      ) : (
        <a
          className={className}
          href={
            (location.pathname === "/" ? "" : location.pathname) + `?${query}`
          }
          onClick={onPaginate}
          data-page={page}
        >
          {children}
        </a>
      )}
    </li>
  );
}

type PaginationProps = {
  page: number;
  totalPages?: number;
  onPaginate: (page: number) => void;
  loading: boolean;
  width?: number;
  hideIfEmpty?: boolean;
  className?: string;
};

function Pagination({
  page,
  totalPages = 1,
  onPaginate,
  loading,
  width = 2,
  hideIfEmpty,
  className,
}: PaginationProps) {
  const handlePaginate = (event) => {
    event.preventDefault();
    onPaginate(event.currentTarget.dataset.page * 1);
    window.scrollTo({ top: 0 });
  };

  if (hideIfEmpty && totalPages && totalPages <= 1) return null;

  return (
    <ul className={classNames("pagination flex", className)}>
      <PaginationButton
        disabled={loading || page === 1}
        page={1}
        onPaginate={handlePaginate}
      >
        <i className="fa fa-pipe mr-0.5" />
        <i className="fa fa-angle-left" />
      </PaginationButton>
      <PaginationButton
        disabled={loading || page === 1}
        page={page - 1}
        onPaginate={handlePaginate}
      >
        <i className="fa fa-angle-left" />
      </PaginationButton>

      {pages({ page, totalPages, width }).map((n, index) => (
        <PaginationButton
          key={n || `null${index}`}
          active={n === page}
          page={n}
          disabled={loading || n === null}
          onPaginate={handlePaginate}
        >
          {loading && n === page ? (
            <i className="fa fa-spinner fa-spin w-4" />
          ) : n === null ? (
            <i className={"fa fa-ellipsis w-4"} />
          ) : (
            <span className={"inline-block w-4"}>{n}</span>
          )}
        </PaginationButton>
      ))}

      <PaginationButton
        disabled={loading || page >= totalPages}
        page={page + 1}
        onPaginate={handlePaginate}
      >
        <i className="fa fa-angle-right" />
      </PaginationButton>
      <PaginationButton
        disabled={loading || page >= totalPages}
        page={totalPages}
        onPaginate={handlePaginate}
      >
        <i className="fa fa-angle-right" />
        <i className="fa fa-pipe ml-0.5" />
      </PaginationButton>
    </ul>
  );
}

export default Pagination;
