import { useEffect } from "react";
import { NavigateFunction, useNavigate } from "helpers/tixxt-router";
import {
  originalPushState,
  originalReplaceState,
} from "helpers/tixxt-router/history-events";

let originalNavigate;

function shimBackboneNavigate(navigate: NavigateFunction) {
  originalNavigate = Backbone.history.navigate;
  Backbone.history.navigate = function reactRouterNavigate(
    path: string,
    options?: { replace?: boolean; trigger?: boolean },
  ) {
    const currentPath = [
      location.pathname,
      location.search,
      location.hash,
    ].join("");
    if (currentPath !== path) {
      debug(
        "[BackboneHistoryShim] navigate(",
        path,
        {
          replace: !!options?.replace,
          trigger: options?.trigger !== undefined && options.trigger,
        },
        ")",
      );

      // Handle rare case when backbone wants to update the url without triggering anything
      if (options?.trigger !== undefined && !options?.trigger) {
        if (options?.replace) {
          originalReplaceState.call(history, null, "", path);
        } else {
          originalPushState.call(history, null, "", path);
        }
      } else {
        navigate(path, { replace: !!options?.replace });
      }
    }
  };
}

function restoreBackboneNavigate() {
  Backbone.history.navigate = originalNavigate;
}

export default function BackboneHistoryShim() {
  const navigate = useNavigate();
  useEffect(() => {
    shimBackboneNavigate(navigate);
    return restoreBackboneNavigate;
  }, []);

  return null;
}
