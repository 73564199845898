import React, { useState, useCallback } from "react";
import createMentionPlugin from "@draft-js-plugins/mention";
import { omit } from "lodash";

interface TagTypes {
  className: string;
  children: React.ReactNode;
}

function Tag({ className, children }: TagTypes) {
  return (
    <div className={className + " inline-mention-container"}>#{children}</div>
  );
}

interface TagEntryTypes {
  className?: string;
  theme?: {
    mentionSuggestionsEntryText?: string;
  };
  mention: {
    name: string;
    id?: string | number | null | undefined;
    new?: boolean;
  };
}

function TagEntry({ mention, className, theme, ...rest }: TagEntryTypes) {
  const parentProps = omit(rest, ["isFocused", "searchValue"]);
  return (
    <div
      {...parentProps}
      className={`${className} suggestion`}
      key={mention.id}
    >
      <i
        className={`icon ${mention.new ? "fa fa-plus" : "fa fa-tag"}`}
        aria-hidden={true}
      />{" "}
      <span className={theme?.mentionSuggestionsEntryText}>{mention.name}</span>
    </div>
  );
}

const inlineTagPlugin = createMentionPlugin({
  mentionTrigger: "#",
  entityMutability: "IMMUTABLE",
  mentionComponent: Tag,
});

function InlineTagSuggestions() {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  return (
    <inlineTagPlugin.MentionSuggestions
      onSearchChange={search}
      open={open}
      onOpenChange={onOpenChange}
      suggestions={suggestions}
      entryComponent={TagEntry}
    />
  );

  async function search({ value }) {
    const response = await $.ajax({
      url: "/tags/find",
      type: "get",
      dataType: "json",
      data: {
        q: value,
      },
      contentType: "application/json; charset=utf-8",
    });

    setSuggestions(response);
  }
}

export default function createInlineTagPlugin() {
  return { inlineTagPlugin, InlineTagSuggestions };
}
