import React from "react";
import PropTypes from "prop-types";

function FileCounter({ filesCount }) {
  return (
    <div className="card-files">
      <i className="fa-regular fa-file file-icon mr-1" />
      {filesCount}
    </div>
  );
}
FileCounter.propTypes = {
  filesCount: PropTypes.number,
};

export default FileCounter;
