import React from "react";
import classNames from "classnames";

const AllDayTag = ({ position }: { position?: "start" | "end" }) => (
  <div
    className={classNames("text-sm bg-primary/25 p-0.5", {
      "pl-[12px]": position === "start",
      "pr-[12px]": position === "end",
    })}
  >
    {I18n.t("js.calendars.date_range.all_day")}
  </div>
);

const LeftArrow = () => (
  <div className="h-0 w-0 border-t-transparent border-b-transparent border-r-[12px] border-b-[12px] border-t-[12px] border-r-primary/25" />
);

const RightArrow = () => (
  <div className="h-0 w-0 border-t-transparent border-b-transparent border-l-[12px] border-b-[12px] border-t-[12px] border-l-primary/25" />
);

AllDayTag.LeftArrow = LeftArrow;
AllDayTag.RightArrow = RightArrow;

export default AllDayTag;
