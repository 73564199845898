import { useEffect } from "react";

export default function useFullWidthLayout() {
  useEffect(() => {
    if (document.body.classList.contains("tixxt--full")) {
      document.body.classList.add("full-content");
    }

    return () => {
      if (document.body.classList.contains("full-content")) {
        document.body.classList.remove("full-content");
      }
    };
  }, []);
}
