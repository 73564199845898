import React from "react";

interface LinkFormTypes {
  link: string;
  text: string;

  onChange(...args: unknown[]): unknown;
}

function LinkForm({ link, text, onChange }: LinkFormTypes) {
  text = text || I18n.t("js.administration.navigation_items.default_text");
  return (
    <input
      className="input-text form-control"
      value={link}
      onChange={(e) => onChange({ link: e.target.value, text })}
      placeholder={I18n.t("js.administration.navigation_items.url_placeholder")}
    />
  );
}

export default LinkForm;
