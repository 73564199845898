import React from "react";
import { map, get } from "lodash";
import classnames from "classnames";

interface TabBarProps {
  tabs: Array<{
    label: string;
    required?: boolean;
    error?: Error;
  }>;
  className?: string;
  selectTabIndex: (val: number) => void;
  selectedTabIndex: number;
}

function TabBar({
  tabs,
  selectTabIndex,
  selectedTabIndex,
  className,
}: TabBarProps) {
  return (
    <nav className={className}>
      <ul className="layout-tabs nav nav-tabs">
        {map(tabs, (tab, i) => (
          <li
            key={i}
            className={classnames({ active: selectedTabIndex === i })}
          >
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                selectTabIndex(i);
              }}
              className={classnames({ "alert-error": get(tab, "error") })}
            >
              {get(tab, "label")}{" "}
              {get(tab, "required") ? (
                <abbr title={I18n.t("js.required")}>*</abbr>
              ) : null}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default TabBar;
