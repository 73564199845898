import React from "react";

import { createRoot } from "react-dom/client";
import { Controller } from "@hotwired/stimulus";
import { isEmpty } from "lodash";
import PropertySetEditWrapper from "../../components/administration/PropertySetEditWrapper";

// Connects to data-controller="shared--property-set-edit"
export default class extends Controller {
  static values = { name: String, groupSlug: String };
  connect() {
    const root = createRoot(this.element);
    root.render(
      <PropertySetEditWrapper
        propertySetName={this.nameValue}
        groupSlug={isEmpty(this.groupSlugValue) ? null : this.groupSlugValue}
      />,
    );
  }

  disconnect() {
    createRoot(this.element).unmount();
  }
}
