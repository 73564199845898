import { put, cancelled, take } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

/*
 * Helper to run yield effect and success / failure effects accordingly
 */
export function* tryYield(effect, getSuccessEffect, getFailureEffect) {
  let result = null;

  try {
    result = yield effect;
  } catch (error) {
    return yield getFailureEffect(error);
  }

  return yield getSuccessEffect(result);
}

/*
 * Creates a saga that converts emitted events into dispatched actions
 */
export const createEventSaga = ({ events, bind, remove }) => {
  return function* eventSaga(subject) {
    const channel = eventChannel((emit) => {
      // create handlers
      const handlers = events(emit, subject);

      // bind handlers to the subject
      bind(subject, handlers);

      // will be called when eventChannel is closed
      return () => (remove ? remove(subject, handlers) : null);
    });
    try {
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    } finally {
      if (yield cancelled()) {
        channel.close();
      }
    }
  };
};
