import { useMutation, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { Appointment, Calendar } from "../../@types/appointments";
import { stringify } from "query-string";

export async function createAppointmentContact({ appointmentId, ...body }) {
  return fetchApi(`/appointments/${appointmentId}/contact`, {
    method: "POST",
    body,
  });
}

export function useCreateAppointmentContact(options) {
  return useMutation(createAppointmentContact, options);
}

type UseAppointmentArgs = {
  date: string;
  view: "sheet" | "list" | string;
  participating: boolean;
  options?: object;
};
export function useAppointments({
  date,
  view,
  participating,
  options = { keepPreviousData: true },
}: UseAppointmentArgs) {
  const isoDate = date.split("T")[0];
  const params = {
    date: isoDate,
    view,
    type: "month",
  };

  return useQuery<Appointment[]>(
    `/appointments${participating ? "/participating" : ""}?${stringify(
      params,
    )}`,
    options,
  );
}

export function useAppointment(id?: string) {
  return useQuery<Appointment>(`/appointments/${id}`, { enabled: !!id });
}

export function useCalendars(groupSlug: string = "") {
  return useQuery<Calendar[]>(
    `${groupSlug ? `/groups/${groupSlug}` : ""}/calendars`,
    {},
  );
}
