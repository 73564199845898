import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./Flash.css";

type Props = {
  alert: string;
  children: string;
  rootId?: string;
};

export default function Flash({
  alert = "success",
  children,
  rootId = "spa-root",
}: Props) {
  const [close, closeNotification] = useState(false);

  useEffect(() => {
    setTimeout(() => closeNotification(true), 5000);
  }, []);

  return ReactDOM.createPortal(
    <div className="flashes">
      <div className={`wrap ${close ? "flash-dismiss" : null}`}>
        <div
          className={`alert alert-${alert} flex justify-between items-center`}
        >
          <span>{children}</span>
          <a className="close text-2xl font-bold" data-dismiss="flash" href="#">
            ×
          </a>
        </div>
      </div>
    </div>,
    document.getElementById(rootId) as HTMLElement,
  );
}
