import { includes, without } from "lodash";

// Maintains unique, ordered list of membership ids which are currently composing (oldest first)
const composingInChat = (state = [], action) => {
  switch (action.type) {
    case "chat/COMPOSING/RECEIVE":
      return includes(state, action.payload.from.id)
        ? state
        : [...state, action.payload.from.id];
    case "chat/COMPOSING/STOPPED":
    case "chat/MESSAGE/RECEIVE":
      return without(state, action.payload.from.id);
    default:
      return state;
  }
};

const composing = (state = {}, action) => {
  switch (action.type) {
    case "chat/COMPOSING/RECEIVE":
    case "chat/COMPOSING/STOPPED":
    case "chat/MESSAGE/RECEIVE":
      return {
        ...state,
        [action.payload.chat_id]: composingInChat(
          state[action.payload.chat_id],
          action,
        ),
      };
    default:
      return state;
  }
};

export default composing;
