import React from "react";
import { Item } from "../../../@types/appNavigation";
import { ICONS } from "components/administration/appNavigationManager/IconSelection";

function NodeTitle({ node }: { node: Item }) {
  return (
    <div>
      <i className={node.icon && ICONS[node.icon]} /> {node.label}{" "}
      {node.isEntryPoint && <i className="fa fa-arrow-left entry-point" />}
    </div>
  );
}

export default NodeTitle;
