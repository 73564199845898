import React from "react";
import map from "lodash/map";
import { propTypeSelections } from "helpers/widgets/widgets";

interface SelectFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: string;
}

function SelectField({ name, onChange, value }: SelectFieldTypes) {
  return (
    <div>
      <label htmlFor={`widget-${name}-input`}>
        {I18n.t(`js.administration.widgetbars.${name}.label`)}
      </label>
      <select
        name={name}
        onChange={onChange}
        value={value}
        id={`widget-${name}-input`}
      >
        {map(propTypeSelections[name], (option, index) => (
          <option value={option} key={index}>
            {I18n.t(`js.administration.widgetbars.selections.${option}`)}
          </option>
        ))}
      </select>
    </div>
  );
}

export default SelectField;
