import React from "react";
import PropTypes from "prop-types";
import { map, some, filter, isEmpty, reject } from "lodash";
import { useQuery } from "react-query";

import DocumentAction from "./DocumentAction";

// Removes review action if edit is authorized
function simplifyActions(actions) {
  const authorizedActions = filter(actions, {
    authorized: true,
  });

  if (some(authorizedActions, { action: "edit" })) {
    return reject(authorizedActions, { action: "review" });
  } else {
    return authorizedActions;
  }
}

async function fetchActions({ fileId, type }) {
  const response = await fetch(`/files/${fileId}/${type}/actions`, {
    credentials: "include",
  });

  return response.json();
}

export default function DocumentActions({
  fileId,
  locked,
  finalized,
  type,
  renderSuptext,
  renderIcon,
}) {
  const { data } = useQuery(["documentActions", fileId, type], () =>
    fetchActions({ fileId, type }),
  );
  const authorizedActions = simplifyActions(data);
  if (isEmpty(authorizedActions)) return null;

  return (
    <div className="flex flex-col pt-1 mt-2">
      <span className={`text-xs text-muted -mt-[15px]`}>
        {renderSuptext(authorizedActions)}
      </span>
      <div className="btn-group">
        {map(authorizedActions, ({ action, app }) => (
          <DocumentAction
            key={action}
            fileId={fileId}
            action={action}
            icon={renderIcon(app)}
            type={type}
            disabled={(locked || finalized) && action !== "view"}
          />
        ))}
      </div>
    </div>
  );
}
DocumentActions.propTypes = {
  fileId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  type: PropTypes.string.isRequired,
  renderSuptext: PropTypes.func.isRequired,
  renderIcon: PropTypes.func.isRequired,
};
