import classNames from "classnames";
import React, { CSSProperties, useMemo } from "react";
import { useLocation } from "helpers/tixxt-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import UnreadBadge from "components/shared/UnreadBadge";
import { escapeRegExp } from "lodash";
import { closeUserbar } from "layout/togglebar";
import { useMaxHeight } from "hooks/useMaxHeight";

type UserbarContentProps = { children: React.ReactNode; id?: string };

type SectionProps = {
  children?: React.ReactNode;
  extraChildren?: React.ReactNode; // to be shown after <ul>
  title?: string;
  unreadCount?: number;
  className?: string;
  collapsible?: boolean;
  isOpen?: boolean;
  setIsOpen?: (open: boolean) => void;
  hasFavorites?: boolean;
  style?: CSSProperties;
  additionalTitleElement?: React.ReactNode;
};

export function ContextNavigation({
  style = "",
  children,
}: {
  children: React.ReactNode;
  style?: string;
}) {
  return (
    <div
      className={`userbar__context-navigation flex flex-col px-3 divide-y ${style}`}
    >
      {children}
    </div>
  );
}

export function UserbarContent({ children, id }: UserbarContentProps) {
  const style = useMaxHeight();

  return (
    <div className={"userbar__content"} id={id} style={style}>
      {children}
    </div>
  );
}

const titleClasses =
  "flex p-1 font-semibold uppercase items-center min-w-0 grow";
const titleTextClasses = "flex-1 text-left";

export function UserbarSection({
  children,
  extraChildren,
  title,
  unreadCount,
  className,
  collapsible,
  isOpen = true,
  setIsOpen,
  hasFavorites,
  style,
  additionalTitleElement,
}: SectionProps) {
  return (
    <div
      className={classNames(
        "userbar-section",
        {
          "userbar-section--closed": !isOpen,
        },
        "flex flex-col first:pt-0 py-3 space-y-2",
        className,
      )}
      style={style}
    >
      <div className="userbar-section__title flex items-center justify-between">
        {title &&
          (collapsible ? (
            <button
              className={titleClasses}
              onClick={() => {
                setIsOpen && setIsOpen(!isOpen);
              }}
            >
              <FontAwesomeIcon
                className="userbar-section__indicator fa-xs"
                icon={regular("chevron-right")}
              />
              <span
                className={`${titleTextClasses} truncate hover:overflow-visible hover:whitespace-normal hover:break-words userbar-section-hyphen`}
              >
                {title}
              </span>
              <UnreadBadge count={unreadCount} className="ml-1" />
            </button>
          ) : (
            <span
              className={`${titleClasses} ${titleTextClasses}`}
              style={{ wordBreak: "break-word" }}
            >
              {title}
            </span>
          ))}
        {additionalTitleElement ? additionalTitleElement : null}
      </div>
      {children ? (
        <ul
          className={classNames("userbar-section__content", {
            "section-content__hide": !hasFavorites,
          })}
        >
          {children}
        </ul>
      ) : null}
      {extraChildren}
    </div>
  );
}

type GroupFavoriteIconProps = {
  favorited: boolean;
  collapsed: boolean;
};

export function FavoriteGroupIcon({
  favorited,
  collapsed,
}: GroupFavoriteIconProps) {
  return (
    <>
      {favorited && (
        <FontAwesomeIcon
          icon={solid("star")}
          className={classNames("transition relative text-favorite/10", {
            "text-favorite/50": !collapsed,
          })}
        />
      )}
    </>
  );
}

type UserbarLinkProps = {
  children: React.ReactNode;
  to: string;
  active?: boolean;
  unreads?: number;
  target?: "_blank" | "_self" | "_parent" | "_top";
  showWhileClosed?: boolean;
  className?: string;
  prefixMatch?: boolean;
  title?: string;
  favorited?: boolean;
  collapsed?: boolean;
};

export function UserbarLink({
  children,
  to,
  unreads,
  active,
  showWhileClosed,
  className,
  prefixMatch,
  title,
  favorited = false,
  collapsed = false,
  ...extraProps
}: UserbarLinkProps) {
  const location = useLocation();
  const regex = useMemo(
    () => (prefixMatch ? new RegExp(`^${escapeRegExp(to)}(/|$|\\?)`) : null),
    [prefixMatch, to],
  );

  if (active === undefined || active === null) {
    if (regex) active = !!location.pathname.match(regex);
    else active = to === location.pathname + location.search;
  }

  function handleClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (window.isApp) {
      e.preventDefault();
      window.Turbo?.visit(to, { action: "replace" });
    }

    closeUserbar();
  }

  return (
    <>
      <li
        title={title}
        className={classNames(
          "userbar-link",
          className,
          {
            "userbar-link--active": active,
            "userbar-link--unreads": unreads,
            "section-content__hide": !showWhileClosed,
          },
          "flex items-baseline",
        )}
      >
        <a
          href={to}
          className={
            "flex-1 py-1 px-2 justify-between flex items-baseline hover:no-underline"
          }
          onClick={handleClick}
          {...extraProps}
        >
          {children}
          <div className={"whitespace-nowrap"}>
            <FavoriteGroupIcon favorited={favorited} collapsed={collapsed} />
            <UnreadBadge count={unreads} className={classNames(className)} />
          </div>
        </a>
      </li>
    </>
  );
}
