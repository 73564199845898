import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { State } from "../../@types";
import { clamp, toInteger } from "lodash";

const LARGE_BREAKPOINT = 1024;

// Proportionally shrink the extended logo in combination with image banners on smaller than large screens
// To always shrink logo on scroll set the following custom css
//   .header__logo { --shrink-breakpoint: 9999999px }
// Also adds the .header__content--logo-shrunken class when the logo is fully shrunken
export default function useLogoScrollResize() {
  const logoRef = useRef<HTMLDivElement | null>(null);
  const { logoType, bannerType } = useSelector((state: State) => state.theme);
  let logoResizeBreakpoint = LARGE_BREAKPOINT;
  if (logoRef.current) {
    const resizeBreakpoint = getComputedStyle(logoRef.current)
      .getPropertyValue("--shrink-breakpoint")
      ?.trim();
    if (resizeBreakpoint && resizeBreakpoint.match(/^\d+px$/))
      logoResizeBreakpoint = toInteger(resizeBreakpoint.replace("px", ""));
  }
  const [active, setActive] = useState(
    logoType == "extended" &&
      bannerType == "image" &&
      window.innerWidth < logoResizeBreakpoint,
  );

  // Toggle active if logo and/or banner type changes
  // Toggle active after resizing
  useEffect(() => {
    function toggleActive() {
      setActive(
        logoType == "extended" &&
          bannerType == "image" &&
          window.innerWidth < logoResizeBreakpoint,
      );
    }

    toggleActive();
    window.addEventListener("resize", toggleActive);
    return function () {
      window.removeEventListener("resize", toggleActive);
    };
  }, [logoType, bannerType, logoResizeBreakpoint]);

  // Resize logo on scroll if active, event handler is not bound otherwise
  useEffect(() => {
    const logoEl = logoRef.current;

    const navEl = document.getElementById("main-navigation") as
      | HTMLDivElement
      | undefined;
    if (!navEl || !logoEl) return;

    const maxHeight = logoEl.clientHeight;
    const maxWidth = logoEl.clientWidth;
    const minHeight = 60;
    const startShrink = navEl.offsetTop - logoEl.clientHeight;
    let r: number | null = null; // has value if animation frame has been requested

    function cleanUp() {
      if (!logoEl) return;

      logoEl.removeAttribute("style");
      document.body.style.removeProperty("--header-logo-height");
      (logoEl.parentNode as HTMLDivElement).classList.remove(
        "header__content--logo-shrunken",
      );
    }

    function onScroll() {
      if (r) return;
      if (!logoEl) return;

      if (logoEl.hasAttribute("style") && scrollY < startShrink) {
        cleanUp();
      } else if (
        logoEl.clientHeight > minHeight ||
        logoEl.clientHeight < maxHeight
      ) {
        r = requestAnimationFrame(() => {
          const newHeight = clamp(
            maxHeight - (scrollY - startShrink),
            minHeight,
            maxHeight,
          );
          logoEl.style.width = `${(newHeight / maxHeight) * maxWidth}px`;
          document.body.style.setProperty(
            "--header-logo-height",
            `${newHeight}px`,
          );
          (logoEl.parentNode as HTMLDivElement).classList.toggle(
            "header__content--logo-shrunken",
            newHeight == minHeight,
          );

          r = null;
        });
      }
    }

    if (active) {
      document.addEventListener("scroll", onScroll);
      if (scrollY >= startShrink) onScroll();
    } else {
      cleanUp();
    }
    return function () {
      document.removeEventListener("scroll", onScroll);
    };
  }, [active]);

  return logoRef;
}
