import React from "react";
import FilterModule from "./FilterModule";
import { map } from "lodash";
import SearchQueryResult from "./SearchQueryResult";
import classNames from "classnames";

function SearchResults({ results, params, loading, error }) {
  return (
    <div
      className={classNames("flex flex-col gap-4", {
        "mt-4": !window.isApp,
        "mt-16": window.isApp,
      })}
    >
      <FilterModule
        searchResults={results}
        routeParams={params}
        loading={loading}
      />
      {loading ? (
        <strong>
          <i className="fa fa-spinner fa-spin" />{" "}
          {I18n.t("js.global_search.loading")}
        </strong>
      ) : null}
      {error ? (
        <div className="alert alert-error">
          {I18n.t("js.global_search.loading_error")}
        </div>
      ) : null}
      {map(results, (result, queryName) => (
        <SearchQueryResult
          key={queryName}
          queryName={queryName}
          routeParams={params}
          loading={loading}
          {...result}
        />
      ))}
    </div>
  );
}

export default SearchResults;
