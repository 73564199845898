import React from "react";
import PropTypes from "prop-types";
import { compose, getContext } from "recompose";

const LayoutToggle = compose(
  getContext({
    enableInheritance: PropTypes.bool,
  }),
)(({ type, onTypeChange, enableInheritance }) => (
  <>
    <div className="max-w-prose mb-4">
      {I18n.t("js.administration.app_creator.app.item_layout.description")}
    </div>
    <div className="controls">
      <label className="radio">
        <input
          type="radio"
          checked={type === "auto"}
          onChange={() => onTypeChange("auto")}
        />
        {I18n.t(
          "js.administration.app_creator.app.item_layout.no_custom_layout",
        )}
      </label>
    </div>
    {enableInheritance ? (
      <div className="controls">
        <label className="radio">
          <input
            type="radio"
            checked={type === "inherit"}
            onChange={() => onTypeChange("inherit")}
          />
          {I18n.t(
            "js.administration.app_creator.app.item_layout.inherit_layout",
          )}
        </label>
      </div>
    ) : null}
    <div className="controls">
      <label className="radio">
        <input
          type="radio"
          checked={type === "custom"}
          onChange={() => onTypeChange("custom")}
        />
        {I18n.t("js.administration.app_creator.app.item_layout.custom_layout")}
      </label>
    </div>
  </>
));

LayoutToggle.propTypes = {
  type: PropTypes.string.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};

export default LayoutToggle;
