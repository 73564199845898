import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

function Tag(tag) {
  return (
    <span className="tag">
      <a className="hover:no-underline" href={`?tag=${tag.name}`}>
        {tag.name}
      </a>
    </span>
  );
}
Tag.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
};

function Tags({ tags }) {
  return (
    <div className="tags-container flex gap-1 flex-wrap">
      {map(tags, (tag) => (
        <Tag key={tag.id} {...tag} />
      ))}
    </div>
  );
}
Tags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
};

export default Tags;
