import React from "react";
import MemberRow, { Member } from "../shared/MemberRow";

export type AdminMembersWidgetProps = {
  headline?: string;
  memberships: Member[];
};

const AdminMembersWidget: React.FC<AdminMembersWidgetProps> = ({
  headline,
  memberships,
}) => {
  return (
    <div className="widget admin-members-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      <ul className="memberships unstyled space-y-2">
        {memberships.map((member) => (
          <MemberRow key={member.id} member={member} />
        ))}
      </ul>
    </div>
  );
};

export default AdminMembersWidget;
