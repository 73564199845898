import PropTypes from "prop-types";
import React from "react";
import { isEmpty, map } from "lodash";
import { v4 as uuidv4 } from "uuid";

import PropertiesReference from "./PropertiesReference";
import SingleEmailIntegration from "./SingleEmailIntegration";

const ADDITIONAL_PROPERTIES = [
  "author",
  "last_author",
  "app_name",
  "actor_name",
];

const EmailIntegration = ({
  properties,
  emailIntegrations,
  onEmailIntegrationsChange,
}) => {
  const addIntegration = () => {
    onEmailIntegrationsChange([
      ...emailIntegrations,
      {
        uuid: uuidv4(),
        type: "email_notification",
        trigger: "assign",
        assignee_property_id: "-1",
        email_subject_template: "",
        email_text_template: "",
      },
    ]);
  };

  const removeIntegration = (index) => {
    onEmailIntegrationsChange([
      ...emailIntegrations.slice(0, index),
      ...emailIntegrations.slice(index + 1),
    ]);
  };

  const changeIntegration = (index, integration) => {
    onEmailIntegrationsChange([
      ...emailIntegrations.slice(0, index),
      integration,
      ...emailIntegrations.slice(index + 1),
    ]);
  };

  return (
    <div className="mt-6">
      <h4>
        {I18n.t(
          "js.administration.app_creator.app.integrations.email_notifications.title",
        )}
      </h4>
      <div className="grid grid-cols-4 gap-4 mt-4">
        <div className="col-span-3">
          <p className="max-w-prose">
            {I18n.t(
              "js.administration.app_creator.app.integrations.email_notifications.description",
            )}
          </p>
          <p
            className="help-block"
            dangerouslySetInnerHTML={{
              __html: I18n.t(
                "js.administration.app_creator.app.overview.list.example",
                {
                  example_var1: "%{category}",
                  example_var2: "%{date}",
                },
              ),
            }}
          ></p>
        </div>
        <PropertiesReference
          properties={properties}
          additional_fields={ADDITIONAL_PROPERTIES}
        />
      </div>
      <div className="btn-toolbar justify-end mt-4">
        <div className="btn-group">
          <a
            className="btn btn-primary"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              addIntegration();
            }}
          >
            <i className="fa-regular fa-circle-plus mr-1" />
            {I18n.t(
              "js.administration.app_creator.app.integrations.email_notifications.add_integration",
            )}
          </a>
        </div>
      </div>
      {map(emailIntegrations, (integration, i) => {
        // dirty workaround to apply a uuid as key (if not available yet), trick the optimised react rendering
        if (isEmpty(integration.uuid)) {
          integration.uuid = uuidv4();
        }

        return (
          <SingleEmailIntegration
            key={integration.uuid}
            integration={integration}
            properties={properties}
            onChange={(updatedIntegration) =>
              changeIntegration(i, updatedIntegration)
            }
            onRemove={() => removeIntegration(i)}
          />
        );
      })}
    </div>
  );
};
EmailIntegration.propTypes = {
  properties: PropertiesReference.propTypes.properties,
  emailIntegrations: PropTypes.arrayOf(
    SingleEmailIntegration.propTypes.integration,
  ),
  onEmailIntegrationsChange: PropTypes.func,
};

export default EmailIntegration;
