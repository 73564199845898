import React from "react";
import MarkdownTranslation from "components/shared/MarkdownTranslation";
import classNames from "classnames";

function InfoText({
  translation,
  showIcon = true,
  small = false,
}: {
  translation: string;
  showIcon?: boolean;
  small?: boolean;
}) {
  return (
    <div
      className={classNames("flex align-baseline", {
        "gap-2": small,
        "mb-4": small,
        "gap-4": !small,
        "mb-6": !small,
      })}
    >
      {showIcon && (
        <i
          className={classNames("fa-regular fa-circle-info text-muted", {
            "ml-0": small,
            "ml-4": !small,
            "text-2xl": small,
            "text-4xl": !small,
          })}
        />
      )}
      <MarkdownTranslation
        translation={translation}
        styles="markdown-content prose max-w-none text-muted text-sm"
      />
    </div>
  );
}

export default InfoText;
