import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="accordion-scroll"
export default class extends Controller {
  connect() {
    this.scrollToOpenAccordion();
    this.element.addEventListener("shown.bs.collapse", () => {
      this.scrollToOpenAccordion();
    });
  }

  scrollToOpenAccordion() {
    const openAccordionItem = this.element.querySelector(".collapse.show");

    if (openAccordionItem) {
      const button =
        openAccordionItem.previousElementSibling.querySelector("button");

      button.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }
}
