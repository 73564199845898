import React, { useEffect, useState } from "react";

function useSelectNavigation(
  elementRef: React.RefObject<HTMLElement>,
  items: any,
  onSelect: (any?) => void,
) {
  const [cursor, setCursor] = useState(0);

  function upHandler(e) {
    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        return setCursor((prevState) =>
          prevState < items.length - 1 ? prevState + 1 : prevState,
        );
      case "ArrowUp":
        e.preventDefault();
        return setCursor((prevState) =>
          prevState > 0 ? prevState - 1 : prevState,
        );
      case "Enter":
        e.preventDefault();
        return onSelect();
      default:
        return;
    }
  }

  useEffect(() => {
    elementRef.current?.addEventListener("keydown", upHandler);

    return () => {
      elementRef.current?.removeEventListener("keydown", upHandler);
    };
  });

  return {
    cursor,
    setCursor,
  };
}

export default useSelectNavigation;
