import React from "react";
import MemberRow from "../shared/MemberRow";

type Member = {
  id: string;
  name: string;
  path: string;
  images: { "40x40@2": string };
  is_external: boolean;
  profile: { [key: string]: { displayValue: string } | string };
  birthday?: string;
  birthday_is_today?: boolean;
  age?: number;
};

export type NewestMembersWidgetProps = {
  headline?: string;
  memberships: Member[];
};

const NewestMembersWidget: React.FC<NewestMembersWidgetProps> = ({
  headline,
  memberships,
}) => {
  return (
    <div className="widget newest-members-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      <ul className="memberships unstyled space-y-2">
        {memberships.map((member) => (
          <MemberRow key={member.id} member={member} />
        ))}
      </ul>
    </div>
  );
};

export default NewestMembersWidget;
