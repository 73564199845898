import React from "react";

interface NoteTypes {
  html_content: string;
}

function Note({ html_content }: NoteTypes) {
  return (
    <div
      className="note-view markdown-content prose break-words"
      dangerouslySetInnerHTML={{
        __html:
          html_content.length > 0
            ? $.truncate(html_content, { length: 600 })
            : `<span class="text-muted">${I18n.t(
                "js.global_search.no_content",
              )}</span>`,
      }}
    />
  );
}

export default Note;
