import React, { useEffect, useRef } from "react";
import moment from "moment-timezone";

interface TimeAgoProps {
  time: string;
}

function TimeAgo(props: TimeAgoProps) {
  const ref = useRef(null);
  let el;

  useEffect(() => {
    if (!el && ref.current) {
      el = $(ref.current);
      el.timeago("init");
    } else {
      el.timeago("updateFromDOM");
    }
    return () => el.timeago("dispose");
  });

  return (
    <time
      className="timeago"
      dateTime={props.time}
      title={moment(props.time).format(
        I18n.t("plugins.Locale.Formats.valid_datetime"),
      )}
      ref={ref}
    >
      {props.time}
    </time>
  );
}

export default TimeAgo;
