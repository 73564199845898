import { useSelector } from "react-redux";
import { Group, GroupCategory } from "../@types";
import {
  groupCategoriesSelector,
  myGroupsByCategoryIdSelector,
} from "../selectors/environment";

export function useGroupsAndCategories() {
  const groupCategories: GroupCategory[] = useSelector(groupCategoriesSelector);
  const groupsByCategoryId: { [key: string]: Group[] } = useSelector(
    myGroupsByCategoryIdSelector,
  );

  return { groupCategories, groupsByCategoryId };
}
