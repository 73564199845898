import React from "react";
import PropTypes from "prop-types";
import { isArray, map } from "lodash";
import AssignedMember from "../details/AssignedMember";

function AssignedMembers({ memberships }) {
  return (
    <div className="card-footer border-t border-neutral border-dashed pt-2">
      <div className="card-assignments">
        <div className="members flex gap-2 flex-wrap">
          {isArray(memberships) ? (
            map(memberships, (membership, index) =>
              index < 7 ? (
                <AssignedMember key={membership.id} {...membership} />
              ) : null,
            )
          ) : (
            <AssignedMember {...memberships} />
          )}
          {memberships.length > 7 ? `+${memberships.length - 7}` : null}
        </div>
      </div>
      <div className="clearfix" />
    </div>
  );
}
AssignedMembers.propTypes = {
  cardId: PropTypes.string,
  memberships: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      imageId: PropTypes.string,
    }),
  ),
};

export default AssignedMembers;
