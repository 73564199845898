import { fetchApi } from "helpers/reactQueryApi";

export const fetchGroup = async (groupSlug) => {
  return await fetchApi(`/api/v1/groups/${groupSlug}`);
};

export const fetchGroupDefaults = async (groupCategorySlug) => {
  return await fetchApi(
    `/api/v1/groups/new?group_category_slug=${groupCategorySlug}`,
  );
};
export const deleteGroup = async ({ slug }) => {
  const response = await fetchApi(`/groups/${slug}`, {
    method: "DELETE",
  });

  return response;
};

export const archiveGroup = async ({ slug, archived }) => {
  const response = await fetchApi(`/groups/${slug}/archive`, {
    method: archived ? "DELETE" : "PUT",
  });

  return response;
};
