import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import { some, isEmpty } from "lodash";

const useFileProcessing = (formName: string, fieldName: string) => {
  const selector = formValueSelector(formName);
  const fieldAttachments = useSelector((state: any) =>
    selector(state, fieldName),
  );

  const isProcessing = !isEmpty(fieldAttachments)
    ? some(fieldAttachments, { state: "uploading" })
    : false;

  return isProcessing;
};

export default useFileProcessing;
