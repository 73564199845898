import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="administration--invites--sent"
export default class extends Controller {
  // Click handler
  showInviteDetails(event) {
    const model = this.findInvite(event.params.inviteId);
    Tixxt.Modal.instance().showBodied(
      I18n.t("js.invites.index.details-modal.title"),
      new Tixxt.Invites.InviteDetailsView({ model }),
    );
  }

  // Click handler
  refreshInvite(event) {
    const model = this.findInvite(event.params.inviteId);
    Tixxt.Modal.instance().showBodied(
      I18n.t("js.invites.actions.renew_invite"),
      new Tixxt.Invites.RenewInviteView({ model }),
    );
  }

  // Helper
  findInvite(id) {
    const model = new Tixxt.Invites.Invite({ id: id });
    model.fetch();
    return model;
  }
}
