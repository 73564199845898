import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--tabbar"
export default class extends Controller {
  onClickChangeTab(event) {
    event.preventDefault();
    const activeTabs = document.querySelectorAll(".active");

    activeTabs.forEach(
      (tab) => (tab.className = tab.className.replace("active", "")),
    );

    event.target.parentElement.className += " active";
    document.getElementById(event.target.href.split("#")[1]).className +=
      " active";
  }
}
