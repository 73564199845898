import React, { useState, useEffect } from "react";
import { LinkOptions } from "../../../../../@types/appNavigation";
import classNames from "classnames";

type Props = {
  options: LinkOptions;
  onChange: (props: { options: LinkOptions }) => void;
};

function Link({ options, onChange }: Props) {
  const [openIn, setOpenIn] = useState<"browser" | "webview">(
    options?.open_in || "webview",
  );
  const [link, setLink] = useState(options?.url || "");
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);
  const [openInDisabled, setOpenInDisabled] = useState(false);

  useEffect(() => {
    onChange({ options: { url: link, open_in: openIn } });
  }, [openIn, link]);

  function onLinkChange(value: string) {
    setLink(value);

    try {
      const url = new URL(value);

      setIsInvalidUrl(false);

      if (url.host === location.host) {
        setOpenInDisabled(true);
        setOpenIn("webview");
      } else setOpenInDisabled(false);
    } catch (error) {
      setIsInvalidUrl(true);
    }
  }

  return (
    <>
      <label>
        {I18n.t("js.administration.app_navigation_items.edit.link_url_label")}
      </label>
      <input
        className="input-text"
        value={options.url}
        onChange={(e) => onLinkChange(e.target.value)}
      />
      {isInvalidUrl && (
        <div className="text-danger text-sm">
          {I18n.t(
            "js.administration.app_navigation_items.edit.link_invalid_hint",
          )}
        </div>
      )}
      <label
        className={classNames("checkbox", {
          "text-muted": openInDisabled || isInvalidUrl,
        })}
      >
        <input
          type="checkbox"
          name="entry_point"
          className="ml-3"
          checked={options?.open_in === "browser"}
          onChange={(e) => setOpenIn(e.target.checked ? "browser" : "webview")}
          disabled={openInDisabled || isInvalidUrl}
        />
        {I18n.t(
          "js.administration.app_navigation_items.edit.link_open_in_browser",
        )}
      </label>
      {openInDisabled && !isInvalidUrl && (
        <div className="help-block mt-1">
          {I18n.t(
            "js.administration.app_navigation_items.edit.link_intern_hint",
          )}
        </div>
      )}
    </>
  );
}

export default Link;
