import React from "react";

export type GroupInfoWidgetProps = {
  headline?: string;
  content: string;
};

const GroupInfoWidget: React.FC<GroupInfoWidgetProps> = ({
  headline,
  content,
}) => {
  return (
    <div className="widget group-info-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      <div
        className="prose prose-sm"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default GroupInfoWidget;
