import React from "react";
import { getSelectedWidget } from "helpers/selectors/widgetbarSelectors";
import { useSelector } from "react-redux";
import { includes } from "lodash";
import MultiselectField from "components/administration/widgetbar/fields/MultiselectField";

interface AdditionalCalendarsFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: Array<string>;
  hint?: string;
}

export default function AdditionalCalendarsField(
  options: AdditionalCalendarsFieldTypes,
) {
  const widget = useSelector(getSelectedWidget) as {
    properties: { scope: string };
  };

  if (
    includes(widget.properties.scope, "additional_calendar_ids") ||
    widget.properties.scope === "additional_calendar_ids"
  ) {
    return <MultiselectField {...options} />;
  } else {
    return null;
  }
}
