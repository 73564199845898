import React, { RefObject } from "react";
import moment from "moment/moment";
import { isEmpty, map, some } from "lodash";
import { Appointment, Calendar } from "../../../@types/appointments";
import CalendarDay from "components/appointments/appointmentsList/CalendarDay";

type CalendarWeekArgs = {
  week: string[];
  appointmentsByDate: { [date: string]: Appointment[] };
  calendars?: Calendar[];
  todayRef: RefObject<HTMLDivElement>;
};

export default function CalendarWeek({
  week,
  appointmentsByDate,
  calendars,
  todayRef,
}: CalendarWeekArgs) {
  const weekNumber = moment(week[0]).startOf("week").add(3, "days").format("w");
  const isEmptyWeek = !some(appointmentsByDate, (appointments, date) => {
    return (
      moment(week[0]).startOf("week").isoWeek() ===
        moment(date).startOf("week").isoWeek() && !isEmpty(appointments)
    );
  });

  return (
    <div>
      <h2 className={`cal-week week-${weekNumber} p-2 font-medium text-sm`}>
        {I18n.t("calendars.overview.calendarweek", { weekNumber })}
      </h2>
      <div className="calendar-week border-box mb-4">
        {isEmptyWeek ? (
          <div className="p-2 grid grid-cols-[100px,1fr] gap-2 p-2">
            <div />
            <div className="no-appointments">
              {I18n.t("calendars.month_list.empty_week")}
            </div>
          </div>
        ) : (
          map(week, (day) => {
            return (
              !isEmpty(appointmentsByDate[day]) && (
                <CalendarDay
                  key={day}
                  appointments={appointmentsByDate[day]}
                  day={day}
                  calendars={calendars}
                  todayRef={todayRef}
                />
              )
            );
          })
        )}
      </div>
    </div>
  );
}
