import React, { useEffect, useState } from "react";
import Modal from "components/shared/Modal";
import { useSelector } from "react-redux";
import { networkSelector } from "selectors/environment";
import MarkdownTranslation from "components/shared/MarkdownTranslation";

export default function TeamsRenewOAuthCredentialsCheck() {
  const network = useSelector(networkSelector);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(!!network?.teams_configuration?.show_renew_secret_modal);
  }, []);

  return (
    <>
      {showModal ? (
        <Modal title={I18n.t("js.teams.renew_oauth_credentials.modal_title")}>
          <div className="flex flex-col gap-4">
            <MarkdownTranslation
              translation={"js.teams.renew_oauth_credentials.modal_body_md"}
              options={{}}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
}
