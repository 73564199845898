import { useState, useEffect } from "react";

// Hook to set ref of a DOM element and return true if is hovered
// @example
// function HoveredDiv()  {
//   const divRef = useRef();
//   const isHovered = useHover(divRef);
//   return <div ref={divRef}>{isHovered ? "Hovered" : "Not hovered"}</div>;
// };
export default function useHover(ref) {
  const [hovered, setHovered] = useState(false);
  useEffect(() => {
    if (!ref.current) return;

    function handleHover(e) {
      setHovered(e.type === "mouseenter");
    }

    ref.current.addEventListener("mouseenter", handleHover);
    ref.current.addEventListener("mouseleave", handleHover);
    return () => {
      ref.current?.removeEventListener("mouseenter", handleHover);
      ref.current?.removeEventListener("mouseleave", handleHover);
    };
  }, [ref.current]);

  return hovered;
}
