import React, { useState } from "react";
import { findIndex } from "lodash";

export interface TabbableFieldsTypes {
  fields: Array<{ type: string; label?: string; name?: string }> & {
    get(i: number): TabbableFieldsTypes["fields"][number];
    name: string;
  };
  children(...args: unknown[]): React.ReactNode;
}

// Render a tab for each field and content with the selected properties form
export default function TabbableFields({
  fields,
  children,
}: TabbableFieldsTypes) {
  const [selectedIndex, selectIndex] = useState(
    // Preselect selection field tab
    Math.max(
      findIndex(
        fields,
        (name, index) => fields.get(index).type === "selection",
      ),
      0,
    ),
  );

  return (
    <div className="tabbable tabs-left mt-10 mb-4">
      <ul className="nav nav-tabs mb-4">
        {fields.map((fieldName, index) => {
          const property = fields.get(index);
          return (
            <li
              key={index}
              className={selectedIndex === index ? "active" : undefined}
            >
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  selectIndex(index);
                }}
              >
                {property.label || property.name}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="tab-content">
        {children({
          name: `${fields.name}[${selectedIndex}]`,
          property: fields.get(selectedIndex),
        })}
      </div>
    </div>
  );
}
