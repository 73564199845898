import React, { useContext } from "react";
import { map, reduceRight, get, isEmpty } from "lodash";
import classNames from "classnames";

import { FolderContentsTableWithProfile } from "../../files/table/FolderContentsTable";
import FileRow from "../../files/table/FileRow";
import Highlights from "./Highlights";
import styles from "../globalSearch.module.css";
import UrlContextProvider from "../../files/UrlContextProvider";
import { parseSortByParam, serializeSortBy } from "../urls";
import SearchQueryPaginationContext from "../SearchQueryPaginationContext";

export function FoundIn({ found_in }) {
  return map(found_in, (path, i) => (
    <div className={styles.FoundIn} key={i}>
      <span>in </span>
      {reduceRight(
        map(path, ({ id, name }) => (
          <a key={id} href={`/files/folders/${id}`}>
            {name}
          </a>
        )),
        (prev, curr) => [
          prev,
          <span className="divider muted " key={curr.key + "sep"}>
            {" / "}
          </span>,
          curr,
        ],
      )}
    </div>
  ));
}

export function MetaRow({ extraColumns, children, className, striped }) {
  return (
    <tr className={classNames(className, { striped })}>
      <td></td>
      <td colSpan={5 + (extraColumns ? extraColumns.length : 0)}>{children}</td>
    </tr>
  );
}

function FileHit({ found_in, meta, striped, ...rowProps }) {
  const highlights = get(meta, ["highlight", "attachment.content"]);

  return (
    <>
      <FileRow {...rowProps} className={styles.FileHit} striped={striped} />
      <MetaRow
        extraColumns={rowProps.extraColumns}
        striped={striped}
        className={styles.FileHitMeta}
      >
        {isEmpty(found_in) ? null : <FoundIn found_in={found_in} />}
        {isEmpty(highlights) ? null : <Highlights highlights={highlights} />}
      </MetaRow>
    </>
  );
}

export default function FileHits({ hits, meta, groupSlug }) {
  const { sort_by, setPaginationState } = useContext(
    SearchQueryPaginationContext,
  );

  const contents = map(hits, (hit, index) => ({
    ...hit,
    type: "file",
    meta: meta[index],
    profile: hit.file_profile,
  }));

  return (
    <UrlContextProvider contextUrl={groupSlug ? `/groups/${groupSlug}` : ""}>
      <FolderContentsTableWithProfile
        contextType={groupSlug ? "group" : "network"}
        contextId={groupSlug}
        contents={contents}
        FileRow={FileHit}
        handleSort={(sortBy) => {
          setPaginationState({ page: 1, sort_by: serializeSortBy(sortBy) });
        }}
        sortBy={parseSortByParam(sort_by)}
      />
    </UrlContextProvider>
  );
}

FileHits.propTypes = {};
