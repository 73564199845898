import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import InboxPage from "components/messages/InboxPage";
import SentPage from "components/messages/SentPage";
import NewMessagePage from "components/messages/NewMessagePage";
import ShowMessagePage from "components/messages/ShowMessagePage";

const routes: RouteObject[] = [
  { path: "/messages", element: <InboxPage /> },
  { path: "/messages/sent", element: <SentPage /> },
  { path: "/messages/new", element: <NewMessagePage /> },
  { path: "/messages/:messageId", element: <ShowMessagePage /> },
];

export default routes;
