import React from "react";
import { map, includes } from "lodash";

import ControlGroup from "../../shared/fields/ControlGroup";
import TextField from "../../appCreator/properties/edit/TextField";
import SelectionField from "../../appCreator/properties/edit/SelectionField";

const AVAILABLE_LANGUAGES = ["de", "de_DU", "en", "es", "fr"];

function BasicSettings() {
  return (
    <>
      <h3>
        {I18n.t("js.administration.network_settings.basic_settings.title")}
      </h3>
      <div className="flex flex-col gap-4">
        <ControlGroup
          name="name"
          label={I18n.t("js.administration.network_settings.name_label")}
          required
        >
          <TextField name="name" required />
        </ControlGroup>

        <ControlGroup
          name="language"
          label={I18n.t("js.administration.network_settings.language_label")}
          required
        >
          <SelectionField
            id="language"
            name="language"
            required
            disabled={
              !includes(
                AVAILABLE_LANGUAGES,
                Tixxt.currentNetwork.get("language"),
              )
            }
            options={
              includes(
                AVAILABLE_LANGUAGES,
                Tixxt.currentNetwork.get("language"),
              )
                ? map(AVAILABLE_LANGUAGES, (lang) => ({
                    value: lang,
                    label: I18n.t(`js.networks.languages.${lang}`),
                  }))
                : [
                    I18n.t(
                      `js.networks.languages.${Tixxt.currentNetwork.get(
                        "language",
                      )}`,
                    ),
                  ]
            }
          />
        </ControlGroup>
        {Preload.current_network.config.abuse_reports.admin_configurable ? (
          <ControlGroup
            name="abuse_reports_enabled"
            label={I18n.t(
              "js.administration.network_settings.abuse_reporting.label",
            )}
            className="white-box checkboxes border-box p-3"
          >
            <SelectionField
              name="abuse_reports_enabled"
              multiple={false}
              select={false}
              required
              options={[
                {
                  value: "enabled",
                  label: I18n.t(
                    "js.administration.network_settings.abuse_reporting.enabled",
                  ),
                },
                {
                  value: "disabled",
                  label: I18n.t(
                    "js.administration.network_settings.abuse_reporting.disabled",
                  ),
                },
              ]}
            />
          </ControlGroup>
        ) : null}
      </div>
    </>
  );
}

export default BasicSettings;
