import React, { useState } from "react";
import classNames from "classnames";
import { find } from "lodash";
import { useParams } from "helpers/tixxt-router";

import VersionApproval from "./VersionApproval";
import ReviewStatus from "./ReviewStatus";
import GroupNavigation from "components/layout/GroupNavigation";
import PageTitle from "components/layout/PageTitle";
import { useFoldersUnreads } from "../../../api/unreads";

type LayoutProps = {
  groupSlug?: string;
  children?: React.ReactNode;
};

function Layout({ groupSlug, children }: LayoutProps) {
  return (
    <>
      <PageTitle
        title={I18n.t("js.files.approval.title")}
        subtitle={I18n.t("js.files.approval.subtitle")}
      />
      {groupSlug ? <GroupNavigation groupSlug={groupSlug} /> : null}
      {children}
    </>
  );
}

export default function ApprovalPage() {
  const { groupSlug } = useParams();
  const [activeTab, setActiveTab] = useState<string | null>(null);

  const { data: foldersUnreads, isLoading } = useFoldersUnreads();

  const unreads = find(foldersUnreads, { group_slug: groupSlug || null });

  if (activeTab === null) {
    Number(unreads?.reviewed_files) === 0 &&
    Number(unreads?.pending_versions) > 0
      ? setActiveTab("versions")
      : setActiveTab("approval");
  }

  if (isLoading) {
    return (
      <Layout groupSlug={groupSlug}>
        <div>
          <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
        </div>
      </Layout>
    );
  }

  return (
    <Layout groupSlug={groupSlug}>
      <div>
        <ul className="nav nav-tabs mb-4">
          <li className={classNames({ active: activeTab === "approval" })}>
            <a
              href="#"
              className="!inline-flex gap-1"
              onClick={(e) => {
                e.preventDefault();
                setActiveTab("approval");
              }}
            >
              {I18n.t("js.files.approval.open_review_status")}{" "}
              {Number(unreads?.reviewed_files) > 0 && (
                <span className="badge rounded-xl self-center bg-unread text-[10px]">
                  {unreads?.reviewed_files}
                </span>
              )}
            </a>
          </li>
          <li className={classNames({ active: activeTab === "versions" })}>
            <a
              href="#"
              className="!inline-flex gap-1"
              onClick={(e) => {
                e.preventDefault();
                setActiveTab("versions");
              }}
            >
              {I18n.t("js.files.approval.new_versions")}{" "}
              {Number(unreads?.pending_versions) > 0 && (
                <span className="badge rounded-xl self-center bg-unread text-[10px]">
                  {unreads?.pending_versions}
                </span>
              )}
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div
            id="#review_status"
            className={classNames("tab-pane", {
              active: activeTab === "approval",
            })}
          >
            <ReviewStatus groupSlug={groupSlug} />
          </div>
          <div
            id="#new_file_versions"
            className={classNames("tab-pane", {
              active: activeTab === "versions",
            })}
          >
            <VersionApproval groupSlug={groupSlug} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
