import React, { Fragment, useState } from "react";
import { isEmpty, map, get } from "lodash";
import { useParams } from "helpers/tixxt-router";

import PageTitle from "../../layout/PageTitle";
import LegalTextVersion from "./LegalTextVersion";
import { useLegalText } from "./api";

function LegalTextVersions() {
  const { legalTextId } = useParams();

  const { isLoading: loading, data: legalText } = useLegalText(legalTextId);

  const [openedText, setOpenedText] = useState(0);

  return (
    <Fragment>
      <PageTitle title={I18n.t("js.administration.legal_texts.versions")} />
      <div className="btn-toolbar">
        <button
          className="btn btn-light backButton"
          onClick={(e) => {
            e.preventDefault();
            Backbone.history.navigate(`/administration/legal_texts`, {
              trigger: true,
            });
          }}
        >
          <i className="fa fa-arrow-left" />
        </button>
      </div>
      {loading || isEmpty(legalText) ? (
        <div>
          <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
        </div>
      ) : (
        <Fragment>
          <div id="legal-texts" className="accordion">
            {map(get(legalText, "versions"), (version, index: number) => (
              <LegalTextVersion
                version={version}
                isCurrent={index === 0}
                isOpened={openedText === index}
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  setOpenedText(index);
                }}
              />
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}

export default LegalTextVersions;
