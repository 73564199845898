import React from "react";
import { isEmpty } from "lodash";

interface MainFieldsTypes {
  onChangeOption(...args: unknown[]): unknown;

  hasChildren: boolean;
  option: string;
  items: {
    apps?: Array<unknown>;
    pages?: Array<unknown>;
    standard?: Array<unknown>;
  };
}

function MainFields({
  hasChildren,
  option,
  onChangeOption,
  items,
}: MainFieldsTypes) {
  let optionLink = [
    {
      label: I18n.t("js.administration.navigation_items.link.option_label"),
      name: "link",
    },
    //{ label: "Folder", name: "folder" },
    //{ label: "Group", name: "group" },
    {
      label: I18n.t("js.administration.navigation_items.page.option_label"),
      name: "pages",
    },
    {
      label: I18n.t("js.administration.navigation_items.app.option_label"),
      name: "apps",
    },
    {
      label: I18n.t("js.administration.navigation_items.standard.option_label"),
      name: "standard",
    },
  ];

  // filter out Apps and Pages from the Dropdown if there are none present
  optionLink = optionLink.filter(
    (item) => !isEmpty(items[item.name]) || item.name == "link",
  );

  return (
    <div>
      {!hasChildren && (
        <div>
          <label>
            {I18n.t("js.administration.navigation_items.link_input_label")}
          </label>
          <select
            className="input-text form-control"
            name="option"
            onChange={onChangeOption}
            value={option}
          >
            {optionLink.map((item, index) => (
              <option value={item.name} key={index}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
      )}
    </div>
  );
}

export default MainFields;
