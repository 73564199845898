import React from "react";
import PropTypes from "prop-types";

import { getEmojiClassName } from "../../utils/emojis";
import markdown from "../../utils/markdown";

const markdownTextToHtml = (text) => {
  let html = null;

  try {
    html = markdown.render(text);
  } catch (e) {
    console.log("Markdown error", e);
    html = `<span class="text-danger">${I18n.t(
      "js.chat.room.message.rendering_error",
    )}</span>`;
  }

  return html;
};

function MessageText({ text }) {
  const emoji = getEmojiClassName(text);

  return (
    <div className={"chat__message_text"}>
      <div
        className={emoji ? emoji : "inline prose prose-sm break-words"}
        dangerouslySetInnerHTML={{ __html: markdownTextToHtml(text) }}
      />
    </div>
  );
}

MessageText.propTypes = { text: PropTypes.string };

// Check if the MessageText should be rendered again via React.memo HOC
export default React.memo(
  MessageText,
  (prevProps, nextProps) => prevProps.text == nextProps.text,
);
