export default function initializeCustomConfirm() {
  $.rails.allowAction = function (element) {
    let message = element.data("confirm");

    if (!message) {
      return true;
    }

    if (element.data("confirmed")) {
      element.data("confirmed", false);

      return $.rails.fire(element, "confirm:complete", [true]);
    }

    if ($.rails.fire(element, "confirm")) {
      try {
        window.bridge.confirm(message, function () {
          element.data("confirmed", true);
          const callback = $.rails.fire(element, "confirm:complete", [true]);
          if (callback) {
            $.rails.fire(element, "click");
          }
        });
      } catch (e) {
        (console.error || console.log).call(console, e.stack || e);
      }
    }

    return false;
  };
}
