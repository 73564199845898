import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import ValueSetTable from "../shared/propertySets/ValueSetTable";
import UrlContextProvider from "./UrlContextProvider";
import { selectFileProfilesEnabled } from "../../selectors/propertySets";

// The rows of the file profile to be used within the existing file info table
// which is rendered by backbone
function FileProfileRows({ fileId, values, contextUrl }) {
  const fileProfilesEnabled = useSelector(selectFileProfilesEnabled);

  return fileProfilesEnabled && values ? (
    <UrlContextProvider contextUrl={contextUrl}>
      <ValueSetTable
        name="file_profile"
        contextId={fileId}
        contextType="file"
        values={values}
        onlyRows
      />
    </UrlContextProvider>
  ) : null;
}
FileProfileRows.propTypes = {
  fileId: PropTypes.string.isRequired,
  values: PropTypes.object,
  contextUrl: PropTypes.string,
};

export default FileProfileRows;
