import { csrfTokenHeader } from "../../helpers/api";

export const allFactors = async () => {
  const response = await fetch("/api/v1/authentication/factors", {
    credentials: "include",
  });
  return await response.json();
};

export const getNewTotp = async () => {
  const response = await fetch("/api/v1/authentication/factors/new?type=totp", {
    credentials: "include",
  });
  return response.json();
};

const okJson = async (response) => {
  if (response.status === 204) return null;

  if (response.ok) {
    return await response.json();
  } else {
    throw new Error(
      response.status === 400
        ? I18n.t("js.authentication.2fa.invalid_code")
        : I18n.t("js.generic_error"),
    );
  }
};

export const createNewTotp = async ({ data, validationCode }) => {
  const response = await fetch("/api/v1/authentication/factors", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      type: data.type,
      code: validationCode,
      provisioning_uri: data.provisioning_uri,
    }),
  });

  return await okJson(response);
};

export const resetBackupCodes = async ({ id, validationCode }) => {
  const response = await fetch(
    `/api/v1/authentication/factors/${id}/reset-backup-codes`,
    {
      method: "post",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        code: validationCode,
      }),
    },
  );

  return await okJson(response);
};

export const deleteFactor = async ({ id, validationCode }) => {
  const response = await fetch(`/api/v1/authentication/factors/${id}`, {
    method: "delete",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      code: validationCode || "",
    }),
  });

  return await okJson(response);
};

// Reuses verification code to perform the 2fa step
// Is used in sign_in context in enforced 2fa networks
// after successfully adding a factor
export async function perform2fa(code) {
  const result = await fetch(`/users/two_factor_authentication.json`, {
    method: "PUT",
    credentials: "include",
    headers: {
      ...csrfTokenHeader(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      code,
    }),
  });
  return await result.json();
}

// E-Mail Two-Factor
export const getNewEmail = async () => {
  const response = await fetch("/api/v1/authentication/factors/new?type=email");
  return response.json();
};

export const createNewEmail = async ({ data, validationCode }) => {
  const response = await fetch("/api/v1/authentication/factors", {
    method: "post",
    headers: { "Content-Type": "application/json" },
    credentials: "include",
    body: JSON.stringify({
      type: data.type,
      code: validationCode,
      secret_key: data.secret_key,
    }),
  });

  return await okJson(response);
};
