import { every } from "lodash";
import createEmojiRegex from "emoji-regex";
import { length, toArray } from "stringz";

const EMOJI_REGEX = createEmojiRegex();

export const isEmoji = (symbol) => {
  // reset lastIndex instead of reinitialize the whole regexp object
  EMOJI_REGEX.lastIndex = 0;
  return EMOJI_REGEX.test(symbol);
};

export const onlyEmojis = (text) => {
  return every(toArray(text), (sym) => isEmoji(sym));
};

export const getEmojiClassName = (text, max = 3) => {
  const symbols_count = length(text);

  if (symbols_count > max) {
    return;
  }

  if (onlyEmojis(text)) {
    return `emoji emoji_${symbols_count}`;
  }
};
