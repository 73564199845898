import React from "react";
import moment from "moment-timezone";
import { get } from "lodash";
import classnames from "classnames";

interface LegalTextVersionTypes {
  onClick?(...args: unknown[]): unknown;

  isOpened?: boolean;
  isCurrent?: boolean;
  version?: {
    created_at?: string;
    text?: string;
  };
}

function LegalTextVersion({
  version,
  isCurrent,
  isOpened,
  onClick,
}: LegalTextVersionTypes) {
  return (
    <div className="accordion-group">
      <div className="accordion-heading">
        <a className="accordion-toggle" onClick={onClick} href="#">
          {I18n.t("js.administration.legal_texts.last_updated_at", {
            last_updated_at: moment(get(version, "created_at")).format(
              I18n.t("plugins.Locale.Formats.valid_datetime"),
            ),
          })}{" "}
          {isCurrent
            ? I18n.t("js.administration.legal_text.currently_used")
            : null}
        </a>
      </div>
      <div
        className={classnames("accordion-body", {
          collapse: !isOpened,
        })}
      >
        <div
          className="accordion-inner pt-4"
          dangerouslySetInnerHTML={{
            __html: get(version, "html_text") || "",
          }}
        />
      </div>
    </div>
  );
}

export default LegalTextVersion;
