import {
  ItemDetailLayoutEditor,
  ItemEditLayoutEditor,
} from "./ItemLayoutEditor";
import IntegrationsEditor from "./IntegrationsEditor";
import ItemsWallView from "./ItemsWallView";

export default {
  "appCreator/ItemDetailLayoutEditor": ItemDetailLayoutEditor,
  "appCreator/ItemEditLayoutEditor": ItemEditLayoutEditor,
  "appCreator/IntegrationsEditor": IntegrationsEditor,
  "appCreator/ItemsWallView": ItemsWallView,
};
