import React, { useState } from "react";
import invariant from "invariant";
import { isEmpty, map, pick } from "lodash";
import { stringify as stringifyQuery } from "query-string";

import GenericErrorBoundary from "components/shared/GenericErrorBoundary";
import ActivityHit from "./hits/ActivityHit";
import MessageHit from "./hits/MessageHit";
import AppointmentHit from "./hits/AppointmentHit";
import RecurringAppointmentHit from "./hits/RecurringAppointmentHit";
import ModifiedRecurringAppointmentHit from "./hits/ModifiedRecurringAppointmentHit";
import FileHits from "./hits/FileHits";
import FolderHits from "./hits/FolderHits";
import MembershipHit from "./hits/MembershipHit";
import GroupHit from "./hits/GroupHit";
import PageHit from "./hits/PageHit";
import ItemHit from "./hits/ItemHit";
import styles from "./globalSearch.module.css";
import { searchUrl } from "./urls";
import { useSearchResultsPage } from "components/globalSearch/api";
import SearchQueryPaginationContext, {
  PaginationState,
} from "components/globalSearch/SearchQueryPaginationContext";
import SearchResultPagination from "./SearchResultPagination";
import SearchSortSelector from "./SearchSortSelector";

// Component that displays search results for a single query
function Result({
  title,
  iconClass,
  fullResultUrl,
  hits,
  meta,
  total,
  per,
  page,
  sort_by,
  loading: initialLoading,
  renderHit,
  renderHits,
  queryName,
  routeParams,
  available_sorts,
}: QueryProps & QueryParams) {
  const [paginationState, setPaginationState] = useState<PaginationState>({
    page: page,
    sort_by: sort_by || null,
    enabled: false,
  });

  const contextValue = {
    ...paginationState,
    setPaginationState: (paginationState) =>
      setPaginationState({ ...paginationState, enabled: true }),
  };

  const { isLoading: pageLoading } = useSearchResultsPage(
    {
      query_name: queryName,
      page: paginationState.page || 1,
      sort_by: paginationState.sort_by,
      ...pick(routeParams, ["q", "groupSlug"]),
    },
    { enabled: paginationState.enabled },
  );

  return (
    <SearchQueryPaginationContext.Provider value={contextValue}>
      <div className={"flex mt-8 mb-4 items-center"}>
        <h2 id={`${queryName}-results`} className={"h2-default m-0 grow"}>
          <i className={`${iconClass} fa-fw text-muted mr-2`} />
          {title}
        </h2>
        {!isEmpty(available_sorts) ? (
          <SearchSortSelector availableSorts={available_sorts} />
        ) : null}
      </div>
      {pageLoading ? (
        <strong>
          <i className="fa fa-spinner fa-spin" />{" "}
          {I18n.t("js.global_search.loading")}
        </strong>
      ) : total > 0 ? (
        <div className="hits flex flex-col gap-2">
          {renderHits
            ? renderHits(hits, meta)
            : renderHit
              ? map(hits as { id: string }[], (hit, index) => (
                  <GenericErrorBoundary key={index}>
                    {renderHit(hit, meta[index])}
                  </GenericErrorBoundary>
                ))
              : JSON.stringify(hits)}
        </div>
      ) : (
        <div className="no-hits muted">
          {I18n.t("js.global_search.no_results")}
        </div>
      )}
      {!pageLoading &&
      !routeParams.module &&
      hits &&
      hits.length < total &&
      fullResultUrl ? (
        <div key="more" className={styles.showMore}>
          <a href={fullResultUrl}>
            {I18n.t("js.global_search.show_more_results")}
          </a>
        </div>
      ) : null}
      {!pageLoading && routeParams.module && hits && hits.length < total ? (
        <SearchResultPagination
          total={total}
          per={per}
          loading={initialLoading || pageLoading}
        />
      ) : null}
    </SearchQueryPaginationContext.Provider>
  );
}

// Map query names to the components to be rendered
export type QueryParams = {
  queryName: string;
  routeParams: {
    q: string;
    groupSlug?: string;
    module: string | null;
    page: string;
  };
  hits?: unknown[];
  meta: { _score: number }[];
  total: number;
  per: number;
  page: number;
  sort_by?: string;
  loading: boolean;
  available_sorts: null | { key: string; label: string }[];
};
type QueryProps = {
  title: string;
  iconClass: string;
  fullResultUrl: string | null;
  renderHit?: (hit: any, meta: any) => React.ReactNode;
  renderHits?: (hits: any, meta: any) => React.ReactNode;
};
export const QUERY_MAPPING: {
  [key: string]:
    | ((
        params: Pick<QueryParams, "routeParams" | "sort_by" | "hits">,
      ) => QueryProps)
    | undefined;
} = {
  activities_query: ({ routeParams: { q, groupSlug } }) => ({
    title: I18n.t("js.activitystreams.module_name"),
    iconClass: "fa-regular fa-earth-europe",
    fullResultUrl: searchUrl({ q, groupSlug, module: "activities" }),
    renderHit(hit) {
      return <ActivityHit {...hit} key={hit.id} />;
    },
  }),
  messages_query: ({ routeParams: { q } }) => ({
    title: I18n.t("js.messages.module_name"),
    iconClass: "fa-regular fa-inbox",
    fullResultUrl: searchUrl({ q, module: "messages" }),
    renderHit(hit) {
      return <MessageHit {...hit} key={hit.id} />;
    },
  }),
  appointments_query: ({ routeParams: { q, groupSlug } }) => ({
    title: I18n.t("js.calendars.module_name"),
    iconClass: "fa-regular fa-calendar-day",
    fullResultUrl: searchUrl({ q, groupSlug, module: "appointments" }),
    renderHit(hit) {
      switch (hit.type) {
        case "recurring_appointment":
          return <RecurringAppointmentHit {...hit} key={hit.id} />;
        case "modified_recurring_appointment":
          return <ModifiedRecurringAppointmentHit {...hit} key={hit.id} />;
        default:
          return <AppointmentHit {...hit} key={hit.id} />;
      }
    },
  }),
  files_query: ({ routeParams: { q, groupSlug } }) => ({
    title: I18n.t("js.files.module_name"),
    iconClass: "fa-regular fa-files",
    fullResultUrl: searchUrl({ q, groupSlug, module: "files" }),
    renderHits(hits, meta) {
      return <FileHits hits={hits} meta={meta} groupSlug={groupSlug} q={q} />;
    },
  }),
  folders_query: ({ routeParams: { q, groupSlug } }) => ({
    title: I18n.t("js.files.search.folder_results_heading"),
    iconClass: "fa-regular fa-folder-open",
    fullResultUrl: searchUrl({ q, groupSlug, module: "files" }),
    renderHits(hits, meta) {
      return <FolderHits hits={hits} meta={meta} groupSlug={groupSlug} q={q} />;
    },
  }),
  memberships_query: ({ routeParams: { q } }) => ({
    title: I18n.t("js.directory.title.members"),
    iconClass: "fa-regular fa-user",
    fullResultUrl: `/members?${stringifyQuery({ q })}`,
    renderHit(hit) {
      return <MembershipHit {...hit} key={hit.id} />;
    },
  }),
  groups_query: ({ routeParams: { q }, hits }) => {
    const groupHits = hits as { group_category_id?: string }[] | undefined;
    const groupCategory =
      groupHits && groupHits.length > 0
        ? Tixxt.currentNetwork
            .group_categories()
            .get(groupHits[0].group_category_id)
        : null;

    return {
      title: I18n.t("js.directory.title.groups"),
      iconClass: "fa-regular fa-users",
      fullResultUrl: groupCategory
        ? `/directory/${groupCategory.get("slug")}?${stringifyQuery({ q })}`
        : null,
      renderHit(hit) {
        return <GroupHit {...hit} key={hit.id} />;
      },
    };
  },
  pages_query: ({ routeParams: { q, groupSlug } }) => ({
    title: I18n.t("js.pages.module_name"),
    iconClass: "fa-regular fa-sidebar",
    fullResultUrl: searchUrl({ q, groupSlug, module: "pages" }),
    renderHit(hit, { highlight }) {
      return <PageHit {...hit} highlight={highlight} key={hit.id} />;
    },
  }),
  items_query: ({ routeParams: { q, groupSlug } }) => ({
    title: I18n.t("js.app_creator.module_name"),
    iconClass: "fa-regular fa-table",
    fullResultUrl: searchUrl({ q, groupSlug, module: "items" }),
    renderHit(hit) {
      return <ItemHit {...hit} key={hit.item_id} />;
    },
  }),
};

export default function SearchQueryResult(props: QueryParams) {
  const { queryName, ...remainingProps } = props;
  const propsFn = QUERY_MAPPING[queryName];
  invariant(propsFn, `No propsFn defined to render results for ${queryName}.`);
  const extraProps = propsFn(props);

  return <Result {...remainingProps} queryName={queryName} {...extraProps} />;
}
