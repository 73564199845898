import React from "react";
import { isEmpty } from "lodash";
import AddOptions from "./AddOptions";

// Renders form for all available modifications depending on
// property type, i.e. add_options for selections

interface ModificationFormTypes {
  name: string;
  property: {
    type: string;
    label?: string;
    name: string;
    options: Array<{ label: string }>;
  };
}

export default function ModificationForm({
  name,
  property,
}: ModificationFormTypes) {
  const availableModifications: Array<React.ReactNode> = [];
  if (property.type === "selection")
    availableModifications.push(
      <AddOptions
        key="add_options"
        existingOptions={property.options}
        name={name}
      />,
    );

  return (
    <>
      <h4>{property.label || property.name}</h4>
      {isEmpty(availableModifications) ? (
        <span className="muted">Dieses Feld kann nicht bearbeitet werden.</span>
      ) : (
        availableModifications
      )}
    </>
  );
}
