import React from "react";

type RssWidgetItem = {
  url: string;
  title: string;
};

export type RssWidgetProps = {
  headline?: string;
  error?: string;
  items: RssWidgetItem[];
};

const RssWidget: React.FC<RssWidgetProps> = ({ headline, error, items }) => {
  return (
    <div className="widget rss-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      {error && (
        <div className="muted">{I18n.t("js.widgets.rss.error_text")}</div>
      )}
      <ul className="list-disc space-y-2">
        {items.map((item, index) => (
          <li key={index}>
            <a
              href={item.url}
              title={item.title}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RssWidget;
