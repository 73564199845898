import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

function AppStage({ logoId, description }) {
  return (
    <div className="wall-view-stage intro flex flex-wrap sm:flex-nowrap gap-4 mb-4">
      {!isEmpty(logoId) ? (
        <img
          className="wall-view-app-logo"
          src={`/api/storage/images/${logoId}/get/200x200`}
        />
      ) : null}
      <div
        className="wall-view-stage-description"
        dangerouslySetInnerHTML={{
          __html: Application.markdown(description),
        }}
      />
      <div className="clearfix" />
    </div>
  );
}

AppStage.propTypes = {
  logoId: PropTypes.string,
  description: PropTypes.string,
};

export default AppStage;
