import React from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import ReduxForm from "components/shared/reduxForm";
import TextField from "../../shared/fields/TextField";
import MarkdownEditorAdapter from "../../shared/markdownEditor/MarkdownEditorAdapter";
import ControlGroup from "../../shared/fields/ControlGroup";
import { Field } from "redux-form";
import { replace } from "lodash";

interface LegalTextFormTypes {
  onSubmit(...args: unknown[]): unknown;

  initialValues?: Record<PropertyKey, unknown>;
  name?: string;
  statement?: string;
  handle?: string;
  isEditing?: boolean;

  updateField?(...args: unknown[]): unknown;
}

function LegalTextForm({
  onSubmit,
  initialValues,
  isEditing,
  updateField,
}: LegalTextFormTypes) {
  return (
    <ReduxForm
      form="editLegalText"
      className={"form-horizontal"}
      initialValues={initialValues}
      onSubmit={onSubmit}
      allowSaveWithoutChange={false}
    >
      <TextField
        label={I18n.t("js.administration.legal_texts.edit.name")}
        name="name"
        onChange={isEditing ? undefined : updateField}
        required
      />
      <TextField
        label={I18n.t("js.administration.legal_texts.edit.statement")}
        name="statement"
        hint={I18n.t("js.administration.legal_texts.edit.statement_hint_md")}
        required
      />
      <TextField
        label={I18n.t("js.administration.legal_texts.edit.handle")}
        name="handle"
        disabled={isEditing}
        hint={I18n.t("js.administration.legal_texts.edit.handle_hint_md")}
        required
      />
      <ControlGroup
        name="text"
        label={I18n.t("js.administration.legal_texts.edit.legal_text")}
        className="text-property"
        required
      >
        <Field component={MarkdownEditorAdapter} name="text" />
      </ControlGroup>
    </ReduxForm>
  );
}

export default connect(null, {
  updateField: (_, data) =>
    change(
      "editLegalText",
      "handle",
      replace(data, /[^a-zA-Z0-9\- ]/g, "")
        .replace(/ +/g, "-")
        .toLowerCase(),
    ),
})(LegalTextForm);
