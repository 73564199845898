import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import { convertRoutes } from "components/tixxt/BackboneFeature";
import NewFilesPage from "./NewFilesPage";
import ApprovalPage from "./approval/ApprovalPage";

const backboneRoutes: RouteObject[] = convertRoutes({
  feature: "Files",
  featureRoutes: {
    "(groups/:group_slug/)files": "showRootFolder",
    "(groups/:group_slug/)files/:file_id": "showFile",
    "(groups/:group_slug/)files/folders/new": "newFolder",
    "(groups/:group_slug/)files/folders/:folder_id": "showFolder",
    "(groups/:group_slug/)files/folders/:folder_id/edit": "editFolder",
    "(groups/:group_slug/)files/activitystream_folders/:stream_slug":
      "showActivitystreamFolder",
    "(groups/:group_slug/)files/:file_id/edit": "editFile",
    "(groups/:group_slug/)files/:file_id/versions/new": "newFileVersion",
    "administration/members/files/:file_id": "showFile",
    "administration/consumers/files/:file_id": "showFile",
    "administration/industrials/files/:file_id": "showFile",
    "activities/:activity_id/files/:file_id": "showFile",
    "(groups/:group_slug/)activitystreams/:slug/files/:file_id": "showFile",
    "(groups/:group_slug/)apps/:app_slug/items/exports/files/:file_id":
      "showFile",
    "(groups/:group_slug/)apps/:app_slug/items/files/:file_id": "showFile",
    "(groups/:group_slug/)apps/:app_slug/items/:item_id/files/:file_id":
      "showFile",
    "appointments/:appointment_id/files/:file_id": "showFile",
    "messages/:message_id/files/:file_id": "showFile",
  },
});

const reactRoutes: RouteObject[] = [
  { path: "/files/new", element: <NewFilesPage /> },
  { path: "/files/approval", element: <ApprovalPage /> },
  { path: "/groups/:groupSlug/files/new", element: <NewFilesPage /> },
  { path: "/groups/:groupSlug/files/approval", element: <ApprovalPage /> },
];

export default reactRoutes.concat(backboneRoutes);
