import { filter, isEmpty, map, kebabCase, now } from "lodash";

// Removes options with empty label
// Adds unique value derived from label if none is present
export default function finalizeSelectionOptions(options) {
  const mil = now();
  let seq = 0;
  // Is required for https://gitlab.sys.mixxt.net/tixxt/foundation/-/issues/689 so that values the cannot be assigned twice
  function uniqueValue(label) {
    return `${kebabCase(label)}-${mil}-${seq++}`;
  }
  return map(
    filter(options, ({ label }) => !isEmpty(label)),
    ({ label, value }) => ({
      label,
      value: isEmpty(value) ? uniqueValue(label) : value,
    }),
  );
}
