import React, { ReactNode } from "react";
import PageTitle from "components/layout/PageTitle";
import { Calendar } from "../../../@types/appointments";
import { isShared } from "components/appointments/calendars/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  useDeleteCalendar,
  useDeleteShare,
} from "components/appointments/calendars/api";
import CalendarListView from "components/appointments/calendars/CalendarListView";
import { useCalendars } from "components/appointments/api";

export default function ManageCalendarsPage() {
  const { data: calendars, isLoading, refetch } = useCalendars();

  const { mutate: deleteCalendar } = useDeleteCalendar({
    onSuccess: () => {
      toastr.success(I18n.t("js.delete_successful"));
      refetch();
    },
  });

  const { mutate: deleteShare } = useDeleteShare({
    onSuccess: () => {
      toastr.success(I18n.t("js.delete_successful"));
      refetch();
    },
  });

  function actions(calendar: Calendar | undefined) {
    const buttons: ReactNode[] = [];

    if (isShared(calendar)) {
      buttons.push(
        <button
          key="delete_share"
          className="btn btn-light btn-sm"
          onClick={() =>
            window.bridge?.confirm(
              I18n.t("delete_confirmation"),
              () => calendar && deleteShare(calendar?.id),
            )
          }
        >
          <FontAwesomeIcon icon={regular("ban")} />
        </button>,
      );
    }

    if (calendar?.can.update) {
      buttons.push(
        <a
          key="edit"
          className="btn btn-light btn-sm"
          href={`/calendars/${calendar.id}/edit`}
        >
          <FontAwesomeIcon icon={regular("pencil")} />
        </a>,
      );
    }

    if (calendar?.can.destroy) {
      buttons.push(
        <button
          key="delete"
          className="btn btn-light btn-sm text-danger"
          onClick={() =>
            window.bridge?.confirm(
              I18n.t("calendars.calendar_manager.destroy_confirmation"),
              () => deleteCalendar(calendar.id),
            )
          }
        >
          <FontAwesomeIcon icon={regular("trash")} />
        </button>,
      );
    }

    return buttons;
  }

  return (
    <>
      <PageTitle title={I18n.t("calendars.manage_calendars")} />
      <CalendarListView
        renderActions={actions}
        calendars={calendars}
        isLoading={isLoading}
        addCalendarsAllowed
      />
    </>
  );
}
