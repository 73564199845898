import React from "react";
import { map, isEmpty } from "lodash";
import moment from "moment-timezone";

import pickImage from "../../../helpers/pickImage";

interface AppointmentTypes {
  html_url: string;
  name: string;
  calendars: Array<{
    id: string;
    color: string;
    name: string;
  }>;
  date: {
    start: string;
    time_zone: string;
  };
  image: {
    sources: Array<{
      width: number;
      height: number;
      url: string;
    }>;
    original_filename: string;
  };
  description?: string;
  location?: {
    name?: string;
  };
}

function Appointment(props: AppointmentTypes) {
  return (
    <div className="activity-object-appointment flex flex-col gap-2">
      <div className="bg-primary/10 border-primary/25 border mb-2 p-2 rounded text-sm">
        <p>
          <a href={props.html_url}>
            <strong>{props.name}</strong>
          </a>
        </p>
        <ol className="calendar-list">
          {map(props.calendars, (calendar) => (
            <li key={calendar.id} className="flex items-center mr-2">
              <div
                className="block h-2 rounded-full w-2 mr-1"
                style={{ backgroundColor: `#${calendar.color}` }}
              />
              <p>{calendar.name}</p>
            </li>
          ))}
        </ol>
        <p>
          <time>{moment(props.date.start).format("LLL")}</time>
          <span className="timezone">
            {`(${moment
              .tz(props.date.start, props.date.time_zone)
              .format("zZ")})`}
          </span>
        </p>
      </div>
      {!isEmpty(props.image) ? (
        <div className="image">
          <img
            src={pickImage(props.image.sources, [100, 100])}
            alt={props.image.original_filename}
          />
        </div>
      ) : null}
      <p />
      <p>{props.description ? props.description : null}</p>
      <p />
      <div className="accordion location">
        {props.location?.name ? (
          <div className="accordion-group">
            <div className="accordion-heading">
              <strong>{props.location.name}</strong>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Appointment;
