import { useQuery, UseQueryResult } from "react-query";
import { stringify } from "query-string";

type WidgetbarMembership = {
  id: string;
  name: string;
  path: string;
  images: { "40x40@2": string };
  is_external: boolean;
  profile: any;
  birthday?: string;
  birthday_is_today?: boolean;
  age?: number;
};

type WidgetbarAppointment = {
  id: string;
  name: string;
  start: string;
  end?: string;
  all_day?: boolean;
  path: string;
  meeting?: {
    join_url?: string;
  };
  location?: string;
};

export type AppointmentsWidgetResponse = {
  id: string;
  type: "appointments";
  headline: string;
  appointments: WidgetbarAppointment[];
};

export type OnlineMembersWidgetResponse = {
  id: string;
  type: "online_members";
  headline: string;
  online_count: number;
  memberships: WidgetbarMembership[];
};

export type AdminMembersWidgetResponse = {
  id: string;
  type: "admin_members";
  headline: string;
  memberships: WidgetbarMembership[];
};

export type RssWidgetResponse = {
  id: string;
  type: "rss";
  headline: string;
  items: { title: string; url: string }[];
  error?: string;
};

export type SpeedDialWidgetResponse = {
  id: string;
  type: "speed_dial";
  headline: string;
  memberships: WidgetbarMembership[];
};

export type GroupInfoWidgetResponse = {
  id: string;
  type: "group_info";
  headline?: string;
  content: string;
};

export type NewestMembersWidgetResponse = {
  id: string;
  type: "newest_members";
  headline: string;
  memberships: WidgetbarMembership[];
};

export type TextWidgetResponse = {
  id: string;
  type: "text";
  headline?: string;
  content: string;
  content_plain: string;
};

export type BirthdayWidgetResponse = {
  id: string;
  type: "birthday";
  headline: string;
  show_age: boolean;
  your_birthday: boolean;
  memberships: WidgetbarMembership[];
};

export type WidgetResponse =
  | AppointmentsWidgetResponse
  | OnlineMembersWidgetResponse
  | AdminMembersWidgetResponse
  | RssWidgetResponse
  | SpeedDialWidgetResponse
  | GroupInfoWidgetResponse
  | NewestMembersWidgetResponse
  | TextWidgetResponse
  | BirthdayWidgetResponse;

type WidgetbarResponse = {
  slug: string;
  widgets: WidgetResponse[];
  empty?: boolean;
};

export function useActivitystreamWidgetbar({
  group_id,
  slug,
}: {
  group_id?: string | null;
  slug: string;
}): UseQueryResult<WidgetbarResponse, Error> {
  const path = group_id
    ? `/widgetbars/g-:group_id-as-:slug?${stringify({ group_id, slug })}`
    : `/widgetbars/as-:slug?${stringify({ slug })}`;

  return useQuery<WidgetbarResponse, Error>(path);
}
