import React from "react";
import PageTitle from "components/layout/PageTitle";
import { get, isEmpty, map } from "lodash";
import moment from "moment-timezone";
import { useDeleteLegalText, useLegalTexts } from "./api";
import InfoText from "components/shared/InfoText";

interface LegalTextRowTypes {
  legalText: {
    id?: string;
    name?: string;
    handle?: string;
    update_at?: string;
  };

  onDeleteLegalText(...args: unknown[]): unknown;
}

function LegalTextRow({ legalText, onDeleteLegalText }: LegalTextRowTypes) {
  return (
    <tr>
      <td>{get(legalText, "name")}</td>
      <td>
        <a
          href={`/legal/${get(legalText, "handle")}`}
          target="_blank"
          rel="noreferrer"
        >
          /{get(legalText, "handle")}
        </a>
      </td>
      <td>
        {moment(get(legalText, "updated_at")).format(
          I18n.t("plugins.Locale.Formats.valid_datetime"),
        )}
      </td>
      <td className="with-context-menu">
        <div className="context-menu">
          <span className="dropdown pull-right">
            <a
              className="dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
              style={{ fontSize: "1.3em" }}
            >
              <i className="fa fa-ellipsis-h" />
            </a>
            <ul className="dropdown-menu context-menu left">
              {get(legalText, "read_only") ? null : (
                <li>
                  <a
                    href={`/administration/legal_texts/${get(
                      legalText,
                      "id",
                    )}/edit`}
                  >
                    <i className="fa fa-pencil mr-1" />
                    {I18n.t("js.administration.legal_texts.edit.button")}
                  </a>
                </li>
              )}
              <li>
                <a href={`/administration/legal_texts/${get(legalText, "id")}`}>
                  <i className="fa fa-code-fork mr-1" />
                  {I18n.t("js.administration.legal_texts.versions")}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();

                    if (confirm(I18n.t("js.delete_confirmation"))) {
                      onDeleteLegalText(get(legalText, "id"));
                    }
                  }}
                >
                  <i className="fa-regular fa-trash mr-1" />
                  {I18n.t("js.administration.legal_texts.delete.button")}
                </a>
              </li>
            </ul>
          </span>
        </div>
      </td>
    </tr>
  );
}

function LegalTextIndex() {
  const {
    isLoading: loading,
    data: legalTexts,
    refetch: refetchLegalTexts,
  } = useLegalTexts();

  const { mutate: deleteLegalText } = useDeleteLegalText({
    onSuccess: () => {
      toastr.success(I18n.t("js.delete_successful"));
      refetchLegalTexts();
    },
  });

  return (
    <>
      <PageTitle title={I18n.t("js.administration.legal_text.title")} />
      <div className="btn-toolbar justify-end mb-10">
        <a className="btn btn-primary" href="/administration/legal_texts/new">
          {I18n.t("js.administration.legal_texts.new.title")}
        </a>
      </div>
      {loading ? (
        <div>
          <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
        </div>
      ) : (
        <>
          <InfoText translation="js.administration.legal_texts.info_md" />
          <table className="tixxt-table table-hover">
            <thead>
              <tr>
                <th>{I18n.t("js.administration.legal_texts.name")}</th>
                <th>{I18n.t("js.administration.legal_texts.link")}</th>
                <th>{I18n.t("js.administration.legal_texts.last_updated")}</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {isEmpty(legalTexts) ? (
                <tr>
                  <td colSpan={4} align="center">
                    {I18n.t("js.administration.legal_texts.no_entries")}
                  </td>
                </tr>
              ) : (
                map(legalTexts, (legalText, index) => (
                  <LegalTextRow
                    key={index}
                    legalText={legalText}
                    onDeleteLegalText={deleteLegalText}
                  />
                ))
              )}
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

export default LegalTextIndex;
