import { useMutation, useQuery } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { SimpleMembership } from "../../@types";

export type TrimmedMessage = {
  id: string;
  created_at: string;
  author: SimpleMembership;
  to: [SimpleMembership] | SimpleMembership[];
  to_count: number;
  subject: string;
  plain_content: string;
  html_content: string;
  has_replies: boolean;
  has_attachments: boolean;
  i: {
    marked: boolean;
    read: boolean;
  };
  conversation_length: number;
};

type MessagesPayload = {
  messages: TrimmedMessage[];
  offset: number;
  limit: number;
  total: number;
  next_url: string | null;
};

export function useFetchMessages(
  filter: string,
  page: number,
  options = { keepPreviousData: true },
) {
  const query = `?limit=10&offset=${(page - 1) * 10}`;
  return useQuery<MessagesPayload>(
    ["messages", filter, page],
    () => fetchApi(`/api/v1/messages${filter ? `/${filter}${query}` : query}`),
    options,
  );
}

export function useFetchMessage(messageId: string, options = {}) {
  return useQuery(
    ["message", messageId],
    () => fetchApi(`/api/v1/messages/${messageId}`),
    options,
  );
}

export function useDeleteMessage(filter, messageId, options = {}) {
  if (filter === "sent") {
    return useMutation(
      () =>
        fetchApi(`/api/v1/messages/sent/${messageId}`, {
          method: "DELETE",
        }),
      options,
    );
  }
  return useMutation(
    () =>
      fetchApi(`/api/v1/messages/${messageId}`, {
        method: "DELETE",
      }),
    options,
  );
}

export function useDeleteMessages(filter, options = {}) {
  if (filter === "sent") {
    return useMutation(
      (messageIds: string[]) =>
        fetchApi(`/api/v1/messages/sent/delete`, {
          method: "DELETE",
          body: {
            message_ids: messageIds,
          },
        }),
      options,
    );
  }
  return useMutation(
    (messageIds: string[]) =>
      fetchApi(`/api/v1/messages/delete`, {
        method: "DELETE",
        body: {
          message_ids: messageIds,
        },
      }),
    options,
  );
}

export function useMarkMessageAsUnread(options = {}) {
  return useMutation(
    (messageId: string) =>
      fetchApi(`/api/v1/messages/${messageId}/read`, {
        method: "DELETE",
      }),
    options,
  );
}

export function useMarkMessageAsRead(options = {}) {
  return useMutation(
    (messageId: string) =>
      fetchApi(`/api/v1/messages/${messageId}/read`, {
        method: "POST",
      }),
    options,
  );
}

export function useMarkMessagesAsUnread(options = {}) {
  return useMutation(
    (messageIds: string[]) =>
      fetchApi(`/api/v1/messages/read`, {
        method: "DELETE",
        body: {
          message_ids: messageIds,
        },
      }),
    options,
  );
}

export function useMarkMessagesAsRead(options = {}) {
  return useMutation(
    (messageIds: string[]) =>
      fetchApi(`/api/v1/messages/read`, {
        method: "POST",
        body: {
          message_ids: messageIds,
        },
      }),
    options,
  );
}

export function useSendMessage(options = {}) {
  return useMutation(
    (body: Record<PropertyKey, unknown>) =>
      fetchApi(`/api/v1/messages`, {
        method: "POST",
        body,
      }),
    options,
  );
}

export function onError() {
  toastr.error(I18n.t("js.generic_network_error"));
}
