import React from "react";
import { map, isString, has, includes } from "lodash";
import pickImage from "helpers/pickImage";
import {
  profileFieldAvailable,
  ExternalMemberBadge,
} from "components/memberships/MemberNameWithAdditions";

function MembershipHit({
  display_name,
  images,
  html_url,
  profile,
  is_network_admin,
  detection_profile_field,
  is_external,
}) {
  return (
    <div className={`media items-center border-box px-3 py-2`}>
      <a
        className="member-link media-object h-10 w-10 self-start"
        href={html_url}
        title={display_name}
      >
        <img
          className={"h-10 w-10"}
          src={pickImage(images, [80, 80])}
          alt={display_name}
          aria-hidden
        />
      </a>
      <div className={"media-body"}>
        <a className={"font-medium"} href={html_url}>
          {display_name}
        </a>
        <ul className="text-sm">
          {profileFieldAvailable({
            detectionProfileField: detection_profile_field,
          }) && !includes(profile, detection_profile_field) ? (
            <li>{detection_profile_field}</li>
          ) : null}
          {map(profile, (value, key) => (
            <li key={key} className={`profile-field-${key}`}>
              {has(value, "displayValue")
                ? value.displayValue
                : isString(value)
                  ? value
                  : null}
            </li>
          ))}
        </ul>
      </div>
      <div className={"badges"}>
        {is_external ? <ExternalMemberBadge /> : null}
        {is_network_admin ? (
          <span
            className="badge bg-primary/50"
            data-bs-toggle="tooltip"
            title={I18n.t("js.directory.member_row.administrator")}
          >
            {I18n.t("js.directory.member_row.administrator_short")}
          </span>
        ) : null}
      </div>
    </div>
  );
}

MembershipHit.propTypes = {};

export default MembershipHit;
