import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { QueryClientProvider, QueryClient } from "react-query";

import FactorsPage from "./FactorsPage";
import AddTotpPage from "./AddTotpPage";
import DeleteFactorPage from "./DeleteFactorPage";
import ResetBackupCodes from "./ResetBackupCodesPage";
import RouteContext from "./RouteContext";
import AddEmailPage from "./AddEmailPage";

function FactorsWrapper({ context }) {
  const [route, setRoute] = useState({ name: "index" });

  const value = { ...route, setRoute };
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <RouteContext.Provider value={value}>
      <QueryClientProvider client={queryClient}>
        <FactorsContainer context={context} />
      </QueryClientProvider>
    </RouteContext.Provider>
  );
}

FactorsWrapper.propTypes = {
  context: PropTypes.oneOf([undefined, "sign_in", ""]),
};

function FactorsContainer({ context }) {
  const routeContext = useContext(RouteContext);

  if (routeContext.name === "index") return <FactorsPage />;
  if (routeContext.name === "add_totp")
    return <AddTotpPage context={context} />;
  if (routeContext.name === "add_email")
    return <AddEmailPage context={context} />;

  if (routeContext.name === "delete_totp")
    return <DeleteFactorPage id={routeContext.id} />;

  if (routeContext.name === "resetBackupCodes")
    return <ResetBackupCodes id={routeContext.id} />;

  return null;
}

FactorsContainer.propTypes = {
  context: PropTypes.oneOf([undefined, "sign_in", ""]),
};

export default FactorsWrapper;
