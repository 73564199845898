import React, { useEffect, useState } from "react";
import { map, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { usePages } from "../../../../hooks/administration/appNavigation";

const OPTIONS = {
  link: {
    label: "js.administration.navigation_items.link.option_label",
    type: "link",
    icon: "link",
  },
  activitystream: {
    label: "js.administration.app_navigation_items.edit.activity_stream",
    type: "activitystream",
    icon: "globe",
  },
  page: {
    label: "js.administration.app_navigation_items.edit.page",
    type: "page",
    icon: "file-o",
  },
  default: {
    label: "js.administration.app_navigation_items.edit.default_module",
    type: "default",
    icon: "globe",
  },
};

type Props = {
  isDefault: boolean;
  type: string;
  changeType: (props: {
    id: string;
    type: string;
    icon: string;
    isDefault: boolean;
    changedToDefault: boolean;
  }) => void;
};

function TypeSelector({ isDefault, type, changeType }: Props) {
  const [selectedOption, selectOption] = useState(isDefault ? "default" : type);
  const { data: pages } = usePages();

  useEffect(() => {
    selectOption(isDefault ? "default" : type);
  }, [type]);

  return (
    <>
      <label>
        {I18n.t(
          "js.administration.app_navigation_items.edit.target_link_label",
        )}
      </label>
      <select
        className="input-text"
        name="type"
        onChange={(e) => {
          const newType = e.target.value;
          const newItem = {
            id: uuidv4(),
            type: newType,
            icon: OPTIONS[newType].icon,
            isDefault: false,
          };

          selectOption(newType);
          changeType({
            ...newItem,
            changedToDefault: type !== "default" && newType === "default",
          });
        }}
        value={OPTIONS[selectedOption]?.type}
      >
        {map(OPTIONS, (option) => {
          if (option.type === "page" && isEmpty(pages)) return null;
          return (
            <option value={option.type} key={option.type}>
              {I18n.t(option.label)}
            </option>
          );
        })}
      </select>
    </>
  );
}

export default TypeSelector;
