import React from "react";
import PropTypes from "prop-types";
import { TagUrlContext } from "../appCreator/properties/show/Tag";
import { SelectionUrlContext } from "../appCreator/properties/show/Selection";
import { searchUrl } from "../globalSearch/urls";

const tagUrl =
  (contextUrl) =>
  ({ name }) =>
    searchUrl({ contextUrl, module: "files", filters: { tag: name } });

const selectionUrl =
  (contextUrl) =>
  ({ value, property }) =>
    searchUrl({
      contextUrl,
      module: "files",
      filters: {
        [`profile.${property.name}`]: value,
      },
    });

function UrlContextProvider({ contextUrl, children }) {
  return (
    <TagUrlContext.Provider value={tagUrl(contextUrl)}>
      <SelectionUrlContext.Provider value={selectionUrl(contextUrl)}>
        {children}
      </SelectionUrlContext.Provider>
    </TagUrlContext.Provider>
  );
}
UrlContextProvider.propTypes = {
  contextUrl: PropTypes.string,
  children: PropTypes.node,
};

export default UrlContextProvider;
