import React from "react";
import { throttle, debounce } from "lodash";
import classNames from "classnames";

const THROTTLE = 250;

/**
 * Component which handles scrolling stuff
 * div that scrolls when a new child (i.e. a chat message) is added
 *
 * [bool] autoscroll - enables autoscrolling if the children will change
 * [integer] autoscrollThreashold - the percentage border at the bottom to break the autoscroll
 * [integer] callbackThreshold - the percentage border when onStartReached and onEndReached triggers
 * [function] onStartReached - callback when scroll to the top
 * [function] onEndReached - callback when scroll to the bottom
 */
class ScrollContainer extends React.Component {
  constructor(props) {
    super(props);

    this.container = React.createRef();
    this.bumper = React.createRef();

    this.state = {
      autoscrollBreaked: false,
    };

    this.lastScrollPoint = 0;
    this.lastScrollPosition = -1;
  }

  componentDidMount() {
    this.container.current.addEventListener("wheel", this.handleWheel);
    this.container.current.addEventListener("scroll", this.handleScrolling);
    window.addEventListener("resize", this.handleResize);

    this.scroll({ smooth: false });
  }

  componentWillUnmount() {
    this.container.current.removeEventListener("wheel", this.handleWheel);
    this.container.current.removeEventListener("scroll", this.handleScrolling);
    window.removeEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      this.setState({ autoscrollBreaked: false });
      this.scroll({ smooth: false });
    } else if (
      React.Children.count(this.props.children) !==
      React.Children.count(prevProps.children)
    ) {
      this.scroll({ smooth: true });
    }
  }

  // Cancel wheel event and prevent parent scroll
  cancelScrollEvent = (e) => {
    e.stopImmediatePropagation();
    e.preventDefault();
    e.returnValue = false;
  };

  // Handle wheel event and calculate scroll position
  // cancel the wheel event when scroll is at top or bottom
  // this will prevent the parent container from scrolling
  //
  // calls trackScrolling() after cancel. This is required,
  // because scroll event will never triggered if the wheel event is canceled
  handleWheel = (e) => {
    const div = this.container.current;
    const scrollTop = div.scrollTop;
    const scrollHeight = div.scrollHeight;
    const height = div.clientHeight;
    const wheelDelta = e.deltaY;
    const isDeltaPositive = wheelDelta > 0;

    if (isDeltaPositive && wheelDelta > scrollHeight - height - scrollTop) {
      this.cancelScrollEvent(e);
      this.trackScrolling();
      return false;
    } else if (!isDeltaPositive && -wheelDelta > scrollTop) {
      this.cancelScrollEvent(e);
      this.trackScrolling();
      return false;
    }
  };

  // Calculate and check threasholds and maybe call callbacks
  trackScrolling = () => {
    const div = this.container.current;

    if (div.scrollTop !== this.lastScrollPosition) {
      const direction = div.scrollTop > this.lastScrollPosition ? 1 : -1;
      const callbackThreshold =
        (div.scrollHeight / 100) * this.props.callbackThreshold;
      const autoscrollThreashold =
        (div.scrollHeight / 100) * this.props.autoscrollThreashold;

      //console.log("handle scroll", "height:", div.clientHeight, "scrollHeight:", div.scrollHeight, "scrollTop:", div.scrollTop, "direction:", direction, "callbackThreshold:", callbackThreshold, "autoscrollThreashold:", autoscrollThreashold);

      // Handle autoscrollThreashold
      if (
        div.scrollTop <
        div.scrollHeight - div.clientHeight - autoscrollThreashold
      ) {
        this.setState({ autoscrollBreaked: true });
      } else {
        this.setState({ autoscrollBreaked: false });
      }

      // Handle callbackThreshold
      if (direction < 0 && div.scrollTop <= callbackThreshold) {
        // scrolling up and be in the threshold at the top
        this.callStartReached();
      } else if (
        direction > 0 &&
        div.scrollTop >= div.scrollHeight - div.clientHeight - callbackThreshold
      ) {
        // scrolling down and be in the threshold at the bottom
        this.callEndReached();
      }
    }

    // Check bumper
    if (this.bumper.current.clientHeight > 10) {
      //console.log("BUMPER bumped");
      this.callStartReached();
    }

    // cache scrollPosition and lastScrollHeight
    this.lastScrollPosition = div.scrollTop;
    this.lastScrollPoint = div.scrollHeight - div.scrollTop;
  };

  // Ensure a debounced call on the onStartReached callback
  callStartReached = debounce(
    () => {
      this.props.onStartReached && this.props.onStartReached();
    },
    THROTTLE,
    { leading: true, trailing: true },
  );

  // Ensure a debounced call on the onEndReached callback
  callEndReached = debounce(
    () => {
      this.props.onEndReached && this.props.onEndReached();
    },
    THROTTLE,
    { leading: true, trailing: true },
  );

  // Perform scrolling or adjust scroll
  scroll = ({ smooth }) => {
    const div = this.container.current;
    if (!div) {
      return;
    }

    if (this.props.autoscroll && !this.state.autoscrollBreaked) {
      //console.log("scroll to bottom");
      this.performScroll({ smooth });
      this.callEndReached();
    } else {
      //console.log("adjust scroll");
      // prevent jumping, by preserving the scroll position
      if (div.scrollHeight !== this.lastScrollHeight) {
        div.scrollTop = div.scrollHeight - this.lastScrollPoint;
      }
    }

    // track the new scroll position
    this.trackScrolling();
  };

  performScroll = ({ smooth }) => {
    const div = this.container.current;
    if (!div) {
      return;
    }

    if (smooth && div.scrollTop) {
      div.style.scrollBehavior = "smooth";
    } else {
      div.style.scrollBehavior = "auto";
    }

    div.scrollTop = div.scrollHeight - div.offsetHeight;
    window.DIV = div;

    setTimeout(() => (div.style.scrollBehavior = "auto"), 250);
  };

  onClickScrollToBottom = () => {
    this.performScroll({ smooth: true });
    this.trackScrolling();
  };

  // throttled calls for use in events
  handleScrolling = throttle(this.trackScrolling, THROTTLE);
  handleResize = throttle(this.trackScrolling, THROTTLE);

  render() {
    return (
      <div
        ref={this.container}
        className={classNames(
          "overflow-x-hidden overflow-y-auto",
          this.props.className,
        )}
      >
        {this.props.startElement}
        <div key="bumperTop" className="bumperTop" ref={this.bumper} />
        {this.props.children}
        <div key="bumperBottom" className="bumperBottom" />
        {this.props.endElement}
        {this.state.autoscrollBreaked ? (
          <div className="scrollToBottom">
            <i
              className="fa fa-chevron-circle-down"
              onClick={this.onClickScrollToBottom}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ScrollContainer;
