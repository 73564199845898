import type { ImageAttachmentsTypes } from "./attachments/ImageAttachments";
import type { LinkAttachmentTypes } from "./attachments/LinkAttachment";
import type { TagAttachmentsTypes } from "./attachments/TagAttachments";
import type { MentionAttachmentsTypes } from "./attachments/MentionAttachments";

import React from "react";
import { isEmpty, groupBy } from "lodash";

import ImageAttachments from "./attachments/ImageAttachments";
import FileAttachments from "./attachments/FileAttachments";
import LinkAttachment from "./attachments/LinkAttachment";
import TagAttachments from "./attachments/TagAttachments";
import MentionAttachments from "./attachments/MentionAttachments";

interface ActivityAttachmentsTypes {
  attachments: Array<unknown>;
  activityUrl: string;
}

function ActivityAttachments({
  attachments,
  activityUrl,
}: ActivityAttachmentsTypes) {
  const { image, file, link, tag, person } = groupBy(
    attachments,
    "type",
  ) as unknown as {
    image: ImageAttachmentsTypes["images"];
    file: Array<unknown>;
    link: Array<LinkAttachmentTypes>;
    tag: TagAttachmentsTypes["tags"];
    person: MentionAttachmentsTypes["mentions"];
  };
  return (
    <div>
      {!isEmpty(image) ? (
        <ImageAttachments images={image} activityUrl={activityUrl} />
      ) : null}
      {!isEmpty(person) ? <MentionAttachments mentions={person} /> : null}
      {!isEmpty(tag) ? <TagAttachments tags={tag} /> : null}
      {!isEmpty(link) ? <LinkAttachment {...link[0]} /> : null}
      {!isEmpty(file) ? <FileAttachments filesCount={file.length} /> : null}
    </div>
  );
}

export default ActivityAttachments;
