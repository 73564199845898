import { useEffect, useState } from "react";

export function useMaxHeight() {
  const [style, setStyle] = useState({});

  useEffect(() => {
    function updateStyle() {
      setStyle({ maxHeight: `${window.innerHeight}px` });
    }

    updateStyle();
    window.addEventListener("resize", updateStyle);
    return () => {
      window.removeEventListener("resize", updateStyle);
    };
  }, []);

  return style;
}
