import { last } from "lodash";
import { IS_API_REQUEST } from "../../actions/chat";

const apiRequests = (state = {}, action) => {
  if (action[IS_API_REQUEST]) {
    return {
      ...state,
      [action.type]: "pending",
    };
  } else if (action.parentAction && action.parentAction[IS_API_REQUEST]) {
    return {
      ...state,
      [action.parentAction.type]: last(action.type.split("/")).toLowerCase(),
    };
  }
  return state;
};

export default apiRequests;
