import React from "react";
import PropTypes from "prop-types";
import {
  compose,
  setDisplayName,
  withState,
  withProps,
  getContext,
  withHandlers,
} from "recompose";
import classnames from "classnames";
import { values, filter, flatten } from "lodash";
import LayoutSelect from "./LayoutSelect";
import ColumnConfigurator from "./ColumnConfigurator";

const updateTabAt = (tabs, index, data) => [
  ...tabs.slice(0, index),
  { ...tabs[index], ...data },
  ...tabs.slice(index + 1),
];
const TabConfigurator = compose(
  getContext({
    enableColumns: PropTypes.bool,
    exclusivePropertyUsage: PropTypes.bool,
  }),
  withState("selectedTabIndex", "selectTabIndex", 0),
  withProps(
    ({ tabs, selectedTabIndex, properties, exclusivePropertyUsage }) => {
      let sourceProperties = properties;

      if (exclusivePropertyUsage) {
        const usedProperties = {};
        tabs.forEach((tab) => {
          flatten(values(tab.columns)).forEach((columnProp) => {
            usedProperties[columnProp.name] = true;
          });
        });

        sourceProperties = filter(
          properties,
          (prop) => !usedProperties[prop.name],
        );
      }

      return {
        selectedTab: tabs[selectedTabIndex],
        sourceProperties,
      };
    },
  ),
  withHandlers({
    addTab:
      ({ tabs, onTabsChange, selectTabIndex }) =>
      (e) => {
        e.preventDefault();
        onTabsChange([
          ...tabs,
          {
            name: I18n.t(
              "js.administration.app_creator.app.item_layout.tabs.new_tab",
            ),
            layout: "a",
            columns: { a: [], b: [], c: [], d: [] },
          },
        ]);
        selectTabIndex(tabs.length);
      },
    updateSelectedTabName:
      ({ tabs, selectedTabIndex, onTabsChange }) =>
      (e) => {
        onTabsChange(
          updateTabAt(tabs, selectedTabIndex, { name: e.target.value }),
        );
      },
    updateSelectedTabColumns:
      ({ tabs, selectedTabIndex, onTabsChange }) =>
      (columns) => {
        onTabsChange(updateTabAt(tabs, selectedTabIndex, { columns }));
      },
    updateSelectedTabLayout:
      ({ tabs, selectedTabIndex, onTabsChange }) =>
      (layout) => {
        onTabsChange(updateTabAt(tabs, selectedTabIndex, { layout }));
      },
    removeSelectedTab:
      ({ tabs, selectedTabIndex, onTabsChange }) =>
      (e) => {
        e.preventDefault();
        onTabsChange([
          ...tabs.slice(0, selectedTabIndex),
          ...tabs.slice(selectedTabIndex + 1),
        ]);
      },
  }),
  setDisplayName("TabConfigurator"),
)(
  ({
    tabs,
    selectedTab,
    selectTabIndex,
    addTab,
    updateSelectedTabName,
    removeSelectedTab,
    properties,
    sourceProperties,
    updateSelectedTabColumns,
    updateSelectedTabLayout,
    enableColumns,
  }) => (
    <div>
      <div className="border-box p-3 mt-4">
        <ul className="nav nav-tabs mb-4">
          {tabs.map((tab, i) => (
            <li key={i} className={classnames({ active: selectedTab === tab })}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  selectTabIndex(i);
                }}
              >
                {tab.name}
              </a>
            </li>
          ))}
          <li>
            <a href="#" onClick={addTab}>
              + Tab
            </a>
          </li>
        </ul>

        {selectedTab ? (
          <div className="flex flex-col gap-4 divide-y divide-neutral">
            <div className="form-horizontal">
              <div className="control-group required">
                <label className="control-label">
                  {I18n.t(
                    "js.administration.app_creator.app.item_layout.tabs.tab_name",
                  )}
                  <abbr title={I18n.t("js.required")}>*</abbr>
                </label>
                <div className="controls">
                  <input
                    type="text"
                    value={selectedTab.name}
                    onChange={updateSelectedTabName}
                  />
                  {tabs.length > 1 ? (
                    <span>
                      &nbsp;
                      <a href="#" onClick={removeSelectedTab}>
                        Tab entfernen
                      </a>
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            {enableColumns ? (
              <div>
                <LayoutSelect
                  layout={selectedTab.layout}
                  onLayoutChange={updateSelectedTabLayout}
                />
              </div>
            ) : null}

            <ColumnConfigurator
              properties={properties}
              sourceProperties={sourceProperties}
              columns={selectedTab.columns}
              layout={selectedTab.layout}
              onColumnsUpdate={updateSelectedTabColumns}
            />
          </div>
        ) : null}
      </div>
    </div>
  ),
);

const tabShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  layout: PropTypes.string.isRequired,
});

TabConfigurator.propTypes = {
  tabs: PropTypes.arrayOf(tabShape).isRequired,
  onTabsChange: PropTypes.func.isRequired,
  properties: PropTypes.array.isRequired,
};

export default TabConfigurator;
