import React from "react";
import { useMutation } from "react-query";
import { get } from "lodash";
import PropTypes from "prop-types";

import { resetBackupCodes } from "./api";
import { BackupCodes } from "./AddTotpPage";
import { AbortButton } from "./FactorActions";

function ResetBackupCodes({ id }) {
  const {
    mutate: submitResetBackupCodes,
    data: resetBackupCodesResult,
    error: resetError,
    isLoading,
  } = useMutation(resetBackupCodes);

  const backupCodes = get(resetBackupCodesResult, "backup_codes");

  return (
    <div className="span10">
      {isLoading ? (
        <span className="loadingMessage">
          <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
        </span>
      ) : backupCodes ? (
        <BackupCodes backupCodes={backupCodes} />
      ) : (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: I18n.t(
                "js.authentication.2fa.reset_backup_codes_description_html",
              ),
            }}
          />

          <form
            onSubmit={(e) => {
              e.preventDefault();

              submitResetBackupCodes({
                id,
                validationCode: e.target.code.value,
              });
            }}
          >
            <div className="control-group">
              <label className="control-label"></label>
              <div className="controls">
                <input
                  type="text"
                  name="code"
                  autoComplete="off"
                  placeholder={I18n.t(
                    "js.authentication.2fa.input_placeholder",
                  )}
                  required
                  autoFocus
                />
              </div>
            </div>

            <div className="btn-toolbar">
              <div className="btn-group">
                <button className="btn btn-primary" type="submit">
                  {I18n.t("js.authentication.2fa.reset_backup_codes")}
                </button>
              </div>
              <div className="btn-group">
                <AbortButton />
              </div>
            </div>
          </form>
          {resetError ? (
            <div className="alert alert-danger">{resetError.message}</div>
          ) : null}
        </>
      )}
    </div>
  );
}

ResetBackupCodes.propTypes = {
  id: PropTypes.string,
};

export default ResetBackupCodes;
