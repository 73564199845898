import React from "react";
import PropTypes from "prop-types";

import CheckboxFields from "../../shared/fields/CheckboxFields";

function MiscSettings({ chat }) {
  return (
    <>
      <h3>{I18n.t("js.groups.edit.misc_settings")}</h3>
      {chat ? (
        <CheckboxFields
          key="chat"
          name="chat"
          label={I18n.t(`js.groups.form.chat`)}
          options={[
            { value: "enabled", label: "js.groups.form.chat_enabled" },
            { value: "disabled", label: "js.groups.form.chat_disabled" },
          ]}
        />
      ) : null}
    </>
  );
}

export default MiscSettings;

MiscSettings.propTypes = {
  chat: PropTypes.string,
};
