import delay from "@redux-saga/delay-p";
import {
  all,
  call,
  take,
  fork,
  put,
  actionChannel,
  cancelled,
  throttle,
  takeLatest,
  select,
} from "redux-saga/effects";
import { map, get, filter, reduce, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { createEventSaga } from "./helpers";
import { IS_CHAT_QUERY, IS_NETWORK_QUERY } from "../../actions/chat";
import presenceSaga from "./presence";
import { COMPOSING_TIMEOUT, fetchChatDetails } from "./application";
import { getMyProfile } from "../../selectors/chat/profile";
import { getChatNavigation } from "../../selectors/chat/application";

const join = (channel) =>
  new Promise((resolve, reject) => {
    channel.join().receive("ok", resolve).receive("error", reject);
  });

const push = (channel, ...args) => channel.push(...args);

const pushReply = (channel, ...args) =>
  new Promise((resolve, reject) => {
    push(channel, ...args)
      .receive("ok", resolve)
      .receive("error", reject)
      .receive("timeout", () => reject("timeout"));
  });

// Perform a push on the channel and expect a reply
function* query(channel, event, { payload = null, meta: baseMeta } = {}) {
  const meta = {
    ...baseMeta,
    topic: channel.topic,
    event,
  };
  // Push phase action
  yield put({
    type: `chat/${event.toUpperCase()}/PUSH`,
    payload,
    meta,
  });

  try {
    // Actually perform the push on the socket
    const reply = yield call(pushReply, channel, event, payload);

    // Reply phase action
    yield put({
      type: `chat/${event.toUpperCase()}/REPLY`,
      payload: reply,
      meta,
    });

    // Reply in case caller wants it
    return reply;
  } catch (error) {
    // Reply phase action
    yield put({
      type: `chat/${event.toUpperCase()}/ERROR`,
      meta,
      error,
    });
  }
}

// Saga which handles incoming messages in a (group) channel
// Listens for all RECEIVE_EVENTS and dispatches them as to redux
const RECEIVE_EVENTS = [
  "message",
  "create_chat",
  "mark_as_read",
  "mark_as_received",
  "mute_chat",
  "unmute_chat",
  "composing",
];
const handleIncomingMessages = createEventSaga({
  events: (dispatch, subject) => {
    return reduce(
      RECEIVE_EVENTS,
      (handlers, event) => {
        const type = `chat/${event.toUpperCase()}/RECEIVE`;
        handlers[event] = (payload) =>
          dispatch({ type, payload, meta: { topic: subject.topic, event } });
        return handlers;
      },
      {},
    );
  },
  bind: (channel, handlers) =>
    map(handlers, (fn, name) => channel.on(name, fn)),
  remove: (channel, handlers) =>
    map(handlers, (fn, name) => channel.off(name, fn)),
});

// This basically does the routing from { type, id } to the correct topic
// All of type member to the member channel
// All of type group to the group channel with matching id
const createShouldHandleTo = (channel) => {
  const [channelType, channelId] = channel.topic.split(":");
  const shouldHandleTo =
    channelType == "network"
      ? (action) => action[IS_NETWORK_QUERY]
      : channelType == "member"
        ? ({ meta, type }) =>
            type === "chat/MUTE_CHAT" ||
            type === "chat/APP_MUTE_CHAT" ||
            (meta && meta.chatId && meta.chatId.indexOf("member/") == 0)
        : ({ meta, type }) =>
            type !== "chat/MUTE_CHAT" &&
            type !== "chat/APP_MUTE_CHAT" &&
            meta &&
            meta.chatId &&
            meta.chatId === `group/${channelId}`;
  return shouldHandleTo;
};

// Saga which handles outgoing messages in a (group) channel
function* handleOutgoingMessages(channel) {
  const outgoingMessages = yield actionChannel("chat/SEND_MESSAGE");
  try {
    const shouldHandleTo = createShouldHandleTo(channel);

    while (true) {
      const action = yield take(outgoingMessages);
      if (shouldHandleTo(action)) {
        const from = yield select(getMyProfile);
        const payload = { ...action.payload, chat_id: action.meta.chatId };
        const meta = { ...action.meta, ref: uuidv4(), from };
        yield call(query, channel, "message", { payload, meta });
      }
    }
  } finally {
    if (yield cancelled()) {
      outgoingMessages.close();
      console.log("handleOutgoingMessages cancelled", channel.topic);
    }
  }
}

// Saga wich handles queries like getting recent messages
function* handleChatQueries(channel) {
  const chatQueries = yield actionChannel((action) => action[IS_CHAT_QUERY]);
  try {
    const shouldHandleTo = createShouldHandleTo(channel);
    while (true) {
      const action = yield take(chatQueries);
      if (shouldHandleTo(action)) {
        const event = action.type.split("/")[1].toLowerCase();
        const payload = { ...action.payload };

        if (get(action, ["meta", "chatId"])) {
          payload.chat_id = get(action, ["meta", "chatId"]);
        }

        yield call(query, channel, event, {
          payload,
          meta: action.meta,
        });
      }
    }
  } finally {
    if (yield cancelled()) {
      chatQueries.close();
      console.log("chatQueries cancelled", channel.topic);
    }
  }
}

// Sends composing event to channel
function* pushComposingEvent(channel, { type, text, chatId }) {
  // Don't send "composing" on i.e. chat/SEND_MESSAGE or when composer was cleared
  if (type !== "chat/COMPOSER/SET_TEXT" || isEmpty(text)) {
    return;
  }
  const payload = { chat_id: chatId };
  yield put({ type: "chat/COMPOSING/PUSH", payload });
  yield call(push, channel, "composing", payload);
}

// Marks a single message as received, unless
// - it is from ourselves
// - or not in a member chat
function* markMessageAsReceived(
  channel,
  membershipId,
  { payload: messagePayload },
) {
  if (messagePayload.from.id === membershipId) return;
  if (messagePayload.chat_id.indexOf("member/") !== 0) return;

  const payload = {
    chat_id: messagePayload.chat_id,
    last_received_id: messagePayload.id,
  };
  yield put({ type: "chat/MARK_AS_RECEIVED/PUSH", payload });
  yield call(push, channel, "mark_as_received", payload);
}

// Marks messages as received when loading list of chats
function* markLastChatMessagesAsReceived(channel, membershipId, { payload }) {
  for (let chat of payload.chats) {
    if (
      !isEmpty(chat.last_message) &&
      chat.last_message.id > get(chat, ["state", "last_received_id"])
    ) {
      yield call(markMessageAsReceived, channel, membershipId, {
        payload: chat.last_message,
      });

      // Lets not flood the server too much
      yield delay(200);
    }
  }
}

// Saga which manages other sagas related to a (group) channel
function* channelSaga(channel) {
  try {
    channel.join();

    yield all([
      call(handleIncomingMessages, channel),
      call(handleOutgoingMessages, channel),
      call(handleChatQueries, channel),
    ]);
  } finally {
    if (yield cancelled()) {
      channel.leave();
      console.log("channelSaga cancelled", channel.topic);
    }
  }
}

// Starts member channel where all private messages must be sent to
function* myChannelSaga(socket, networkChannel) {
  const { profile } = yield call(query, networkChannel, "my_profile");
  const memberChannel = socket.channel(`member:${profile.id}`);
  yield fork(channelSaga, memberChannel);

  // Send events via member channel, regardless where origin from
  // This removes the need for channel routing and the backend doesn't care either
  yield throttle(
    COMPOSING_TIMEOUT * 0.8,
    ["chat/COMPOSER/SET_TEXT", "chat/SEND_MESSAGE"],
    pushComposingEvent,
    memberChannel,
  );
  yield throttle(
    500,
    ["chat/MESSAGE/RECEIVE"],
    markMessageAsReceived,
    memberChannel,
    profile.id,
  );
  yield takeLatest(
    ["chat/MY_CHATS/REPLY"],
    markLastChatMessagesAsReceived,
    memberChannel,
    profile.id,
  );
}

const isGroupChat = ({ id }) => id.indexOf("group/") === 0;
const groupChatIdToTopic = (id) => id.replace("/", ":");

// Launch a group channel for every group chat returned by my_chats
// Also launch channels for future group chats
function* chatChannelsSaga(socket, networkChannel) {
  const { chats } = yield call(query, networkChannel, "my_chats");
  const navigation = yield select(getChatNavigation);
  if (navigation.route === "showChat")
    yield call(fetchChatDetails, { chatId: navigation.params.chatId });

  yield all(
    map(filter(chats, isGroupChat), ({ id }) =>
      fork(channelSaga, socket.channel(groupChatIdToTopic(id))),
    ),
  );

  while (true) {
    const action = yield take([
      "chat/CREATE_CHAT/REPLY",
      "chat/CREATE_CHAT/RECEIVE",
    ]);
    if (isGroupChat(action.payload)) {
      yield fork(
        channelSaga,
        socket.channel(groupChatIdToTopic(action.payload.id)),
      );
    }
  }
}

// Do stuff on the network channel
function* channelsSaga(socket, networkId) {
  const networkChannel = socket.channel(`network:${networkId}`);
  yield fork(presenceSaga, networkChannel);

  yield call(join, networkChannel);
  yield call(query, networkChannel, "network_info");
  yield fork(myChannelSaga, socket, networkChannel);
  yield fork(chatChannelsSaga, socket, networkChannel);
  yield fork(handleChatQueries, networkChannel);
}

export default channelsSaga;
