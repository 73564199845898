import React from "react";
import invariant from "invariant";
import BasicPropertySetForm from "./BasicPropertySetForm";
import ModifiedPropertySetForm from "./ModifiedPropertySetForm";
import InfoText from "components/shared/InfoText";
import {
  usePersistPropertySet,
  usePropertySet,
} from "components/shared/propertySets/api";

const EDITOR_MAPPING = {
  basic: BasicPropertySetForm,
  modified: ModifiedPropertySetForm,
};

interface EditorTypes {
  name: string;
  contextType: string;
  contextId?: string;
}

export default function Editor({ name, contextType, contextId }: EditorTypes) {
  const { isLoading: loading, data: propertySet } = usePropertySet({
    name,
    contextId,
    contextType,
    inherit: false,
  });

  const { mutate: submitPropertySet } = usePersistPropertySet();

  if (loading) return <div>{I18n.t("js.loading")}</div>;
  if (!propertySet?.type)
    return <div>{I18n.t("js.errors.not_found.title")}</div>;

  const SpecializedForm = EDITOR_MAPPING[propertySet.type];

  invariant(
    SpecializedForm,
    "No form component for",
    propertySet.type,
    "defined in components/administration/propertySets/Editor.js",
  );

  return (
    <>
      {contextType === "network" ? (
        <InfoText translation="js.administration.property_sets.edit.hint_network" />
      ) : (
        <InfoText translation="js.administration.property_sets.edit.hint_group" />
      )}
      <SpecializedForm
        form={name}
        initialValues={propertySet}
        onSubmit={(body) =>
          submitPropertySet({
            name,
            contextId,
            contextType,
            body,
          })
        }
        onSubmitSuccess={() => {
          toastr.success(I18n.t("js.saving_successful"));
        }}
        onSubmitFail={(error: Error) =>
          toastr.error(
            "_error" in error
              ? (error["_error"] as string)
              : I18n.t("js.saving_failure"),
          )
        }
      />
    </>
  );
}
