import React from "react";
import TixxtModuleSwitcher from "../layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";

export default function MessagesUserbar() {
  return (
    <>
      <TixxtModuleSwitcher activeModule={"messages"} />
      <ContextNavigation>
        <UserbarSection title={I18n.t("js.userbar.content_section.title")}>
          <UserbarLink to="/messages">
            {I18n.t("js.messages.index.title")}
          </UserbarLink>
          <UserbarLink to="/messages/sent">
            {I18n.t("js.messages.sent.title")}
          </UserbarLink>
        </UserbarSection>
      </ContextNavigation>
    </>
  );
}
