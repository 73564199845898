import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, trim, throttle } from "lodash";
import Textarea from "react-textarea-autosize";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import { getComposerMessageToQuoteId } from "../../../../selectors/chat/composer";
import { getCanForChat } from "../../../../selectors/chat/chat";
import MessageToQuote from "./MessageToQuote";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import usePersistentState from "../../../../hooks/usePersistentSate";

type FormData = { text: string };

export default function MessageComposer({ chatId }: { chatId: string }) {
  const dispatch = useDispatch();
  const messageToQuoteId = useSelector((state) =>
    getComposerMessageToQuoteId(state, { chatId }),
  );
  const [composerTextByChatId, setComposerTextByChatId] = usePersistentState(
    "composerTextByChatId",
    {},
  );

  const { handleSubmit, register, getValues, setFocus, setValue, reset } =
    useForm<FormData>();

  const invokeDebounced = useMemo(
    () =>
      throttle((text) => {
        dispatch({ type: "chat/COMPOSER/SET_TEXT", chatId, text });
      }, 1000),
    [chatId],
  );

  function changeText(e: { target: { value: string } }) {
    setComposerTextByChatId({
      ...composerTextByChatId,
      [chatId]: e.target.value,
    });
    invokeDebounced(e.target.value);
  }

  function submit({ text }: FormData) {
    if (isEmpty(trim(text))) {
      return;
    }

    dispatch({
      type: "chat/SEND_MESSAGE",
      meta: { chatId },
      payload: { text, quoted_message_id: messageToQuoteId },
    });

    setComposerTextByChatId({ ...composerTextByChatId, [chatId]: "" });
    reset();
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.keyCode == 13) {
      if (!e.shiftKey && !e.ctrlKey && !e.altKey && !e.metaKey) {
        handleSubmit(submit)(e);
      }
    }
  }

  useEffect(() => {
    setFocus("text");
  }, [chatId, messageToQuoteId]);

  useEffect(() => {
    setValue("text", composerTextByChatId[chatId]);
  }, [chatId]);

  const can = useSelector((state) => getCanForChat(state, { chatId }));
  const disabled = can && !can.write; // optimistically enable if we haven't received permissions yet

  return (
    <div
      className={classNames(
        "messageComposer flex flex-col border-t border-neutral",
      )}
    >
      <MessageToQuote id={messageToQuoteId} chatId={chatId} />
      <form onSubmit={handleSubmit(submit)} className={"flex items-stretch"}>
        <Textarea
          {...register("text", {
            required: true,
            onChange: changeText,
          })}
          minRows={1}
          maxRows={8}
          placeholder={
            disabled
              ? I18n.t("js.chat.composer.disabled")
              : I18n.t("js.chat.composer.placeholder")
          }
          onKeyDown={onKeyDown}
          autoFocus
          className={
            "chat__message-composer grow text-sm rounded-none border-0 border-r border-neutral"
          }
          disabled={disabled}
        />
        <button
          className="btn btn-primary btn-sm rounded-none messageComposerAction"
          type="submit"
          disabled={!trim(getValues("text"))}
        >
          <FontAwesomeIcon icon={solid("paper-plane")} />
        </button>
      </form>
    </div>
  );
}
