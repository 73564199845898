import React from "react";

function NotFound() {
  return (
    <div>
      <h1 className="standalone">{I18n.t("js.errors.error_while_loading")}</h1>
      <div className="row">
        <div className="span6">
          <h2>{I18n.t("js.errors.not_found.title")}</h2>
          <p>{I18n.t("js.errors.not_found.text")}</p>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
