import React from "react";
import SearchBar from "components/layout/SearchBar";
import { useSelector } from "react-redux";
import { State } from "../../@types";

export default function AppSearchHeader() {
  const theme = useSelector((state: State) => state.theme);
  const duo = theme?.userbarIconType == "duotone";

  return (
    <div id="app__header" className="header__content">
      <SearchBar duo={duo} />
    </div>
  );
}
