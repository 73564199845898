import React from "react";
import { QueryClientProvider, QueryClient, QueryCache } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

const queryCache = new QueryCache();
const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }) => fetchApi(queryKey[0]),
    },
  },
});

function Wrapper({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
}

window.queryClient = queryClient;

export default queryClient;
export { queryClient, queryCache, Wrapper };
