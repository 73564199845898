import type { MouseEventHandler } from "react";
import React from "react";
import { isEmpty, map } from "lodash";
import classNames from "classnames";

function InteractionsButton({
  onClick,
  className,
  icons,
  title,
  isDisabled = false,
  onlyIcon,
}: InteractionsButtonProps) {
  return (
    <button
      className={classNames("btn btn-sm btn-light", className)}
      onClick={onClick}
      disabled={isDisabled}
    >
      {map(icons, (iconClassName, index) => (
        <i
          key={index}
          className={classNames(`${iconClassName} text-primary`, {
            "mr-1": !(onlyIcon || isEmpty(title)),
          })}
        />
      ))}

      {onlyIcon ? null : title}
    </button>
  );
}

interface InteractionsButtonProps {
  onClick: MouseEventHandler;
  className?: string;
  icons?: Array<string>;
  title?: string;
  isDisabled?: boolean;
  onlyIcon?: boolean;
  testId?: string;
}

export default InteractionsButton;
