import React from "react";
import PropTypes from "prop-types";
import { map, size, get, range, at, keys, find, concat, drop } from "lodash";
import moment from "moment";
import CalendarSheetCell from "./calendarSheet/CalendarSheetCell";
import FullSizeContent from "../shared/FullSizeContent";
import useFullWidthLayout from "hooks/useFullWidthLayout";

function HeaderCell({ day }) {
  return <div className="text-sm px-1 text-muted">{day}</div>;
}

HeaderCell.propTypes = {
  day: PropTypes.string,
};

function CalendarSheet({ appointmentsByDate, calendars }) {
  useFullWidthLayout();

  const days = moment.weekdays();
  const weekCount = Math.ceil(size(appointmentsByDate) / 7);
  const dates = keys(appointmentsByDate);

  const startOfMonth = find(dates, (date) => !!date.match(/-01$/));
  const endOfMonth = moment(startOfMonth).endOf("month").format("YYYY-MM-DD");

  const rowStyle = {
    gridTemplateRows: `repeat(${weekCount}, 1fr)`,
  };

  return (
    <FullSizeContent className={`CalendarSheet flex flex-col gap-1`}>
      {({ height }) => {
        const headerCellHeight = 24; // h-5 + gap-1 = 24px
        const maxCellHeight = (height - headerCellHeight) / weekCount;

        return (
          <>
            <div className="grid grid-cols-7 h-5">
              {map(concat(drop(days), days[0]), (day) => (
                <HeaderCell day={day} key={day} />
              ))}
            </div>
            <div className="min-h-0 grid grid-cols-7" style={rowStyle}>
              {map(range(0, weekCount), (i) => {
                const days = at(dates, range(7 * i, 7 * (i + 1)));
                const weekNum = moment(days[0], "YYYY-MM-DD").week();

                return map(days, (day, j) => (
                  <CalendarSheetCell
                    key={day}
                    day={day}
                    appointments={get(appointmentsByDate, day)}
                    isFirst={i === 0 && j === 0}
                    weekNum={j === 0 && weekNum}
                    notActualMonth={day < startOfMonth || day > endOfMonth}
                    calendars={calendars}
                    className={
                      "border-r border-b " +
                      (i === 0 ? "border-t " : "") +
                      (j === 0 ? "border-l " : "") +
                      (i === 0
                        ? j === 0
                          ? "rounded-tl "
                          : j === 6
                            ? "rounded-tr"
                            : ""
                        : i === weekCount - 1
                          ? j === 0
                            ? "rounded-bl"
                            : j === 6
                              ? "rounded-br"
                              : ""
                          : "")
                    }
                    maxHeight={maxCellHeight}
                    columnNumber={j}
                    lastRow={i === weekCount - 1}
                  />
                ));
              })}
            </div>
          </>
        );
      }}
    </FullSizeContent>
  );
}

CalendarSheet.propTypes = {
  appointmentsByDate: PropTypes.shape({
    [PropTypes.any]: CalendarSheetCell.propTypes.appointments,
  }),
  calendars: CalendarSheetCell.propTypes.calendars,
};

export default CalendarSheet;
