import type { MouseEventHandler } from "react";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "helpers/tixxt-router";
import classNames from "classnames";
import { get, set } from "lodash";
import { queryClient } from "helpers/queryClient";
import BackButton from "components/shared/BackButton";
import InteractionsButton from "../InteractionsButton";
import {
  useMarkMessageAsRead,
  useMarkMessageAsUnread,
  useDeleteMessage,
  onError,
} from "../api";
import { MessageInteractionsProps } from "../@types";
import { useQueryParams } from "helpers/useQueryParams";

function MessageInteractions({
  messageId,
  isRead,
  onSetMessageAction,
  messageAction,
  filter,
}: MessageInteractionsProps) {
  const navigate = useNavigate();
  const { mutate: deleteMessage } = useDeleteMessage(filter, messageId, {
    onSuccess: () => {
      if (navigate) {
        navigate(-1);
      } else {
        window.history.back();
      }
      toastr.success(I18n.t("js.messages.message_deleted"));
    },
    onError,
  });

  const { mutate: markAsUnread, isLoading: isLoadingUnread } =
    useMarkMessageAsUnread({
      onSuccess: () => {
        const updatedCache = set(
          queryClient.getQueryData(["message", messageId]) as Record<
            PropertyKey,
            unknown
          >,
          "i.read",
          false,
        );
        queryClient.setQueryData(["message", messageId], updatedCache);
      },
      onError,
    });

  const { mutate: markAsRead, isLoading: isLoadingRead } = useMarkMessageAsRead(
    {
      onSuccess: () => {
        const updatedCache = set(
          queryClient.getQueryData(["message", messageId]) as Record<
            PropertyKey,
            unknown
          >,
          "i.read",
          true,
        );
        queryClient.setQueryData(["message", messageId], updatedCache);
      },
      onError,
    },
  );

  const sendDirectMessages = useSelector((state) =>
    get(state, "currentMember.can.send_direct_messages"),
  );
  const { state } = useQueryParams();
  const search = (state as Record<PropertyKey, unknown>)?.search || "";

  return (
    <div className="flex justify-between gap-2 mt-2 px-3 py-2 border-box mb-10">
      <div className="flex gap-2 items-center">
        <BackButton
          title={I18n.t("js.messages.back_to_overview")}
          href={`/messages${filter ? `/${filter}${search}` : search}`}
        />
        <span className={"block border-neutral border-r mx-1 self-stretch"} />

        {filter !== "sent" ? (
          <Fragment>
            {isRead ? (
              <InteractionsButton
                onClick={() => markAsUnread(messageId)}
                isDisabled={isLoadingUnread}
                icons={["fa-regular fa-envelope text-normal fa-lg"]}
                title={I18n.t("js.messages.index_toolbar.unread")}
              />
            ) : (
              <InteractionsButton
                onClick={() => markAsRead(messageId)}
                isDisabled={isLoadingRead}
                icons={["fa-regular fa-envelope text-normal fa-lg"]}
                title={I18n.t("js.messages.index_toolbar.read")}
              />
            )}
          </Fragment>
        ) : null}
        <InteractionsButton
          onClick={onDeleteMessage as unknown as MouseEventHandler}
          icons={["fa-regular fa-trash-can text-danger fa-lg"]}
          testId="deleteMsg"
        />
      </div>
      <div className="reply-toolbar flex gap-2 items-center desktop-only">
        {filter !== "sent" && sendDirectMessages ? (
          <Fragment>
            <InteractionsButton
              onClick={() => onSetMessageAction("reply")}
              icons={["fa fa-reply"]}
              title={I18n.t("js.messages.combined.answer")}
              onlyIcon={false}
              className={classNames({
                active: messageAction === "reply",
              })}
            />
            <InteractionsButton
              onClick={() => onSetMessageAction("replyAll")}
              icons={["fa fa-reply-all"]}
              title={I18n.t("js.messages.combined.answer_all")}
              onlyIcon={false}
              className={classNames({
                active: messageAction === "replyAll",
              })}
            />
          </Fragment>
        ) : null}
        {sendDirectMessages ? (
          <InteractionsButton
            onClick={() => onSetMessageAction("share")}
            icons={["fa fa-share"]}
            title={I18n.t("js.messages.combined.forward")}
            onlyIcon={false}
            className={classNames({
              active: messageAction === "share",
            })}
          />
        ) : null}
      </div>
    </div>
  );

  function onDeleteMessage(e: Event) {
    e.preventDefault();
    window.bridge?.confirm(
      filter == "sent"
        ? `${I18n.t("js.delete_confirmation")}\n${I18n.t(
            "js.messages.message.delete_confirmation_sent",
          )}`
        : I18n.t("js.delete_confirmation"),
      () => deleteMessage(),
    );
  }
}

export default MessageInteractions;
