import React, { Fragment, useEffect } from "react";
import { isEmpty, divide, ceil, floor, size } from "lodash";
import { queryClient } from "helpers/queryClient";
import InteractionsButton from "./InteractionsButton";
import Pagination from "../shared/Pagination";
import {
  useDeleteMessages,
  useMarkMessagesAsRead,
  useMarkMessagesAsUnread,
  onError,
  TrimmedMessage,
} from "./api";
import classNames from "classnames";

type MessagesInteractionsProps = {
  filter: "" | "sent";
  total: number;
  limit: number;
  offset: number;
  messages: TrimmedMessage[];
  isFetching: boolean;
  selectedMessageIds: Array<string>;
  setSelectedMessageIds: (ids: string[]) => void;
  onLoadMoreMessages: (page: number) => void;
  refetchMessages: () => void;
};

function MessagesInteractions({
  filter,
  total,
  limit,
  offset,
  messages,
  isFetching,
  selectedMessageIds,
  setSelectedMessageIds,
  onLoadMoreMessages,
  refetchMessages,
}: MessagesInteractionsProps) {
  const allMessageIds = messages?.map((m) => m.id);
  const { mutate: deleteMessages } = useDeleteMessages(filter, {
    onSuccess: () => {
      // need to call refetch to update messages list in turbo app
      refetchMessages();
      toastr.success(I18n.t("js.messages.messages_deleted"));
    },
    onError,
  });
  const { mutate: markAsRead, isLoading: isLoadingRead } =
    useMarkMessagesAsRead({
      onSuccess: () => {
        queryClient.invalidateQueries(["messages", filter, page]);
      },
      onError,
    });
  const { mutate: markAsUnread, isLoading: isLoadingUnread } =
    useMarkMessagesAsUnread({
      onSuccess: () => {
        queryClient.invalidateQueries(["messages", filter, page]);
      },
      onError,
    });

  const page = floor(divide(offset, limit)) + 1;

  useEffect(() => {
    setSelectedMessageIds([]);
    return () => setSelectedMessageIds([]);
  }, [filter]);

  const checked = size(selectedMessageIds) === size(messages);
  const indeterminate =
    size(selectedMessageIds) > 0 && size(selectedMessageIds) < size(messages);

  return total > 0 ? (
    <>
      <div className={"flex justify-end"}>
        <Pagination
          page={page}
          totalPages={ceil(divide(total, limit))}
          onPaginate={onLoadMoreMessages}
          loading={isFetching}
          width={2}
        />
      </div>
      <div
        className={
          "messages-interactions flex gap-2 mt-2 px-3 py-2 bg-gray-100 border-b border-b-neutral"
        }
      >
        {total > 0 ? (
          <input
            type="checkbox"
            onChange={onSelectAllMessages}
            checked={checked}
            title={I18n.t("js.messages.message_list.mark_all")}
            className={classNames("!h-6 !w-6 mr-2", {
              "!bg-primary !border-primary": indeterminate,
            })}
            ref={(input) => {
              if (input) input.indeterminate = indeterminate;
            }}
          />
        ) : null}
        {filter !== "sent" ? (
          <Fragment>
            <InteractionsButton
              onClick={onReadMessages}
              isDisabled={isEmpty(selectedMessageIds) || isLoadingRead}
              icons={["fa-regular fa-circle-check text-success fa-lg"]}
              title={I18n.t("js.messages.index_toolbar.read")}
            />
            <InteractionsButton
              onClick={onUnreadMessages}
              isDisabled={isEmpty(selectedMessageIds) || isLoadingUnread}
              icons={["fa-regular fa-envelope text-normal fa-lg"]}
              title={I18n.t("js.messages.index_toolbar.unread")}
            />
          </Fragment>
        ) : null}
        <InteractionsButton
          onClick={onDeleteMessages}
          isDisabled={isEmpty(selectedMessageIds)}
          icons={["fa-regular fa-trash-can text-danger fa-lg"]}
          title={I18n.t("js.messages.index_toolbar.delete")}
        />
      </div>
    </>
  ) : null;

  function onSelectAllMessages() {
    if (
      isEmpty(selectedMessageIds) ||
      size(selectedMessageIds) < size(messages)
    ) {
      setSelectedMessageIds(allMessageIds);
    } else {
      setSelectedMessageIds([]);
    }
  }

  function onDeleteMessages() {
    const confirmMessage =
      filter === "sent"
        ? `${I18n.t("js.delete_confirmation")}\n${I18n.t(
            "js.messages.delete_confirmation_sent",
          )}`
        : I18n.t("js.delete_confirmation");

    window.bridge?.confirm(confirmMessage, () => {
      deleteMessages(selectedMessageIds);
      setSelectedMessageIds([]);
    });
  }

  function onReadMessages() {
    markAsRead(selectedMessageIds);
    setSelectedMessageIds([]);
  }

  function onUnreadMessages() {
    markAsUnread(selectedMessageIds);
    setSelectedMessageIds([]);
  }
}

export default MessagesInteractions;
