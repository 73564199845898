import { useQuery, useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

export function fetchImprint() {
  return fetchApi("/administration/imprint/edit");
}
export function useImprint() {
  return useQuery("imprint", fetchImprint);
}

export function updateImprint({ ...body }) {
  return fetchApi(`/administration/imprint/`, { method: "PUT", body });
}
export function useUpdateImprint(options) {
  return useMutation(updateImprint, options);
}
