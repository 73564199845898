import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { createChat } from "../../../actions/chat";
import classnames from "classnames";

const Tile = ({ createChat, id, name, type }) => (
  <button
    key={id}
    onClick={createChat}
    className={classnames("btn btn-light btn-sm", {
      memberChat: type === "member",
      groupChat: type === "group",
    })}
  >
    <i
      className={classnames("mr-1 text-muted", {
        "fa fa-user": type === "member",
        "fa fa-users": type === "group",
      })}
    />
    {name}
  </button>
);
Tile.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  createChat: PropTypes.func,
};

export default connect(null, (dispatch, { id, type }) => ({
  createChat: (e) => {
    e.preventDefault();
    dispatch(createChat({ payload: { id, type } }));
  },
}))(Tile);
