import React, { useState, useEffect } from "react";
import { Field } from "redux-form";
import { map, filter } from "lodash";

import ReduxForm from "../../shared/reduxForm";
import TixxtComposerAdapter from "../composer/TixxtComposerAdapter";
import Attachments from "./Attachments";
import Modal from "components/shared/Modal";
import { useCreateComment, useUpdateComment } from "./api";

import styles from "./ReplyComposer.module.css";
import useFileProcessing from "components/shared/hooks/useFileProcessing";

const resizeCallback = ($modalEl, modalBodyHeight) => {
  let composerCss;
  let editorRootCss;

  if (modalBodyHeight == null) {
    composerCss = {
      maxHeight: "none",
      minHeight: "0",
    };

    editorRootCss = {
      maxHeight: "none",
      minHeight: "0",
    };
  } else {
    const composerHeight = modalBodyHeight - 32;
    const editorHeight = composerHeight - 34 - 50;

    composerCss = {
      maxHeight: `${composerHeight}px`,
      minHeight: `${composerHeight}px`,
    };

    editorRootCss = {
      maxHeight: `${editorHeight}px`,
      minHeight: `${editorHeight}px`,
    };
  }

  $modalEl.find(".Composer").css(composerCss);
  $modalEl.find(".DraftEditor-root").css(editorRootCss);
};

const filterRemoved = (items) =>
  filter(items, (item) => item.state != "removed");

interface ReplyComposerTypes {
  type?: "replies" | "answers";
  activityId: string;
  reply: {
    id: string;
    content: string;
    files: Array<unknown>;
    images: Array<unknown>;
  };
  isOpen?: boolean;
  selectedTab: string;

  saveCallback(...args: unknown[]): unknown;

  cancelCallback(...args: unknown[]): unknown;
}

function ReplyComposer({
  type = "replies",
  activityId,
  reply,
  isOpen = false,
  selectedTab,
  saveCallback,
  cancelCallback,
}: ReplyComposerTypes) {
  const [open, setOpen] = useState(isOpen);
  const [fullscreen, setFullscreen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const composerName = `replyComposer-${activityId}-${reply.id || type}`;

  const openComposer = () => {
    setOpen(true);
  };
  const closeComposer = (reset?) => {
    if (reset) {
      reset();
    }
    setOpen(false);
  };
  const openFullscreen = () => {
    setFullscreen(true);
  };
  const closeFullscreen = () => {
    setFullscreen(false);
  };

  const onSuccess = (result) => {
    if (saveCallback) {
      saveCallback(result);
    }

    setIsProcessing(false);
    closeComposer();
  };

  const onCancel = () => {
    if (cancelCallback) {
      cancelCallback();
    }

    closeComposer();
  };

  const onError = () => {
    setIsProcessing(false);
  };

  const { mutate: createComment } = useCreateComment({
    onSuccess,
    onError,
  });
  const { mutate: updateComment } = useUpdateComment({ onSuccess, onError });

  function handleSubmit(values) {
    setIsProcessing(true);
    const body = {
      ...values,
      files: map(filterRemoved(values.files), (file) => file.id),
      images: map(filterRemoved(values.images), (image) => image.id),
    };

    if (reply.id) {
      return updateComment({
        activityId: activityId,
        replyId: reply.id,
        type,
        ...body,
      });
    } else {
      return createComment({ activityId: activityId, type, ...body });
    }
  }

  // To react to external isOpen prop changes
  useEffect(() => {
    isOpen ? openComposer() : closeComposer();
  }, [isOpen]);

  const fileProcessing = useFileProcessing(composerName, "files");
  const imageProcessing = useFileProcessing(composerName, "images");

  return open ? (
    <ReduxForm
      form={composerName}
      submitText={I18n.t(`js.activitystreams.${type}.submit`)}
      initialValues={reply}
      processing={isProcessing || fileProcessing || imageProcessing}
      onSubmit={handleSubmit}
      onCancel={onCancel}
      className={styles.ReplyComposer}
      classNameFormActions={styles.ReplyComposerFormActions}
    >
      {fullscreen ? (
        <Modal
          static
          fullscreen
          resizeCallback={resizeCallback}
          onClose={closeFullscreen}
          title={I18n.t(`js.activitystreams.${type}.author_hint`)}
        >
          <Field
            name="content"
            component={TixxtComposerAdapter}
            props={{ handleFullscreen: closeFullscreen }}
          />
        </Modal>
      ) : (
        <Field
          name="content"
          component={TixxtComposerAdapter}
          props={{ handleFullscreen: openFullscreen }}
        />
      )}
      <Attachments composerName={composerName} selectedTab={selectedTab} />
    </ReduxForm>
  ) : (
    <button
      key="dummy"
      className={`Composer object-content-input form-control ${styles.closed}`}
      onClick={openComposer}
    >
      <span className="placeholder">
        {I18n.t(`js.activitystreams.${type}.author_hint`)}
      </span>
    </button>
  );
}

export default ReplyComposer;
