import React from "react";
import { useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { compose, mapProps } from "recompose";
import { omit } from "lodash";
import PropTypes from "prop-types";
import { selectFolderProfilesEnabled } from "selectors/propertySets";
import EmbeddedValueSetForm from "components/shared/propertySets/EmbeddedValueSetForm";
import ControlGroup from "components/shared/fields/ControlGroup";

function FolderForm({
  id,
  parentFolderId,
  handleSubmit,
  onCancel,
  submitting,
}) {
  const showFolderProfileFields = useSelector(selectFolderProfilesEnabled);

  return (
    <form className="form-horizontal" onSubmit={handleSubmit}>
      <ControlGroup
        name="name"
        label={I18n.t("js.files.folder.form.name.label")}
      >
        <Field
          name="name"
          component="input"
          type="text"
          autoFocus
          className="flex"
        />
      </ControlGroup>
      <ControlGroup
        name="description"
        label={I18n.t("js.files.folder.form.description.label")}
      >
        <Field
          name="description"
          component="textarea"
          rows={5}
          className="flex"
        />
      </ControlGroup>
      {showFolderProfileFields ? (
        <EmbeddedValueSetForm
          name="folder_profile"
          contextType="folder"
          contextId={id || parentFolderId}
        />
      ) : null}
      <div className="form-actions flex my-4 gap-2 justify-end">
        <a className="btn btn-light" href="#cancel" onClick={onCancel}>
          {I18n.t("js.cancel")}
        </a>
        <button className="btn btn-success" disabled={submitting}>
          {id
            ? I18n.t("js.files.folder.form.update_folder")
            : I18n.t("js.files.folder.form.create_folder")}
        </button>
      </div>
    </form>
  );
}
FolderForm.propTypes = {
  id: PropTypes.string,
  parentFolderId: PropTypes.string,
  handleSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  submitting: PropTypes.bool,
};

export default compose(
  mapProps(({ folder, onSubmit, onCancel }) => ({
    id: folder.id,
    parentFolderId: folder.parent_folder_id,
    initialValues: {
      name: folder.name,
      description: folder.description,
      folder_profile: omit(folder.folder_profile, ["$_displayValues_"]),
    },
    onSubmit,
    onCancel,
  })),
  reduxForm({ form: "folder", enableReinitialize: true }),
)(FolderForm);
