import React from "react";
import { useLoadApp, useLoadItem } from "components/appCreator/api";
import Loading from "components/shared/Loading";
import { isArray, isObject } from "lodash";

type Props = {
  item_id: string;
  app_id: string;
  frontend_app_url: string;
};

export default function ItemHit({
  item_id: itemId,
  app_id: appId,
  frontend_app_url: frontendAppUrl,
}: Props) {
  const { data: app, isLoading: appLoading } = useLoadApp({
    appId,
    externalAppId: null,
  });
  const { data: item, isLoading: itemLoading } = useLoadItem({
    appId,
    itemId,
    externalItemId: null,
    externalAppId: null,
  });

  if (appLoading || itemLoading || !app || !item)
    return (
      <div className={`ItemHit border-box px-3 py-2`}>
        <Loading />
      </div>
    );

  const title =
    item?.display_values?.[app.title_property] ||
    item?.values?.[app.title_property];

  const imageProp = app.properties.find((p) => p.type == "image");
  let imageUrl: string | undefined;
  if (imageProp && item.values[imageProp.name]) {
    const imageValue = item.values[imageProp.name];

    if (isArray(imageValue) && isObject(imageValue[0]))
      imageUrl = imageValue[0].url;
    else if (isObject(imageValue) && "url" in imageValue)
      imageUrl = imageValue.url;
  }

  // const teaser = getTeaser(app, item)

  return (
    <div className={`ItemHit border-box px-3 py-2`}>
      <div className={"flex gap-2"}>
        {imageUrl ? (
          <a href={`${frontendAppUrl}/items/${item.id}`}>
            <img
              className={"w-[100px]"}
              src={`/api/storage${imageUrl}/get/200x200`}
              alt={title}
            />
          </a>
        ) : null}
        <h4>
          <a href={`${frontendAppUrl}/items/${item.id}`}>{title}</a>{" "}
          <a className="text-muted" href={frontendAppUrl}>
            {app.name}
          </a>
        </h4>
      </div>
    </div>
  );
}

// Tixxt.Apps is not available outside of legacy frontend, so this doesn't work
// Keeping it as a reference for now

// function getTeaser(app, item) {
//   let teaser: string | undefined;
//   const layoutOptions =
//       app.collection_layout.options[app.collection_layout.layout];
//   if ("teaser" in layoutOptions && layoutOptions?.teaser?.body_template) {
//     const formatter = new Tixxt.Apps.Formatters.TemplatePropertyFormatter();
//     teaser = Application.markdown(
//         formatter.fromRaw(
//             layoutOptions?.teaser?.body_template,
//             new Backbone.Model(item),
//         ),
//     );
//   }
// }
