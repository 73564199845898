import React from "react";
import Hint from "components/shared/fields/Hint";

interface TextareaFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: string;
  hint?: string;
}

function TextareaField({ name, onChange, value, hint }: TextareaFieldTypes) {
  return (
    <div>
      <label htmlFor={`widget-${name}-input`}>
        {I18n.t(`js.administration.widgetbars.${name}.label`)}
      </label>
      <textarea
        id={`widget-${name}-input`}
        value={value || ""}
        rows={7}
        onChange={onChange}
      />
      <Hint hint={hint} className="!mb-0" />
    </div>
  );
}

export default TextareaField;
