import React from "react";
import MemberRow, { Member } from "../shared/MemberRow";

export type OnlineMembersWidgetProps = {
  headline?: string;
  memberships: Member[];
};

const OnlineMembersWidget: React.FC<OnlineMembersWidgetProps> = ({
  headline,
  memberships,
}) => {
  return (
    <div className="widget online-members-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      <ul className="memberships unstyled space-y-2">
        {memberships.length === 0 ? (
          <li>{I18n.t("js.widgets.online_members.no_other_members_online")}</li>
        ) : (
          memberships.map((member) => (
            <MemberRow key={member.id} member={member} />
          ))
        )}
      </ul>
    </div>
  );
};

export default OnlineMembersWidget;
