import React from "react";
import { FieldArray } from "redux-form";
import { isEmpty } from "lodash";
import SelectionOptionsFields from "./selection/SelectionOptionsFields";

interface PropertyFormTypes {
  name: string;
  property: { type: string; label?: string; name: string };
}

// Renders form for all available property settings depending on
// property type, i.e. options for selections
export default function PropertyForm({ name, property }: PropertyFormTypes) {
  const availableModifications: Array<React.ReactElement> = [];
  if (property.type === "selection")
    availableModifications.push(
      <FieldArray
        key="options"
        name={`${name}.options` as string}
        component={SelectionOptionsFields as never}
        label="Auswahloptionen bearbeiten"
      />,
    );

  return (
    <>
      <h4 className="mt-4 mb-6 font-semibold">
        {property.label || property.name}
      </h4>
      {isEmpty(availableModifications) ? (
        <span className="text-muted">
          Dieses Feld kann nicht bearbeitet werden.
        </span>
      ) : (
        availableModifications
      )}
    </>
  );
}
