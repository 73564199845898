import React from "react";
import AppointmentRow, { Appointment } from "../shared/AppointmentRow";

export type AppointmentsWidgetProps = {
  appointments: Appointment[];
  headline?: string;
};

const AppointmentsWidget: React.FC<AppointmentsWidgetProps> = ({
  appointments,
  headline,
}) => {
  return (
    <div className="widget appointments-widget">
      {headline && <h2 className="widget-title">{headline}</h2>}
      <ul className="appointments unstyled space-y-2">
        {appointments.length > 0 ? (
          appointments.map((appointment) => (
            <AppointmentRow key={appointment.id} appointment={appointment} />
          ))
        ) : (
          <p>{I18n.t("widgets.appointment.no_upcoming_appointments")}</p>
        )}
      </ul>
    </div>
  );
};

export default AppointmentsWidget;
