import React from "react";
import PropTypes from "prop-types";

import { AppointmentInviteDate } from "../AppointmentInvite";

function AppointmentPreview({ name, date, description_rendered }) {
  return (
    <div className="appointment-preview bg-primary/10 border-primary/25 border mb-4 p-3 rounded text-sm">
      <h4 className="name text-normal font-semibold mb-2">{name}</h4>
      <div className="detail-date flex flex-row gap-2">
        <i className="fa-regular fa-clock text-muted" />
        <AppointmentInviteDate date={date} />
      </div>
      {description_rendered ? (
        <div
          className="description mt-2"
          dangerouslySetInnerHTML={{ __html: description_rendered }}
        />
      ) : null}
    </div>
  );
}
AppointmentPreview.propTypes = {
  name: PropTypes.string,
  date: PropTypes.shape({
    all_day: PropTypes.bool,
    end: PropTypes.any,
    same_day: PropTypes.bool,
    start: PropTypes.any,
  }),
  description_rendered: PropTypes.string,
};

export default AppointmentPreview;
