import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

type ParticipantParams = {
  membership_ids: (string | undefined)[];
  group_ids: (string | undefined)[];
  membership_role_ids: (string | undefined)[];
  status: string;
  send_email_notification: boolean;
  invite_consumers?: boolean;
};

export function useAddParticipants(
  appointmentId: string,
  options: {
    onSuccess: (
      data: any,
      params: { participant: ParticipantParams; apply_to?: "this" | "all" },
    ) => void;
    onError: (error: string) => void;
  },
) {
  return useMutation(
    (params: { participant: ParticipantParams; apply_to?: "this" | "all" }) =>
      fetchApi(`/appointments/${appointmentId}/participants`, {
        method: "POST",
        body: params,
      }),
    options,
  );
}

export function useUpdateParticipation(
  participantId: string,
  appointmentId: string,
  options: { onSuccess: () => void },
) {
  return useMutation(
    (params: {
      participant: {
        status: string | null;
      };
      apply_to?: string;
    }) =>
      fetchApi(`/appointments/${appointmentId}/participants/${participantId}`, {
        method: "PUT",
        body: params,
      }),
    options,
  );
}

export function useDeleteParticipant(
  participantId: string,
  appointmentId: string,
  options: { onSuccess: () => void },
) {
  return useMutation(
    (params: { apply_to: string }) =>
      fetchApi(`/appointments/${appointmentId}/participants/${participantId}`, {
        method: "DELETE",
        body: params,
      }),
    options,
  );
}
