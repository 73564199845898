import React from "react";
import { Provider } from "react-redux";

const wrapProvider = (store) => (Component) => {
  const wrapped = function (props) {
    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
  };

  wrapped.displayName = `Provider(${Component.name})`;

  return wrapped;
};

export default wrapProvider;
