import React, { useEffect, useState } from "react";
import { find, map } from "lodash";
import { Item } from "../../../../../@types/appNavigation";

type Props = {
  unusedDefaults: Item[];
  onChange: (item: Item) => void;
  changedToDefault?: boolean;
} & Item;

function Default({
  unusedDefaults,
  onChange,
  changedToDefault = false,
  ...item
}: Props) {
  const defaults =
    item && item?.isDefault ? [item, ...unusedDefaults] : unusedDefaults;
  const [selected, select] = useState<Item | undefined>(
    item?.isDefault && !changedToDefault ? item : defaults[0],
  );

  useEffect(() => {
    selected && onChange(selected);
  }, []);

  return (
    <select
      className="input-text"
      name="default"
      onChange={(e) => {
        const item = find(defaults, { id: e.target.value });

        if (item) {
          select(item);
          onChange(item);
        }
      }}
      value={selected?.id}
    >
      {map(defaults, (item: Item) => (
        <option value={item.id} key={item.id}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

export default Default;
