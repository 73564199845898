import { useMutation } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";

/* Replies */
export function createComment({ activityId, type = "replies", ...body }) {
  return fetchApi(`/api/v1/activities/${activityId}/${type}`, {
    method: "POST",
    body,
  });
}
export function useCreateComment(options) {
  return useMutation(createComment, options);
}

export function updateComment({
  activityId,
  replyId,
  type = "replies",
  ...body
}) {
  return fetchApi(`/api/v1/activities/${activityId}/${type}/${replyId}`, {
    method: "PUT",
    body,
  });
}
export function useUpdateComment(options) {
  return useMutation(updateComment, options);
}
