import { useEffect, useState } from "react";
import { useLocation, useParams } from "helpers/tixxt-router";

function useQueryParams(): Record<PropertyKey, unknown> {
  console.warn(
    "useQueryParams can be replaced with useSearchParams from helpers/tixxt-router",
  );
  const location = useLocation();
  const params = useParams();
  const [queryParam, setQueryParam] = useState(getQueryParams);

  useEffect(() => setQueryParam(getQueryParams), [location]);

  return queryParam;

  function getQueryParams() {
    if ("URLSearchParams" in window) {
      return {
        ...params,
        ...Object.fromEntries(new URLSearchParams(location.search)),
        ...(location.state ? { state: location.state } : {}),
      };
    } else {
      return {
        ...params,
        ...getQueryObject(location.search),
        ...(location.state ? { state: location.state } : {}),
      };
    }
  }
}

function getQueryObject(search: string) {
  return (search.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(function (
    state: Record<PropertyKey, unknown>,
    curr,
  ) {
    const [key, value] = curr.split("=");
    state[key] = value;
    return state;
  }, {});
}

export { useQueryParams };
