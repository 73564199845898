import PropTypes from "prop-types";
import React from "react";
import { compose } from "recompose";

import { useCreateAppointmentContact } from "./api";
import ReduxForm from "components/shared/reduxForm";
import wrapProvider from "../../helpers/components/wrapProvider";
import store from "../../store";

import ControlGroup from "../shared/fields/ControlGroup";
import TextField from "../appCreator/properties/edit/TextField";
import { Field } from "redux-form";

function Contact({
  hasAttachments,
  areAttachmentsInLimit,
  isLoading,
  appointment,
}) {
  const { mutateAsync: createAppointmentContact } = useCreateAppointmentContact(
    {
      onSuccess: () => {
        toastr.success(I18n.t("js.saving_successful"));
        Backbone.history.navigate(`/appointments/${appointment.id}`, {
          trigger: true,
        });
      },
    },
  );

  const onSubmit = async (values) => {
    return createAppointmentContact({
      appointmentId: appointment.id,
      ...values,
    });
  };

  return isLoading ? (
    <p>
      <i className="fa fa-spin fa-spinner"></i>{" "}
      {I18n.t("js.calendars.appointment.loading")}
    </p>
  ) : (
    <div className="AppointmentContact">
      <ReduxForm
        form="networkSettingsForm"
        className="form-horizontal boxed-form-fields"
        onSubmit={onSubmit}
        submitText={I18n.t("js.calendars.appointment.attendees.contact.send")}
        onSubmitFail={() => toastr.error(I18n.t("js.saving_failure"))}
        onCancel={(reset) => {
          reset();
          Backbone.history.navigate(`/appointments/${appointment.id}`, {
            trigger: true,
          });
        }}
        allowSaveWithoutChange={false}
      >
        <ControlGroup>
          <div className="help-block mt-4">
            {I18n.t("js.calendars.appointment.attendees.contact.general_help")}
          </div>
        </ControlGroup>

        <ControlGroup
          label={I18n.t(
            "js.calendars.appointment.attendees.contact.audience.label",
          )}
          className="white-box checkboxes"
        >
          <label className="choice radio mt-1.5">
            <Field
              component="input"
              type="radio"
              name="contact.audience"
              value="all"
            />{" "}
            {I18n.t("js.calendars.appointment.attendees.contact.audience.all")}
          </label>
          <label className="choice radio">
            <Field
              component="input"
              type="radio"
              name="contact.audience"
              value="accepted"
            />{" "}
            {I18n.t(
              "js.calendars.appointment.attendees.contact.audience.accepted",
            )}
          </label>
          <label className="choice radio">
            <Field
              component="input"
              type="radio"
              name="contact.audience"
              value="declined"
            />{" "}
            {I18n.t(
              "js.calendars.appointment.attendees.contact.audience.declined",
            )}
          </label>
          <label className="choice radio">
            <Field
              component="input"
              type="radio"
              name="contact.audience"
              value="no_reaction"
            />{" "}
            {I18n.t(
              "js.calendars.appointment.attendees.contact.audience.no_reaction",
            )}
          </label>
        </ControlGroup>

        <ControlGroup
          name="contact.subject"
          label={I18n.t("js.calendars.appointment.attendees.contact.subject")}
          required
        >
          <TextField name="contact.subject" required />
        </ControlGroup>

        <ControlGroup
          name="contact.text"
          label={I18n.t("js.calendars.appointment.attendees.contact.text")}
          required
        >
          <TextField name="contact.text" multiline required />
        </ControlGroup>

        {hasAttachments ? (
          <ControlGroup
            className="white-box checkboxes"
            label={I18n.t(
              "js.calendars.appointment.attendees.contact.attachments",
            )}
          >
            <label className="choice checkbox">
              <Field
                component="input"
                type="checkbox"
                name="contact.include_attachments"
                disabled={!areAttachmentsInLimit}
              />{" "}
              {I18n.t(
                "js.calendars.appointment.attendees.contact.include_attachments",
              )}
            </label>
            {areAttachmentsInLimit ? null : (
              <div className="help-block">
                {I18n.t(
                  "js.activitystreams.email_recommendations.attachments_too_large",
                  {
                    limit: `${
                      Tixxt.currentNetwork.getConfig(
                        "email_recommendations.attachment_size_limit",
                      ) /
                      1024 /
                      1024
                    }MB`,
                  },
                )}
              </div>
            )}
          </ControlGroup>
        ) : null}
      </ReduxForm>
    </div>
  );
}

Contact.propTypes = {
  hasAttachments: PropTypes.bool,
  areAttachmentsInLimit: PropTypes.bool,
  isLoading: PropTypes.bool,
  appointment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default compose(wrapProvider(store))(Contact);
