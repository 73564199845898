import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  duotone,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import { isEmpty, map } from "lodash";
import classNames from "classnames";
import useSelectNavigation from "../../hooks/useSelectNavigation";
import { generateItems, getIcon } from "./searchBar/helpers";
import { Item } from "../../@types/searchBar";
import { useNavigate, useSearchParams } from "helpers/tixxt-router";
import { stringify } from "query-string";

export default function SearchBar({ duo }: { duo?: boolean }) {
  const ref = useRef<HTMLFormElement>(null);
  const [searchParams] = useSearchParams();
  const [text, setText] = useState(searchParams.get("q") || "");
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const navigate = useNavigate();

  const items = generateItems();

  function onSearch(e) {
    if (e) e.preventDefault();
    const item = items[cursor];
    if (!item) return;

    let url = "/search";
    if (item.groupSlug) url = `/groups/${item.groupSlug}${url}`;
    navigate(`${url}?${stringify({ module: item.module, q: text })}`);
  }

  const { setCursor, cursor } = useSelectNavigation(ref, items, onSearch);

  if (!Preload.current_member) return null;

  return (
    <form
      className="search-bar flex items-center relative max-w-lg mx-auto flex-1 px-4"
      ref={ref}
      onSubmit={onSearch}
    >
      <div className="relative w-full">
        <FontAwesomeIcon
          className={classNames(
            "search-bar__icon absolute left-3 top-2.5 pointer-events-none",
            {
              "search-bar__focus": searchIsFocused,
            },
          )}
          icon={duo ? duotone("magnifying-glass") : regular("magnifying-glass")}
        />

        <input
          onFocus={() => setSearchIsFocused(true)}
          onBlur={() => {
            setTimeout(() => setSearchIsFocused(false), 250);
          }}
          onChange={(e) => setText(e.target.value)}
          value={text}
          className={classNames(
            "search-bar__input w-full rounded-full bg-transparent border border-solid py-1 px-9 text-white focus-visible:outline-none",
            {
              "rounded-b-none rounded-t-2xl": !isEmpty(text) && searchIsFocused,
            },
          )}
        />
        <div
          className={classNames(
            "bg-white border-box absolute rounded-t-none rounded-b-2xl w-full -mt-[1px]",
            {
              hidden: isEmpty(text) || !searchIsFocused,
            },
          )}
        >
          <ul className="flex flex-col divide-y divide-neutral">
            {map(items, (item: Item, index: number) => (
              <li
                key={index}
                className={classNames(
                  "flex justify-between gap-2 p-2 last:rounded-b-2xl cursor-pointer",
                  {
                    "bg-primary/25": index === cursor,
                  },
                )}
                onMouseEnter={() => setCursor(index)}
                onClick={onSearch}
              >
                <span className="truncate">
                  <FontAwesomeIcon
                    className={classNames("search-bar__focus mr-2 text-sm")}
                    icon={
                      duo
                        ? duotone("magnifying-glass")
                        : regular("magnifying-glass")
                    }
                  />
                  {text}
                </span>
                <div className="flex gap-1">
                  {item.groupSlug && (
                    <span className="rounded-full bg-primary/50 px-3 py-1 text-xs whitespace-nowrap truncate max-w-[125px]">
                      <FontAwesomeIcon
                        className={""}
                        icon={duo ? duotone("users") : regular("users")}
                      />
                      <span className="ml-1">{item.parentName}</span>
                    </span>
                  )}
                  {item.module || !item.groupSlug ? (
                    <span className="rounded-full bg-primary/50 px-3 py-1 text-xs whitespace-nowrap truncate max-w-[125px]">
                      {getIcon(item, duo)}
                      <span className="ml-1">
                        {[
                          undefined,
                          "activities",
                          "messages",
                          "files",
                          "appointments",
                          "pages",
                          "items",
                        ].includes(item.module)
                          ? I18n.t(`js.searchbar.${item.module || "global"}`)
                          : item.module}
                      </span>
                    </span>
                  ) : null}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <button
          className={classNames(
            "search-bar__delete-button absolute right-3 top-1.5",
            { top: isEmpty(text), "search-bar__focus": searchIsFocused },
          )}
          onClick={() => setText("")}
          type="reset"
        >
          <FontAwesomeIcon
            className={classNames("text-gray-200", {
              "text-muted": searchIsFocused,
              hidden: isEmpty(text),
            })}
            icon={regular("xmark")}
          />
        </button>
      </div>

      <button
        className={classNames(
          "search-bar__button btn btn-primary rounded-full w-8 h-8 shrink-0 ml-2 p-0",
          {
            invisible: isEmpty(text),
          },
        )}
        type="submit"
      >
        <FontAwesomeIcon className={"w-[6px]"} icon={solid("chevron-right")} />
      </button>
    </form>
  );
}
