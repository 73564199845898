import React from "react";
import PropTypes from "prop-types";
import SearchResults from "./SearchResults";
import { searchQuery } from "./urls";
import { useSearchResults } from "./api";

/*
This component can be used to embed search results into a different module
It is used to give immediate feedback when using the files search/filters
 */
function EmbeddedSearchResults({ q, filters, groupSlug, module, page = 1 }) {
  const query = searchQuery({ q, filters });
  const {
    loading,
    data: searchResults,
    error,
  } = useSearchResults({
    q: query,
    groupSlug,
    module,
    page,
  });

  const searchParams = { q: query, groupSlug, module, page };

  return (
    <SearchResults
      results={searchResults}
      params={searchParams}
      loading={loading}
      error={error}
    />
  );
}

EmbeddedSearchResults.propTypes = {
  q: PropTypes.string,
  filters: PropTypes.object,
  groupSlug: PropTypes.string,
  module: PropTypes.string,
  page: PropTypes.number,
};

export default EmbeddedSearchResults;
