import { identity, isEmpty, map, pickBy, trim } from "lodash";
import { stringify as stringifyQuery } from "query-string";

// Wraps value with quotes if necessary
const quote = (value) =>
  value.match(/^[a-z0-9_\-.]+$/i) ? value : `"${value.replace(/"/g, '\\"')}"`;

// Turns given value and name into filter syntax
// Produces special syntax for tags
const filterToQueryString = (value, name) => {
  const prefix = name === "tag" ? "#" : `${name}:`;
  return `${prefix}${quote(value)}`;
};

// @param filters [Object] { tag: 'bla', 'profile.fach': 'potato' }
export function searchUrl({
  groupSlug,
  contextUrl,
  q,
  filters,
  format,
  ...params
}) {
  const formatExt = format === "json" ? ".json" : "";
  const baseSearchPath =
    isEmpty(contextUrl) && !isEmpty(groupSlug)
      ? `/groups/${groupSlug}/search${formatExt}`
      : `${contextUrl || ""}/search${formatExt}`;
  if (!isEmpty(filters) || !isEmpty(q)) {
    params.q = searchQuery({ q, filters });
  }

  // Removes null and undefined
  params = pickBy(params, identity);

  return isEmpty(params)
    ? baseSearchPath
    : `${baseSearchPath}?${stringifyQuery(params)}`;
}

// Prepend filters as i.e. filtername:"foo" or #tag to q
export function searchQuery({ q: rawQ, filters }) {
  let q = null;
  // Prepend filters as i.e. filtername:"foo" or #tag to q
  if (!isEmpty(filters)) {
    const filterStrings = map(filters, filterToQueryString);
    if (!isEmpty(rawQ)) {
      filterStrings.push(rawQ);
    }
    q = trim(filterStrings.join(" "));
  } else if (!isEmpty(rawQ)) {
    q = trim(rawQ);
  }

  return q;
}

// Turns "foo,asc" into ["foo", 1]
// Turns "foo,desc" into ["foo", -1]
export const parseSortByParam = (sortByParam) => {
  if (isEmpty(sortByParam)) return null;

  const [attribute, directionString] = sortByParam.split(",");
  return attribute ? [attribute, directionString === "desc" ? -1 : 1] : null;
};

// Turns ["foo", 1] into "foo,asc"
// Turns ["foo", -1] into "foo,desc"
export const serializeSortBy = (sortBy) => {
  if (isEmpty(sortBy)) return null;

  const [attribute, directionNumber] = sortBy;
  return [attribute, directionNumber === -1 ? "desc" : "asc"].join(",");
};
