import React from "react";
import { get, map, isEmpty } from "lodash";
import moment from "moment";
import { useFilesReviewStatus } from "./api";
import DocumentAction from "../documents/DocumentAction";
import FileIcon from "../../shared/FileIcon";
import Pagination from "../../shared/Pagination";

export default function ReviewStatus({ groupSlug }) {
  const {
    data: files,
    pagination,
    isLoading,
  } = useFilesReviewStatus(groupSlug);

  if (isLoading) {
    return (
      <div>
        <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
      </div>
    );
  }

  if (isEmpty(files) && pagination.page === 1) {
    return (
      <div className="alert alert-info">
        {I18n.t("js.files.approval.review_status.empty_list")}
      </div>
    );
  }

  return (
    <>
      <table className="tixxt-table">
        <thead>
          <tr className="muted">
            <th></th>
            <th>{I18n.t("js.files.overview.file_name")}</th>
            <th>{I18n.t("js.files.overview.updated_at")}</th>
            <th>{I18n.t("js.files.overview.last_author")}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {map(files, (file) => (
            <ReviewStatusRow key={file.id} {...file} groupSlug={groupSlug} />
          ))}
        </tbody>
      </table>
      <Pagination {...pagination} width={2} hideIfEmpty />
    </>
  );
}

function ReviewStatusRow({
  original_filename,
  updated_at,
  current_version_authors,
  id,
  review_status,
  extension,
  groupSlug,
}) {
  const insertions = get(review_status, "insertions", 0);
  const deletions = get(review_status, "deletions", 0);
  const changes = get(review_status, "changes", 0);
  const comments = get(review_status, "comments", 0);
  return (
    <tr key={id}>
      <td>
        <FileIcon extension={extension} />
      </td>
      <td>
        <a href={`${groupSlug ? `/groups/${groupSlug}` : ""}/files/${id}`}>
          {original_filename}
        </a>
      </td>
      <td>
        {moment(updated_at).format(
          I18n.t("js.time.formats.short_date_and_time"),
        )}
      </td>
      <td>{map(current_version_authors, "name").join(", ")}</td>
      <td>
        <div className={"btn-group"}>
          <DocumentAction
            fileId={id}
            action="edit"
            icon={<i className="fa-regular fa-file-word" aria-hidden="true" />}
            type="docs"
            showReviewChanges={true}
          />
        </div>
      </td>

      <td>
        <div className="flex gap-2">
          <span
            title={`${changes + insertions + deletions} ${I18n.t(
              "js.files.approval.review_status.changes",
            )}`}
          >
            <i className="fa-regular fa-edit mr-1 text-primary" />
            {changes + insertions + deletions}
          </span>
          <span
            title={`${comments} ${I18n.t(
              "js.files.approval.review_status.comments",
            )}`}
          >
            <i className="fa-regular fa-comments mr-1" />
            {comments}
          </span>
        </div>
      </td>
    </tr>
  );
}
