import React from "react";
import PropTypes from "prop-types";

import GroupAccess from "./GroupAccess";

function JoinSettings({ active_main_group, access, sensitive, can, formName }) {
  return (
    <>
      <h3>{I18n.t("js.groups.edit.join_settings")}</h3>
      <GroupAccess
        active_main_group={active_main_group}
        access={access}
        sensitive={sensitive}
        can_create_hidden={can.create_hidden}
        formName={formName}
      />
    </>
  );
}

export default JoinSettings;

JoinSettings.propTypes = {
  active_main_group: PropTypes.bool,
  can: PropTypes.shape({
    create_hidden: PropTypes.bool,
  }),
  access: PropTypes.string,
  sensitive: PropTypes.bool,
  formName: PropTypes.string,
};
