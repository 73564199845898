import React from "react";
import { useDispatch } from "react-redux";
import classNames from "classnames";

type MessageSettingsArgs = {
  id: string;
  chatId: string;
  fromId: string;
  myMessage: boolean;
  className?: string;
};

import { createChat } from "../../../../actions/chat";

export default function MessageSettings({
  myMessage,
  className,
  id,
  chatId,
  fromId,
}: MessageSettingsArgs) {
  const dispatch = useDispatch();
  const type = chatId.indexOf("group") === 0 ? "group" : "member";
  const hideStartPrivateChat =
    myMessage || type === "member" || Preload.current_member?.is_external;

  function setMessageToQuote(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    dispatch({
      type: "chat/COMPOSER/SET_MESSAGE_TO_QUOTE",
      messageId: id,
      chatId,
    });
  }

  function createPrivateChat(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    dispatch(createChat({ payload: { id: fromId, type: "member" } }));
  }

  return (
    <div className={classNames("chatMessageSettings", className)}>
      <div className={"dropdown"}>
        <button
          className={"btn btn-light btn-sm leading-none px-1"}
          data-bs-toggle="dropdown"
        >
          <i className="fa fa-ellipsis-h fa-sm" />
        </button>
        <ul className="dropdown-menu">
          <li>
            <a onClick={setMessageToQuote} href={"#"}>
              {I18n.t("js.chat.room.message.quote_message")}
            </a>
          </li>
          {!hideStartPrivateChat && (
            <li>
              <a onClick={createPrivateChat} href={"#"}>
                {I18n.t("js.chat.room.message.start_private_chat")}
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
