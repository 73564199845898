import React from "react";
import { map, startsWith } from "lodash";
import classnames from "classnames";

import ControlGroup from "./ControlGroup";
import CheckboxField from "./CheckboxField";
import Hint from "components/shared/fields/Hint";

interface CheckboxFieldsProps {
  name: string;
  label?: string;
  required?: boolean;
  options: Array<{
    value: boolean | string;
    label: string;
    disabled?: boolean;
    hint?: string;
  }>;
  multiple?: boolean;
  hint?: string;
  disabled?: boolean;
}

function CheckboxFields({
  name,
  label,
  required,
  options,
  multiple,
  hint,
  disabled,
}: CheckboxFieldsProps) {
  return (
    <ControlGroup
      name={name}
      label={label}
      required={required}
      className="radio_buttons border-box p-3"
    >
      {map(options, (option) => {
        return (
          <CheckboxField
            name={multiple ? `${name}[${option.value}]` : name}
            key={String(option.value)}
            type={multiple ? "checkbox" : "radio"}
            className={classnames({ radio: !multiple, checkbox: multiple })}
            disabled={disabled || option.disabled}
            label={
              startsWith(option.label, "js.")
                ? I18n.t(option.label)
                : option.label
            }
            value={option.value}
            labelHint={option.hint}
          />
        );
      })}
      <Hint hint={hint} />
    </ControlGroup>
  );
}

export default CheckboxFields;
