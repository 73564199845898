import React, { useContext } from "react";
import SearchQueryPaginationContext from "components/globalSearch/SearchQueryPaginationContext";
import { QueryParams } from "components/globalSearch/SearchQueryResult";

export default function SearchSortSelector({
  availableSorts,
}: {
  availableSorts: QueryParams["available_sorts"];
}) {
  const { setPaginationState, sort_by, ...paginationState } = useContext(
    SearchQueryPaginationContext,
  );

  if (!availableSorts) return null;

  return (
    <div className={"max-w-md"}>
      <select
        onChange={(e) =>
          setPaginationState({
            ...paginationState,
            sort_by: e.target.value,
          })
        }
        value={sort_by || "score,asc"}
      >
        {availableSorts.map(({ key, label }) => (
          <option key={key} value={key}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}
