import React from "react";
import PropTypes from "prop-types";
import { map } from "lodash";

function CalendarList({ calendars }) {
  return (
    <ul className={`CalendarList flex `}>
      {map(calendars, ({ id, name, color }) => (
        <li className="flex items-center mr-2" key={id}>
          <div
            className="block h-2 rounded-full w-2 mr-1"
            style={{ backgroundColor: `#${color}` }}
          />{" "}
          <span className="font-semibold leading-none text-normal text-sm">
            {name}
          </span>
        </li>
      ))}
    </ul>
  );
}

CalendarList.propTypes = {
  calendars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ),
  className: PropTypes.string,
};

export default CalendarList;
