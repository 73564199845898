import React from "react";
import moment from "moment";
import "moment-timezone";

export type Appointment = {
  id: string;
  name: string;
  start: string;
  end?: string;
  all_day?: boolean;
  path: string;
  meeting?: {
    join_url?: string;
  };
  location?: string;
};

function displayTime(appointment: Appointment) {
  const currentTime = moment.tz(moment(), moment.tz.guess());
  const startTime = moment.tz(appointment.start, moment.tz.guess());
  const allDay = appointment.all_day;
  if (appointment.end) {
    const endTime = moment.tz(appointment.end, moment.tz.guess());

    if (
      currentTime.isBetween(startTime, endTime, "minute", "[]") ||
      currentTime.isBetween(startTime, endTime, "day", "[]")
    ) {
      // today
      if (startTime.isSame(endTime, "day")) {
        if (!allDay) {
          // Case-3
          return (
            <>
              {`${startTime.format("LT")} - ${endTime.format("LT")}, `}
              <strong>{I18n.t("js.widgets.appointment.today")}</strong>
            </>
          );
        }
      } else {
        if (currentTime.isSame(startTime, "day")) {
          if (allDay) {
            // Case-7 Case-12
            return (
              <>
                <strong>{I18n.t("js.widgets.appointment.today")}</strong>
                {` -`}
                <br />
                {`${endTime.format("dd")}., ${endTime.format("L")}`}
              </>
            );
          } else {
            // Case-10 Case-16
            return (
              <>
                {`${startTime.format("LT")}, `}
                <strong>{I18n.t("js.widgets.appointment.today")}</strong>
                {` -`}
                <br />
                {`${endTime.format("dd")}., ${endTime.format(
                  "L",
                )}, ${endTime.format("LT")}`}
              </>
            );
          }
        } else {
          if (currentTime.isSame(endTime, "day")) {
            if (allDay) {
              // Case-8 Case-14
              return <strong>{I18n.t("js.widgets.appointment.today")}</strong>;
            } else {
              // Case-11 Case-18
              return (
                <>
                  {I18n.t("js.widget.appointment.until")}
                  {` ${endTime.format("LT")}, `}
                  <strong>{I18n.t("js.widgets.appointment.today")}</strong>
                </>
              );
            }
          } else {
            if (allDay) {
              // Case-13
              return (
                <>
                  <strong>{I18n.t("js.widgets.appointment.today")}</strong>
                  {" -"}
                  <br />
                  {`${endTime.format("dd")}., ${endTime.format("L")}`}
                </>
              );
            } else {
              // Case-17
              return (
                <>
                  <strong>{I18n.t("js.widgets.appointment.today")}</strong>
                  {" -"}
                  <br />
                  {`${endTime.format("dd")}., ${endTime.format(
                    "L",
                  )}, ${endTime.format("LT")}`}
                </>
              );
            }
          }
        }
      }
    } else {
      // future
      if (startTime.isSame(endTime, "day")) {
        if (allDay) {
          // Case-4
          return startTime.format("dd., L");
        } else {
          // Case-6
          return startTime.format("dd., L, LT - ") + endTime.format("LT");
        }
      } else {
        if (allDay) {
          // Case-9 Case-15
          return startTime.format("dd., L - ") + endTime.format("dd., L");
        } else {
          // Case-19
          return (
            startTime.format("dd., L, LT - ") + endTime.format("dd., L, LT - ")
          );
        }
      }
    }
  } else {
    if (currentTime.isSame(startTime, "day")) {
      if (allDay) {
        // Case-1
        return <strong>{I18n.t("js.widgets.appointment.today")}</strong>;
      } else {
        // Case-2
        return (
          <>
            {`${startTime.format("LT")}, `}
            <strong>{I18n.t("js.widgets.appointment.today")}</strong>
          </>
        );
      }
    } else {
      if (allDay) {
        // Case-4
        return startTime.format("dd., L");
      } else {
        // Case-5
        return startTime.format("dd., L, LT");
      }
    }
  }
}

type AppointmentRowProps = {
  appointment: Appointment;
};

const AppointmentRow: React.FC<AppointmentRowProps> = ({ appointment }) => {
  const currentTime = moment.tz(moment(), moment.tz.guess());
  const startTime = moment.tz(appointment.start, moment.tz.guess());

  return (
    <li className="appointment flex flex-col">
      <span className={"space-x-1"}>
        {appointment.meeting?.join_url && (
          <i className="fa-regular fa-comments fa-fw"></i>
        )}
        <a
          className={`appointment-title ${
            currentTime.isSameOrAfter(startTime) ? "font-bold" : ""
          }`}
          href={appointment.path}
        >
          {appointment.name}
        </a>
      </span>
      <span className={"appointment--timeDisplay space-x-1"}>
        <i className="fa-regular fa-calendar fa-fw"></i>
        {displayTime(appointment)}
      </span>
      {appointment.location && (
        <span className={"appointment--location space-x-1"}>
          <i className="fa-regular fa-location-dot fa-fw"></i>
          {appointment.location}
        </span>
      )}
    </li>
  );
};

export default AppointmentRow;
