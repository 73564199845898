import React from "react";
import { map, mapValues, isEmpty } from "lodash";
import widgets, { PropTypeFields, generateHint } from "helpers/widgets/widgets";

export interface WidgetPropertiesTypes {
  widget: {
    properties: Record<PropertyKey, unknown>;
    type: string;
  };

  removeWidget?(...args: unknown[]): unknown;

  groupId?: string;
}

export default function WidgetProperties({
  widget,
  removeWidget,
  groupId,
}: WidgetPropertiesTypes) {
  const defaultProperties = widgets(widget.type).properties;
  const properties = mapValues(
    defaultProperties,
    (prop, key) => widget.properties[key] || prop,
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="control-group">
        <input
          type="hidden"
          name="type"
          value={widget.type}
          key="widget-type"
        />
        <strong>
          Typ: {I18n.t(`js.administration.widgetbars.${widget.type}.label`)}
        </strong>
      </div>
      {map(properties, (value, property) => {
        const Component = PropTypeFields[property];
        const hint = generateHint(widget, property, groupId);

        if (isEmpty(Component)) {
          return;
        }
        return (
          <div className="control-group" key={`${widget.type}-${property}`}>
            <Component
              name={property}
              hint={hint}
              value={value}
              type={widget.type}
            />
          </div>
        );
      })}

      <button
        type="button"
        onClick={removeWidget}
        key="remove"
        className="btn btn-danger mt-4 self-end"
      >
        {I18n.t("js.administration.widgetbars.remove")}
      </button>
    </div>
  );
}
