import React from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";

import Highlights from "./Highlights";

const pagePath = ({ group_slug, slug }) => {
  const url = `/pages/${slug}`;
  if (group_slug) {
    return `/groups/${group_slug}${url}`;
  }
  return url;
};

function PageHit({ title, highlight, group_slug, slug }) {
  return (
    <div className={`PageHit border-box px-3 py-2`}>
      <h4>
        <a href={pagePath({ group_slug, slug })}>{title}</a>
      </h4>

      {isEmpty(highlight) || isEmpty(highlight["values.content"]) ? null : (
        <Highlights highlights={highlight["values.content"]} />
      )}
    </div>
  );
}

PageHit.propTypes = {
  title: PropTypes.string,
  highlight: PropTypes.array,
  group_slug: PropTypes.any,
  slug: PropTypes.any,
};

export default PageHit;
