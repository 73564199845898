import delay from "@redux-saga/delay-p";
import {
  call,
  take,
  all,
  takeLatest,
  put,
  select,
  race,
  fork,
  actionChannel,
  cancel,
} from "redux-saga/effects";
import { get, at } from "lodash";

import {
  loadChatDetails,
  markAsRead,
  loadMoreMessages,
} from "../../actions/chat";
import {
  getLastReadIdForChat,
  getMoreMessagesAvailableForChat,
} from "../../selectors/chat/chat";
import {
  getLastUnreadMessageInChat,
  getLastMessageIdInChat,
  getFirstMessageIdInChat,
} from "../../selectors/chat/messages";

import { handleApiRequests } from "./api";

export const UNREAD_TIMER = 1000;

// Time after which a previously received composing event is timed out
// This is also used to determine throttle duration for pushing composing events (80% of this value)
export const COMPOSING_TIMEOUT = 2000;

const isShowChatNavigationAction = (action) =>
  action.type === "chat/NAVIGATE" && action.payload.route === "showChat";

const createWindowFocusPromise = () =>
  new Promise((resolve) => {
    const handler = () => {
      window.removeEventListener("focus", handler);
      resolve();
    };

    window.addEventListener("focus", handler);
  });

export function* fetchChatDetails({ chatId }) {
  yield put(loadChatDetails({ meta: { chatId } }));
}

export function* fetchMoreRecentMessages({ chatId }) {
  const moreMessagesAvailable = yield select(getMoreMessagesAvailableForChat, {
    chatId,
  });

  if (moreMessagesAvailable) {
    const firstMessageId = yield select(getFirstMessageIdInChat, { chatId });

    if (firstMessageId) {
      yield put(
        loadMoreMessages({
          meta: { chatId },
          payload: { message_id: firstMessageId },
        }),
      );
    }
  }
}

function* markChatAsRead({ chatId, messageId }) {
  // Cancel mark as read if another navigation occurs quickly
  const result = yield race({
    ok: delay(UNREAD_TIMER),
    navigate: take("chat/NAVIGATE"),
    mark_as_read: take("chat/CHAT/MARK_AS_READ"),
    mark_as_read_start: take("chat/CHAT/MARK_AS_READ/START_TIMER"),
  });

  if (result.ok) {
    yield put(
      markAsRead({
        meta: { chatId },
        payload: { last_read_id: messageId },
      }),
    );
  }
}

function* markChatAsReadHandler({ chatId }) {
  const lastReadId = yield select(getLastReadIdForChat, { chatId });
  const lastMessageId = yield select(getLastMessageIdInChat, { chatId });

  if (lastMessageId && lastReadId !== lastMessageId) {
    if (!document.hasFocus()) {
      yield call(createWindowFocusPromise);
    }

    const unreadMessage = yield select(getLastUnreadMessageInChat, { chatId });
    yield put({
      type: "chat/CHAT/MARK_AS_READ/START_TIMER",
      chatId,
      messageId: unreadMessage ? unreadMessage.id : lastMessageId,
    });
  }
}

function* navigateToChatWeCreated(action) {
  yield put({
    type: "chat/NAVIGATE",
    payload: { route: "showChat", params: { chatId: action.payload.id } },
  });
}

function* chatSaga(navigateAction) {
  const chatId = get(navigateAction, ["payload", "params", "chatId"]);

  yield call(fetchChatDetails, { chatId });

  yield all([
    takeLatest("chat/CHAT/MARK_AS_READ/START_TIMER", markChatAsRead),
    takeLatest("chat/CHAT/SCROLL_START_REACHED", fetchMoreRecentMessages),
    takeLatest("chat/CHAT/SCROLL_END_REACHED", markChatAsReadHandler),
  ]);

  yield fork(markChatAsReadHandler, { chatId });
}

function* timeoutComposing(payload) {
  yield delay(COMPOSING_TIMEOUT);
  yield put({ type: "chat/COMPOSING/STOPPED", payload });
}

// Makes sure that composing state per chat and member is timed out
function* timeoutComposingStates() {
  const timeoutTasks = {};
  const composingActions = yield actionChannel("chat/COMPOSING/RECEIVE");
  while (true) {
    const { payload } = yield take(composingActions);
    const ident = at(payload, ["chat_id", "membership_id"]).join("/");
    if (timeoutTasks[ident] && timeoutTasks[ident].isRunning()) {
      yield cancel(timeoutTasks[ident]);
    }
    timeoutTasks[ident] = yield fork(timeoutComposing, payload);
  }
}

function* applicationSaga() {
  yield all([
    takeLatest(isShowChatNavigationAction, chatSaga),
    fork(handleApiRequests),
    takeLatest("chat/CREATE_CHAT/REPLY", navigateToChatWeCreated),
    fork(timeoutComposingStates),
  ]);
}

export default applicationSaga;
