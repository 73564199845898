import type { TabbableFieldsTypes } from "./TabbableFields";
import type { FormTypes } from "components/shared/reduxForm";
import React from "react";
import { FieldArray } from "redux-form";
import { map } from "lodash";
import ReduxForm from "../../shared/reduxForm";
import finalizeSelectionOptions from "./properties/selection/finalizeSelectionOptions";
import TabbableFields from "./TabbableFields";
import PropertyForm from "./properties/PropertyForm";

interface PropertiesFieldsTypes {
  fields: TabbableFieldsTypes["fields"];
}

function PropertiesFields({ fields }: PropertiesFieldsTypes) {
  return (
    <TabbableFields fields={fields}>
      {({ name, property }) => <PropertyForm name={name} property={property} />}
    </TabbableFields>
  );
}

const addDefaultValuesToSelectionOptions = ({ properties, ...other }) => {
  const modifiedProperties = map(properties, (p) => {
    if (p.type !== "selection") return p;

    return { ...p, options: finalizeSelectionOptions(p.options) };
  });
  return { properties: modifiedProperties, ...other };
};

export default function BasicPropertySetForm({
  onSubmit,
  ...formProps
}: FormTypes) {
  return (
    <ReduxForm
      className="form-horizontal"
      {...formProps}
      onSubmit={(values, ...args) =>
        onSubmit(
          addDefaultValuesToSelectionOptions(values as { properties: unknown }),
          ...args,
        )
      }
      renderChildren={() => (
        <FieldArray name="properties" component={PropertiesFields as never} />
      )}
    />
  );
}
