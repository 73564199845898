import React from "react";
import { useSelector } from "react-redux";
import { formValueSelector } from "redux-form";
import moment from "moment";
import { flatMap, each, memoize } from "lodash";
import ControlGroup from "../../shared/fields/ControlGroup";
import SelectionField from "../../appCreator/properties/edit/SelectionField";
import { Field } from "redux-form";
import NumberField from "components/appCreator/properties/edit/NumberField.js";
import { State } from "@types";
import FileUploadField from "components/shared/fields/FileUploadField";
import TextField from "../../appCreator/properties/edit/TextField";
import { useSendTestBirthdayEmail } from "./api";
import MarkdownEditorAdapter from "components/shared/markdownEditor/MarkdownEditorAdapter";

type day = { name: string; index: number };

const createDaysArray = memoize(() => {
  const days: Array<day> = [];
  each(moment.localeData().weekdays(), (weekday, index) =>
    days.push({ name: weekday, index: index }),
  );

  let moveArray = moment.localeData().firstDayOfWeek();
  while (moveArray != 0) {
    if (days.length) {
      days.push(days.shift() as day);
    }
    moveArray--;
  }

  return days;
});

function MailSettings() {
  const selector = formValueSelector("networkSettingsForm");
  const digests_daily_activity_enabled = useSelector((state) =>
    selector(state as State, "daily_activity.enabled"),
  );
  const birthday_emails_enabled = useSelector((state) =>
    selector(state as State, "birthday_emails.enabled"),
  );

  const birthday_emails_values = useSelector((state) =>
    selector(
      state as State,
      "birthday_emails.subject",
      "birthday_emails.text",
      "birthday_emails.image",
    ),
  );

  const { mutateAsync: sendTestMail } = useSendTestBirthdayEmail({
    onSuccess: () => {
      toastr.success("Test Mail erfolgreich gesendet");
    },
  });

  const days = createDaysArray();
  return (
    <>
      <h3>
        {I18n.t("js.administration.network_settings.mail_settings.title")}
      </h3>
      <div className="flex flex-col gap-3">
        <ControlGroup
          name="notifications"
          label={I18n.t("js.networks.notifications.label")}
          className="white-box checkboxes border-box p-3"
        >
          <label className="choice checkbox">
            <Field
              component="input"
              type="checkbox"
              name="notifications_send_direct_message_body"
            />{" "}
            {I18n.t("js.networks.notifications.dm_body.label")}
          </label>
          <div className="help-block mt-1">
            {I18n.t("js.networks.notifications.dm_body.help")}
          </div>
        </ControlGroup>
        {Tixxt.currentNetwork.getConfig(
          "email_recommendations.allow_email_recommendation_configuration_for_network_admins",
        ) ? (
          <ControlGroup
            name="digest_sending_days"
            label={I18n.t(
              "js.administration.network_settings.activity_recommendation_email.label",
            )}
            className="white-box checkboxes border-box p-3"
          >
            <label className="choice checkbox">
              <Field
                component="input"
                type="checkbox"
                name="email_recommendations.allow_external_recipients"
              />{" "}
              {I18n.t(
                "js.administration.network_settings.activity_recommendation_email.checkbox",
              )}
            </label>
            <div className="help-block mt-1">
              {I18n.t(
                "js.administration.network_settings.activity_recommendation_email.help",
              )}
            </div>
            <label className="choice checkbox">
              <Field
                component="input"
                type="checkbox"
                name="email_recommendations.include_replies_default"
              />{" "}
              {I18n.t(
                "js.activitystreams.email_recommendations.include_replies_label",
              )}
            </label>
          </ControlGroup>
        ) : null}
        {Tixxt.currentNetwork.getConfig("digests.enabled") ? (
          <>
            <h4 className="font-bold">
              {I18n.t("js.administration.network_settings.digests.label")}
            </h4>
            <ControlGroup
              name="digest_sending_days"
              label={I18n.t(
                "js.administration.network_settings.digest_sending_days.label",
              )}
            >
              <SelectionField
                name="digest_sending_days"
                multiple={true}
                options={flatMap(days, (day) => [
                  {
                    key: `${day.index}_am`,
                    value: { day: day.index, time: "am" },
                    label: `${day.name} ${I18n.t(
                      "js.administration.network_settings.digests.am",
                    )}`,
                  },
                  {
                    key: `${day.index}_pm`,
                    value: { day: day.index, time: "pm" },
                    label: `${day.name} ${I18n.t(
                      "js.administration.network_settings.digests.pm",
                    )}`,
                  },
                ])}
              />
              {Tixxt.currentNetwork.getConfig("digests.configurable") ? null : (
                <div className="help-block mt-1 !mb-3">
                  {I18n.t(
                    "js.administration.network_settings.setting_restricted_by_plan",
                  )}
                </div>
              )}
            </ControlGroup>
            {Tixxt.currentNetwork.getConfig("digests.configurable") ? (
              <>
                <ControlGroup
                  name="digests.max_number_of_upcoming_appointments"
                  label={I18n.t(
                    "js.administration.network_settings.max_number_of_upcoming_appointments.label",
                  )}
                >
                  <NumberField
                    name="digests.max_number_of_upcoming_appointments"
                    required
                    max={30}
                    min={0}
                  />
                </ControlGroup>
                <ControlGroup
                  name="digests.upcoming_appointments_position"
                  label={I18n.t(
                    "js.administration.network_settings.upcoming_appointments_position.label",
                  )}
                  className="white-box checkboxes border-box p-3"
                >
                  <SelectionField
                    name="digests.upcoming_appointments_position"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "top",
                        label: I18n.t(
                          "js.administration.network_settings.upcoming_appointments_position.top",
                        ),
                      },
                      {
                        value: "bottom",
                        label: I18n.t(
                          "js.administration.network_settings.upcoming_appointments_position.bottom",
                        ),
                      },
                    ]}
                  />
                </ControlGroup>
                <ControlGroup
                  name="digests.show_comments"
                  label={I18n.t(
                    "js.administration.network_settings.show_comments.label",
                  )}
                  className="white-box checkboxes border-box p-3"
                >
                  <SelectionField
                    name="digests.show_comments"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "true",
                        label: I18n.t(
                          "js.administration.network_settings.show_comments.show",
                        ),
                      },
                      {
                        value: "false",
                        label: I18n.t(
                          "js.administration.network_settings.show_comments.show_not",
                        ),
                      },
                    ]}
                  />
                </ControlGroup>
              </>
            ) : null}
          </>
        ) : null}
        {Tixxt.currentNetwork.getConfig(
          "digests.daily_activity.feature_available",
        ) ? (
          <div className="flex flex-col gap-4">
            <ControlGroup
              name=""
              label={I18n.t(
                "js.administration.network_settings.daily_digests.label",
              )}
              className="white-box checkboxes border-box p-3"
            >
              <SelectionField
                name="daily_activity.enabled"
                multiple={false}
                select={false}
                required
                options={[
                  {
                    value: "true",
                    label: I18n.t(
                      "js.administration.network_settings.daily_digests.enabled.true",
                    ),
                  },
                  {
                    value: "false",
                    label: I18n.t(
                      "js.administration.network_settings.daily_digests.enabled.false",
                    ),
                  },
                ]}
              />
              <div className="help-block mt-1 !mb-3">
                {I18n.t(
                  "js.administration.network_settings.daily_digests.enabled.help",
                )}
              </div>
              {digests_daily_activity_enabled === "true" && (
                <>
                  <hr className="mb-2" />
                  <span className="pb-1">
                    {I18n.t(
                      "js.administration.network_settings.daily_digests.include_network_level.label",
                    )}
                  </span>
                  <SelectionField
                    name="daily_activity.include_network_level"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "true",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.include_network_level.true",
                        ),
                      },
                      {
                        value: "false",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.include_network_level.false",
                        ),
                      },
                    ]}
                  />
                  <hr className="mb-2" />
                  <span className="pb-1">
                    {I18n.t(
                      "js.administration.network_settings.daily_digests.mode.label",
                    )}
                  </span>
                  <SelectionField
                    name="daily_activity.mode"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "new",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.mode.new",
                        ),
                      },
                      {
                        value: "new_and_updated",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.mode.new_and_updated",
                        ),
                      },
                    ]}
                  />
                  <hr className="my-2" />
                  <span className="pb-1">
                    {I18n.t(
                      "js.administration.network_settings.daily_digests.sending_time.label",
                    )}
                  </span>
                  <SelectionField
                    name="daily_activity.sending_time"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "am",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.sending_time.am",
                        ),
                      },
                      {
                        value: "pm",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.sending_time.pm",
                        ),
                      },
                    ]}
                  />
                  <hr className="my-2" />
                  <span className="pb-1">
                    {I18n.t(
                      "js.administration.network_settings.daily_digests.only_weekdays.label",
                    )}
                  </span>
                  <SelectionField
                    name="daily_activity.only_weekdays"
                    multiple={false}
                    select={false}
                    required
                    options={[
                      {
                        value: "true",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.only_weekdays.true",
                        ),
                      },
                      {
                        value: "false",
                        label: I18n.t(
                          "js.administration.network_settings.daily_digests.only_weekdays.false",
                        ),
                      },
                    ]}
                  />
                </>
              )}
            </ControlGroup>
          </div>
        ) : null}
        {Tixxt.currentNetwork.getConfig(
          "birthday_emails.admin_configurable",
        ) ? (
          <div className="flex flex-col gap-4">
            <ControlGroup
              name=""
              label={I18n.t(
                "js.administration.network_setting.birthday_emails.title",
              )}
              className="white-box checkboxes border-box p-3"
            >
              <span className="pb-1">
                {I18n.t(
                  "js.administration.network_setting.birthday_emails.enable_label",
                )}
              </span>
              <SelectionField
                name="birthday_emails.enabled"
                multiple={false}
                select={false}
                required
                options={[
                  {
                    value: "true",
                    label: I18n.t(
                      "js.administration.network_setting.choices.yes",
                    ),
                  },
                  {
                    value: "false",
                    label: I18n.t(
                      "js.administration.network_setting.choices.no",
                    ),
                  },
                ]}
              />

              {birthday_emails_enabled === "true" && (
                <>
                  <hr className="my-2" />
                  <span className="pb-2">
                    {I18n.t(
                      "js.administration.network_setting.birthday_emails.image_label",
                    )}
                  </span>

                  <Field
                    name="birthday_emails.image"
                    component={FileUploadField}
                    props={{
                      multiple: false,
                      isImage: true,
                      storageDirectory: "assets",
                    }}
                  />
                  <div className="help-block mt-1">
                    {I18n.t(
                      "js.administration.network_setting.birthday_emails.image_size_hint",
                    )}
                  </div>

                  <hr className="my-2" />
                  <span className="pb-2">
                    {I18n.t(
                      "js.administration.network_setting.birthday_emails.subject_label",
                    )}
                  </span>

                  <TextField name="birthday_emails.subject" />
                  <hr className="my-2" />
                  <span className="mt-4">
                    {I18n.t(
                      "js.administration.network_setting.birthday_emails.text_label",
                    )}
                  </span>

                  <Field
                    label={I18n.t(
                      "js.administration.network_setting.birthday_emails.text_label",
                    )}
                    component={MarkdownEditorAdapter}
                    name="birthday_emails.text"
                  />
                  <div className="flex justify-end mt-4">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        sendTestMail(birthday_emails_values);
                      }}
                    >
                      {I18n.t(
                        "js.administration.network_setting.birthday_emails.send_test_mail_label",
                      )}
                    </button>
                  </div>
                </>
              )}
            </ControlGroup>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default MailSettings;
