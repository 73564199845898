import { useState } from "react";
import useWindowEvent from "components/shared/hooks/useWindowEvent";

const MEDIUM_BREAKPOINT = 768;
function isSmall() {
  return window.innerWidth < MEDIUM_BREAKPOINT;
}

export type ViewType = "sheet" | "list" | string;

export default function useAppointmentView() {
  const [persistedView, setPersistedView] = useState(
    sessionStorage.getItem("appointmentsViewType") || "sheet",
  );
  const [view, setView] = useState<ViewType>(
    isSmall() ? "list" : persistedView,
  );

  function updateView(view: ViewType) {
    if (isSmall()) {
      setView("list");
    } else {
      setView(view);
    }
  }

  function maybeForceListView() {
    updateView(persistedView);
  }

  function setViewType(view: ViewType) {
    sessionStorage.setItem("appointmentsViewType", view);
    setPersistedView(view);
    updateView(view);
  }

  useWindowEvent("resize", maybeForceListView);

  return { view, setViewType };
}
