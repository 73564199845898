import React from "react";

interface FileAttachmentsTypes {
  filesCount: number;
}

function FileAttachments({ filesCount }: FileAttachmentsTypes) {
  return (
    <div className="file-infos text-sm text-muted">
      <i className="fa-regular fa-file mr-1" />
      <span className="file-name">
        {I18n.t("js.global_search.activities.attachments.counting", {
          count: filesCount,
        })}
      </span>
    </div>
  );
}

export default FileAttachments;
