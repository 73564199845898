import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  get,
  map,
  isEqual,
  isEmpty,
  concat,
  orderBy,
  includes,
  set,
  uniqBy,
} from "lodash";
import moment from "moment";
import { queryClient } from "helpers/queryClient";
import { useLocation } from "helpers/tixxt-router";
import { useQueryParams } from "helpers/useQueryParams";
import { currentMemberSelector } from "selectors/environment";
import { useMarkMessageAsRead, useFetchMessage } from "../api";
import NewMessage from "../composer";
import MessageInteractions from "./MessageInteractions";
import MessageContainer from "./MessageContainer";
import { Author, Message } from "../@types";

function MessageDetails(): JSX.Element {
  const [messageAction, setMessageAction] = useState("");
  const { messageId } = useQueryParams() as { messageId: string };
  const location = useLocation() as unknown as Location & {
    state: History["state"];
  };
  const { isLoading, isSuccess, data: message } = useFetchMessage(messageId);
  const { mutate: markAsRead } = useMarkMessageAsRead({
    onSuccess: () => {
      const updatedCache = set(
        queryClient.getQueryData(["message", messageId]) as Record<
          PropertyKey,
          unknown
        >,
        "i.read",
        true,
      );
      queryClient.setQueryData(["message", messageId], updatedCache);
    },
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const currentMemberId = useSelector(currentMemberSelector)?.id;
  const filter = location.state?.pathname?.includes("sent") ? "sent" : "";
  const conversation = get(message, "conversation");

  useEffect(() => {
    if (isSuccess && message?.i.read === false) {
      markAsRead(messageId);
    }
  }, [message]);

  if (isLoading || !currentMemberId)
    return (
      <div className="messagesLoading">
        <i className="fa fa-spinner fa-spin" />
      </div>
    );

  return (
    <>
      <MessageInteractions
        messageId={messageId}
        isRead={get(message, ["i", "read"])}
        onSetMessageAction={onSetMessageAction}
        messageAction={messageAction}
        filter={filter}
      />

      {isEmpty(messageAction) ? null : (
        <div>
          <NewMessage
            initialValues={messageInitialValues(
              messageAction,
              message,
              currentMemberId,
            )}
          />
        </div>
      )}

      <MessageContainer {...message} currentMemberId={currentMemberId} />

      {map(orderBy(conversation, "created_at", "desc"), (message, i) => (
        <MessageContainer
          {...message}
          currentMemberId={currentMemberId}
          key={get(message, "id") || i}
          isConversation
        />
      ))}
    </>
  );

  function onSetMessageAction(action: string) {
    setMessageAction(action);
  }
}

function selectedMembers(recipients: Array<Author>) {
  const newMemberArray: Array<{ id: string; displayValue: string }> = [];
  map(recipients, (recipient) =>
    newMemberArray.push({
      id: recipient.id,
      displayValue: recipient.name,
    }),
  );
  return newMemberArray;
}

interface Attachment {
  content_type: string;
  download_url: string;
  extension: string;
  external_id: null;
  html_url: string;
  i: object;
  id: string;
  original_filename: string;
  size: number;
  state: string;
  type: string;
}

export function messageInitialValues(
  messageAction: string,
  message: Message,
  currentMemberId: string,
) {
  const sender_name = get(message, ["author", "name"]);
  const recipients =
    get(message, "to")?.filter(
      // filter out the currentMember, as we do not want to send a message to self (https://gitlab.sys.mixxt.net/tixxt/foundation/-/issues/2380)
      (recipient) => recipient.id != currentMemberId,
    ) || [];
  const subject = get(message, "subject") ? get(message, "subject") : "";
  const htmlContent = get(message, "content");
  const send_date = moment(get(message, "created_at")).format("DD.MM.YYYY");
  const send_time = moment(get(message, "created_at")).format("HH:mm");
  const messageId = get(message, "id");
  const conversation_starter_id =
    get(message, "conversation_starter_id") || messageId;

  switch (messageAction) {
    case "reply": {
      return {
        to: selectedMembers([get(message, "author")]),
        subject: `${I18n.t("js.messages.message.re")}: ${subject}`,
        conversation_starter_id,
      };
    }
    case "replyAll": {
      const isAuthorInRecipients = includes(
        map(recipients, (r) => isEqual(r, get(message, "author"))),
        true,
      );
      const to = uniqBy(
        selectedMembers(
          isAuthorInRecipients
            ? recipients
            : concat(recipients, [get(message, "author")]),
        ),
        "id",
      );

      return {
        to,
        subject: `${I18n.t("js.messages.message.re")}: ${subject}`,
        conversation_starter_id,
      };
    }
    case "share": {
      return {
        subject: `${I18n.t("js.messages.message.fwd")}: ${subject}`,
        content: `\n\n${I18n.t("js.messages.message.forwarded_message", {
          sender_name,
          send_date,
          send_time,
        })} \n${htmlContent}`,
        attachments: map(
          get(message, "attachments"),
          (attachment: Attachment) => ({
            ...attachment,
            name: attachment.original_filename,
          }),
        ),
      };
    }
    default: {
      return {};
    }
  }
}

export default MessageDetails;
