import React from "react";
import { useSelector } from "react-redux";
import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import GroupCategorySections from "../shared/GroupCategorySections";
import { networkNameSelector } from "selectors/environment";
import { useSearchParams } from "helpers/tixxt-router";
import { stringify } from "query-string";

export default function SearchUserbar() {
  const networkName = useSelector(networkNameSelector);
  const [searchParams] = useSearchParams();
  const query = stringify(Object.fromEntries(searchParams.entries()));
  const pathAndQuery = query.length > 0 ? `/search?${query}` : "/search";

  return (
    <>
      <TixxtModuleSwitcher />
      <ContextNavigation>
        <UserbarSection>
          <UserbarLink to={pathAndQuery}>{networkName}</UserbarLink>
        </UserbarSection>
        <GroupCategorySections>
          {({ slug, name, favorited }) => (
            <UserbarLink
              key={slug}
              to={`/groups/${slug}${pathAndQuery}`}
              showWhileClosed={favorited}
            >
              {name}
            </UserbarLink>
          )}
        </GroupCategorySections>
      </ContextNavigation>
    </>
  );
}
