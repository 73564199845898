import React from "react";

export const OnlineStatus = ({ isOnline, isBusy, children }) => (
  <div className="chat__status relative">
    {isBusy ? (
      <span className="busy">{I18n.t("js.chat.member_busy")}</span>
    ) : isOnline ? (
      <span className="online">{I18n.t("js.chat.member_online")}</span>
    ) : null}
    {children}
  </div>
);
