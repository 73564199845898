import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, withState, withHandlers, withProps } from "recompose";
import classNames from "classnames";
import { isEmpty, isArray } from "lodash";
import { DragSource, DropTarget } from "react-dnd";
import AssignedMembers from "./details/AssignedMembers";
import Info from "./details/Info";
import FileCounter from "./details/FileCounter";
import Title from "./details/Title";
import ArchiveInfo from "./details/ArchiveInfo";
import { archiveItem, unarchiveItem } from "../../../actions/appCreator";

const cardSource = {
  canDrag(props) {
    console.log("CAN MOVE", props.canMoveCard);
    return props.canMoveCard;
  },
  beginDrag(props) {
    props.setCardHeight(props.cardRef.clientHeight);
    return {
      cardId: props.cardId,
    };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop()) {
      const result = monitor.getDropResult();
      props.onMoveCard({
        cardId: props.cardId,
        fieldName: result.fieldName,
        targetColumn: result.column,
        insertAfterCardId: result.insertAfterCardId,
      });
    }
  },
};

const cardTarget = {
  hover(props) {
    props.setHoveredCardId && props.setHoveredCardId(props.cardId);
  },
};

const Card = compose(
  DragSource("card", cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })),
  DropTarget("card", cardTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
  })),
)(({
  title,
  description,
  date,
  tags,
  logo,
  connectDragSource,
  isDragging,
  connectDropTarget,
  isOver,
  cardId,
  filesCount,
  memberships,
  onSetCardRef,
  cardHeight,
  archiveItem,
  isArchive,
  unarchiveItem,
  archivedBy,
  archivedAt,
  setFilteredByTag,
  setFilteredByMember,
  isUnassigned,
}) => {
  return connectDragSource(
    connectDropTarget(
      <div>
        <div
          className={classNames("border-box p-3 hover:cursor-move", {
            ["opacity-25"]: isDragging,
            ["w-40"]: isUnassigned,
          })}
        >
          <div ref={onSetCardRef} className="card flex flex-col gap-2">
            <Title
              toggleArchive={isArchive ? unarchiveItem : archiveItem}
              isArchive={isArchive}
              title={title}
              date={date}
              cardId={cardId}
              isUnassigned={isUnassigned}
            />
            <div
              className={classNames("flex flex-col gap-2", {
                hidden: isUnassigned,
              })}
            >
              <Info
                logo={logo}
                description={description}
                tags={tags}
                setFilteredByTag={setFilteredByTag}
                setFilteredByMember={setFilteredByMember}
              />
              {filesCount > 0 ? <FileCounter filesCount={filesCount} /> : null}
              {!isEmpty(memberships) ? (
                <AssignedMembers memberships={memberships} />
              ) : null}
              {isArchive ? (
                <ArchiveInfo archivedBy={archivedBy} archivedAt={archivedAt} />
              ) : null}
            </div>
          </div>
        </div>
        {isOver && !isDragging ? (
          <div
            className="new-position bg-neutral rounded mt-2"
            style={{ height: cardHeight }}
          />
        ) : null}
      </div>,
    ),
  );
});
Card.propTypes = {
  title: PropTypes.string,
  filesCount: PropTypes.number,
  memberships: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({ id: PropTypes.string, displayValue: PropTypes.string }),
    ),
    PropTypes.shape({ id: PropTypes.string, displayValue: PropTypes.string }),
  ]),
  description: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      format: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    }),
  ),
  logo: PropTypes.shape({
    url: PropTypes.string,
  }),
  setCard: PropTypes.func,
  onMoveCard: PropTypes.func,
  setHoveredCardId: PropTypes.func,
  onSetCardRef: PropTypes.func,
  setCardHeight: PropTypes.func,
  archiveItem: PropTypes.func,
  unarchiveItem: PropTypes.func,
  setFilteredByTag: PropTypes.func,
  setFilteredByMember: PropTypes.func,
  cardId: PropTypes.string,
  appId: PropTypes.string,
  cardHeight: PropTypes.number,
  isArchive: PropTypes.bool,
  archivedBy: PropTypes.string,
  archivedAt: PropTypes.string,
  column: PropTypes.string,
  isUnassigned: PropTypes.bool,
  canMoveCard: PropTypes.bool,
};

export default compose(
  connect(null, (dispatch, { appId, cardId, column }) => ({
    archiveItem: () => dispatch(archiveItem({ appId, itemId: cardId, column })),
    unarchiveItem: () => dispatch(unarchiveItem({ appId, itemId: cardId })),
  })),
  withProps(({ logo }) => ({
    logo: isArray(logo) ? logo[0] : logo,
  })),
  withState("cardRef", "setCardRef", null),
  withHandlers({
    onSetCardRef:
      ({ setCardRef }) =>
      (ref) => {
        setCardRef(ref);
      },
  }),
)(Card);
