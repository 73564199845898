import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import pickImage from "../../../helpers/pickImage";

function GroupHit({
  name,
  images,
  html_url,
  archived,
  access,
  member_count,
  activity_count,
  teaser,
}) {
  return (
    <div className={`media items-center border-box px-3 py-2`}>
      <a href={html_url} className={"media-object h-16 w-16 -ml-1 self-start"}>
        {isEmpty(images) ? (
          <i className="fa fa-users fa-2xl block flex h-16 w-16 items-center justify-center text-gray-400" />
        ) : (
          <img
            alt=""
            aria-hidden
            className={"h-16 w-16"}
            src={pickImage(images, [128, 128])}
          />
        )}
      </a>
      <div className={"media-body"}>
        <span className={"media-heading flex gap-1"}>
          {archived ? (
            <i
              className="fa fa-archive text-muted"
              title={I18n.t("js.directory.group_row.archived")}
            />
          ) : null}
          {access === "closed" ? (
            <i
              className="fa fa-lock text-muted"
              title={I18n.t("js.directory.group_row.closed")}
            />
          ) : null}
          {access === "hidden" ? (
            <i
              className="fa fa-eye-slash text-muted"
              title={I18n.t("js.directory.group_row.hidden")}
            />
          ) : null}
          <a href={html_url} className={"font-medium"}>
            {name}
          </a>
        </span>
        <p className={"text-muted text-sm"}>
          {I18n.t("js.directory.group_row.member_count", {
            count: member_count || 0,
          })}
          {", "}
          {I18n.t("js.directory.group_row.activity", {
            count: activity_count || 0,
          })}
        </p>
        {isEmpty(teaser) ? null : (
          <p className="mt-1 text-sm whitespace-pre-line break-words">
            {teaser}
          </p>
        )}
      </div>
    </div>
  );
}

GroupHit.propTypes = {
  name: PropTypes.string.isRequired,
  images: pickImage.imagesPropType,
  html_url: PropTypes.string.isRequired,
};

export default GroupHit;
