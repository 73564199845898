import React from "react";

interface QuestionTypes {
  html_content: string;
  answer_count: number;
}

function Question(props: QuestionTypes) {
  return (
    <div className={`activity-object-question`}>
      <div className="clearfix question-content-container">
        <i className="fa fa-question-circle question-mark" />
        <div className="question-content grow min-w-0">
          <div
            className="note-view markdown-content prose break-words"
            dangerouslySetInnerHTML={{ __html: props.html_content }}
          />
        </div>
      </div>
    </div>
  );
}

export default Question;
