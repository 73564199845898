import React, { Suspense } from "react";

const ChatBar = React.lazy(() => import("../chat/ChatBar"));

export default function TixxtUserbar() {
  return (
    <Suspense fallback={"Loading..."}>
      <ChatBar />
    </Suspense>
  );
}
