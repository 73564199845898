import { Controller } from "@hotwired/stimulus";
import { range, toInteger } from "lodash";

// Connects to data-controller="administration--newsletter--interval"
export default class extends Controller {
  static values = {
    interval: String,
    day: String,
    secondDay: String,
    time: String,
    secondTime: String,
  };

  #SELECTOR_MAPPING = {
    weekly: ["mon", "tue", "wed", "thu", "fri", "sat", "sun"],
    semimonthly: range(1, 32).map(String),
    monthly: range(1, 32).map(String),
  };

  #DEFAULT_VALUE_MAPPING = {
    weekly: "mon",
    monthly: "1",
    semimonthly: "1",
    semimonthly_2: "15",
    time: "am",
  };

  connect() {
    this.$dayTimeContainer = this.element.querySelector(".day_time_container");
    this.$daySelector = this.element.querySelector(".day_selector");
    this.#intervalSelector(this.intervalValue);
  }

  onClickChangeInterval(event) {
    this.#intervalSelector(event.target.value);
  }

  #intervalSelector(targetValue) {
    targetValue = targetValue || "weekly";
    const isSemiMonthly = targetValue === "semimonthly";
    const isMonthly = targetValue === "monthly";

    this.$daySelector.innerHTML = "";

    if (isSemiMonthly) {
      this.#createSecondDaySelector();
    } else {
      this.#removeSecondDaySelector();
    }

    this.#createDaySelectorOptions("$daySelector", targetValue);

    this.#setSelectorValue(
      "$daySelector",
      this.dayValue,
      targetValue,
      targetValue === this.intervalValue,
    );

    this.#setSelectorValue(
      "$secondDaySelector",
      this?.secondDayValue,
      "semimonthly_2",
      this?.secondDayValue,
    );

    this.#setSelectorValue(
      "$secondDaySelector",
      this?.secondDayValue,
      "semimonthly_2",
      targetValue,
      this.intervalValue,
    );

    this.#setSelectorValue(
      "$secondDaySelector",
      this?.secondDayValue,
      "semimonthly_2",
      this?.secondDayValue,
    );

    this.#createAndAppendTimeSelector(
      "time",
      "$daySelector",
      "$timeSelector",
      this?.timeValue,
    );

    this.#createAndAppendTimeSelector(
      "second_time",
      "$secondDaySelector",
      "$secondTimeSelector",
      this?.secondTimeValue,
    );

    if (isSemiMonthly || isMonthly) {
      this.#createMonthHelpBlock();
    } else {
      this.#removeMonthHelpBlock();
    }
  }

  #setSelectorValue(selector, value, mapping, tern) {
    if (this[selector]) {
      this[selector].value = tern
        ? value
        : this.#DEFAULT_VALUE_MAPPING[mapping];
    }
  }

  #createSecondDaySelector() {
    if (!this.$secondDayTimeContainer) {
      this.$secondDayTimeContainer = document.createElement("div");
      this.$secondDayTimeContainer.setAttribute(
        "class",
        "day_time_container flex mt-2 gap-2",
      );
      this.#createDaySelector(
        "$secondDaySelector",
        "newsletter_sending_day[second_day]",
        "secondDaySelector",
      );
      this.$secondDayTimeContainer.appendChild(this.$secondDaySelector);
      this.$dayTimeContainer.after(this.$secondDayTimeContainer);
    } else {
      this.$secondDaySelector.innerHTML = "";
    }

    this.#createDaySelectorOptions("$secondDaySelector", "semimonthly");
  }

  #createDaySelector(selector, name, className) {
    this[selector] = document.createElement("select");
    this[selector].name = name;
    this[selector].classList.add(className, "mt-2");
  }

  #createAndAppendTimeSelector(name, parent, variable, value) {
    if (!this[variable] && this[parent]) {
      this[variable] = this.#createTimeSelector(name, value);
      this[parent].after(this[variable]);
    }
  }

  #createTimeSelector(name, selected) {
    const select = document.createElement("select");
    select.setAttribute("class", "time_selector max-w-max");
    select.setAttribute("name", `newsletter_sending_day[${name}]`);
    select.setAttribute("type", "select");

    ["am", "pm"].forEach((value) => {
      const option = this.#createTimeSelectorOption(value);
      select.appendChild(option);
    });

    select.value = selected || this.#DEFAULT_VALUE_MAPPING["time"];
    return select;
  }

  #createTimeSelectorOption(value) {
    const option = document.createElement("option");
    option.setAttribute("value", value);
    option.textContent = I18n.t(
      `js.administration.newsletter.settings.newsletter_interval.${value}`,
    );

    return option;
  }

  #createDaySelectorOptions(selector, mapping) {
    this.#SELECTOR_MAPPING[mapping].forEach((value) => {
      const opt = this.#createDaySelectorOption(value);

      this[selector].appendChild(opt);
    });
  }

  #createDaySelectorOption(value) {
    const opt = document.createElement("option");

    opt.value = value;
    opt.textContent = I18n.t(
      `administration.newsletter.settings.newsletter_interval.days.${
        toInteger(value) === 0 ? value : "day"
      }`,
      {
        day: value,
      },
    );

    return opt;
  }

  #createMonthHelpBlock() {
    if (!this.$monthHelpBlock) {
      const parentContainer = this.$secondDayTimeContainer
        ? this.$secondDayTimeContainer
        : this.$dayTimeContainer;

      this.$monthHelpBlock = document.createElement("div");
      this.$monthHelpBlock.classList.add("help-block", "mt-1", "mb-3");
      this.$monthHelpBlock.textContent = I18n.t(
        "administration.newsletter.settings.help_md",
      );

      parentContainer.after(this.$monthHelpBlock);
    }
  }

  #removeElement(element) {
    if (this[element]) {
      this[element].remove();
      this[element] = null;
    }
  }

  #removeSecondDaySelector() {
    this.#removeElement("$secondDaySelector");
    this.#removeElement("$secondTimeSelector");
    this.#removeElement("$secondDayTimeContainer");
  }

  #removeMonthHelpBlock() {
    this.#removeElement("$monthHelpBlock");
  }
}
