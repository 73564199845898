import { isUndefined, memoize, some } from "lodash";

const CHAT_STATIC_BREAKPOINT = 1884;

document.addEventListener(
  "click",
  function (e) {
    if (!(e.target instanceof HTMLElement || e.target instanceof SVGElement))
      return;

    // togglebar element clicked directly
    if (e.target?.dataset?.togglebar) {
      toggle(e.target.dataset.togglebar);
      return;
    }

    // togglebar child clicked check
    for (
      let target: Node | null = e.target;
      target && target !== document;
      target = target.parentNode
    ) {
      if (target instanceof HTMLButtonElement && target?.dataset?.togglebar) {
        toggle(target.dataset.togglebar);
        break;
      }
    }
  },
  false,
);

function coversWholeScreen(el: HTMLElement) {
  return window.getComputedStyle(el).width === `${window.innerWidth}px`;
}

function shouldRestoreBodyScrolling() {
  return !some(Array.from(openBarElements), coversWholeScreen);
}

const openBarElements = new Set();

function toggle(bar, show?: boolean) {
  const el = document.querySelector(`.tixxt__${bar}`);
  if (!(el instanceof HTMLElement)) return;

  const persist = el.dataset?.persist !== undefined;

  if (!el) {
    console.warn(`no .tixxt__${bar} element found to toggle`);
    return null;
  }

  if (isUndefined(show))
    show =
      !el.classList.contains(`${bar}--visible`) ||
      el.classList.contains("hidden");

  if (show) {
    if (el.classList.contains("hidden")) el.classList.remove("hidden");
    if (persist) sessionStorage.setItem(`${bar}Visible`, "true");

    el.classList.add(`${bar}--visible`);
    openBarElements.add(el);

    if (coversWholeScreen(el)) disableBodyScrolling(bar);
  } else {
    if (persist) sessionStorage.removeItem(`${bar}Visible`);

    el.classList.add(`${bar}--hiding`);

    openBarElements.delete(el);
    restoreBodyScrolling(bar);

    setTimeout(() => {
      el.classList.remove(`${bar}--hiding`, `${bar}--visible`);
    }, 500);
  }
}

const getResizeHandler = memoize((bar: string) => () => {
  const el = document.querySelector(`.tixxt__${bar}`);
  if (!(el instanceof HTMLElement)) return;

  if (!coversWholeScreen(el)) toggle(bar, false);
});

export function disableBodyScrolling(bar: string) {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";

  window.addEventListener("resize", getResizeHandler(bar));
}

export function restoreBodyScrolling(bar: string) {
  if (!shouldRestoreBodyScrolling()) return;

  const scrollY = document.body.style.top;
  document.body.style.removeProperty("overflow");
  document.body.style.removeProperty("position");
  document.body.style.removeProperty("top");
  window.scrollTo(0, parseInt(scrollY || "0") * -1);
  window.removeEventListener("resize", getResizeHandler(bar));
}

export function closeUserbar() {
  toggle("userbar", false);
}

/* show bar immediately if it was visible before */
export function restoreVisibility(bar: string, el: HTMLElement) {
  if (sessionStorage.getItem(`${bar}Visible`)) {
    el.classList.add(`${bar}--visible`);
  }
}

export function toggleChatbar() {
  if (window.innerWidth >= CHAT_STATIC_BREAKPOINT) {
    toggleStaticChat();
  } else {
    toggle("chatbar");
  }
}

function toggleStaticChat() {
  if (document.body.classList.contains("tixxt--with-chat")) {
    sessionStorage.setItem("chatbarStaticHidden", "true");
    hideStaticChat();
  } else {
    sessionStorage.removeItem("chatbarStaticHidden");
    document.body.classList.add("tixxt--with-chat");
    document.getElementById("chatbar-root")?.classList.remove("hidden");
  }
}

function hideStaticChat() {
  document.body.classList.remove("tixxt--with-chat");
  document.getElementById("chatbar-root")?.classList.add("hidden");
}

/* hide chat immediately if it was hidden before */
export function restoreStaticChat() {
  if (sessionStorage.getItem("chatbarStaticHidden")) {
    hideStaticChat();
  }
}
