import React from "react";

export type TextWidgetProps = {
  headline?: string;
  content: string;
};

const TextWidget: React.FC<TextWidgetProps> = ({ headline, content }) => (
  <div className="widget text-widget">
    {headline && <h2 className="widget-title">{headline}</h2>}
    <div
      className="prose prose-sm"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  </div>
);

export default TextWidget;
