import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { get, set, reject, findIndex } from "lodash";
import { fetchApi } from "helpers/reactQueryApi";
import { queryClient } from "helpers/queryClient";
import { stringify } from "query-string";

function fetchFilesForReviewStatus(groupSlug, page) {
  return fetchApi(
    `/api/storage/v2/files?${
      groupSlug
        ? `${stringify({
            context_id: groupSlug,
            context_type: "group",
            query: "reviewed",
            page: page,
          })}`
        : `${stringify({
            context_id: Tixxt.currentNetwork.id,
            context_type: "network",
            query: "reviewed",
            page: page,
          })}`
    }`,
    { parsePaginationHeaders: true },
  );
}

export function approvePendingFileVersion({ fileId, pendingVersionId }) {
  return fetchApi(
    `/api/storage/files/${fileId}/pending_versions/${pendingVersionId}/approve`,
    { method: "POST" },
  );
}
export function declinePendingFileVersion({ fileId, pendingVersionId }) {
  return fetchApi(
    `/api/storage/files/${fileId}/pending_versions/${pendingVersionId}`,
    { method: "DELETE" },
  );
}

export function useFilesVersionApproval(groupSlug) {
  return useQuery(["filesVersionApproval", groupSlug], () =>
    fetchApi(
      `/api/storage/folders/${
        groupSlug ? groupSlug : Tixxt.currentNetwork.rootFolder().id
      }/files/approval`,
    ),
  );
}

// that can directly be spread into <Pagination />
// fetcher must parsePaginationHeaders (i.e. return { payload, pagination: { totalPages }})
export function useFilesReviewStatus(groupSlug) {
  const [page, onPaginate] = useState(1);
  const { data, isFetching, ...rest } = useQuery(
    ["filesReviewStatus", groupSlug, page],
    () => fetchFilesForReviewStatus(groupSlug, page),
    {
      keepPreviousData: true,
    },
  );

  return {
    data: get(data, ["payload"]),
    pagination: {
      totalPages: get(data, ["pagination", "totalPages"]),
      page,
      loading: isFetching,
      onPaginate,
    },
    isFetching,
    ...rest,
  };
}

function removePendingFileVersionFromCache(
  _response,
  { groupSlug, fileId, pendingVersionId },
) {
  const files = queryClient.getQueryData(["filesVersionApproval", groupSlug]);
  const index = findIndex(files, { id: fileId });
  set(
    files,
    [index, "pending_versions"],
    reject(get(files, [index, "pending_versions"]), {
      id: pendingVersionId,
    }),
  );
  queryClient.setQueryData(["filesVersionApproval", groupSlug], files);
  queryClient.invalidateQueries("folderUnreads");
}

export function useApprovePendingFileVersion() {
  return useMutation(approvePendingFileVersion, {
    onSuccess: removePendingFileVersionFromCache,
  });
}
export function useDeclinePendingFileVersion() {
  return useMutation(declinePendingFileVersion, {
    onSuccess: removePendingFileVersionFromCache,
  });
}
