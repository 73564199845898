import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { omit, isEmpty, find } from "lodash";

import Text from "../appCreator/properties/show/Text";
import ValueSetTable from "../shared/propertySets/ValueSetTable";
import FolderContentsTable, {
  FolderContentsTableWithProfile,
} from "./table/FolderContentsTable";
import BatchActions from "./BatchActions";
import UrlContextProvider from "./UrlContextProvider";
import SelectableFileRow from "./table/SelectableFileRow";
import SelectableFolderRow from "./table/SelectableFolderRow";
import {
  selectFileProfilesEnabled,
  selectFolderProfilesEnabled,
} from "../../selectors/propertySets";
import EmbeddedSearchResults from "../globalSearch/EmbeddedSearchResults";
import FolderActions from "./FolderActions";
import { useFoldersUnreads } from "../../api/unreads";
import FullSizeContent from "../shared/FullSizeContent";
import useFullWidthLayout from "hooks/useFullWidthLayout";

function FolderListing({
  description,
  profile,
  loading,
  error,
  contents,
  sortBy,
  handleSort,
  onRemoveItems,
  id,
  namespace,
  parentFolderId,
  onMoveItems,
  contextUrl,
  type,
  can,
  name,
  total_files_count,
  total_subfolders_count,
  ...tableProps
}) {
  useFullWidthLayout();

  const [searchParams, setSearchParams] = useState(null);
  const folderProfilesEnabled = useSelector(selectFolderProfilesEnabled);
  const fileProfilesEnabled = useSelector(selectFileProfilesEnabled);
  const TableComponent =
    fileProfilesEnabled || folderProfilesEnabled
      ? FolderContentsTableWithProfile
      : FolderContentsTable;

  const groupSlug = Tixxt.currentContext.get("slug") || null;

  const { data: unreads } = useFoldersUnreads();

  const unreadsCount = find(unreads, {
    group_slug: groupSlug,
  })?.sum;

  return (
    <>
      {unreadsCount > 0 && (
        <div className="alert alert-info flex gap-2 items-center">
          {unreadsCount === 1
            ? I18n.t("js.files.approval.single.hint")
            : I18n.t("js.files.approval.hint", { count: unreadsCount })}
          <a
            className="btn btn-light"
            href={
              groupSlug !== null
                ? `/groups/${groupSlug}/files/approval`
                : "/files/approval"
            }
          >
            {I18n.t("js.files.approval.activation")}
          </a>
        </div>
      )}
      <UrlContextProvider contextUrl={contextUrl}>
        <FolderActions
          folderId={id}
          name={name}
          namespace={namespace}
          type={type}
          searchEnabled={folderProfilesEnabled}
          can={can}
          onRemoveItems={onRemoveItems}
          onMoveItems={onMoveItems}
          searchProps={{
            contextId: groupSlug,
            contextType: groupSlug ? "group" : "network",
            values: profile,
            showLoading: false,
            onSubmit: setSearchParams,
          }}
        />
        {(description || (folderProfilesEnabled && !isEmpty(profile))) && (
          <div className="border-box p-2 mb-4 text-sm">
            <div className="mb-4">
              <Text value={description} property={{ multiline: true }} />
            </div>
            {folderProfilesEnabled && (
              <ValueSetTable
                name="folder_profile"
                contextId={groupSlug}
                contextType={groupSlug ? "group" : "network"}
                values={profile}
                showLoading={false}
                options={{
                  listStyle: "flex gap-2",
                  listItemStyle: "flex after:content-['']",
                }}
              />
            )}
          </div>
        )}
        {searchParams ? (
          <EmbeddedSearchResults
            module="files"
            groupSlug={contextUrl.split("/")[2]}
            {...searchParams}
          />
        ) : (
          <BatchActions
            contents={contents}
            currentFolder={{ id, namespace, parentFolderId }}
            onRemoveItems={onRemoveItems}
            onMoveItems={onMoveItems}
          >
            {({
              selectedIds,
              onSelectItem,
              headerRef,
              selectAllCheckbox,
              rowProps,
            }) => (
              <FullSizeContent>
                {(style) => (
                  <div
                    style={style}
                    className={"overflow-auto w-full min-h-content"}
                  >
                    <TableComponent
                      contextId={groupSlug}
                      contextType={groupSlug ? "group" : "network"}
                      loading={loading}
                      error={error}
                      selectedIds={selectedIds}
                      onSelectItem={onSelectItem}
                      contents={contents}
                      total_files_count={total_files_count}
                      total_subfolders_count={total_subfolders_count}
                      sortBy={sortBy}
                      handleSort={handleSort}
                      headerRef={headerRef}
                      extraPreColumn={selectAllCheckbox}
                      FileRow={SelectableFileRow}
                      FolderRow={SelectableFolderRow}
                      rowProps={rowProps}
                      {...tableProps}
                    />
                  </div>
                )}
              </FullSizeContent>
            )}
          </BatchActions>
        )}
      </UrlContextProvider>
    </>
  );
}

FolderListing.propTypes = {
  ...omit(FolderContentsTableWithProfile.propTypes, [
    "contextId",
    "contextType",
  ]),
  description: PropTypes.string,
  profile: PropTypes.object,
  groupId: PropTypes.string,
  contextUrl: PropTypes.string,
  can: PropTypes.shape({
    destroy_contents: PropTypes.bool,
  }),
  total_files_count: PropTypes.number,
  total_subfolders_count: PropTypes.number,
};

export default FolderListing;
