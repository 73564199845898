import React from "react";
import { isEmpty } from "lodash";

export interface LinkAttachmentTypes {
  image?: string;
  title?: string;
  href?: string;
  text?: string;
}

function LinkAttachment({ image, title, href, text }: LinkAttachmentTypes) {
  return (
    <div className="activity-links attachments">
      <div className="activity-object activity-object-link clearfix grid grid-cols-6 ">
        {!isEmpty(image) ? (
          <div>
            <img src={image} alt={title} />
          </div>
        ) : null}
        <div
          className={isEmpty(image) ? "col-span-6 full-width " : "col-span-4 "}
        >
          {title && (
            <p className="link-title">
              <a rel="nofollow" href={href}>
                {title}
              </a>
            </p>
          )}
          {href && (
            <p>
              <a className="link-href" rel="nofollow" href={href}>
                {href}
              </a>
            </p>
          )}
          <p className="link-text">{text}</p>
        </div>
      </div>
    </div>
  );
}

export default LinkAttachment;
