import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "../globalSearch.module.css";

export default function Highlights({ highlights }) {
  const [index, setIndex] = useState(0);
  const highlight = highlights[index];
  const total = highlights.length;

  function nextHighlight(event) {
    event.preventDefault();
    setIndex(Math.min(index + 1, total - 1));
  }
  function prevHighlight(event) {
    event.preventDefault();
    setIndex(Math.max(index - 1, 0));
  }

  return (
    <div
      className={`Highlights ${styles.Highlights} flex gap-2 bg-gray-100 px-3 py-1 -mx-3 last:-mb-2 last:rounded-b`}
    >
      <div
        className="prose prose-sm grow"
        dangerouslySetInnerHTML={{ __html: highlight }}
      />
      <div className="flex text-sm shrink-0 gap-1">
        <div
          className="previous-container"
          style={{ opacity: index === 0 ? 0 : null }}
        >
          <a className="previous-hit" href="#" onClick={prevHighlight}>
            <i className="fa fa-arrow-left" aria-hidden="" />
          </a>
        </div>
        <span className="counter">{`${index + 1} / ${total}`}</span>
        <div
          className="next-container"
          style={{ opacity: index === total - 1 ? 0 : null }}
        >
          <a className="next-hit" href="#" onClick={nextHighlight}>
            <i className="fa fa-arrow-right" aria-hidden="" />
          </a>
        </div>
      </div>
    </div>
  );
}

Highlights.propTypes = {
  highlights: PropTypes.arrayOf(PropTypes.string).isRequired,
};
