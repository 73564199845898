import React from "react";
import MarkdownEditor from "./index";
import styles from "../../appCreator/properties/edit/editFields.module.css";
import FieldError from "../../appCreator/items/form/FieldError";

interface MarkdownEditorAdapterTypes {
  input: {
    onChange(...args: unknown[]): unknown;
    onFocus(...args: unknown[]): unknown;
    onBlur(...args: unknown[]): unknown;
    value: string;
    name: string;
  };
  meta: { error: string; initial: string };
}

function MarkdownEditorAdapter({ input, meta }: MarkdownEditorAdapterTypes) {
  return (
    <div className={`${styles.Text} property-${input.name}`}>
      <MarkdownEditor
        {...input}
        key={meta.initial} // Recreate MarkdownEditor component if the initialValue has changed
        content={input.value}
        onChange={input.onChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
      />
      {meta.error && <FieldError error={meta.error} />}
    </div>
  );
}

export default MarkdownEditorAdapter;
