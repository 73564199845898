import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="administration--invites--toggle-batch-actions"
export default class extends Controller {
  connect() {
    this.toggleCheckboxListener = () => this.toggle();
    this.checkboxes = this.element.querySelectorAll(
      'input[name="invite_select"]',
    );
    this.checkboxes.forEach((checkbox) =>
      checkbox.addEventListener("change", this.toggleCheckboxListener),
    );
    this.toggle();
  }

  disconnect() {
    this.checkboxes.forEach((checkbox) =>
      checkbox.removeEventListener("change", this.toggleCheckboxListener),
    );
  }

  toggle() {
    const anyChecked =
      this.element.querySelectorAll('input[name="invite_select"]:checked')
        .length > 0;

    this.element
      .querySelector(".batch_processing-actions")
      .classList.toggle("hidden", !anyChecked);

    this.toggleDisableButtons();
  }

  toggleDisableButtons() {
    const inviteStatus = this.getSelectedInviteStatus();

    const revokeable = inviteStatus.every((status) => status === "open");
    const renewable = inviteStatus.every(
      (status) => !["accepted", "obsolete", "joined"].includes(status),
    );

    const revokeButton = document.getElementById("revoke-invites");
    const renewButton = document.getElementById("renew-invites");

    if (revokeButton) {
      revokeButton.disabled = !revokeable;
    }

    if (renewButton) {
      renewButton.disabled = !renewable;
    }
  }

  getSelectedInviteStatus() {
    const selectedCheckboxes = this.element.querySelectorAll(
      'input[name="invite_select"]:checked',
    );

    return Array.from(selectedCheckboxes).map((checkbox) => checkbox.value);
  }
}
