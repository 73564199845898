import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

function ArchiveInfo({ archivedBy, archivedAt }) {
  return (
    <div className="card-archive-info">
      {I18n.t("js.apps.wall.archive_info", {
        date: moment(archivedAt).format("DD. MMMM YYYY"),
        time: moment(archivedAt).format("hh:mm"),
        person_name: archivedBy,
      })}
    </div>
  );
}
ArchiveInfo.propTypes = {
  archivedBy: PropTypes.string,
  archivedAt: PropTypes.string,
};
export default ArchiveInfo;
