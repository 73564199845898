import React, { useContext } from "react";
import Pagination from "components/shared/Pagination";
import { QueryParams } from "./SearchQueryResult";
import SearchQueryPaginationContext from "./SearchQueryPaginationContext";

export default function SearchResultPagination({
  total,
  per,
  loading,
}: Pick<QueryParams, "total" | "per" | "loading">) {
  const { setPaginationState, page, ...paginationState } = useContext(
    SearchQueryPaginationContext,
  );

  return (
    <Pagination
      page={page || 1}
      onPaginate={(page) => {
        setPaginationState({ ...paginationState, page });
      }}
      loading={loading}
      totalPages={Math.ceil(total / per)}
    />
  );
}
