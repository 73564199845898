import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";

import { getMessageById } from "../../../../selectors/chat/messages";

import MessageAuthor from "../ChatMessage/MessageAuthor";
import MessageText from "../ChatMessage/MessageText";

const MessageToQuote = ({ message, removeMessageToQuote }) =>
  !isEmpty(message) ? (
    <div
      className={"relative bg-white gap-2 px-2 py-2 border-b border-neutral"}
    >
      <div className="chat__quote rounded">
        <MessageAuthor id={message.from.id} name={message.from.name} />
        <MessageText text={message.text} />
      </div>
      <button
        className="btn btn-sm text-muted absolute top-2 right-2"
        onClick={removeMessageToQuote}
      >
        <i className="fa fa-times" />
      </button>
    </div>
  ) : null;
MessageToQuote.propTypes = {
  id: PropTypes.string,
  chatId: PropTypes.string,
  message: PropTypes.shape({}),
  removeMessageToQuote: PropTypes.func,
};

export default connect(
  (state, { id, chatId }) => ({
    message: getMessageById(state, { id, chatId }),
  }),
  (dispatch, { chatId }) => ({
    removeMessageToQuote: () =>
      dispatch({ type: "chat/COMPOSER/SET_MESSAGE_TO_QUOTE", id: "", chatId }),
  }),
)(MessageToQuote);
