import React, { useEffect } from "react";
import classNames from "classnames";
import useTooltip from "../../hooks/useTooltip";
import { throttle } from "lodash";
import Tooltip from "bootstrap/js/dist/tooltip";
import UnreadBadge from "components/shared/UnreadBadge";
import { closeUserbar } from "../../layout/togglebar";

function ModuleSwitcher({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return (
    <ul className={classNames(className, "module-switcher")}>{children}</ul>
  );
}

function toggleTooltips(tooltipRef: React.RefObject<HTMLElement>) {
  if (!tooltipRef.current) return;

  const titleElem = tooltipRef.current?.getElementsByClassName(
    "module-switcher-module__title",
  )[0];
  if (!titleElem) return;

  if (window.getComputedStyle(titleElem).display === "none") {
    Tooltip.getInstance(tooltipRef.current)?.enable();
  } else {
    Tooltip.getInstance(tooltipRef.current)?.disable();
  }
}

function useTooltipsIfTitlesAreHidden() {
  const tooltipRef = useTooltip<HTMLLIElement>();

  useEffect(() => {
    const throttledToggleTooltips = throttle(
      () => toggleTooltips(tooltipRef),
      500,
    );
    window.addEventListener("resize", throttledToggleTooltips);
    throttledToggleTooltips();

    return () => window.removeEventListener("resize", throttledToggleTooltips);
  }, [tooltipRef.current]);

  return tooltipRef;
}

function Module({
  title,
  icon,
  active,
  url,
  notifications,
  className,
}: {
  title: string;
  icon: React.ReactNode;
  active?: boolean;
  url: string;
  className?: string;
  notifications?: number;
}) {
  const tooltipRef = useTooltipsIfTitlesAreHidden();

  return (
    <li
      className={className}
      data-bs-toggle="tooltip"
      title={title}
      data-bs-placement="right"
      ref={tooltipRef}
    >
      <a
        title={title}
        href={url}
        className={classNames("module-switcher__module relative", {
          "module-switcher__module--active": active,
        })}
        onClick={closeUserbar}
      >
        <UnreadBadge count={notifications} className="absolute right-1 top-1" />
        <span className="module-switcher-module__icon">{icon}</span>
        <span className="module-switcher-module__title truncate">{title}</span>
      </a>
    </li>
  );
}

ModuleSwitcher.Module = Module;
export default ModuleSwitcher;
