import React from "react";
import PropTypes from "prop-types";
import { map, uniq } from "lodash";

import DocumentActions from "./DocumentActions";
import excel from "./wopi_icons/excel_16x2.png";
import word from "./wopi_icons/word_16x2.png";
import powerpoint from "./wopi_icons/powerpoint_16x2.png";
import visio from "./wopi_icons/visio_16x2.png";
import office from "./wopi_icons/Office-color_32x32x32.png";

const ICONS = {
  excel,
  word,
  powerpoint,
  visio,
  default: office,
};

export default function WopiActions({ fileId, locked, finalized }) {
  return (
    <DocumentActions
      type="wopi"
      fileId={fileId}
      locked={locked}
      finalized={finalized}
      renderSuptext={(authorizedActions) =>
        I18n.t("js.files.show.toolbar.wopi.suptext", {
          app: uniq(map(authorizedActions, "app")).join("/"),
        })
      }
      renderIcon={(app) => (
        <img src={ICONS[app.toLowerCase()] || ICONS.default} alt={app} />
      )}
    />
  );
}
WopiActions.propTypes = {
  fileId: PropTypes.string.isRequired,
  locked: PropTypes.bool,
};
