import React from "react";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compose, withHandlers, lifecycle, toClass } from "recompose";
import { map } from "lodash";

import preventDefault from "helpers/components/preventDefault";
import WidgetTypes from "components/administration/widgetbar/WidgetTypes";
import WidgetbarLayout from "components/administration/widgetbar/WidgetbarLayout";
import WidgetPropertiesLayout from "components/administration/widgetbar/WidgetPropertiesLayout";
import NotFound from "components/shared/errors/NotFound";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  fetchWidgetbar,
  fetchCalendars,
  updateWidgetbar,
  setGroupId,
  resetSuccessfulSave,
  resetErrors,
  setIdentifier,
} from "actions/administration/widgetbar";
import {
  getWidgets,
  getWidgetbarLoadingState,
  getWidgetbarSuccessfulSave,
  getWidgetbarErrors,
} from "helpers/selectors/widgetbarSelectors";
import classnames from "classnames";

function WidgetbarForm({ onSubmit, loading, errors, groupId }) {
  if (loading) {
    return (
      <div>
        <i className="fa fa-spinner fa-spin" />
        {I18n.t("js.administration.widgetbars.loading")}
      </div>
    );
  } else if (errors["notFound"]) {
    return <NotFound />;
  } else if (errors["fetchWidgetbar"]) {
    return <div>{I18n.t("js.administration.widgetbars.save_error")}</div>;
  } else {
    return (
      <form onSubmit={onSubmit} autoComplete="off">
        <div
          className={classnames("btn-toolbar", {
            "justify-end": groupId,
          })}
        >
          {groupId ? null : (
            <div className="btn-group">
              <a className="btn btn-light" href="/administration/widgetbars">
                <FontAwesomeIcon
                  className="text-primary"
                  icon={solid("arrow-left")}
                />
              </a>
            </div>
          )}
          <button type="submit" className="btn btn-primary">
            {I18n.t("js.save")}
          </button>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
          <WidgetTypes />
          <WidgetbarLayout />
          <WidgetPropertiesLayout />
        </div>
      </form>
    );
  }
}

WidgetbarForm.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  errors: PropTypes.object,
};

export default compose(
  connect(
    (state) => ({
      widgets: getWidgets(state),
      loading: getWidgetbarLoadingState(state),
      saveSuccessful: getWidgetbarSuccessfulSave(state),
      errors: getWidgetbarErrors(state),
    }),
    (dispatch, { groupId, identifier }) => ({
      fetchWidgetbar: () =>
        dispatch(
          fetchWidgetbar({
            groupId,
            identifier,
          }),
        ),
      fetchCalendars: () =>
        dispatch(
          fetchCalendars({
            groupId,
            identifier,
          }),
        ),
      setGroupId: () => dispatch(setGroupId({ groupId })),
      setIdentifier: () => dispatch(setIdentifier(identifier)),
      submit: (data) => dispatch(updateWidgetbar(data)),
      resetSuccessfulSave: () => dispatch(resetSuccessfulSave()),
      resetErrors: () => dispatch(resetErrors()),
    }),
  ),
  withHandlers({
    onSubmit: ({ submit, widgets, groupId, identifier }) =>
      preventDefault(() => {
        submit({
          groupId,
          identifier,
          body: {
            widgetbar: {
              widgets: map(widgets, (w) => {
                // backend need the string as a boolean
                if (w.properties.show_age) {
                  return {
                    ...w,
                    properties: {
                      ...w.properties,
                      show_age: w.properties.show_age === "true",
                    },
                  };
                } else if (w.properties.count) {
                  return {
                    ...w,
                    properties: {
                      ...w.properties,
                      count: parseInt(w.properties.count, 10),
                    },
                  };
                }
                return w;
              }),
            },
          },
        });
      }),
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetchWidgetbar();
      this.props.setIdentifier();
      this.props.fetchCalendars();
      this.props.setGroupId();
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (nextProps.saveSuccessful) {
        toastr.success(I18n.t("js.administration.widgetbars.save_success"));
        this.props.resetSuccessfulSave();
      } else if (nextProps.errors.updateWidgetbar) {
        toastr.error(I18n.t("js.administration.widgetbars.save_error"));
        this.props.resetErrors();
      } else if (nextProps.errors.fetchWidgetbar) {
        toastr.error(I18n.t("js.administration.widgetbars.load_error"));
        if (!nextProps.errors.notFound) {
          this.props.resetErrors();
        }
      }
    },
  }),
  DragDropContext(HTML5Backend),
  toClass,
)(WidgetbarForm);
