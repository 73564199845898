import React, { useState } from "react";
import { isEmpty, map } from "lodash";
import StatusButton from "./StatusButton";
import {
  showNameWithProfileField,
  ExternalMemberBadge,
} from "components/memberships/MemberNameWithAdditions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRecurringAppointment } from "../../../hooks/useRecurringAppointment";
import {
  useDeleteParticipant,
  useUpdateParticipation,
} from "components/appointments/participants/api";
import { Membership } from "../../../@types";
import DropDown, { DropDownItem } from "components/shared/DropDown";
import RecurringAppointmentDecisionModal from "components/shared/RecurringAppointmentDecisionModal";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const PARTICIPANT_STATUS_LOOKUP = {
  open: { icon: regular("circle-half-stroke"), className: "muted" },
  accepted: { icon: solid("circle"), className: "text-success" },
  declined: { icon: regular("circle"), className: "text-danger" },
};

type ParticipantRowArgs = {
  id: string;
  appointmentId: string;
  membership: Membership;
  status: "accepted" | "open" | "declined" | "invited";
  reloadAppointment: () => void;
};

export default function ParticipantRow({
  membership,
  status,
  id,
  appointmentId,
  reloadAppointment,
}: ParticipantRowArgs) {
  const [showModal, setShowModal] = useState(false);
  const [newStatus, setNewStatus] = useState<string | null>(null);

  const { mutate: updateParticipation, isLoading } = useUpdateParticipation(
    id,
    appointmentId,
    {
      onSuccess: () => {
        reloadAppointment();
      },
    },
  );
  const { mutate: deleteParticipant } = useDeleteParticipant(
    id,
    appointmentId,
    {
      onSuccess: () => {
        reloadAppointment();
      },
    },
  );

  const displayModal = useRecurringAppointment(appointmentId);

  function handleDecision(applyTo: string) {
    if (applyTo) {
      updateParticipation({
        participant: { status: newStatus },
        apply_to: applyTo,
      });
    }
    handleCloseModal();
  }

  function handleCloseModal() {
    setNewStatus(null);
    setShowModal(false);
  }

  function onChangeParticipantStatus(status: string) {
    if (displayModal) {
      setNewStatus(status);
      setShowModal(true);
    } else {
      setNewStatus(status);
      updateParticipation({ participant: { status } });
    }
  }

  function onRemoveParticipant(applyTo: string) {
    window?.bridge?.confirm(
      I18n.t("js.calendars.appointment.remove_participant.confirm_hint"),
      () => deleteParticipant({ apply_to: applyTo }),
    );
  }

  return (
    <tr className="participant-row text-sm">
      <td className="participant-info p-2">
        <span className="flex flex-row gap-2 items-center">
          <img
            src={
              !isEmpty(membership.images)
                ? membership.images.small
                : Assets["profile_25x25.jpg"]
            }
            className="participant-membership-image rounded-full w-7 h-7"
          />
          <a href={membership.path}>
            {showNameWithProfileField({
              name: membership.name,
              detectionProfileField: membership.detection_profile_field,
            })}
          </a>
          {membership.is_external ? <ExternalMemberBadge /> : null}
        </span>
      </td>
      <td className="participant-status p-2">
        <span className="flex flex-row gap-2 items-baseline">
          <FontAwesomeIcon
            icon={PARTICIPANT_STATUS_LOOKUP[status]?.icon}
            className={`participant-status-icon ${PARTICIPANT_STATUS_LOOKUP[status]?.className}`}
          />
          {I18n.t(
            `js.calendars.appointment.participant_status.${
              status === "invited" ? "open" : status
            }`,
          )}
        </span>
      </td>
      <td className="participant-status-interactions  p-2">
        <div className="btn-group">
          {map(["open", "accepted", "declined"], (state) => (
            <StatusButton
              key={state}
              changeStatus={() => onChangeParticipantStatus(state)}
              icon={PARTICIPANT_STATUS_LOOKUP[state].icon}
              isActive={
                state == "open"
                  ? status === state || status === "invited"
                  : status === state
              }
              className={PARTICIPANT_STATUS_LOOKUP[state]?.className}
              isLoading={
                state == "open"
                  ? isLoading &&
                    (newStatus === state || newStatus === "invited")
                  : isLoading && newStatus === state
              }
            />
          ))}
        </div>
      </td>
      <td className="remove-participant-button  p-2">
        <div className="btn-group pull-right">
          <button
            className="btn btn-light btn-sm dropdown-toggle"
            data-bs-toggle="dropdown"
          >
            <FontAwesomeIcon icon={solid("ellipsis-h")} className="" />
          </button>
          <DropDown className="context-menu dropdown-menu">
            <DropDownItem
              text={I18n.t("js.calendars.appointment.remove_participant.title")}
              onSelect={() => onRemoveParticipant("this")}
            />
            {/^r-/.test(appointmentId) && (
              <DropDownItem
                text={I18n.t(
                  "js.calendars.appointment.remove_participant_from_recurring.title",
                )}
                onSelect={() => onRemoveParticipant("this")}
              />
            )}
          </DropDown>
        </div>
      </td>
      {showModal && (
        <RecurringAppointmentDecisionModal
          showModal={showModal}
          onClose={handleCloseModal}
          handleDecision={handleDecision}
        />
      )}
    </tr>
  );
}
