import PropTypes from "prop-types";
import React, { useState } from "react";
import { map } from "lodash";

import PropertiesReference from "./PropertiesReference";

function SingleEmailIntegration({
  properties,
  integration,
  onChange,
  onRemove,
}) {
  const [subject, setSubject] = useState(integration.email_subject_template);
  const [text, setText] = useState(integration.email_text_template);

  return (
    <div className="mt-4 border-box p-3">
      <div className="flex justify-end mb-2">
        <a
          href="#"
          className="btn btn-danger btn-sm"
          onClick={(e) => {
            e.preventDefault();
            onRemove();
          }}
        >
          <i className="fa-regular fa-trash mr-1" />
          {I18n.t(
            "js.administration.app_creator.app.integrations.email_notifications.delete_integration",
          )}
        </a>
      </div>
      <div className="row-fluid">
        <div className="span12 form-horizontal">
          <div className="control-group">
            <label
              htmlFor="app-integration-item-owner-property"
              className="control-label"
            >
              {I18n.t(
                "js.administration.app_creator.app.integrations.email_notifications.assignee_property",
              )}
            </label>
            <div className="controls">
              <select
                className="span12"
                value={integration.assignee_property_id}
                onChange={(e) => {
                  e.preventDefault();
                  onChange({
                    ...integration,
                    assignee_property_id: e.target.value,
                  });
                }}
              >
                <option value={-1}>
                  {I18n.t(
                    "js.administration.app_creator.app.relation.no_selection",
                  )}
                </option>
                {map(properties, (prop) =>
                  prop.type === "membership" ? (
                    <option key={prop.name} value={prop.name}>
                      {prop.label}
                    </option>
                  ) : null,
                )}
              </select>
            </div>
          </div>

          <div className="control-group">
            <label
              htmlFor="app-integration-item-owner-trigger"
              className="control-label"
            >
              {I18n.t(
                "js.administration.app_creator.app.integrations.email_notifications.trigger",
              )}
            </label>
            <div className="controls">
              <select
                className="span12"
                value={integration.trigger}
                onChange={(e) => {
                  e.preventDefault();
                  onChange({
                    ...integration,
                    trigger: e.target.value,
                  });
                }}
              >
                <option value="assign">
                  {I18n.t(
                    "js.administration.app_creator.app.integrations.email_notifications.trigger_assign",
                  )}
                </option>
                <option value="unassign">
                  {I18n.t(
                    "js.administration.app_creator.app.integrations.email_notifications.trigger_unassign",
                  )}
                </option>
              </select>
            </div>
          </div>

          <div className="control-group">
            <label
              className="control-label"
              htmlFor="app-integration-email-subject"
            >
              {I18n.t(
                "js.administration.app_creator.app.integrations.email_notifications.subject",
              )}
            </label>
            <div className="controls">
              <input
                className="span12"
                id="app-integration-email-subject"
                type="text"
                value={subject}
                onChange={(e) => {
                  e.preventDefault();
                  setSubject(e.target.value);

                  onChange({
                    ...integration,
                    email_subject_template: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="control-group">
            <label
              className="control-label"
              htmlFor="app-integration-email-body"
            >
              {I18n.t(
                "js.administration.app_creator.app.integrations.email_notifications.text",
              )}
            </label>
            <div className="controls">
              <textarea
                className="span12"
                id="app-integration-email-body"
                rows={5}
                value={text}
                onChange={(e) => {
                  e.preventDefault();
                  setText(e.target.value);

                  onChange({
                    ...integration,
                    email_text_template: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SingleEmailIntegration.propTypes = {
  properties: PropertiesReference.propTypes.properties,
  integration: PropTypes.shape({
    type: PropTypes.string,
    trigger: PropTypes.string,
    assignee_property_id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    email_subject_template: PropTypes.string,
    email_text_template: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
};

export default SingleEmailIntegration;
