import type { TreeItem } from "react-sortable-tree";
import React, { Component } from "react";
import SortableTree, {
  changeNodeAtPath,
  addNodeUnderParent,
  removeNodeAtPath,
  getNodeAtPath,
  walk,
} from "react-sortable-tree";
import { isEqual, isEmpty } from "lodash";
import classNames from "classnames";

import "react-sortable-tree/style.css";
import NavigationManagerForm from "./navigationManager/NavigationManagerForm";
import ItemButton from "./navigationManager/ItemButton";
import Flash from "../shared/Flash";
import PageTitle from "../layout/PageTitle";
import GroupNavigation from "../layout/GroupNavigation";
import "./navigationManager.css";
import { queryClient } from "helpers/queryClient";

const getNodeKey = ({ treeIndex }) => treeIndex;

const countAllNodes = (treeData) => {
  let sum = 0;
  walk({
    treeData,
    getNodeKey,
    callback: () => {
      sum = sum + 1;
    },
  });
  return sum;
};

// Validation of empty item-name and url fields in data-object (see state.treeData). Used in:
// - "isValidTree" method
// - "generateNodeProps" method in SortableTree component to set css-class of item with empty field
const isValidNode = (node) => {
  if (isEmpty(node.text)) return false;
  if (isEmpty(node.link) && isEmpty(node.children)) return false;
  return true;
};

// Method returns true if every node in the tree is valid
// Used in "sendData" method as validation before data sending
const isValidTree = (treeData) => {
  const result: Array<string> = [];
  walk({
    treeData,
    getNodeKey,
    ignoreCollapsed: false,
    callback: ({ node, path }) => {
      if (!isValidNode(node)) result.push(path);
    },
  });
  return isEmpty(result);
};

// Validation of drop possibility. Maximal depth of Tree Data is 2,
// means: main-parent-item + sub-children-items (sub-children-items cannot have children)
const canDrop = ({ node, nextPath }) => {
  if (nextPath.length > 2) return false;
  if (node.children.length > 0 && nextPath.length > 1) return false;
  return true;
};

const reloadNavbar = (groupSlug: null | string) => {
  queryClient.invalidateQueries(["groupNavigationItems", groupSlug]);
};

const navigationItemsUrl = ({ groupSlug }) =>
  groupSlug ? `/groups/${groupSlug}/administration` : "/administration";

type State = {
  treeData: Array<TreeItem>;
  activePath: Array<number | string>;
  apps: Array<unknown>;
  pages: Array<unknown>;
  isLoading: boolean;
  loadError: boolean;
  isSaving: boolean;
  saveError: boolean;
  saveSuccess: boolean;
};

interface NavigationManagerTypes {
  groupSlug: string | null;
}

class NavigationManager extends Component<NavigationManagerTypes> {
  treeRef: React.RefObject<HTMLDivElement>;

  constructor(props: NavigationManagerTypes) {
    super(props);

    this.state = {
      treeData: [],
      activePath: [],
      apps: [],
      pages: [],
      isLoading: false,
      loadError: false,
      isSaving: false,
      saveError: false,
      saveSuccess: false,
    };

    this.treeRef = React.createRef();
  }

  onChangeTree = (treeData) => this.setState({ treeData });

  onMoveNode = (event) => {
    this.setState({ activePath: event.path });
  };

  onChangeActiveNode = (newNode) =>
    this.setState(({ activePath, treeData }: State) => ({
      treeData: changeNodeAtPath({
        treeData,
        path: activePath,
        getNodeKey,
        newNode,
      }),
    }));

  // Method sends a request with data-object (see state.treeData) to backend,
  // reloads site when catches no errors.
  // Has also a validation of empty fields.
  sendData = async () => {
    this.setState({ isSaving: true, saveError: false, saveSuccess: false });

    if (!isValidTree((this.state as State).treeData)) {
      toastr.error(
        I18n.t("js.administration.navigation_items.edit.message_empty_fields"),
      );
      this.setState({
        isSaving: false,
      });
      return;
    }

    try {
      const response = await fetch(
        `${navigationItemsUrl(this.props)}/navigation`,
        {
          method: "PUT",
          body: JSON.stringify({
            navigation_items: this.serializeTree(
              (this.state as State).treeData,
            ),
          }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        },
      );
      const responseData = await response.json();

      this.setState({
        treeData: responseData.map(this.parseNavigationItems),
        isSaving: false,
        saveSuccess: true,
      });
      reloadNavbar(this.props.groupSlug);
      // groupSlug ? reloadGroupNavbar(groupSlug) : null;
    } catch (error) {
      console.error(error);
      this.setState({
        isSaving: false,
        saveError: true,
      });
    }
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const navResponse = await fetch(
        `${navigationItemsUrl(this.props)}/navigation.json`,
        { credentials: "include" },
      );
      const navData = await navResponse.json();
      const pagesResponse = await fetch(
        `${navigationItemsUrl(this.props)}/pages.json`,
        { credentials: "include" },
      );
      const pagesData = await pagesResponse.json();
      const appsResponse = await fetch(
        `${navigationItemsUrl(this.props)}/apps.json`,
        { credentials: "include" },
      );
      const appsData = await appsResponse.json();
      const { groupSlug } = this.props;
      this.setState({
        treeData: navData.map(this.parseNavigationItems),
        pages: pagesData.map((item) => ({
          label: item.name,
          link: groupSlug
            ? `/groups/${groupSlug}/pages/${item.slug}`
            : `/pages/${item.slug}`,
        })),
        apps:
          appsResponse.status == 200
            ? appsData.map((item) => ({
                label: item.name,
                link: groupSlug
                  ? `/groups/${groupSlug}/apps/${item.slug}`
                  : `/apps/${item.slug}`,
              }))
            : [],
        isLoading: false,
      });
    } catch (error) {
      console.error(error);
      this.setState({
        isLoading: false,
        loadError: true,
      });
    }
  }

  // Method adapts keys in data-object received from backend to data-object processed in "SortableTree" component.
  // Adds new option key.
  // Used by component mounting in lifecycle method "componentDidMount"
  // and sending data in "sendData" method.
  parseNavigationItems = (data) => {
    data.expanded = true;

    if (!isEmpty(data.children)) data.link = "";
    if (data.navigation_items) {
      data.children = data.navigation_items.map(this.parseNavigationItems);
      delete data.navigation_items;
    }

    return data;
  };

  // Method adapts keys in data-object (see state.treeData) to data-object processed in backend.
  // Used by sending data in "sendData" method.
  serializeTree = (treeData) => treeData.map(this.serializeNode);

  serializeNode = ({ text, link, public: isPublic, new_window, children }) => {
    const result = { text, link, public: isPublic, new_window };
    if (children && children.length > 0) {
      result.link = "#";
      Reflect.set(result, "navigation_items", children.map(this.serializeNode));
    }
    return result;
  };

  getActiveNode = () => {
    const node = getNodeAtPath({
      treeData: (this.state as State).treeData,
      path: (this.state as State).activePath,
      getNodeKey,
    });
    return node ? node.node : null;
  };

  buildNewNode = () => ({
    text: I18n.t("js.administration.navigation_items.default_text"),
    link: "",
    option: "link",
    new_window: false,
    public: false,
    children: [],
  });

  // Method adds new node in tree data. Used as onClick-handler in "itemButtons" method, where adds new nodes as sub-items (children).
  // "Add new item" blue button (see in browser on the bottom of "Tree") uses also this method as onClick-handler.
  // In this case method calls the function "scrollToBottom"
  addNodeAt = (path) => (e) => {
    e.stopPropagation();

    const { treeData, treeIndex } = addNodeUnderParent({
      treeData: (this.state as State).treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey,
      newNode: this.buildNewNode(),
      addAsFirstChild: false,
    });

    // Select nodeIndex under current parent or last node without parent
    const activePath =
      path.length > 0
        ? path.concat([treeIndex])
        : [countAllNodes(treeData) - 1];

    this.setState({
      treeData,
      activePath,
    });
    if (isEmpty(path)) this.scrollToBottom();
  };

  removeItemAt = (path) => (e) => {
    e.stopPropagation();

    this.setState((state: State) => ({
      treeData: removeNodeAtPath({
        treeData: state.treeData,
        path,
        getNodeKey,
      }),
    }));
  };

  // Method shows specific buttons on every items.
  // On parent-items: "add" and "remove" buttons, on child-items only "remove" buttons.
  itemButtons(path) {
    const buttons: Array<React.ReactNode> = [];

    if (path.length <= 1) {
      buttons.push(
        <ItemButton
          onClick={this.addNodeAt(path)}
          icon="fa fa-plus"
          buttonClass="btn-primary add-navigation-child-button"
        />,
      );
    }

    buttons.push(
      <ItemButton
        onClick={this.removeItemAt(path)}
        icon="fa-regular fa-trash"
        buttonClass="btn-danger"
      />,
    );

    return buttons;
  }

  scrollToBottom = () => {
    window.scrollTo({
      top: this.treeRef.current?.clientHeight,
      behavior: "smooth",
    });
  };

  render() {
    const items = {
      apps: (this.state as State).apps,
      pages: (this.state as State).pages,
    };
    const {
      saveSuccess,
      isLoading,
      loadError,
      saveError,
      treeData,
      activePath,
      isSaving,
    } = this.state as State;
    const { groupSlug } = this.props;
    return (
      <div>
        <PageTitle
          title={I18n.t("js.administration.navigation_items.page_title")}
        />

        <div className="btn-toolbar justify-end mb-4">
          <button
            className="btn btn-success"
            type="submit"
            onClick={this.sendData}
            disabled={isSaving}
          >
            {I18n.t("js.administration.navigation_items.edit.save")}
          </button>
        </div>
        {groupSlug ? <GroupNavigation groupSlug={groupSlug} /> : null}
        {saveSuccess ? (
          <Flash alert="success">
            {I18n.t("js.administration.navigation_items.edit.success")}
          </Flash>
        ) : null}
        {saveError ? (
          <Flash alert="error">
            {I18n.t("js.administration.navigation_items.edit.error")}
          </Flash>
        ) : null}
        <div className="row">
          <div className="navigation-manager">
            {isLoading ? (
              <p className="message">
                {I18n.t("js.administration.navigation_items.loading")}
              </p>
            ) : loadError ? (
              <p className="message error-message">
                {I18n.t(
                  "js.administration.navigation_items.error_while_loading",
                )}
              </p>
            ) : null}
            <div className="navigation-column" ref={this.treeRef}>
              {!isEmpty(treeData) ? (
                <SortableTree
                  treeData={treeData}
                  isVirtualized={false}
                  maxDepth={2}
                  canDrop={canDrop}
                  onChange={this.onChangeTree}
                  onMoveNode={this.onMoveNode}
                  generateNodeProps={({ node, path }) => ({
                    onClick: () => {
                      this.setState({
                        activePath: path,
                      });
                    },
                    className: classNames(
                      {
                        "selected move-item": isEqual(activePath, path),
                        "not-empty-field": isValidNode(node) || isSaving,
                        "empty-field": !isValidNode(node) && !isSaving,
                      },
                      "content-item",
                    ),
                    title: node.text,
                    buttons: this.itemButtons(path),
                  })}
                />
              ) : null}
              {!isLoading && !loadError ? (
                <button
                  className="btn btn-primary add-new-item-button"
                  onClick={this.addNodeAt([])}
                >
                  <i className="fa fa-plus" />
                  {I18n.t("js.administration.navigation_items.edit.add_link")}
                </button>
              ) : null}
            </div>
            <div className="input-column">
              {!isEmpty(activePath) ? (
                <NavigationManagerForm
                  node={this.getActiveNode()}
                  groupSlug={groupSlug}
                  items={items}
                  onChangeNode={this.onChangeActiveNode}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NavigationManager;
