import React, { useEffect, useState } from "react";
import { filter, map, some } from "lodash";

import Flash from "components/shared/Flash";
import { Category } from "../../../../@types/appointmentCategories";
import CategoryRow from "components/administration/appointments/categories/CategoryRow";
import InfoText from "components/shared/InfoText";

function CategoriesEditor({ categories }: { categories: Category[] }) {
  const [selectedCategories, setSelectedCategories] = useState(categories);
  const [text, setText] = useState("");
  const [error, setError] = useState("");

  // set value to hidden input in administration appointment settings
  useEffect(() => {
    document.getElementsByName("custom_categories")[0].setAttribute(
      "value",
      JSON.stringify(
        map(selectedCategories, (cat) => {
          // if category is new remove the id key
          if (cat.id === cat.label) {
            return { label: cat.label };
          } else {
            return cat;
          }
        }),
      ),
    );
  }, [selectedCategories]);

  function addCategory() {
    if (some(selectedCategories, { label: text })) {
      setError(
        I18n.t(
          "js.administration.appointment_settings.edit.duplicated_category",
        ),
      );
    } else {
      setSelectedCategories([...selectedCategories, { id: text, label: text }]);
      setText("");
      setError("");
    }
  }

  function changeCategory(id: string, label: string) {
    const changedCategories = map(selectedCategories, (cat) => {
      if (cat.id === id) {
        return { ...cat, id: cat.id === cat.label ? label : cat.id, label };
      } else {
        return cat;
      }
    });

    setSelectedCategories(changedCategories);
  }

  function removeCategory(id: string) {
    let changedCategories;

    if (some(categories, { id })) {
      changedCategories = map(selectedCategories, (cat) => {
        if (cat.id === id) {
          return { ...cat, _destroy: true };
        } else {
          return cat;
        }
      });
    } else {
      changedCategories = filter(selectedCategories, (cat) => cat.id !== id);
    }

    setSelectedCategories(changedCategories);
  }

  return (
    <>
      {error !== "" ? (
        <Flash alert="error" rootId="legacy-root">
          {error}
        </Flash>
      ) : null}
      <div className="mt-4">
        <InfoText
          translation={
            "js.networks.appointment_settings.custom_categories.help"
          }
          small
        />
        <ul className="custom-categories divide-y divide-neutral mb-4">
          {map(
            filter(selectedCategories, (cat) => !cat._destroy),
            (category) => (
              <CategoryRow
                key={category.id}
                category={category}
                changeCategory={changeCategory}
                removeCategory={removeCategory}
              />
            ),
          )}
        </ul>
        <div className="input-group">
          <input
            type="text"
            placeholder={I18n.t(
              "js.networks.appointment_settings.custom_categories.new_category",
            )}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <button
            className="btn btn-light"
            onClick={(e) => {
              e.preventDefault();
              addCategory();
            }}
            disabled={text === ""}
          >
            {I18n.t(
              "js.networks.appointment_settings.custom_categories.add_category",
            )}
          </button>
        </div>
      </div>
    </>
  );
}

export default CategoriesEditor;
