import ProfileOnboarding from "./ProfileOnboarding";
import { RouteObject } from "helpers/tixxt-router";
import React from "react";

const onboardingRoutes: RouteObject[] = [
  {
    path: "/onboarding/profile",
    element: <ProfileOnboarding />,
    forceTurbo: true,
  },
];

export default onboardingRoutes;
