import React, { useState } from "react";
import { map, filter, includes, without, concat, isEmpty } from "lodash";
import classNames from "classnames";

import ParticipantRow from "./ParticipantRow";
import { Participant } from "../../../@types/appointments";

type ParticipantsOverviewArgs = {
  participants: Participant[];
  appointmentId: string;
  reloadAppointment: () => void;
};

export default function ParticipantsOverview({
  participants,
  appointmentId,
  reloadAppointment,
}: ParticipantsOverviewArgs) {
  const [searchText, setSearchText] = useState("");
  const [activeFilter, setActiveFilter] = useState<string[]>([]);
  const regex = new RegExp(`(${searchText})`, "gi");

  const filteredParticipants: Participant[] = filter(
    participants,
    (participant: Participant) =>
      !isEmpty(participant.membership.name.match(regex)) &&
      (includes(activeFilter, participant.status) || isEmpty(activeFilter)),
  );

  function onSearch(event: { target: { value: string } }) {
    setSearchText(event.target.value);
  }

  function changeActiveFilter(status: string) {
    setActiveFilter(
      includes(activeFilter, status)
        ? without(activeFilter, status)
        : concat(activeFilter, status),
    );
  }

  return (
    <div className="participants-overview">
      <div className="participants-search btn-toolbar">
        <input
          className="participants-search-input border-box p-2 w-80 text-sm"
          onChange={onSearch}
          placeholder={I18n.t("js.calendars.appointment.search_participants")}
        />
        <div className="participants-status-filter flex flex-row gap-2 items-center flex-wrap">
          <div className="flex flex-row gap-2 items-baseline flex-wrap">
            <ul className="filter-bar border-none nav nav-pills unshim">
              {map(["open", "accepted", "declined"], (status) => (
                <li
                  className={classNames("nav-item", {
                    active: includes(activeFilter, status),
                  })}
                  key={status}
                >
                  <a
                    className={classNames("filter-bar__link", {
                      active: includes(activeFilter, status),
                    })}
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      changeActiveFilter(status);
                    }}
                  >
                    {I18n.t(
                      `js.calendars.appointment.participant_status.${status}`,
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <table className="w-full mt-4">
        <tbody className="divide-y divide-gray-300">
          {map(filteredParticipants, (participant) => (
            <ParticipantRow
              key={participant.id}
              appointmentId={appointmentId}
              reloadAppointment={reloadAppointment}
              {...participant}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}
