import React from "react";
import { useSelector } from "react-redux";

import TixxtModuleSwitcher from "components/layout/TixxtModuleSwitcher";
import {
  ContextNavigation,
  UserbarLink,
  UserbarSection,
} from "components/layout/Userbar";
import GroupCategorySections from "../shared/GroupCategorySections";
import { networkNameSelector } from "../../selectors/environment";
import { useFoldersUnreads } from "../../api/unreads";
import { filter, find, mapValues, compact } from "lodash";
import indexBy from "../../helpers/indexBy";
import useOpenJoinRequestsCount from "components/shared/hooks/useOpenJoinRequests";

export default function FilesUserbar() {
  const networkName = useSelector(networkNameSelector);
  const { data: unreads } = useFoldersUnreads();
  const networkUnreadCount = find(unreads, { group_slug: null })?.sum;
  // Lookup for unread counts of groups
  const unreadCountByGroupSlug: { [slug: string]: number } = mapValues(
    indexBy(
      filter(unreads, ({ group_slug }) => group_slug),
      "group_slug",
    ),
    "sum",
  );

  const openJoinRequestCount = useOpenJoinRequestsCount();

  const getUserbarLinkTitle = (groupSlug) => {
    return compact([
      unreadCountByGroupSlug[groupSlug]
        ? `${I18n.t("js.files.module_name")}: ${
            unreadCountByGroupSlug[groupSlug]
          }`
        : null,
      openJoinRequestCount[groupSlug]
        ? `${I18n.t("js.administration.join_requests.label")}: ${
            openJoinRequestCount[groupSlug]
          }`
        : null,
    ]).join(" , ");
  };

  const unreadSumBySlug = mapValues(
    unreadCountByGroupSlug,
    (value, key) => value + (openJoinRequestCount[key] || 0),
  );

  return (
    <>
      <TixxtModuleSwitcher activeModule={"files"} />
      <ContextNavigation>
        <UserbarSection title={I18n.t("js.userbar.content_section.title")}>
          <UserbarLink to={`/files`} unreads={networkUnreadCount}>
            {networkName}
          </UserbarLink>
        </UserbarSection>
        <GroupCategorySections unreadCountByGroupSlug={unreadSumBySlug}>
          {({ slug, name, favorited }, { collapsed }) => (
            <UserbarLink
              className="relative"
              key={slug}
              to={`/groups/${slug}/files`}
              title={getUserbarLinkTitle(slug)}
              unreads={
                unreadCountByGroupSlug[slug] + (openJoinRequestCount[slug] || 0)
              }
              showWhileClosed={favorited}
              favorited={favorited}
              collapsed={collapsed}
            >
              {name}
            </UserbarLink>
          )}
        </GroupCategorySections>
      </ContextNavigation>
    </>
  );
}
