import React from "react";
import { map, isEmpty } from "lodash";
import moment from "moment";
import {
  useFilesVersionApproval,
  useDeclinePendingFileVersion,
  useApprovePendingFileVersion,
} from "./api";
import FileIcon from "../../shared/FileIcon";

export default function VersionApproval({ groupSlug }) {
  const { isLoading, data: files } = useFilesVersionApproval(groupSlug);

  if (isLoading) {
    return (
      <div>
        <i className="fa fa-spinner fa-spin" /> {I18n.t("js.loading")}
      </div>
    );
  }

  if (isEmpty(files))
    return (
      <div className="well">
        {I18n.t("js.files.approval.review_status.empty_list")}
      </div>
    );

  return map(files, ({ id: fileId, name, pending_versions }) => {
    if (isEmpty(pending_versions)) return null;

    return (
      <div key={fileId} className="mb-6">
        <h4>
          <a
            href={`${groupSlug ? `/groups/${groupSlug}` : ""}/files/${fileId}`}
          >
            {name}
          </a>
        </h4>
        <table className="tixxt-table">
          <thead>
            <tr>
              <th></th>
              <th>{I18n.t("js.files.overview.new_filename")}</th>
              <th>{I18n.t("js.files.overview.created_at")}</th>
              <th>{I18n.t("js.files.overview.author")}</th>
              <th>{I18n.t("js.files.overview.description")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {map(pending_versions, (pendingVersion) => (
              <PendingVersionApprovalRow
                key={pendingVersion.id}
                {...pendingVersion}
                fileId={fileId}
                groupSlug={groupSlug}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  });
}

function PendingVersionApprovalRow({
  fileId,
  groupSlug,
  id,
  extension,
  original_filename,
  created_at,
  author_name,
  description,
}) {
  const { mutate: decline, isLoading: isDeclining } =
    useDeclinePendingFileVersion();
  const { mutate: approve, isLoading: isApproving } =
    useApprovePendingFileVersion();

  const fileUrl = `/api/storage/files/${fileId}/pending_versions/${id}`;
  return (
    <tr key={id}>
      <td className="this-icon">
        <i className="icon icons-file">
          <FileIcon extension={extension} />
        </i>
      </td>
      <td>{original_filename}</td>
      <td>
        {moment(created_at).format(
          I18n.t("js.time.formats.short_date_and_time"),
        )}
      </td>
      <td>{author_name}</td>
      <td>{description}</td>
      <td>
        <div className="whitespace-nowrap text-right">
          <a
            className="mr-2"
            href={`${fileUrl}/get/${original_filename}`}
            title={I18n.t("js.files.show.open_file")}
            target="_blank"
            rel="noreferrer"
          >
            <i className={`fa-regular fa-eye`}></i>
          </a>
          <a
            href={`${fileUrl}/get/${original_filename}?download`}
            title={I18n.t("js.files.show.download_file")}
            aria-label={I18n.t("js.files.show.download_file")}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fa-regular fa-cloud-download"></i>
          </a>
        </div>
      </td>

      <td>
        <div className="btn-group">
          <button
            className="btn btn-primary btn-sm"
            title={I18n.t("js.files.approval.accept_version")}
            onClick={() => {
              approve({
                fileId,
                pendingVersionId: id,
                groupSlug,
                foo: "Bar",
              });
            }}
            disabled={isDeclining || isApproving}
          >
            <i className="fa-regular fa-check"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            title={I18n.t("js.files.approval.decline_version")}
            onClick={() => {
              decline({
                fileId,
                pendingVersionId: id,
                groupSlug,
              });
            }}
            disabled={isDeclining || isApproving}
          >
            <i className="fa-regular fa-xmark"></i>
          </button>
        </div>
      </td>
    </tr>
  );
}
