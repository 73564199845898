import React, { useEffect, useState } from "react";
import PageTitle from "components/layout/PageTitle";
import { useParams } from "helpers/tixxt-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import TixxtPageTitle from "components/layout/TixxtPageTitle";
import { useRecurringAppointment } from "../../hooks/useRecurringAppointment";

type AppointmentTitleData = {
  id: string;
  name: string;
  type: "appointment" | "recurring_appointment" | "virtual_appointment";
};

export function AppointmentPageTitle() {
  const { appointmentId } = useParams();
  const [appointment, setAppointment] = useState<AppointmentTitleData>();

  useEffect(() => {
    const appointments = Tixxt.Calendars.Appointment.all();
    const assignAppointment = (model, data: AppointmentTitleData) => {
      if (data.id == appointmentId) setAppointment(data);
    };
    appointments.on("sync change add reset", assignAppointment);
    return () => appointments.off("sync change add reset", assignAppointment);
  }, [appointmentId]);

  const isRecurring =
    useRecurringAppointment(appointmentId) ||
    appointment?.type == "recurring_appointment" ||
    appointment?.type == "virtual_appointment";

  if (!isRecurring) return <TixxtPageTitle />;

  return (
    <PageTitle title={appointment?.name || I18n.t("js.loading")}>
      <FontAwesomeIcon
        icon={regular("arrows-repeat")}
        title={I18n.t("js.recurring_appointment.icon.title")}
      />
    </PageTitle>
  );
}
