import React from "react";
import { map } from "lodash";

import pickImage from "../../../helpers/pickImage";
import styles from "../activitystreams.module.css";

export interface ImageAttachmentsTypes {
  images: Array<{ sources: string; original_filename: string; id: string }>;
  activityUrl: string;
}

function ImageAttachments({ images, activityUrl }: ImageAttachmentsTypes) {
  return (
    <div className="activity-images attachments">
      <div className="images-view activity-object-image clearfix">
        <div className="image-preview preview preview-large">
          <a
            href={activityUrl}
            aria-label={I18n.t("js.composer.editor.buttons.image_link")}
          >
            <img
              src={pickImage(images[0].sources, [300, 300])}
              alt={images[0].original_filename}
              className={styles.activityImage}
            />
          </a>
        </div>
        <div className="mt-4 flex gap-2">
          {map(images, (img, index: number) =>
            index != 0 && index <= 7 ? (
              <div key={img.id} className="image-preview preview preview-small">
                <img
                  src={pickImage(img.sources, [50, 50])}
                  alt={img.original_filename}
                  className={styles.activityImage}
                />
              </div>
            ) : null,
          )}
          {images.length > 7 ? (
            <div className="image-preview preview preview-small w-[50px] h-[50px] text-center items-center bg-gray-200 rounded">
              <span className="preview-text leading-[50px]">...</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ImageAttachments;
