import type { ChangeEventHandler, MouseEventHandler } from "react";
import React from "react";
import useTooltip from "../../hooks/useTooltip";
import Spectrum from "../shared/Spectrum";
import { mostReadable } from "@ctrl/tinycolor";

type Role = {
  id?: string;
  name?: string;
  slug?: string;
  plural_name?: string;
  color?: string;
  display_type?: string;
};
interface RoleTypes extends Role {
  isEditing?: boolean;
  handleEdit(...args: unknown[]): unknown;
  handleDestroy(...args: unknown[]): unknown;
}

function Role({
  id,
  name,
  slug,
  plural_name,
  color,
  display_type,
  isEditing,
  handleEdit,
  handleDestroy,
}: RoleTypes) {
  const textColor = mostReadable(color || "#FFFFFF", ["white", "black"], {
    includeFallbackColors: false,
  })?.toHexString();

  return (
    <tr>
      <td>{slug}</td>
      <td>{name}</td>
      <td>{plural_name}</td>
      <td>
        <span
          className="label"
          style={{ backgroundColor: color, color: textColor }}
        >
          {color || "-"}
        </span>
      </td>
      <td>
        {I18n.t(
          `js.administration.membership_roles.display_type.${display_type}`,
        )}
      </td>
      <td>
        <div className="btn-group">
          <button
            className="btn"
            onClick={handleEdit(id) as MouseEventHandler}
            disabled={isEditing}
          >
            <i className="fa fa-pencil" />
          </button>
          <button
            className="btn btn-danger"
            onClick={handleDestroy(id) as MouseEventHandler}
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      </td>
    </tr>
  );
}

interface EditRoleTypes extends Role {
  handleChange(...args: unknown[]): (...args: unknown[]) => unknown;
  handleCancelEdit(...args: unknown[]): unknown;
}

function EditRole({
  name,
  plural_name,
  color,
  display_type,
  handleChange,
  handleCancelEdit,
}: EditRoleTypes) {
  return (
    <tr>
      <td>
        <input
          type="text"
          value={I18n.t("js.administration.membership_roles.slug.generation")}
          required
          disabled
        />
      </td>
      <td>
        <input
          type="text"
          onChange={handleChange("name") as ChangeEventHandler}
          value={name || ""}
          required
          autoFocus
        />
      </td>
      <td>
        <input
          type="text"
          onChange={handleChange("plural_name") as ChangeEventHandler}
          value={plural_name || ""}
          required
        />
      </td>
      <td>
        <Spectrum onChange={handleChange("color")} value={color || "#000000"} />
      </td>
      <td>
        <select
          onChange={handleChange("display_type") as ChangeEventHandler}
          value={display_type}
        >
          <option value="default">
            {I18n.t("js.administration.membership_roles.display_type.default")}
          </option>
          <option value="grouped">
            {I18n.t("js.administration.membership_roles.display_type.grouped")}
          </option>
          <option value="hidden">
            {I18n.t("js.administration.membership_roles.display_type.hidden")}
          </option>
        </select>
      </td>
      <td>
        <div className="btn-group">
          <button className="btn" onClick={handleCancelEdit}>
            <i className="fa fa-times" />
          </button>
          <button className="btn btn-success" type="submit">
            <i className="fa fa-save" />
          </button>
        </div>
      </td>
    </tr>
  );
}

interface MembershipRolesManagerTypes {
  roles: Array<Role>;
  editingId?: string;
  isEditing: boolean;
  contextUrl?: string;
  handleNew(...args: unknown[]): unknown;
  handleEdit(...args: unknown[]): unknown;
  handleDestroy(...args: unknown[]): unknown;
  handleChange(...args: unknown[]): (...args: unknown[]) => unknown;
  handleCancelEdit(...args: unknown[]): unknown;
  handleSubmit(...args: unknown[]): unknown;
}

function MembershipRolesManager({
  roles,
  editingId,
  isEditing,
  contextUrl,
  handleNew,
  handleEdit,
  handleDestroy,
  handleChange,
  handleCancelEdit,
  handleSubmit,
}: MembershipRolesManagerTypes) {
  const tooltipRef = useTooltip<HTMLDivElement>();

  return (
    <div ref={tooltipRef}>
      <div className="btn-toolbar">
        <div className="btn-group">
          <a
            className="btn"
            title={I18n.t("js.back")}
            href={`${contextUrl}/administration/members`}
          >
            <i className="fa fa-caret-left" /> <i className="fa fa-bars" />
          </a>
        </div>

        <div className="btn-group pull-right">
          <button
            className="btn btn-primary"
            onClick={handleNew}
            disabled={isEditing}
          >
            <i className="fa fa-plus" />{" "}
            {I18n.t("js.administration.membership_roles.add")}
          </button>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <table className="table membership-roles">
          <thead>
            <tr>
              <th className="text-left">
                {I18n.t("js.administration.membership_roles.slug.label")}
              </th>
              <th className="text-left">
                {I18n.t("js.administration.membership_roles.name.label")}
              </th>
              <th className="text-left">
                {I18n.t("js.administration.membership_roles.plural_name.label")}
              </th>
              <th className="text-left">
                {I18n.t("js.administration.membership_roles.color.label")}
              </th>
              <th
                className="text-left"
                data-bs-toggle="tooltip"
                title={I18n.t(
                  "js.administration.membership_roles.display_type.info",
                )}
              >
                {I18n.t(
                  "js.administration.membership_roles.display_type.label",
                )}
                &nbsp;
              </th>
              <th className="text-left w-[1%]" />
            </tr>
          </thead>

          <tbody>
            {roles.map((role) => {
              return role.id == editingId ? (
                <EditRole
                  key={role.id || "newRole"}
                  handleChange={handleChange}
                  handleCancelEdit={handleCancelEdit}
                  {...role}
                />
              ) : (
                <Role
                  key={role.id}
                  handleEdit={handleEdit}
                  handleDestroy={handleDestroy}
                  isEditing={isEditing}
                  {...role}
                />
              );
            })}
          </tbody>
        </table>
      </form>
    </div>
  );
}

export default MembershipRolesManager;
