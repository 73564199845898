import {
  call,
  apply,
  take,
  actionChannel,
  put,
  cancelled,
  select,
} from "redux-saga/effects";
import { IS_API_REQUEST } from "../../actions/chat";
import { getNetworkUrl } from "../../selectors/chat/application";

// Saga wich handles api requests like getting available chats
// Super simple and only handles GET returning json
export function* handleApiRequests() {
  const apiRequests = yield actionChannel((action) => action[IS_API_REQUEST]);
  try {
    while (true) {
      const action = yield take(apiRequests);
      const { type, endpoint } = action;
      const networkUrl = yield select(getNetworkUrl);
      const url = `${networkUrl}${endpoint}`;

      yield put({ type: `${type}/REQUEST`, url, parentAction: action });

      try {
        const response = yield call(fetch, `${networkUrl}${endpoint}`, {
          credentials: "include",
          headers: { Accept: "application/json" },
        });
        const payload = yield apply(response, Response.prototype.json);
        yield put({
          type: `${type}/SUCCESS`,
          url,
          payload,
          parentAction: action,
        });
      } catch (error) {
        yield put({
          type: `${type}/FAILURE`,
          url,
          error,
          parentAction: action,
        });
      }
    }
  } finally {
    if (yield cancelled()) {
      apiRequests.close();
      console.log("handleApiRequests cancelled");
    }
  }
}
