import React, { Fragment, useEffect } from "react";
import { map, isEmpty, get, filter } from "lodash";
import { SubmissionError, change } from "redux-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "helpers/tixxt-router";
import publishAllVolatile from "helpers/files/publishAllVolatile";
import ReduxForm from "components/shared/reduxForm";
import BackButton from "components/shared/BackButton";
import { useFetchBaseMembershipData } from "actions/memberships";
import { useQueryParams } from "helpers/useQueryParams";
import MessageForm from "./MessageForm";
import { useSendMessage } from "../api";
import { messageInitialValues } from "../message";
import useFileProcessing from "components/shared/hooks/useFileProcessing";

function MessageComposer({ initialValues }: MessageComposerProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useQueryParams();
  const membershipId = params.to;
  const { data } = useFetchBaseMembershipData(membershipId);
  const { mutateAsync: sendMessage } = useSendMessage();

  useEffect(() => {
    if (membershipId && !isEmpty(membershipId)) {
      dispatch(
        change("newMessage", "to", [
          { id: membershipId, displayValue: I18n.t("js.tasks.loading") },
        ]),
      );
    }
  }, []);

  useEffect(() => {
    if (!data) return;

    dispatch(
      change("newMessage", "to", [
        { id: membershipId, displayValue: data.name },
      ]),
    );
  }, [data]);

  useEffect(() => {
    if (!window.isApp) {
      window.addEventListener("beforeunload", onUnload);

      return () => window.removeEventListener("beforeunload", onUnload);
    }
  }, []);

  function onUnload(e) {
    e.preventDefault();
  }

  const fileProcessing = useFileProcessing("newMessage", "attachments");

  return (
    <Fragment>
      {!initialValues ? (
        <div className="px-3 py-2 border-box mb-10">
          <BackButton />
        </div>
      ) : null}
      <ReduxForm
        form="newMessage"
        className="form-horizontal mb-10"
        renderChildren={MessageForm}
        fullWidth={true}
        initialValues={initialValues}
        submitText={I18n.t("js.messages.composer.send")}
        submitIcon="fa fa-paper-plane"
        enableReinitialize
        onSubmit={onSubmit}
        processing={fileProcessing}
        onSubmitSuccess={() => {
          navigate("/messages");
          toastr.success(I18n.t("js.messages.message_sent"));
        }}
        onSubmitFail={(error: Error) =>
          toastr.error(error?.message || I18n.t("js.messages.message_not_sent"))
        }
      />
    </Fragment>
  );

  async function onSubmit(values: Record<PropertyKey, unknown>) {
    interface Recipient {
      type: string;
    }

    if (isEmpty(values.to) && !values.to_all_network_members) {
      throw new SubmissionError({
        message: I18n.t("js.messages.composer.missing_recipient"),
      });
    }

    const attachments = !isEmpty(get(values, "attachments"))
      ? await publishAllVolatile({
          dispatch,
          values: { attachments: get(values, "attachments") },
          storageDirectory: "files",
        })
      : [];

    await sendMessage(
      {
        ...values,
        to: map(
          filter(
            values.to as Record<PropertyKey, unknown>,
            (recipient: Recipient) => recipient.type != "group",
          ),
          "id",
        ),
        to_group: map(
          filter(
            values.to as Record<PropertyKey, unknown>,
            (recipient: Recipient) => recipient.type == "group",
          ),
          "id",
        ),
        ...attachments,
      },
      {
        onError: () => {
          throw new SubmissionError({});
        },
      },
    );
  }
}

interface MessageComposerProps {
  initialValues?: ReturnType<typeof messageInitialValues>;
}

export default MessageComposer;
