import { find, last } from "lodash";
import PropTypes from "prop-types";

const pickImage = (images, [wantedWidth, wantedHeight]) =>
  (
    find(
      images,
      ({ width, height }) => width >= wantedWidth && height >= wantedHeight,
    ) || last(images)
  ).url;

pickImage.imagesPropType = PropTypes.arrayOf(
  PropTypes.shape({
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
  }),
);

export default pickImage;
