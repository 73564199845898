import React from "react";
import { RouteObject } from "helpers/tixxt-router";
import BackboneFeature from "components/tixxt/BackboneFeature";

const directoryRoutes: RouteObject[] = [
  {
    path: "/directory",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  {
    path: "/directory/:category_id",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  {
    path: "/members",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id"]}
      />
    ),
  },
  {
    path: "/groups/:group_id/directory",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  {
    path: "/groups/:group_id/directory/:category_id",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id", "category_id"]}
      />
    ),
  },
  {
    path: "/groups/:group_id/members",
    element: (
      <BackboneFeature
        feature="Directory"
        action="showList"
        paramKeys={["group_id"]}
      />
    ),
  },
];

export default directoryRoutes;
