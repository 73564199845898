import React from "react";
import PropTypes from "prop-types";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import {
  compose,
  setDisplayName,
  withState,
  withProps,
  withHandlers,
  getContext,
} from "recompose";
import { get } from "lodash";
import DragSourceProperty from "./DragSourceProperty";
import DropTargetColumn from "./DropTargetColumn";

const ColumnConfigurator = compose(
  getContext({
    enableLabelHiding: PropTypes.bool,
  }),
  DragDropContext(HTML5Backend),
  withState("selectedColumnProperty", "selectColumnProperty", null), // { index: number, column: char }
  withHandlers({
    addColumnProperty:
      ({ columns, onColumnsUpdate, selectColumnProperty }) =>
      ({ name }, { index, column }) => {
        onColumnsUpdate({
          ...columns,
          [column]: [
            ...(columns[column] || []).slice(0, index),
            { name, show_label: true },
            ...(columns[column] || []).slice(index),
          ],
        });
        selectColumnProperty({ index, column });
      },
    moveColumnProperty:
      ({ columns, onColumnsUpdate, selectColumnProperty }) =>
      (from, to) => {
        const item = { ...columns[from.column][from.index] };
        if (from.column === to.column) {
          if (from.index === to.index) {
            return;
          }

          const column = [...columns[to.column]]; // copy
          column.splice(to.index, 0, column.splice(from.index, 1)[0]); // mutate
          onColumnsUpdate({
            ...columns,
            [to.column]: column,
          });
          selectColumnProperty({
            column: to.column,
            index: to.index > from.index ? to.index - 1 : to.index,
          });
        } else {
          if (to.column === null) {
            onColumnsUpdate({
              ...columns,
              [from.column]: [
                ...columns[from.column].slice(0, from.index),
                ...columns[from.column].slice(from.index + 1),
              ],
            });
          } else {
            onColumnsUpdate({
              ...columns,
              [from.column]: [
                ...columns[from.column].slice(0, from.index),
                ...columns[from.column].slice(from.index + 1),
              ],
              [to.column]: [
                ...(columns[to.column] || []).slice(0, to.index),
                item,
                ...(columns[to.column] || []).slice(to.index),
              ],
            });
          }
        }
      },
    toggleShowLabel:
      ({ columns, onColumnsUpdate, selectedColumnProperty }) =>
      () => {
        const { index, column } = selectedColumnProperty;
        const columnProperty =
          columns[selectedColumnProperty.column][selectedColumnProperty.index];
        onColumnsUpdate({
          ...columns,
          [column]: [
            ...columns[column].slice(0, index),
            { ...columnProperty, show_label: !columnProperty.show_label },
            ...columns[column].slice(index + 1),
          ],
        });
      },
    removeSelectedColumnProperty:
      ({ columns, onColumnsUpdate, selectedColumnProperty }) =>
      () => {
        const { index, column } = selectedColumnProperty;
        onColumnsUpdate({
          ...columns,
          [column]: [
            ...columns[column].slice(0, index),
            ...columns[column].slice(index + 1),
          ],
        });
      },
  }),
  // assigns matching property to column entry
  withProps(({ columns, properties }) => {
    const byName = {};
    properties.forEach(({ name, label, type }) => {
      byName[name] = { name, label, type };
    });

    const columnProperties = _.transform(
      ["a", "b", "c", "d"],
      (result, char) => {
        result[char] = (columns[char] || []).map(({ name, show_label }) => ({
          name,
          show_label,
          property: byName[name],
        }));
      },
      {},
    );

    return {
      properties,
      columns: columnProperties,
    };
  }),
  withProps(({ columns, selectedColumnProperty }) => ({
    selectedColumnProperty:
      selectedColumnProperty &&
      get(columns, [
        selectedColumnProperty.column,
        selectedColumnProperty.index,
      ])
        ? {
            ...columns[selectedColumnProperty.column][
              selectedColumnProperty.index
            ],
            ...selectedColumnProperty,
          }
        : null,
  })),
  setDisplayName("ColumnConfigurator"),
)(
  ({
    layout,
    sourceProperties,
    columns,
    selectedColumnProperty,
    addColumnProperty,
    moveColumnProperty,
    removeSelectedColumnProperty,
    selectColumnProperty,
    toggleShowLabel,
    enableLabelHiding,
  }) => (
    <div className="assign-properties">
      <div className="grid grid-cols-1 grid-rows-[auto,_1fr,_auto] sm:grid-rows-[auto,_1fr] md:grid-rows-1 sm:grid-cols-5 gap-4 mt-4">
        <div className="flex flex-col">
          <h4>
            {I18n.t("js.administration.app_creator.app.item_layout.fields")}
          </h4>
          <div className="properties-source flex flex-col gap-1">
            {sourceProperties.map((property, i) => (
              <DragSourceProperty
                key={i}
                property={property}
                column={null}
                addColumnProperty={addColumnProperty}
              />
            ))}
          </div>
        </div>
        <div className="sm:row-span-2 sm:col-span-4 md:col-span-3 md:row-span-1">
          <h4>
            {I18n.t("js.administration.app_creator.app.item_layout.layout")}
          </h4>
          <div className="flex flex-col gap-2">
            {_.map(layout.split("-"), (row) => (
              <div className="flex gap-2" key={row}>
                {_.map(row, (char) => (
                  <div
                    className="border-box p-2 flex-grow flex flex-col gap-2"
                    key={char}
                  >
                    <div className="text-center font-semibold">
                      {I18n.t(
                        `js.administration.app_creator.app.item_layout.columns.${char}`,
                      )}
                    </div>
                    <DropTargetColumn
                      char={char}
                      column={columns[char]}
                      selectedColumnProperty={selectedColumnProperty}
                      selectColumnProperty={selectColumnProperty}
                      moveColumnProperty={moveColumnProperty}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col flex-grow">
          <h4>
            {I18n.t(
              "js.administration.app_creator.app.item_layout.fieldoptions",
            )}
          </h4>
          {selectedColumnProperty && (
            <div className="property-options flex flex-col gap-2 items-stretch">
              {enableLabelHiding && (
                <label className="checkbox !items-baseline text-sm">
                  <input
                    type="checkbox"
                    onChange={toggleShowLabel}
                    checked={!!selectedColumnProperty.show_label}
                  />
                  {I18n.t(
                    "js.administration.app_creator.app.property.show_fieldname",
                  )}
                </label>
              )}
              <button
                className="btn btn-light btn-sm"
                onClick={removeSelectedColumnProperty}
              >
                {I18n.t(
                  "js.administration.app_creator.app.property.remove_fieldname",
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  ),
);

const propertyShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
});
const columnShape = PropTypes.shape({
  showLabel: PropTypes.bool,
  property: propertyShape,
});
ColumnConfigurator.propTypes = {
  properties: PropTypes.arrayOf(propertyShape).isRequired,
  layout: PropTypes.string.isRequired,
  columns: PropTypes.shape({
    a: PropTypes.arrayOf(columnShape),
    b: PropTypes.arrayOf(columnShape),
    c: PropTypes.arrayOf(columnShape),
    d: PropTypes.arrayOf(columnShape),
  }).isRequired,
  onColumnsUpdate: PropTypes.func.isRequired,
};

export default ColumnConfigurator;
