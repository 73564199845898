import React from "react";

// Method gets default menu-item-name from selected page, app or standard-site.
const getDefaultName = (target) => {
  const selectedIndex = target.selectedIndex;
  return target[selectedIndex].text;
};

interface SelectContentTypes {
  data: {
    label?: string;
    link?: string;
  }[];
  link: string;

  onChange(...args: unknown[]): unknown;
}

function SelectContent({ data, link, onChange }: SelectContentTypes) {
  return (
    <select
      className="input-text form-control"
      onChange={(e) =>
        onChange({ link: e.target.value, text: getDefaultName(e.target) })
      }
      value={link}
    >
      <option value="">
        {I18n.t("js.administration.navigation_items.select_option")}
      </option>
      {data.map((item, index) => (
        <option value={item.link} key={index}>
          {item.label}
        </option>
      ))}
    </select>
  );
}

export default SelectContent;
