import React from "react";
import PropTypes from "prop-types";

const MessageAuthor = ({ id, name, imageUrl }) => (
  <div className="chatMessageAuthor flex gap-2 items-center">
    {imageUrl ? (
      <img
        className="chatMessageImage h-5 w-5 rounded-full"
        alt={name}
        src={imageUrl}
      />
    ) : null}
    <a className={"grow text-sm font-semibold"} href={`/members/${id}`}>
      {name}
    </a>
  </div>
);
MessageAuthor.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
};

export default MessageAuthor;
