import React from "react";
import PageTitle from "../layout/PageTitle";
import Editor from "./propertySets/Editor";
import GroupNavigation from "components/layout/GroupNavigation";

export default function PropertySetEdit({
  groupSlug,
  propertySetName,
}: {
  groupSlug?: string;
  propertySetName: string;
}) {
  return (
    <>
      <PageTitle
        title={I18n.t(
          `js.groups.navigation.edit_${propertySetName}_property_set`,
        )}
      />
      {groupSlug && <GroupNavigation groupSlug={groupSlug} />}
      <Editor
        name={propertySetName}
        contextType={groupSlug ? "group" : "network"}
        contextId={groupSlug}
        key={propertySetName}
      />
    </>
  );
}
