import React from "react";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import MessageComposer from "components/messages/composer";

export default function NewMessagePage() {
  return (
    <>
      <PageTitle title={I18n.t("js.messages.module_name")} />
      <Subtitle className={"mb-3 mt-8"}>
        {I18n.t("js.messages.new.new_message")}
      </Subtitle>
      <MessageComposer />
    </>
  );
}
