import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="external-activitystreams"
export default class extends Controller {
  connect() {
    const popups = [];
    $(".images-view").each(function () {
      popups.push($(this).magnificPopup(window.Lightbox.defaultOptions));
    });
    this.popups = popups;
  }

  disconnect() {
    this.popups.forEach((el) => {
      $(el).magnificPopup("close");
    });
  }
}
