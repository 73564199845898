import { Calendar } from "../../../@types/appointments";
import React, { ReactNode } from "react";
import { isShared } from "components/appointments/calendars/helpers";
import { isEmpty, map } from "lodash";

export default function CalendarListItem({
  calendar,
  actions,
}: {
  calendar?: Calendar;
  actions?: (calendar: Calendar | undefined) => ReactNode[];
}) {
  return (
    <li className="calendar-view flex items-center justify-between gap-2 py-1 px-3 py-2">
      <div className="flex gap-2 items-center">
        <div
          className="cal-include rounded-full w-4 h-4"
          style={{ backgroundColor: `#${calendar?.color}` }}
        />
        {calendar?.name}
        <div className="text-muted">
          {isShared(calendar) &&
            !isEmpty(calendar?.author) &&
            I18n.t("calendars.shared_from", {
              name: calendar?.author.name,
            })}
        </div>
      </div>
      <div className="btn-group">{actions && map(actions(calendar))}</div>
    </li>
  );
}
