import React, { useEffect } from "react";
import InfoUserbar from "components/info/Userbar";

export default function AppCreatorUserbar() {
  useEffect(() => {
    document.body.classList.add("tixxt--network-app");
    return () => document.body.classList.remove("tixxt--network-app");
  }, []);

  return <InfoUserbar />;
}
