import React from "react";
import AppointmentInvite, { AppointmentInviteDate } from "./AppointmentInvite";

export default function RecurringAppointmentInvite(props) {
  return (
    <AppointmentInvite
      renderDate={({ date, frequency }) => (
        <AppointmentInviteDate date={date} frequency={frequency} />
      )}
      {...props}
    />
  );
}
