import React from "react";
import Modal from "components/shared/Modal";
import { stringify } from "query-string";
import { Calendar } from "../../../@types/appointments";
import { find, isEmpty, map, split } from "lodash";
import { groupCalendarsByType } from "components/appointments/calendars/helpers";
import CalendarListItem from "components/appointments/calendars/CalendarListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

const COLLECTION_URL_LOOKUP = {
  all_appointments: "appointments",
  participating_appointments: "appointments/participating",
  personal_calendar: "calendars/my/appointments",
  shared_calendar: "calendars/shared/appointments",
  network_calendar: "calendars/all/appointments",
};

type CalendarSubscribeModalArgs = {
  setShowModal: (showModal: boolean) => void;
  modalOptions?: ModalOptions;
  calendars?: Calendar[];
};

export type ModalOptions = {
  title: string;
  collectionType?: string;
  calendarId?: string;
};

export default function CalendarSubscribeModal({
  setShowModal,
  modalOptions,
  calendars = [],
}: CalendarSubscribeModalArgs) {
  const isCollection = !!modalOptions?.collectionType;
  const isCalendar = !!modalOptions?.calendarId;
  const url = `webcal:${Preload.current_network?.url}/${
    modalOptions?.collectionType
      ? COLLECTION_URL_LOOKUP[modalOptions?.collectionType]
      : `calendars/${modalOptions?.calendarId}/appointments`
  }.ics?${stringify({
    auth_token: Preload.current_member?.authentication_token,
  })}`;

  const groupedCalendars = groupCalendarsByType(calendars);

  let filteredCalendars: (Calendar | undefined)[] = [];

  if (modalOptions?.collectionType) {
    const calendarFilter = split(modalOptions?.collectionType, "_")[0];
    filteredCalendars =
      calendarFilter == "all" ? calendars : groupedCalendars[calendarFilter];
  }

  if (modalOptions?.calendarId) {
    const calendar = find(calendars, { id: modalOptions?.calendarId });
    filteredCalendars = [calendar];
  }

  function copyToClipboard(url: string) {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toastr.success(I18n.t("js.calendars.webcal_url_copied"));
      })
      .catch(() => {
        toastr.error(I18n.t("js.generic_error"));
      });
  }

  return (
    <Modal
      static
      title={modalOptions?.title}
      onClose={() => setShowModal(false)}
    >
      <div className="divider divide-y divide-neutral">
        <div className="mb-4">
          {isCalendar && !isEmpty(filteredCalendars) && (
            <>
              <CalendarListItem calendar={filteredCalendars[0]} />
              <p className="pt-4">
                {I18n.t("calendars.subscriptions.subscribe")}
              </p>
            </>
          )}
          {isCollection && (
            <p>{I18n.t("calendars.subscriptions.collection_subscribe")}</p>
          )}
          <p>
            <a href={url} className="break-words">
              {url}
            </a>
            <button className="btn-small" onClick={() => copyToClipboard(url)}>
              <FontAwesomeIcon className="ml-2" icon={regular("copy")} />
            </button>
          </p>
        </div>
        {isCollection && (
          <div className="pt-4">
            <p>{I18n.t("js.calendars.subscriptions.following_calendars")}:</p>
            {!isEmpty(filteredCalendars) ? (
              <ul>
                {map(
                  filteredCalendars,
                  (calendar) =>
                    calendar && (
                      <CalendarListItem calendar={calendar} key={calendar.id} />
                    ),
                )}
              </ul>
            ) : (
              <p>
                {modalOptions?.collectionType === "participating_appointments"
                  ? I18n.t("js.calendars.subscriptions.no_specific_calendars")
                  : I18n.t("js.calendars.calendar_manager.no_calendars_listed")}
              </p>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
}
