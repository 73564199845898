import { useMutation, useQuery } from "react-query";
import { Calendar } from "../../../@types/appointments";
import { fetchApi } from "helpers/reactQueryApi";

export function useDeleteCalendar(options) {
  return useMutation(
    (id: string) => fetchApi(`/calendars/${id}`, { method: "DELETE" }),
    options,
  );
}
export function useDeleteShare(options) {
  return useMutation(
    (id: string) => fetchApi(`/calendars/${id}/share`, { method: "DELETE" }),
    options,
  );
}

export function useCalendar(id?: string) {
  return useQuery<Calendar>(`/calendars/${id}`, { enabled: !!id });
}

export type CreateCalendarParams = {
  parent_id?: string | string[];
  calendar: {
    name: string;
    color?: string;
    membership_ids?: (string | undefined)[];
  };
};

export function useCreateCalendar(options) {
  return useMutation(
    (params: CreateCalendarParams) =>
      fetchApi(`/calendars`, {
        method: "POST",
        body: params,
      }),
    options,
  );
}

export type UpdateCalendarParams = {
  calendar: {
    id: string;
    name: string;
    color?: string;
    membership_ids?: (string | undefined)[];
  };
};

export function useUpdateCalendar(options) {
  return useMutation(
    (params: UpdateCalendarParams) =>
      fetchApi(`/calendars/${params.calendar.id}`, {
        method: "PUT",
        body: params,
      }),
    options,
  );
}
