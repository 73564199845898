import PropTypes from "prop-types";
import React, { useState } from "react";
import { compose, withState, withHandlers } from "recompose";
import Editor from "@draft-js-plugins/editor";
import { v4 as uuidv4 } from "uuid";

import "@draft-js-plugins/mention/lib/plugin.css";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "draft-js/dist/Draft.css";
import styles from "./index.module.css";
import markdownByEditorState from "components/shared/markdownEditor/utils/markdownByEditorState";
import editorStateByMarkdown from "components/shared/markdownEditor/utils/editorStateByMarkdown";
import createStyleMap from "components/shared/markdownEditor/styleMap";
import withPlugins from "components/activitystreams/composer/withPlugins";
import useInterceptorPrompt from "../../../hooks/useInterceptorPrompt";

const isIE = () => /MSIE|Trident/.test(window.navigator.userAgent);
const isIOS = () => /iPhone|iPad|iOS/.test(window.navigator.userAgent);

function Composer({
  key,
  onEditorStateChange,
  containerClasses,
  editorState,
  onSetEditorRef,
  plugins,
  pluginComponents,
  onClickFullscreen,
  handleFullscreen,
  placeholder,
  focusEditor,
}) {
  const customStyleMap = createStyleMap();

  useInterceptorPrompt(
    I18n.t("js.composer.interceptor_prompt"),
    editorState.getCurrentContent().hasText(),
  );

  return (
    <div
      className={`Composer object-content-input form-control ${containerClasses} px-2 py-1`}
    >
      <div className={"flex flex-col-reverse"}>
        <div onClick={focusEditor}>
          <Editor
            key={`editor-${key}`}
            ref={onSetEditorRef}
            name="content"
            placeholder={placeholder}
            editorState={editorState}
            onChange={onEditorStateChange}
            plugins={plugins}
            customStyleMap={customStyleMap}
            spellCheck
            stripPastedStyles
          />
        </div>
        <div className={"-mx-2 -mt-1 mb-2"}>
          <pluginComponents.Toolbar className={"-px-2"} />
        </div>
      </div>
      <div className="composer-statistics hidden sm:block">
        <ul className="unstyled inline flex justify-between gap-2">
          <div className="flex items-center gap-2">
            <li>
              {I18n.t("js.composer.editor.statistics.lines")}:{" "}
              <pluginComponents.LineCounter />
            </li>
            <li>
              {I18n.t("js.composer.editor.statistics.words")}:{" "}
              <pluginComponents.WordCounter />
            </li>
            <li>
              {I18n.t("js.composer.editor.statistics.chars")}:{" "}
              <pluginComponents.CharCounter />
            </li>
          </div>
          <div className="flex items-center items-stretch gap-2">
            <li className="flex items-center justify-center">
              <pluginComponents.EmojiSelect />
            </li>
            <li
              className="flex items-center justify-center"
              title={I18n.t("js.composer.editor.undo.hint")}
            >
              <pluginComponents.UndoButton />
            </li>
            <li
              className="flex items-center justify-center"
              title={I18n.t("js.composer.editor.redo.hint")}
            >
              <pluginComponents.RedoButton />
            </li>
            {handleFullscreen ? (
              <li title={I18n.t("js.composer.editor.fullscreen.hint")}>
                <button
                  type="button"
                  className="btn btn-sm btn-light"
                  onClick={onClickFullscreen}
                >
                  <i className="fa fa-arrows-alt text-primary" />
                </button>
              </li>
            ) : null}
          </div>
        </ul>
      </div>
      <div style={{ textAlign: "center" }}>
        <pluginComponents.InlineMentionSuggestions key="mentionSuggestions" />
        <pluginComponents.InlineTagSuggestions key="tagSuggestions" />
        <pluginComponents.EmojiSuggestions key="emojiSuggestions" />
      </div>
    </div>
  );
}

Composer.propTypes = {
  key: PropTypes.string,
  content: PropTypes.string,
  onEditorStateChange: PropTypes.func,
  placeholder: PropTypes.string,
  containerClasses: PropTypes.string,
  editorState: PropTypes.object,
  onSetEditorRef: PropTypes.func,
  plugins: PropTypes.array,
  pluginComponents: PropTypes.object,
  onClickFullscreen: PropTypes.func,
  handleFullscreen: PropTypes.func,
  focusEditor: PropTypes.func,
};

export const TixxtComposer = compose(
  withPlugins,
  withState("key", "setKey", () => uuidv4()),
  withState("editorState", "setEditorState", ({ content }) =>
    editorStateByMarkdown(content),
  ),
  withState("editorRef", "setEditorRef", null),
  withHandlers({
    onEditorStateChange:
      ({ onChange, setEditorState }) =>
      (newEditorState) => {
        setTimeout(() => onChange(markdownByEditorState(newEditorState)), 1);
        setEditorState(newEditorState);
      },
    onClickFullscreen:
      ({ handleFullscreen }) =>
      (e) => {
        e.preventDefault();
        handleFullscreen();
      },
    onSetEditorRef:
      ({ setEditorRef, setComposerRefCallback }) =>
      (ref) => {
        if (ref && !isIE() && !isIOS()) {
          setTimeout(() => ref.focus(), 1);
        }
        if (setComposerRefCallback) {
          setComposerRefCallback(ref);
        }
        setEditorRef(ref);
      },
    focusEditor:
      ({ editorRef }) =>
      (e) => {
        e.preventDefault();
        if (editorRef) {
          if (isIOS()) {
            editorRef.focus();
          } else {
            setTimeout(() => editorRef.focus(), 1);
          }
        }
      },
  }),
)(Composer);

export function ClosableTixxtComposer({ onOpen, open, ...props }) {
  const [key] = useState(uuidv4);
  const [closed, setClosed] = useState(!open);

  return closed ? (
    <button
      key="dummy"
      className={`Composer object-content-input form-control closed ${props.containerClasses} ${styles.closed}`}
      onClick={onOpenHandler}
    >
      <span className="placeholder">{props.placeholder}</span>
    </button>
  ) : (
    <TixxtComposer key={key} {...props} />
  );

  function onOpenHandler(e) {
    e.preventDefault();
    setClosed(false);
    onOpen();
  }
}

export default ClosableTixxtComposer;
