import React from "react";
import { Field, FormSection } from "redux-form";
import PropTypes from "prop-types";

import BasicItemForm from "../../appCreator/items/form/BasicItemForm";
import TextField from "../../shared/fields/TextField";

function BasicSettings({
  externalAppId,
  externalItemId,
  initialize,
  initialValues,
}) {
  return (
    <div className="form-horizontal">
      <h3>{I18n.t("js.groups.edit.basic_settings")}</h3>
      <Field type="hidden" component="input" name="group_category_id" />
      <TextField
        name="name"
        label={I18n.t("js.groups.form.name.label")}
        placeholder={I18n.t("groups.form.name.placeholder")}
        required
      />

      <FormSection name="profile">
        <BasicItemForm
          externalAppId={externalAppId}
          externalItemId={externalItemId}
          initialize={(profile, options) =>
            initialize({ ...initialValues, profile }, options)
          }
        />
      </FormSection>
    </div>
  );
}

export default BasicSettings;

BasicSettings.propTypes = {
  externalAppId: PropTypes.string,
  externalItemId: PropTypes.string,
  initialize: PropTypes.func,
  initialValues: PropTypes.object,
};
