import { useEffect } from "react";

let confirmationMessage: string | null = null;

// https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event#examples
const beforeUnloadListener = (event) => {
  event.preventDefault();
  return (event.returnValue = "");
};

export function getConfirmationMessage() {
  return confirmationMessage;
}

function enableInterceptor(message) {
  confirmationMessage = message;
  window.addEventListener("beforeunload", beforeUnloadListener, {
    capture: true,
  });
}

function disableInterceptor() {
  confirmationMessage = null;
  removeEventListener("beforeunload", beforeUnloadListener, { capture: true });
}

export default function useInterceptorPrompt(message: string, when = true) {
  useEffect(() => {
    if (when && !window.isApp) {
      enableInterceptor(message);
    } else {
      disableInterceptor();
    }

    return disableInterceptor;
  }, [message, when]);
}
