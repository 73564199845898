import React from "react";
import { FormSection } from "redux-form";
import { get, uniq } from "lodash";
import ControlGroup from "../fields/ControlGroup";
import Fields from "../../appCreator/items/form/Fields";
import { TagParentsContext } from "../../appCreator/properties/edit/TagField";
import { usePropertySet } from "components/shared/propertySets/api";

interface EmbeddedValueSetFormProps {
  name: string;
  contextId: string;
  contextType: string;
}

function EmbeddedValueSetForm({
  name,
  contextId,
  contextType,
}: EmbeddedValueSetFormProps) {
  const { isLoading: loading, data: propertySet } = usePropertySet({
    name,
    contextId,
    contextType,
  });

  if (loading) {
    return (
      <ControlGroup name={name} label="Wird geladen...">
        <i className="fa fa-spinner fa-spin" />
      </ControlGroup>
    );
  }

  // Always include network scope when looking up tags for file_- and folder_profile
  const tagParents = get(propertySet, ["tag_parents"], []);
  if (name === "file_profile" || name === "folder_profile")
    tagParents.unshift("network");

  return (
    <TagParentsContext.Provider value={uniq(tagParents)}>
      <FormSection name={name}>
        <Fields
          name={name}
          properties={propertySet ? propertySet.properties : null}
          itemId={name}
        />
      </FormSection>
    </TagParentsContext.Provider>
  );
}

export default EmbeddedValueSetForm;
