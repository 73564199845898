import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import AppointmentInvite, { AppointmentInviteDate } from "./AppointmentInvite";

function AppointmentParticipation({
  appointment,
  invited_by,
  status,
  inviteStatus,
}) {
  return !isEmpty(appointment) ? (
    <AppointmentInvite
      status={status}
      author={
        !isEmpty(invited_by)
          ? { id: invited_by.id, name: invited_by.name }
          : null
      }
      inviteStatus={inviteStatus}
      invitable={{
        date: appointment.date,
        id: appointment.id,
        name: appointment.name,
        status: appointment.status,
      }}
      renderDate={(props) => <AppointmentInviteDate {...props} />}
      renderMeta={() => null}
    />
  ) : null;
}
AppointmentParticipation.propTypes = {
  appointment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.shape({
      start: PropTypes.string,
      all_day: PropTypes.bool,
      same_day: PropTypes.bool,
      end: PropTypes.string,
    }),
  }),
  invited_by: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  status: PropTypes.string,
  inviteStatus: PropTypes.string,
};

export default AppointmentParticipation;
