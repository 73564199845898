import React, { Fragment } from "react";
import CheckboxFields from "../../shared/fields/CheckboxFields";
import { compact, includes, get, map } from "lodash";
import PropTypes from "prop-types";

const mapOptions =
  (configCategory, disabled, conditions) => (_value, config) =>
    get(conditions, [configCategory, config], true)
      ? {
          label: `js.memberships.settings.${configCategory}.checkboxes.${config}`,
          value: config,
          disabled: includes(disabled[configCategory], config),
        }
      : null;

function EmailConfigList({ settings, configCategory, conditions, disabled }) {
  return (
    <Fragment>
      <h3>{I18n.t(`js.memberships.settings.${configCategory}.title`)}</h3>
      <p>{I18n.t(`js.memberships.settings.${configCategory}.description`)}</p>
      <CheckboxFields
        name={configCategory}
        options={compact(
          map(
            settings[configCategory],
            mapOptions(configCategory, disabled, conditions),
          ),
        )}
        multiple
      />
    </Fragment>
  );
}

EmailConfigList.propTypes = {
  settings: PropTypes.object,
  configCategory: PropTypes.string,
  conditions: PropTypes.object,
  disabled: PropTypes.object,
};

export default EmailConfigList;
