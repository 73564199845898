import React from "react";

export default function Muted() {
  return (
    <span className="fa-layers absolute bottom-0 left-0 leading-none">
      <i className="fa-solid fa-volume-xmark text-white" />
      <i className="fa-regular fa-volume-xmark text-danger" />
    </span>
  );
}
