import { useQuery, useQueryClient } from "react-query";
import { fetchApi } from "helpers/reactQueryApi";
import { searchUrl } from "./urls";
import { identity, keys, pickBy, mapValues, slice } from "lodash";

type SearchQueryParams = {
  q: string;
  module?: string;
  groupSlug?: string;
};

type SearchResultsPayload = {
  [queryName: string]: {
    hits: {
      id: string;
      // etc...
    }[];
    meta: { _score: number }[];
    total: number;
    per: number;
    page: number;
    sort_by: number;
  };
};

export function useSearchResults(
  query: SearchQueryParams,
  options: { enabled?: boolean } = {},
) {
  const filteredQuery = pickBy(query, identity);
  const url = searchUrl({ ...filteredQuery, format: "json" });

  return useQuery<SearchResultsPayload>(
    ["search", filteredQuery],
    () => fetchApi(url),
    {
      refetchOnWindowFocus: false,
      staleTime: 60_000,
      ...options,
    },
  );
}

// This fetches pages of a single query
// It assumes that a module filter is active
// and updates the query in the module search result in the query cache directly
export function useSearchResultsPage(
  query: {
    query_name: string;
    page: number;
    sort_by?: string | null;
    q: string;
    groupSlug?: string;
  },
  options: {
    enabled?: boolean;
  } = {},
) {
  const queryClient = useQueryClient();
  const filteredQuery = pickBy(query, identity);
  const url = searchUrl(filteredQuery);

  return useQuery<SearchResultsPayload>(url, () => fetchApi(url), {
    refetchOnWindowFocus: false,
    cacheTime: 0,
    ...options,
    onSuccess: (pageData) => {
      const queriesData = queryClient.getQueriesData<SearchResultsPayload>([
        "search",
      ]);
      queriesData.forEach(([key, cacheData]) => {
        const [, cachedQuery] = key as ["search", SearchQueryParams];

        // Update module search result
        if (cachedQuery.module && keys(pageData)[0] in cacheData) {
          queryClient.setQueryData(key, { ...cacheData, ...pageData });
        }

        // Update a matching global search result query
        if (
          !cachedQuery.module &&
          cachedQuery.q === query.q &&
          cachedQuery.groupSlug === query.groupSlug
        ) {
          // Limit to 5 results so it still works with the global search results
          const truncatedPageData = mapValues(
            pageData,
            ({ hits, meta, ...rest }) => ({
              hits: slice(hits, 0, 5),
              meta: slice(meta, 0, 5),
              ...rest,
            }),
          );
          queryClient.setQueryData(key, { ...cacheData, ...truncatedPageData });
        }
      });
    },
  });
}
