import React from "react";
import Hint from "components/shared/fields/Hint";

interface TextFieldTypes {
  name: string;
  onChange?(...args: unknown[]): unknown;
  value?: string;
  hint?: string;
}

function TextField({ name, onChange, value, hint }: TextFieldTypes) {
  return (
    <div>
      <label htmlFor={`widget-${name}-input`}>
        {I18n.t(`js.administration.widgetbars.${name}.label`)}
      </label>
      <input
        id={`widget-${name}-input`}
        onChange={onChange}
        type="text"
        value={value || ""}
        height={5}
      />
      <Hint hint={hint} className="!mb-0" />
    </div>
  );
}

export default TextField;
