import { useEffect, useRef } from "react";
import { omit } from "lodash";
import {
  storeOverrideTheme,
  useTheme,
} from "components/administration/designManager/useTheme";
import { DerivedTheme } from "components/administration/designManager/types";
import themeToCss from "components/administration/designManager/themeToCss";

function applyStyleOverride(
  styleEl: React.MutableRefObject<HTMLStyleElement | undefined>,
  theme: DerivedTheme,
) {
  /* Create style tag to apply theme overrides */
  if (!styleEl.current) {
    styleEl.current = document.createElement("style");
    styleEl.current.type = "text/css";
    styleEl.current.setAttribute("data-theme-source", "override");
  }
  styleEl.current.textContent = themeToCss(theme);
  if (!styleEl.current.isConnected) document.head.appendChild(styleEl.current);
}

function applyFaviconOverride(
  linkEl: React.MutableRefObject<HTMLLinkElement | undefined>,
  theme: DerivedTheme,
) {
  /* Create link tag to apply favicon override */
  if (!linkEl.current) {
    linkEl.current = document.createElement("link");
    linkEl.current.setAttribute("data-theme-source", "override");
  }
  if (theme.faviconImage) {
    linkEl.current.href = `/api/storage/assets/${theme.faviconImage.id}/preview`;
    if (theme.faviconImage.extension == "ico") {
      linkEl.current.rel = "shortcut icon";
      linkEl.current.type = "image/x-icon";
    } else {
      linkEl.current.rel = "icon";
      linkEl.current.type = "image/png";
    }
    if (!linkEl.current.isConnected) document.head.appendChild(linkEl.current);
  } else {
    if (linkEl.current.isConnected) document.head.removeChild(linkEl.current);
  }
}

function removeStyleOverride(
  styleEl: React.MutableRefObject<HTMLStyleElement | undefined>,
) {
  if (styleEl.current) document.head.removeChild(styleEl.current);
}

export default function ApplyOverrideTheme() {
  const theme = useTheme();
  const styleEl = useRef<HTMLStyleElement>();
  const faviconEl = useRef<HTMLLinkElement>();

  useEffect(() => {
    if (theme.override) {
      applyStyleOverride(styleEl, theme);
      applyFaviconOverride(faviconEl, theme);
      storeOverrideTheme(omit(theme, ["derivedColors"]));
    } else if (styleEl.current?.isConnected) {
      removeStyleOverride(styleEl);
      storeOverrideTheme(null);
    }

    function toggleBodyClasses(event) {
      event.detail.newBody.classList.toggle(
        "tixxt--full",
        theme?.fullWidth === true,
      );
    }

    document.addEventListener("turbo:before-render", toggleBodyClasses);
    toggleBodyClasses({ detail: { newBody: document.body } });

    return () => {
      document.removeEventListener("turbo:before-render", toggleBodyClasses);
    };
  }, [theme]);

  return null;
}
