import React from "react";
import { map } from "lodash";

import pickImage from "../../../helpers/pickImage";

export interface MentionAttachmentsTypes {
  mentions: Array<{
    id: string;
    html_url: string;
    display_name: string;
    images: Array<{
      width: number;
      height: number;
      url: string;
    }>;
  }>;
}

function MentionAttachments({ mentions }: MentionAttachmentsTypes) {
  return (
    <div className="activity-people attachments">
      <ul className="people-container activity-section empty:hidden !border-0">
        {map(mentions, (m) => (
          <li key={m.id} className="mb-2 last:mb-0">
            <a
              className="flex flex-1 gap-2 member-link"
              href={m.html_url}
              title={m.display_name}
            >
              <img src={pickImage(m.images, [25, 25])} alt={m.display_name} />
              {m.display_name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default MentionAttachments;
