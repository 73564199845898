import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { light, duotone } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useSelector } from "react-redux";
import Notification from "components/chat/shared/Notification";
import { getAggregatedUnreadCount } from "selectors/chat/chat";
import { toggleChatbar } from "../../../layout/togglebar";

export default function ChatButton({ duo }: { duo?: boolean }) {
  const chatCount = useSelector(getAggregatedUnreadCount);

  return (
    <button className="header__link relative" onClick={toggleChatbar}>
      <FontAwesomeIcon
        className="chat__icon fa-xl"
        icon={duo ? duotone("comments") : light("comments")}
      />
      <Notification counter={chatCount} className={"!absolute top-3 right-2"} />
    </button>
  );
}
