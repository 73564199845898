import React from "react";
import PageTitle, { Subtitle } from "components/layout/PageTitle";
import MessagesList from "components/messages/MessagesList";
import { InboxToolbar } from "components/messages/InboxPage";

export default function SentPage() {
  return (
    <>
      <PageTitle title={I18n.t("js.messages.module_name")} />
      <Subtitle className={"mb-3 mt-8"}>
        {I18n.t("js.messages.sent.title")}
      </Subtitle>
      <InboxToolbar className={"mb-10"} />
      <MessagesList filter="sent" />
    </>
  );
}
