import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import { isEmpty } from "lodash";
import preventDefault from "../../../../helpers/components/preventDefault";

export default function Title({
  title,
  date,
  cardId,
  toggleArchive,
  isArchive,
  isUnassigned,
}) {
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  return (
    <div className="card-title flex justify-between">
      <div className="card-title-wrapper">
        <a
          className="title-text font-semibold line-clamp-2"
          href={isArchive ? `${cardId}` : `items/${cardId}`}
        >
          {title}
        </a>
        {date && !isEmpty(date.value) ? (
          <p>
            <strong>{date.label}:</strong>{" "}
            {moment(date.value).format(date.format)}
          </p>
        ) : null}
      </div>

      {!isUnassigned ? (
        <div
          className="card-actions"
          onBlur={() => (showMenu ? setTimeout(toggleMenu, 250) : null)}
        >
          <a
            href="#"
            onClick={preventDefault(toggleMenu)}
            className="dropdown-toggle show-card-details text-muted"
          >
            <i className="fa fa-ellipsis-h show-card-details-icon" />
          </a>
          <ul
            className="dropdown-menu pull-right"
            style={{ display: showMenu ? "inherit" : "none" }}
          >
            <li>
              <a href={isArchive ? cardId : `items/${cardId}`}>
                {I18n.t("js.apps.wall.show")}
              </a>
              <a href={isArchive ? `${cardId}/edit` : `items/${cardId}/edit`}>
                {I18n.t("js.apps.wall.edit")}
              </a>
              <a onClick={toggleArchive} href="#">
                {isArchive
                  ? I18n.t("js.apps.wall.restore")
                  : I18n.t("js.apps.wall.archive")}
              </a>
            </li>
          </ul>
        </div>
      ) : null}
    </div>
  );
}
Title.propTypes = {
  title: PropTypes.string,
  date: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      format: PropTypes.string,
    }),
    PropTypes.string,
  ]),
  cardId: PropTypes.string,
  toggleMenu: PropTypes.func,
  onSetShowMenuRef: PropTypes.func,
  toggleArchive: PropTypes.func,
  showMenu: PropTypes.bool,
  isArchive: PropTypes.bool,
  isUnassigned: PropTypes.bool,
};
