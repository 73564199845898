import documentReady from "./documentReady";
import Modal from "bootstrap/js/dist/modal";

/* This is used to show the modal from app/views/shared/_cookie_modal.html.haml */
export default function showCookieModal() {
  let cookieModal;

  documentReady(function () {
    const cookieModalElement = document.getElementById(
      "cookieModal",
    ) as HTMLElement;
    cookieModal = new Modal(cookieModalElement, {
      keyboard: false,
      backdrop: "static",
    });

    cookieModalElement.addEventListener("click", modalCloser);
    cookieModal.show();
  });

  function modalCloser() {
    document.cookie = "modal=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    cookieModal.hide();
  }
}
