import React, { useEffect, useState } from "react";

import More from "./form/types/More";
import Default from "./form/types/Default";
import Link from "./form/types/Link";
import IconSelection from "./IconSelection";
import TypeSelector from "./form/TypeSelector";
import Page from "./form/types/Page";
import { Item } from "../../../@types/appNavigation";
import Activitystream from "./form/types/Activitystream";
import { TreeItem } from "react-sortable-tree";

type TypeComponentLookup = {
  [moduleName: string]: ((any) => JSX.Element) | undefined;
};
const TYPE_LOOKUP: TypeComponentLookup = {
  more: More,
  files: Default,
  messages: Default,
  appointments: Default,
  pages: Default,
  activitystreams: Default,
  search: Default,
  directory: Default,
  settings: Default,
  link: Link,
  page: Page,
  activitystream: Activitystream,
  default: Default,
};

type Props = {
  item: TreeItem & {
    changedToDefault?: boolean;
    type: any;
    options: { items: (TreeItem | Item)[] };
  };
  unusedDefaults: (TreeItem | Item)[];
  changeActiveNode: (item: TreeItem) => void;
  changeEntryPoint: (item: TreeItem, checked: boolean) => void;
};

function ItemForm({
  item,
  unusedDefaults,
  changeActiveNode,
  changeEntryPoint,
}: Props) {
  const TypeForm = TYPE_LOOKUP[item.type] || Default;
  const [entryPoint, setEntryPoint] = useState(item?.isEntryPoint || false);

  useEffect(() => {
    setEntryPoint(item?.isEntryPoint || false);
  }, [item]);

  return item ? (
    <div className="navigation-manager-form">
      <form>
        {item.type != "more" && (
          <TypeSelector
            type={item.type}
            isDefault={item.isDefault}
            changeType={(changedItem) => {
              changeActiveNode({
                ...item,
                ...changedItem,
              });
            }}
          />
        )}
        <TypeForm
          {...item}
          unusedDefaults={unusedDefaults as any}
          onChange={(props: any) => changeActiveNode({ ...item, ...props })}
        />
        <label>
          {I18n.t("js.administration.app_navigation_items.edit.title")}
        </label>
        <input
          className="input-text"
          value={item.label}
          disabled={item.isDefault}
          onChange={(e) => changeActiveNode({ ...item, label: e.target.value })}
        />
        {item?.isDefault && (
          <p>
            {I18n.t("js.administration.app_navigation_items.edit.hint_no_edit")}
          </p>
        )}
        {!item?.isDefault && (
          <IconSelection
            icon={item.icon}
            changeIcon={(icon: string) => changeActiveNode({ ...item, icon })}
            disabled={item.isDefault}
          />
        )}
        {item.type !== "more" && item.type !== "link" && (
          <label className="checkbox">
            <input
              type="checkbox"
              name="entry_point"
              checked={entryPoint}
              onChange={(e) => {
                setEntryPoint(e.target.checked);
                changeEntryPoint(item, e.target.checked);
              }}
            />
            {I18n.t("js.administration.app_navigation_items.edit.entry_point")}
          </label>
        )}
      </form>
    </div>
  ) : (
    <p>{I18n.t("js.administration.app_navigation_items.edit.hint")}</p>
  );
}

export default ItemForm;
