import { combineReducers } from "redux";
import identity from "lodash/fp/identity";
import get from "lodash/fp/get";
import flow from "lodash/fp/flow";
import groupBy from "lodash/fp/groupBy";
import chats from "./chats";
import messages from "./messages";
import session from "./session";
import composer from "./composer";
import apiRequests from "./apiRequests";
import composing from "./composing";
import homies from "./homies";

export const transformPayload = (
  type,
  payloadTransformer,
  defaultState = null,
) => {
  return (state = defaultState, action) => {
    if (action.type !== type) {
      return state;
    }
    return payloadTransformer(action.payload);
  };
};

const getGroupCategoryName = get(["category", "plural_name"]);
const categoryOrMemberType = (memberOrGroup) =>
  memberOrGroup.type === "member"
    ? "member"
    : getGroupCategoryName(memberOrGroup);

const chatReducer = combineReducers({
  chats,
  session,
  messages,
  composer,
  apiRequests,
  profile: transformPayload("chat/MY_PROFILE/REPLY", get("profile")),
  navigation: transformPayload("chat/NAVIGATE", identity, { route: "root" }),
  presence: combineReducers({
    onlineMembers: transformPayload("chat/PRESENCE/SYNC", get("onlineMembers")),
    busyMembers: transformPayload("chat/PRESENCE/SYNC", get("busyMembers")),
  }),
  availableChats: transformPayload(
    "chat/AVAILABLE_CHATS/SUCCESS",
    flow(get("entries"), groupBy(categoryOrMemberType)),
    {},
  ),
  composing,
  homies,
});

export default chatReducer;
