import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="administration--newsletter--unlayer-proxy"
export default class extends Controller {
  connect() {
    this.setupIframe();
    window.addEventListener("message", this.onMessage);
    this.element.addEventListener("submit", this.onSubmit);
    this.allSubmitsDisabled = true;
  }

  disconnect() {
    window.removeEventListener("message", this.onMessage);
    this.element.removeEventListener("submit", this.onSubmit);
  }

  setupIframe() {
    this.iframe = this.element.querySelector("iframe[data-target-origin]");
    this.targetOrigin = this.iframe.dataset.targetOrigin;
    this.storageToken = this.iframe.dataset.storageToken;
    this.projectId = this.iframe.dataset.projectId;
    this.iframe.src =
      this.targetOrigin + "/unlayer_editor.html?_=" + new Date().getTime();
  }

  onMessage = (event) => {
    if (
      event.source !== this.iframe.contentWindow ||
      event.data.source !== "unlayer_editor"
    )
      return;

    if (event.data.event === "ready") {
      let design = this.element.querySelector(
        'input[name="newsletter_unlayer_template[design]"]',
      ).value;
      try {
        if (design && design.length > 0) design = JSON.parse(design);
      } catch (e) {
        console.warn("Failed parsing design json:", design);
      }

      const unlayerLocale = {
        de: "de-DE",
        en: "en-US",
        es: "es-ES",
        fr: "fr-FR",
      };

      this.postMessage({
        method: "init",
        design,
        projectId: this.projectId,

        translations: {
          tixxt_content_placeholder: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.tixxt_content_placeholder",
          ),
          salutation: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.salutation",
          ),
          salutation_sample: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.salutation_sample",
          ),
          disclaimer_and_settings: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.disclaimer_and_settings",
          ),
          disclaimer_and_settings_sample: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.disclaimer_and_settings_sample",
          ),
          published_in_preview_info: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.published_in_preview_info",
          ),
          published_in_preview_date: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.published_in_preview_date",
          ),
          published_in_preview_published_in: ` ${I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.published_in_preview_published_in",
          )} `,
          published_in_preview_streams: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.published_in_preview_streams",
          ),
          link_color_published_in_label: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.link_color_published_in_label",
          ),
          text_color_published_in_label: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.text_color_published_in_label",
          ),
          colors_group_title: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.colors_group_label",
          ),
          tixxt_content_label: I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.tixxt_content_label",
          ),
        },
        network: {
          host: location.host,
          imprint: this.data.get("imprint"),
          // the split is needed because of custom locales i.e de_bitkom
          locale: unlayerLocale[I18n.locale.split("_")[0]],
        },

        storageToken: this.storageToken,
      });
      this.allSubmitsDisabled = false;
    }
    if (event.data.event === "exportHtml:done") {
      if (this.resolveExportHtml) {
        this.resolveExportHtml(event.data.payload);
        this.resolveExportHtml = null;
      }
    }
  };

  postMessage = (payload) => {
    this.iframe.contentWindow.postMessage(
      { ...payload, source: "tixxt" },
      this.targetOrigin,
    );
  };

  onSubmit = async (event) => {
    if (this.submitting) return;

    this.submitting = true;
    event.stopPropagation();
    event.preventDefault();
    this.allSubmitsDisabled = true;
    try {
      const { design, chunks } = await new Promise((resolve) => {
        this.resolveExportHtml = resolve;
        this.postMessage({ method: "exportHtml" });
      });

      const values = {
        ...chunks,
        design: JSON.stringify(design),
        fonts: JSON.stringify(chunks.fonts),
      };
      ["design", "body", "css", "js", "fonts"].forEach((field) => {
        const name = `newsletter_unlayer_template[${field}]`;
        this.element.querySelector(`input[name="${name}"]`).value =
          values[field];
      });

      const errors = [];
      if (countOccurrences(values.body, "{TIXXT_CONTENT}") !== 1) {
        errors.push(
          I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.tixxt_content_missing_error",
          ),
        );
      }
      if (countOccurrences(values.body, "{{disclaimer_and_settings}}") < 1) {
        errors.push(
          I18n.t(
            "js.administration.newsletter.unlayer_embed.editor.disclaimer_and_settings_missing_error",
          ),
        );
      }
      if (errors.length > 0) {
        toastr.error(errors.join("<br/><br/>"));
        this.allSubmitsDisabled = false;
        return;
      }

      this.allSubmitsDisabled = false;
      event.submitter.click();
      this.allSubmitsDisabled = true;
    } catch (e) {
      console.error(e);
      this.submitting = false;
      this.allSubmitsDisabled = false;
    }
  };

  set allSubmitsDisabled(value) {
    this.element.querySelectorAll("[type=submit]").forEach(function (button) {
      button.disabled = value;
    });
  }
}

function countOccurrences(content, search) {
  const matches = content.match(new RegExp(search, "g"));
  return matches ? matches.length : 0;
}
