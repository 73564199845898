import React from "react";

import { TixxtComposer } from "./index";
import styles from "../../appCreator/properties/edit/editFields.module.css";
import FieldError from "../../appCreator/items/form/FieldError";

interface TixxtComposerAdapterTypes {
  input: {
    name: string;
    value: string;
    onChange(...args: unknown[]): unknown;
    onFocus(...args: unknown[]): unknown;
    onBlur(...args: unknown[]): unknown;
  };
  meta: { error: string; initial: string };
  disabled: boolean;
  id: string;
}

function TixxtComposerAdapter({
  input,
  meta,
  ...props
}: TixxtComposerAdapterTypes) {
  return (
    <div className={`${styles.Text} property-${input.name}`}>
      <TixxtComposer
        {...props}
        {...input}
        key={meta.initial} // Recreate TixxtComposer component if the initialValue has changed
        content={input.value}
        onChange={input.onChange}
        onFocus={input.onFocus}
        onBlur={input.onBlur}
      />
      {meta.error && <FieldError error={meta.error} />}
    </div>
  );
}

export default TixxtComposerAdapter;
