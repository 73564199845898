import { get, intersection, keyBy, keys, map, uniqBy } from "lodash";

// Create a deep merged profile property set from file and folder profile which contains all fields from both profiles
// It tries to merge options or solve all conflicts in favor of the fileProfile
//
// @param [Array] fileProfileProps
// @param [Array] folderProfileProps
//
// @return [Object] mergedProfile object indexed by name key
const mergeFileAndFolderProfiles = (fileProfileProps, folderProfileProps) => {
  const keyedFileProfile = keyBy(fileProfileProps, "name");
  const keyedFolderProfile = keyBy(folderProfileProps, "name");

  const intersectionProfileKeys = intersection(
    keys(keyedFileProfile),
    keys(keyedFolderProfile),
  );

  return {
    ...keyedFolderProfile, // folderProfile first, then overwrite with fileProfile
    ...keyedFileProfile,
    ...keyBy(
      map(intersectionProfileKeys, (key) => {
        // in both profiles the field is a selection => merge options but prefer props (like label) from fileProfile
        if (
          get(keyedFileProfile, [key, "type"]) === "selection" &&
          get(keyedFolderProfile, [key, "type"]) === "selection"
        ) {
          return {
            ...keyedFolderProfile[key],
            ...keyedFileProfile[key],
            options: [
              ...uniqBy(
                [
                  ...get(keyedFileProfile, [key, "options"], []), // fileProfile first for uniqBy
                  ...get(keyedFolderProfile, [key, "options"], []),
                ],
                (o) => o["value"],
              ),
            ],
          };
        }

        // all other cases => prefer fileProfile
        return keyedFileProfile[key];
      }),
      "name",
    ),
  };
};

export default mergeFileAndFolderProfiles;
