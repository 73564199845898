import React from "react";
import { connect } from "react-redux";
import { map, get, isEmpty } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import classNames from "classnames";

import { fetchAvailableChats } from "../../../actions/chat";
import { getApiRequestState } from "../../../selectors/chat/apiRequests";
import ChatHeader from "../shared/ChatHeader";
import GroupedItems from "../shared/GroupedItems";
import Tile from "./Tile";
import { BackToRootButton } from "../shared/BackButton";

class NewChat extends React.Component {
  render() {
    const { member, ...groupsByCategoryName } = this.props.availableChats || {};

    return (
      <>
        <ChatHeader
          title={I18n.t("js.chat.new.headline")}
          ButtonLeft={<BackToRootButton />}
        />
        <form
          className={classNames("px-3 py-2 chatNewForm flex overflow-visible", {
            "mt-14": window.isApp,
          })}
          onSubmit={this.submitSearch}
        >
          <div className={"input-group"}>
            <input
              type="text"
              name="q"
              placeholder={I18n.t("js.chat.new.search_placeholder")}
              autoFocus
              className={"grow"}
            />
            <button
              className="btn btn-primary"
              type="submit"
              disabled={this.props.requestState === "request"}
            >
              {this.props.requestState === "request" ? (
                <FontAwesomeIcon
                  className={"fa-spin"}
                  icon={regular("spinner")}
                />
              ) : (
                <FontAwesomeIcon icon={regular("search")} />
              )}
            </button>
          </div>
        </form>
        {isEmpty(member) ? null : (
          <GroupedItems
            key="members"
            items={member}
            renderItemComponent={(item, index) => (
              <Tile key={index} type="member" {...item} />
            )}
            title={I18n.t("js.chat.new.users")}
            allowToggle={true}
          />
        )}
        {map(groupsByCategoryName, (groups, name) => [
          <GroupedItems
            key={name}
            items={groups}
            renderItemComponent={(item, index) => (
              <Tile key={index} type="group" {...item} />
            )}
            title={name}
            allowToggle={true}
          />,
        ])}
      </>
    );
  }

  componentDidMount() {
    this.props.fetchAvailableChats("");
  }

  submitSearch = (e) => {
    e.preventDefault();
    this.props.fetchAvailableChats(e.currentTarget.elements.q.value);
  };
}

export default connect(
  (state) => ({
    availableChats: get(state, ["chat", "availableChats"]),
    requestState: getApiRequestState(state, fetchAvailableChats),
  }),
  (dispatch) => ({
    fetchAvailableChats: (q) => dispatch(fetchAvailableChats({ q })),
  }),
)(NewChat);
