import lodash from "lodash";

/* This is here to make legacy Backbone(.Marionette) work with modern lodash */

function compatThisArg(fn) {
  return function (collection, callback, thisArg) {
    if (lodash.isFunction(callback)) {
      return fn(collection, lodash.bind(callback, thisArg));
    } else {
      return fn(collection, callback);
    }
  };
}

// Create copy of lodash that can be modified independently
const customLodashCompat = lodash.runInContext();

// Add some aliases for backwards compat
customLodashCompat.mixin({
  any: lodash.some,
  contains: lodash.includes,
  pairs: lodash.toPairs,
  rest: lodash.tail,

  // These functions had a third thisArg in old lodash
  sortBy: compatThisArg(lodash.sortBy),
  forEach: compatThisArg(lodash.forEach),
  each: compatThisArg(lodash.forEach),
  map: compatThisArg(lodash.map),
});

// Disable chaining for some functions, making them return a value instead of the wrapped object
customLodashCompat.mixin(
  {
    // _([1,2,3]).first() returned 1 in old lodash, in modern lodash another call to value() is required
    first: lodash.head,
  },
  { chain: false },
);

// forEach is lazily evaluated when used in chain in modern lodash
// This prevents iterations in templates like
//   file.folders().each ->
//     # something haml
// from rendering
const lazyForEach = customLodashCompat.prototype.forEach;
customLodashCompat.prototype.each = customLodashCompat.prototype.forEach =
  function (...args) {
    lazyForEach.apply(this, args).value(); // run whole chain immediately
    return this; // return original object for further chaining
  };

export default customLodashCompat;
