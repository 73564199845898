import { convertRoutes } from "components/tixxt/BackboneFeature";
import { RouteObject } from "helpers/tixxt-router";
import React from "react";
import AppointmentsPage from "components/appointments/AppointmentPage";
import ManageCalendarsPage from "components/appointments/calendars/ManageCalendarsPage";
import SubscribeCalendarsPage from "components/appointments/calendars/SubscribeCalendarsPage";
import ParticipantsView from "components/appointments/participants/ParticipantsView";
import NewCalendarPage from "components/appointments/calendars/NewCalendarPage";
import EditCalendarPage from "components/appointments/calendars/EditCalendarPage";

const backboneRoutes: RouteObject[] = convertRoutes({
  feature: "Calendars",
  featureRoutes: {
    "calendars/my": "showMyCalendars",
    "calendars/shared": "showSharedCalendars",
    "appointments/invites": "showAppointmentInvites",
    "appointments/invites/archive": "showAppointmentInvitesArchive",
    "appointments/new": "newAppointment",
    "appointments/:id": "showAppointment",
    "appointments/:id/edit": "editAppointment",
    "appointments/:id/contact": "appointmentContact",
  },
});

const reactRoutes: RouteObject[] = [
  { path: "/groups/:groupSlug/calendars", element: <AppointmentsPage /> },
  { path: "/appointments", element: <AppointmentsPage /> },
  {
    path: "/appointments/participating",
    element: <AppointmentsPage participating />,
  },
  {
    path: "/calendars/manage",
    element: <ManageCalendarsPage />,
  },
  {
    path: "/calendars/subscribe",
    element: <SubscribeCalendarsPage />,
  },
  {
    path: "/calendars/new",
    element: <NewCalendarPage />,
  },
  {
    path: "/calendars/:id/edit",
    element: <EditCalendarPage />,
  },
  {
    path: "/appointments/:id/participants",
    element: <ParticipantsView />,
  },
];

export default reactRoutes.concat(backboneRoutes);
