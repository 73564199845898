import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="shared--select-all"
export default class extends Controller {
  selectAllSelector = "[type=checkbox][data-shared--select-all-targets-param]";

  connect() {
    this.element.addEventListener("input", this.#onInput);
  }

  disconnect() {
    this.element.removeEventListener("input", this.#onInput);
  }

  // Main action for select-all checkbox which specifies targets as param
  // Toggles all targets
  toggle(event) {
    this.element.querySelectorAll(event.params.targets).forEach((input) => {
      if (!this.#valid(input)) return;
      input.checked = event.target.checked;
    });
  }

  get #selectAllCheckboxes() {
    return this.element.querySelectorAll(this.selectAllSelector);
  }

  #onInput = (event) => {
    if (!this.#valid(event.target)) return;

    // select all checkbox clicked, do nothing
    if (event.target.matches(this.selectAllSelector)) return;

    // iterate over all select all checkboxes and update their state if event was triggered by a target
    this.#selectAllCheckboxes.forEach((selectAll) => {
      if (
        event.target.matches(selectAll.dataset["shared-SelectAllTargetsParam"])
      ) {
        this.#determineAllChecked(selectAll);
      }
    });
  };

  #valid = (input) => input.type === "checkbox" && !input.disabled;

  #determineAllChecked = (selectAll) => {
    const checkboxes = Array.from(
      this.element.querySelectorAll(
        selectAll.dataset["shared-SelectAllTargetsParam"],
      ),
    ).filter(this.#valid);
    selectAll.checked = checkboxes.every((input) => input.checked);
    selectAll.indeterminate =
      !selectAll.checked && checkboxes.some((input) => input.checked);
  };
}
