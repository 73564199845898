import React, { useEffect, useState } from "react";
import { find, isEmpty, map } from "lodash";
import { usePages } from "../../../../../hooks/administration/appNavigation";
import {
  Page as PageType,
  PageOptions,
} from "../../../../../@types/appNavigation";

type Props = {
  options: PageOptions;
  onChange: (props: { label: string; options: PageOptions }) => void;
};

function Page({ options, onChange }: Props) {
  const { data: pages, isLoading } = usePages();
  const [selectedPage, selectPage] = useState<PageType>(options?.page);

  useEffect(() => {
    if (pages && isEmpty(options?.page)) {
      onChange({
        label: pages[0].name,
        options: { page: pages[0] },
      });
    }
  }, [pages]);

  return (
    <>
      {isLoading ? (
        <p className="input-text">
          <i className="fa fa-spinner spin" />{" "}
          {I18n.t("js.administration.app_navigation_items.edit.loading_pages")}
        </p>
      ) : (
        <select
          className="input-text"
          name="page"
          onChange={(e) => {
            const page = find(pages, { slug: e.target.value });

            if (page) {
              selectPage(page);
              onChange({
                label: page.name,
                options: { page },
              });
            }
          }}
          value={selectedPage?.slug}
        >
          {map(pages, (page: PageType) => (
            <option value={page.slug} key={page.id}>
              {page.name}
            </option>
          ))}
        </select>
      )}
      <p>
        {I18n.t("js.administration.app_navigation_items.edit.hint_group_page")}
      </p>
    </>
  );
}

export default Page;
